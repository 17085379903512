import { cloneDeep, isEqual } from "lodash"
import { Expression, Filter, Query } from "components/common/TabularView/types"

// TODO: remove redundant implementations of this method
export const makeUndirectedGraph = (data?: { [key: string]: string[] }) => {
  if (!data) {
    return {}
  }

  const undirectedGraph: { [key: string]: string[] } = {}

  Object.keys(data).forEach((key: string) => {
    data[key].forEach((value: string) => {
      undirectedGraph[key] = [value]
      undirectedGraph[value] = [key]
    })
  })

  return undirectedGraph
}

// TODO: This behavior was disabled at some point but it should be restored
// as part of the UI code determining which operations to allow the user to
// select.
export const getOperationsToDedupe = (operation?: string): string[] => {
  if (!operation) {
    return []
  }

  const DEDUPLICATION_PAIRS = makeUndirectedGraph({
    is_empty: ["is_not_empty"],
    is_exactly: ["is_not_exactly"],
    is_true: ["is_false"],
  })

  return DEDUPLICATION_PAIRS[operation] || []
}

const stringifyAndSortFilters = (filters?: Filter[] | null): string => {
  if (!filters) {
    return ""
  }
  const funcToComparable = (func: Filter) =>
    JSON.stringify([func.field, func.operands, func.operation])
  return filters.map(funcToComparable).sort().join(",")
}

const stringifyAndSortExpressions = (
  expressions?: Expression[] | null
): string => {
  if (!expressions) {
    return ""
  }
  return expressions.map(stringifyExpression).sort().join(",")
}

const sortExpressions = (expressions: Expression[]) => {
  expressions.sort((expr1, expr2) => {
    const compareOperator = expr1.operator.localeCompare(expr2.operator)

    const filtersStr1 = stringifyAndSortFilters(expr1.filters)
    const filtersStr2 = stringifyAndSortFilters(expr2.filters)
    const compareFilters = filtersStr1.localeCompare(filtersStr2)

    const expressionsStr1 = stringifyAndSortExpressions(expr1.expressions)
    const expressionsStr2 = stringifyAndSortExpressions(expr2.expressions)
    const compareExpressions = expressionsStr1.localeCompare(expressionsStr2)

    return compareOperator || compareFilters || compareExpressions
  })
}

const stringifyExpression = (expression?: Expression | null): string => {
  if (!expression) {
    return ""
  }
  if (expression.expressions) {
    sortExpressions(expression.expressions)
  }
  return JSON.stringify({
    operator: expression.operator,
    filters: stringifyAndSortFilters(expression.filters),
    expressions: stringifyAndSortExpressions(expression.expressions),
  })
}

export const areExpressionsEqual = (
  leftExpression?: Expression | null,
  rightExpression?: Expression | null,
  cloneCopy: boolean = true
): boolean => {
  let left = leftExpression
  let right = rightExpression

  if (cloneCopy) {
    left = cloneDeep(leftExpression)
    right = cloneDeep(rightExpression)
  }

  return isEqual(stringifyExpression(left), stringifyExpression(right))
}

/**
 * Returns true iff left and right are semantically equivalent queries.
 */
export const areQueriesEqual = (
  leftQuery: Query,
  rightQuery: Query,
  cloneCopy: boolean = true
): boolean => {
  let left = leftQuery
  let right = rightQuery

  if (cloneCopy) {
    left = cloneDeep(leftQuery)
    right = cloneDeep(rightQuery)
  }

  if ((left.mode ?? null) !== (right.mode ?? null)) {
    return false
  }

  const hasSameFields = isEqual(
    left.fields.map((f) => f.slug).sort(),
    right.fields.map((f) => f.slug).sort()
  )
  if (!hasSameFields) {
    return false
  }

  const hasSameOrder = isEqual(left.order, right.order)
  if (!hasSameOrder) {
    return false
  }

  const hasSameExpression = areExpressionsEqual(
    left.expression,
    right.expression
  )
  if (!hasSameExpression) {
    return false
  }

  return true
}
