import { areQueriesEqual } from "components/common/QueryFunctions/helpers"
import { Expression, Filter, UiView } from "components/common/TabularView/types"
import { PanelMode, useNavigator } from "providers/Navigator"
import { QueryOperator } from "generated/api"
import {
  RefinementEvent,
  refinementReducer,
} from "components/common/QueryFunctions/refinements"
import { useQueryFromHash } from "components/common/QueryFunctions/useQueryFromHash"
import { useSchema } from "providers/SchemaProvider/SchemaContext"
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

type WorkspacesContextProps = {
  currentView: UiView
  fieldsWithActiveFilters: Set<string>
  reorderDataFields: (source: number, destination: number) => void
  toggleDataField: (fieldSlug: string, checked: boolean) => void
  updateViewFilters: (data: Filter, previousField?: string) => void
  updateAdvancedFilters: (expression: Expression) => void
  removeAllFilters: () => void
  visibleFilters: Filter[]
  isViewChanged: boolean
  validateViewName: (name: string, id?: number) => string
  advancedFiltersActive: boolean
  setAdvancedFiltersActive: (active: boolean) => void
  selectedView: PanelMode
  setSelectedView: (mode: PanelMode) => void
  searchByField: (fieldSlugs: string[], searchValue: string) => void
}

export const WorkspacesContext = createContext<WorkspacesContextProps | null>(
  null
)

export const useWorkspaces = () => {
  const context = useContext(WorkspacesContext)
  if (context === null) {
    throw Error("useWorkspaces can only be used within a WorkspacesProvider.")
  }
  return context
}

export const WorkspacesProvider: FC = ({ children }) => {
  const { baseView, uiFieldsTable, uiViewsTable } = useSchema()
  const [refined, setRefined, clearRefined] = useQueryFromHash(uiFieldsTable)
  const [isViewChanged, setIsViewChanged] = useState(false)
  const [advancedFiltersActive, setAdvancedFiltersActive] = useState(false)
  const { selectedView, setSelectedView } = useNavigator()

  const sendRefinement = useCallback(
    (event: RefinementEvent) => {
      const newRefinements = refinementReducer(refined, event, baseView.query)
      if (areQueriesEqual(newRefinements, baseView.query)) {
        clearRefined()
      } else {
        setRefined(newRefinements)
      }
    },
    [baseView.query, clearRefined, refined, setRefined]
  )

  const currentView = useMemo(
    (): UiView => ({
      ...baseView,
      query: refined || baseView.query,
    }),

    [baseView, refined]
  )
  useEffect(() => {
    if (areQueriesEqual(currentView.query, baseView.query)) {
      setIsViewChanged(false)
    } else {
      setIsViewChanged(true)
    }
  }, [currentView.query, baseView.query])

  const getFieldsWithActiveFilters = useCallback(
    (expression?: Expression | null): Set<string> => {
      const fields: Set<string> = new Set()
      expression?.expressions?.forEach((expr) =>
        getFieldsWithActiveFilters(expr).forEach((f) => fields.add(f))
      )
      expression?.filters?.forEach((filt) => {
        if (filt.operation !== QueryOperator.OrderBy) {
          fields.add(filt.field)
        }
      })
      return fields
    },
    []
  )
  const fieldsWithActiveFilters = useMemo(
    () => getFieldsWithActiveFilters(currentView.query.expression),
    [currentView.query.expression, getFieldsWithActiveFilters]
  )

  const visibleFilters = useMemo(
    () =>
      (currentView.query.expression?.filters || []).filter(
        (filter) => filter.operation !== QueryOperator.OrderBy
      ),
    [currentView.query.expression]
  )

  const validateViewName = useCallback(
    (viewName: string, id?: number) => {
      const allViews = Object.values(uiViewsTable)
      const viewNameisOnlySpaces =
        /\s/.test(viewName) && !viewName.replace(/\s/g, "").length
      const viewNameAlreadyExists = allViews
        .filter((view) => (id ? view.id !== id : true))
        .map((view) => view.name)
        .includes(viewName.trim())

      if (viewNameAlreadyExists) {
        return "View name already exists"
      }
      if (viewNameisOnlySpaces) {
        return "View name must not be blank"
      }
      if (viewName === "") {
        return "Please enter a name"
      }
      return ""
    },
    [uiViewsTable]
  )

  const value: WorkspacesContextProps = {
    currentView,
    fieldsWithActiveFilters,
    reorderDataFields: (source, destination) =>
      sendRefinement({ type: "UPDATE_ORDER", source, destination }),
    toggleDataField: (fieldSlug, checked) =>
      sendRefinement({ type: "UPDATE_FIELDS", fieldSlug, checked }),
    updateViewFilters: (data, previousField) =>
      sendRefinement({
        type: "UPDATE_FILTERS",
        dataOp: data,
        previousField,
      }),
    updateAdvancedFilters: (expression) =>
      sendRefinement({
        type: "UPDATE_ADVANCED_FILTERS",
        expression,
      }),
    removeAllFilters: () => sendRefinement({ type: "REMOVE_ALL_FILTERS" }),
    visibleFilters,
    isViewChanged,
    validateViewName,
    advancedFiltersActive,
    setAdvancedFiltersActive,
    selectedView,
    setSelectedView,
    searchByField: (fieldSlugs: string[], searchValue: string) => {
      sendRefinement({ type: "SEARCH_BY_FIELD", fieldSlugs, searchValue })
    },
  }

  return (
    <WorkspacesContext.Provider value={value}>
      {children}
    </WorkspacesContext.Provider>
  )
}
