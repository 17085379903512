import React, { FC } from "react"

import { ErrorAccountNotLinkedToAnyOrganization } from "components/common/errors/ErrorAccountNotLinkedToAnyOrganization"
import { useCurrentUser } from "providers/CurrentUserProvider"

/**
 * RequireUserHasOrganizations requires that the user be a part of an
 * organization before we render the children.
 */
export const RequireUserHasOrganizations: FC = ({ children }) => {
  const { organizations } = useCurrentUser()
  if (organizations.length > 0) {
    return <>{children}</>
  }
  return <ErrorAccountNotLinkedToAnyOrganization />
}
