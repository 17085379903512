import { ERROR_FEATURE_UNAVAILABLE } from "lib/errorMessages"
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react"
import React, { FC } from "react"

interface Props {
  feature: string
  message?: string
}

export const FeatureErrorBoundary: FC<Props> = ({
  children,
  feature,
  message,
}) => {
  return (
    <SentryErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("feature", feature)
      }}
      fallback={<>{message || ERROR_FEATURE_UNAVAILABLE}</>}
    >
      {children}
    </SentryErrorBoundary>
  )
}
