import { CircularProgress } from "@material-ui/core"
import { ErrorQuerying } from "components/common/errors/ErrorQuerying"
import { Row, UiView } from "components/common/TabularView/types"
import { useDocumentsForTabularViewQuery } from "generated/api"

import {
  convertQueryResponseToRowItems,
  createQuery,
} from "lib/document_queries/document_queries"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import React, { createContext, FC, useContext } from "react"

export type BasicQueryResult = {
  rows: Row[]
  rowsCount: number
}

export const BasicQueryContext = createContext<BasicQueryResult | null>(null)

/** Returns the query results from the nearest BasicQueryProvider.  */
export const useBasicQueryResult = () => {
  const context = useContext(BasicQueryContext)
  if (context === null) {
    throw Error(
      "useBasicQueryResult cannot be used outside of a BasicQueryProvider."
    )
  }
  return context
}

type Props = {
  collectionId?: string
  view: UiView
}

/**
 * BasicQueryProvider queries for all rows that satisfy a view query.
 */
export const BasicQueryProvider: FC<Props> = ({
  children,
  collectionId,
  view,
}) => {
  if (view == null) {
    throw Error("BasicQueryProvider requires a valid view=.")
  }
  const { collection } = useCurrentCollection()
  const chosenCollectionId = collectionId ? collectionId : collection.id
  const [results] = useDocumentsForTabularViewQuery({
    requestPolicy: "cache-and-network",
    variables: createQuery(
      chosenCollectionId,
      {
        page: 0,
        rowsPerPage: 0,
      },
      view.query
    ),
  })

  if (results.fetching) {
    return <CircularProgress />
  }

  if (results.error) {
    return <ErrorQuerying details={results.error.message} />
  }

  if (!results.data) {
    throw Error("results.data is unexpectedly empty")
  }

  const result: BasicQueryResult = {
    rows: convertQueryResponseToRowItems(results.data.queryResults),
    rowsCount: results.data.queryResults.totalCount,
  }
  return (
    <BasicQueryContext.Provider value={result}>
      {children}
    </BasicQueryContext.Provider>
  )
}
