import {
  ERROR_PAGE_NOT_FOUND,
  ERROR_PAGE_NOT_FOUND_SUBMESSAGE,
} from "lib/errorMessages"
import { ErrorGeneric } from "components/common/errors/ErrorGeneric"
import React from "react"

export const ErrorPageNotFound = () => (
  <ErrorGeneric
    message={ERROR_PAGE_NOT_FOUND}
    submessage={ERROR_PAGE_NOT_FOUND_SUBMESSAGE}
  />
)
