import { appBarBasicStyles } from "styles/theme"
import { getCrumb } from "./helpers"
import { Keys } from "lib/localStorage"
import { makeStyles } from "@material-ui/core/styles"
import { useAuth0 } from "@auth0/auth0-react"
import { useCurrentOrganization } from "providers/CurrentOrganizationProvider"
import { useCurrentUser } from "providers/CurrentUserProvider"
import { useLocalStorage } from "@rehooks/local-storage"
import { useNavigator } from "providers/Navigator"
import { useSnackbar } from "providers/Snackbar"
import AppBar from "@material-ui/core/AppBar"
import AppBarAction from "components/core/AppBar/AppBarAction"
import EnvironmentChip from "components/common/chips/EnvironmentChip"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import React, { useEffect } from "react"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(appBarBasicStyles)
interface Props {
  banner: React.ReactChild
  hideOrgName?: boolean
}

export default function AppBarBasic({ hideOrgName, banner }: Props) {
  const { breadcrumbs, openMenu, isMenuOpen } = useNavigator()
  const { openSnackbar } = useSnackbar()
  const { user } = useAuth0()
  const { organizations } = useCurrentUser()
  const {
    organization: { fullName },
    desiredOrganizationId,
    isRefreshing,
  } = useCurrentOrganization()
  const [welcomedUser, setWelcomedUser] = useLocalStorage<boolean>(
    String(Keys.WelcomedUser),
    false
  )
  const classes = useStyles()

  useEffect(() => {
    if (user && !welcomedUser) {
      setWelcomedUser(true)
      openSnackbar(`Welcome, ${user.name}!`)
    }
  }, [user, openSnackbar, welcomedUser, setWelcomedUser])
  const currentCrumbName = getCrumb(breadcrumbs, -1, (b) => b.name) || ""

  const desiredOrg = isRefreshing
    ? organizations.find((o) => o.organizationId === desiredOrganizationId)
    : null
  const orgFullName = desiredOrg ? desiredOrg.fullName : fullName
  const displayOrgName = !hideOrgName && organizations.length > 1

  return (
    <>
      <AppBar elevation={0} className={classes.root}>
        <Toolbar disableGutters variant="dense" className={classes.toolbar}>
          <Grid item container>
            <Grid item container justify="flex-start" alignItems="center">
              <AppBarAction
                breadcrumbs={breadcrumbs}
                openMenu={openMenu}
                isMenuOpen={isMenuOpen}
                getCrumb={getCrumb}
              />
              <Typography variant="h6">
                {displayOrgName ? `${orgFullName}: ` : null}
                <Link color="inherit" underline="none">
                  {currentCrumbName}
                </Link>
              </Typography>
              <EnvironmentChip />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {banner}
    </>
  )
}
