import { createStyles, Theme } from "@material-ui/core/styles"
import { UI_COLORS } from "styles/theme"

export const columnManagementStyles = (theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
    },
    captionHeader: {
      display: "flex",
      margin: "2px 16px",
      color: UI_COLORS.black_700,
    },
    columnsContainer: {
      height: "calc(100vh - 180px)",
      overflowY: "scroll",
      width: "276px",
      paddingTop: theme.spacing(2),
    },
    columnItem: {
      width: "276px",
      padding: "12px",
      background: "#eee",
    },
    container: {
      background: UI_COLORS.white,
      width: "calc(100% - 1px)",
    },
    containerUnselected: {
      background: UI_COLORS.white,
      width: "calc(100% - 1px)",
      paddingLeft: theme.spacing(2),
    },
    hidden: {
      visibility: "hidden",
    },
    switch: {
      "&$disabled + $track": {
        backgroundColor: UI_COLORS.blue_900,
      },
      "&$disabled": {
        color: "#bdd1ff", // TODO confirm with design
      },
    },
    switchGrid: {
      height: theme.spacing(6),
      paddingRight: "6px",
    },
    disabled: {},
    track: {},
    switchBaseUnselected: {
      color: UI_COLORS.black_700,
    },
    dragHandle: {
      color: UI_COLORS.black_500,
    },
    fieldName: {
      flexBasis: "160px",
      wordWrap: "break-word",
    },
  })
