// settings.js embeds build-time environment variables into the code as static
// variables that are readable at runtime.
//
// To ensure that we don't push code to an environment that does not provide
// the requisite configuration values, all variables that are *required* for
// proper functioning of the application should be validated PRIOR to webpack
// running the build. This is currently done in yarn-build.sh.
// yarn-build.sh is also where the build-time env variables are set

// UNKNOWN_SOURCE_VERSION is the version string we use to indicate that the JS
// doesn't know what version of the backend is running.
export const UNKNOWN_SOURCE_VERSION = "unknown"

// SOURCE_VERSION is some arbitrary label describing the build version. In
// Heroku, this is provided by the heroku-buildpack-version buildpack. This is
// used in human-readable messages in the UI.
export const SOURCE_VERSION: string = (
  process.env.REACT_APP_SOURCE_VERSION || UNKNOWN_SOURCE_VERSION
).substring(0, 8)

// SENTRY_RELEASE is same as SOURCE_VERSION, but with values compatible w/Sentry.
export const SENTRY_RELEASE: string | undefined =
  process.env.REACT_APP_SOURCE_VERSION || undefined

// ENVIRONMENT should be one of: "development", "production", or "staging".
export const ENVIRONMENT: string = process.env.REACT_APP_ENVIRONMENT || ""

// HEROKU_APP_NAME is the name of the app in heroku, which can be used to
// differentiate sandbox and staging.
export const HEROKU_APP_NAME: string =
  process.env.REACT_APP_HEROKU_APP_NAME || ""

// HEROKU_BRANCH is the name of the git branch for review instances of the app.
export const HEROKU_BRANCH: string = process.env.REACT_APP_HEROKU_BRANCH || ""

// API_IDENTIFIER corresponds to the "identifier" (aka "audience") of the Auth0 API
// configuration. See https://manage.auth0.com/dashboard/us/czi-bailfund-dev/apis.
export const API_IDENTIFIER: string = process.env.REACT_APP_API_IDENTIFIER || ""

// API_BASE_URL is the base URL of the API. This is usually the same value as
// API_IDENTIFIER.
// Example: https://staging.api.bailfundapp.org/api/
export const API_BASE_URL: string = process.env.REACT_APP_API_BASE_URL || ""

// GRAPHQL_API_BASE_URL is the base URL of the GraphQL API.
// There should not be a trailing slash.
// Example: https://staging.api.bailfundapp.org/graphql
export const GRAPHQL_API_BASE_URL: string =
  process.env.REACT_APP_GRAPHQL_BASE_URL || ""

// REFERRAL_FORM_BASE_URL is the base URL of the server hosting the referral form.
// The referral form is hosted by the API server, so this value is usually the
// same as API_BASE_URL. There should not be a trailing slash.
export const REFERRAL_FORM_BASE_URL: string =
  process.env.REACT_APP_REFERRAL_FORM_BASE_URL || ""

// AUTH0_CLIENT_ID is the identifier of the auth0 tenant configuration.
export const AUTH0_CLIENT_ID: string =
  process.env.REACT_APP_AUTH0_CLIENT_ID || ""

// AUTH0_DOMAIN is the auth0.com endpoint for a specific auth0 tenant
// configuration.
export const AUTH0_DOMAIN: string = process.env.REACT_APP_AUTH0_DOMAIN || ""
export const SENTRY_DSN: string = process.env.REACT_APP_SENTRY_DSN || ""

// HEROKU_PR_NUMBER is set in Heroku review instance build environments.
export const HEROKU_PR_NUMBER: string =
  process.env.REACT_APP_HEROKU_PR_NUMBER || ""

export const HOTLOAD_ENABLED = ENVIRONMENT === "development"
export const IS_PRODUCTION = ENVIRONMENT === "production"
export const SENTRY_ENABLED =
  ["staging", "production"].includes(ENVIRONMENT) ||
  process.env.REACT_APP_ENABLE_SENTRY === "true"

// GITHUB_REPO is the name of the the repository that this application was built
// from.
export const GITHUB_REPO = "chanzuckerberg/bailfundapp2"
