import {
  Organization as ApiOrganization,
  OrganizationLite,
} from "generated/api"
import { Select } from "@material-ui/core"
import _ from "lodash"
import MenuItem from "@material-ui/core/MenuItem"
import React, { FC } from "react"
interface Props {
  onChange: (e: any) => void
  organizations: OrganizationLite[]
  selectedOrganization: ApiOrganization | OrganizationLite
  variant?: "AppMenu" | "UserAdmin"
}

const OrganizationSelector: FC<Props> = ({
  organizations,
  selectedOrganization,
  onChange,
  variant = "AppMenu",
}) => {
  const isAppMenu = variant === "AppMenu"
  const getValue = (org: ApiOrganization | OrganizationLite) =>
    isAppMenu ? org.organizationId : org.bailFundSlug
  const sorted = _.sortBy(organizations, "fullName")
  return (
    <Select
      value={getValue(selectedOrganization)}
      onChange={onChange}
      role="listbox"
    >
      {sorted.map((org, key) => (
        <MenuItem key={key} value={getValue(org)}>
          {org.fullName}
        </MenuItem>
      ))}
    </Select>
  )
}

export default OrganizationSelector
