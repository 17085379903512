import { TextField } from "@material-ui/core"
import { useSchema } from "providers/SchemaProvider/SchemaContext"
import { useWorkspaces } from "providers/WorkspacesProvider"
import ActionDialog from "components/core/ActionDialog/ActionDialog"
import React, { useState } from "react"

interface Props {
  onClose: () => void
}

const SaveNewDialog = ({ onClose }: Props) => {
  const { createViewHandler } = useSchema()
  const [newViewName, setNewViewName] = useState("")
  const { currentView, validateViewName } = useWorkspaces()
  const validationMessage = validateViewName(newViewName)
  const saveViewDescription = (
    <TextField
      fullWidth
      id="dialog-name"
      label="Workspace Name"
      value={newViewName}
      onChange={(e) => setNewViewName(e.target.value)}
      error={!!validationMessage}
      helperText={validationMessage}
    />
  )
  return (
    <ActionDialog
      confirmButtonLabel="Save as New Workspace"
      descriptionMessage={saveViewDescription}
      dialogId="remove-view-dialog"
      headerMessage={"Save as New Workspace"}
      onClose={onClose}
      onConfirm={() => {
        createViewHandler({
          ...currentView,
          name: newViewName.trim(),
        })
      }}
      disableConfirm={!!validationMessage}
    />
  )
}

export default SaveNewDialog
