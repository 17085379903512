import { createStyles } from "@material-ui/core/styles"
import { UI_COLORS } from "styles/theme"

export const workspaceManagementStyles = () =>
  createStyles({
    sidebarContainer: {
      overflowY: "auto",
      height: "calc(100vh - 180px)",
      width: "276px",
    },
    workspaceItem: {
      "padding": "7px 16px",
      "minHeight": "44px",
      "cursor": "pointer",
      "& button": {
        display: "none",
      },
      "&:hover": {
        "background": UI_COLORS.black_100,
        "& button": {
          display: "block",
        },
      },
    },
    selectedItem: {
      backgroundColor: UI_COLORS.black_50,
      boxShadow: "inset -2px 0px 0px #F2F2F3",
    },
    workspaceTitle: {
      flexBasis: "198px",
      wordWrap: "break-word",
    },
  })
