import { makeStyles } from "@material-ui/core/styles"
import { OnChangeMultiselectType, SelectOption } from "lib/forms/types"
import Chip from "@material-ui/core/Chip"
import React from "react"

import { requestFieldStyles } from "styles/theme"

const useStyles = makeStyles((theme) => requestFieldStyles(theme))

interface Props {
  isDisabled: boolean
  onChange: OnChangeMultiselectType
  options: Array<SelectOption>
  slug: string
  // The currently selected options. "" is allowed because that is the value
  // the component receives when there are no options checked.
  // TODO: use null to represent unset consistently throughout the flex forms.
  value: string[] | ""
}

const MultiSelect = ({ isDisabled, options, onChange, slug, value }: Props) => {
  const classes = useStyles()
  const selected = value === "" ? [] : value
  return (
    <div role="listbox">
      {options.map((option, i) => {
        const valueIsSelected = selected.includes(option.value)
        const hidden = option.hidden && !valueIsSelected
        if (hidden) {
          return null
        }
        return (
          <Chip
            className={`${classes.multiSelect} ${
              valueIsSelected ? classes.selected : ""
            }`}
            role="option"
            aria-selected={valueIsSelected}
            disabled={isDisabled}
            key={i}
            label={option.label}
            onClick={() => onChange(slug, option.value)}
          />
        )
      })}
    </div>
  )
}
export default MultiSelect
