import { Collection, Organization, UserFeatureFlag } from "generated/api"
import { Collections } from "lib/Collections"
import { findDefaultView, viewUrlById } from "lib/navigationHelpers"
import { IconButton, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { navigationRailStyles } from "styles/theme"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import { useCurrentOrganization } from "providers/CurrentOrganizationProvider"
import { useHistory } from "react-router-dom"
import { useUserFeatureFlag } from "providers/CurrentUserProvider"
import { useWorkspaces } from "providers/WorkspacesProvider"
import Account from "@material-ui/icons/AccountCircle"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import Article from "./ArticleIcon"
import Contacts from "@material-ui/icons/ContactsOutlined"
import Dashboard from "@material-ui/icons/Dashboard"
import React, { useEffect, useState } from "react"

const useStyles = makeStyles(navigationRailStyles)

const getFallbackCollectionOrder = (collections: Collection[]) => {
  const ordering = [Collections.BAILREQUESTS]
  if (collections.find((coll) => coll.slug === Collections.CONTACTS)) {
    ordering.push(Collections.CONTACTS)
  }
  return ordering
}
const computeLinksForCollections = (organization: Organization) => {
  const sortOrder =
    organization.collectionOrder && organization.collectionOrder.length > 0
      ? organization.collectionOrder
      : getFallbackCollectionOrder(organization.collections)

  const collections = new Map(
    organization.collections.map((coll) => [coll.slug, coll])
  )
  return sortOrder
    .map((collectionSlug) => {
      const collection = collections.get(collectionSlug)
      if (!collection) {
        throw Error(`unable to find ${collectionSlug} in list of collections`)
      }
      return collection
    })
    .filter((collection) => collection.actions?.navigation)
    .map((collection) => {
      const view = findDefaultView(collection.views)
      if (!view) {
        throw Error(
          `unable to find a default view for collection ${collection.slug}`
        )
      }
      return {
        label: collection.title,
        href: viewUrlById(organization, collection, view.id),
        key: collection.slug,
      }
    })
}

const generateLinks = (
  organization: Organization,
  collectionEditorEnabled: boolean
) => {
  const links = computeLinksForCollections(organization)
  if (collectionEditorEnabled) {
    links.push({
      label: "Collection Editor",
      href: `/o/${organization.organizationId}/collection-editor`,
      key: "collection_editor",
    })
    links.push({
      label: "Fund Management",
      href: "/fund-admin",
      key: "fund_admin",
    })
  }
  links.push({
    label: "Users",
    href: `/o/${organization.organizationId}/user-admin`,
    key: "user_admin",
  })
  return links
}
const icons = new Map([
  ["bailrequests", <Dashboard />],
  ["contacts", <Contacts />],
  ["collection_editor", <Article />],
  ["user_admin", <Account />],
  ["fund_admin", <AccountBalanceWalletIcon />],
])

const NavigationRail = () => {
  const classes = useStyles()
  const { organization } = useCurrentOrganization()

  const collectionEditorEnabled = useUserFeatureFlag(
    UserFeatureFlag.CollectionEditor
  )
  const history = useHistory()
  const { setSelectedView, setAdvancedFiltersActive } = useWorkspaces()
  const { collection } = useCurrentCollection()
  const currentCollectionSlug = collection.slug

  const [activeTab, setActiveTab] = useState(currentCollectionSlug)
  const links = React.useMemo(
    () => generateLinks(organization, collectionEditorEnabled),
    [organization, collectionEditorEnabled]
  )

  useEffect(() => {
    setActiveTab(currentCollectionSlug)
  }, [currentCollectionSlug])

  const handleClick = (event: any, destination: string) => {
    history.push(destination)
    setAdvancedFiltersActive(false)
    setSelectedView("closed")
  }
  return (
    <div className={classes.flexContainer}>
      {links.map((link) => (
        <Tooltip
          key={`navRail-${link.key}`}
          title={link.label}
          placement="left"
        >
          <IconButton
            aria-labelledby={`${link.key}-nav-rail`}
            className={
              activeTab === link.key
                ? classes.activeIconButton
                : classes.iconButton
            }
            onClick={(event) => {
              handleClick(event, link.href)
            }}
          >
            {activeTab === link.key && (
              <span className={classes.leftBorder}></span>
            )}
            <span id={`${link.key}-nav-rail`} hidden>
              {link.label}
            </span>
            {icons.get(link.key) ? icons.get(link.key) : <Contacts />}
          </IconButton>
        </Tooltip>
      ))}
    </div>
  )
}

export default NavigationRail
