import {
  BasicQueryContext,
  BasicQueryProvider,
  BasicQueryResult,
} from "providers/BasicQueryProvider"
import { maybeToEmpty } from "lib/apihelpers"
import { OnChangeType } from "lib/forms/types"
import {
  UNSET_SELECT_EMDASH_LABEL,
  UNSET_SELECT_EMDASH_VALUE,
} from "lib/forms/constants"
import { useContactsCollection } from "providers/CurrentOrganizationProvider"
import { VOLUNTEER_DATA_VIEW } from "lib/contacts/views"
import _ from "lodash"
import ContactReferenceUi from "components/core/ContactReference/ContactReferenceUi"
import React, { FC } from "react"

type Props = {
  isDisabled: boolean
  label: string
  onChange: OnChangeType
  selectedContactId: string | null
  slug: string
}

const NO_CONTACT_SELECTED_CHOICE = {
  id: 0,
  uuid: UNSET_SELECT_EMDASH_VALUE,
  name: UNSET_SELECT_EMDASH_LABEL,
}

const ContactReferenceDropdown: FC<Props> = ({
  isDisabled,
  label,
  onChange,
  selectedContactId,
  slug,
}) => {
  const contactsCollection = useContactsCollection()

  return (
    <BasicQueryProvider
      collectionId={contactsCollection.id}
      view={VOLUNTEER_DATA_VIEW}
    >
      <BasicQueryContext.Consumer>
        {(queryResult: BasicQueryResult | null) => {
          if (!queryResult) {
            throw Error("Given View Should return a Query Result")
          }
          const contacts = [
            NO_CONTACT_SELECTED_CHOICE,
            ..._.reject(
              _.sortBy(queryResult.rows, (row) => row.name),
              (row) => maybeToEmpty(row.name) == null
            ),
          ]
          return (
            <ContactReferenceUi
              contacts={contacts}
              isDisabled={isDisabled}
              label={label}
              onChange={onChange}
              selectedContactId={selectedContactId ? selectedContactId : ""}
              slug={slug}
            />
          )
        }}
      </BasicQueryContext.Consumer>
    </BasicQueryProvider>
  )
}

export default ContactReferenceDropdown
