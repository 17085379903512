import {
  Expression,
  FieldOrdering,
  Filter,
  Query,
  VisibleField,
} from "components/common/TabularView/types"
import { ExpressionOperator, QueryOperator, ViewMode } from "generated/api"
import { updateArrayOrder } from "lib/array_helpers/array_helpers"

export type RefinementEvent =
  | { type: "REMOVE_ALL_FILTERS" }
  | { type: "SEARCH_BY_FIELD"; fieldSlugs: string[]; searchValue: string }
  | { type: "UPDATE_FIELDS"; fieldSlug: string; checked: boolean }
  | {
      type: "UPDATE_FILTERS"
      dataOp: Filter
      previousField?: string
    }
  | { type: "UPDATE_ORDER"; source: number; destination: number }
  | { type: "UPDATE_ADVANCED_FILTERS"; expression: Expression }

export const refinementReducer = (
  state: Query | null,
  event: RefinementEvent,
  initial: Query
): Query => {
  if (state === null) {
    state = initial
  }
  switch (event.type) {
    case "REMOVE_ALL_FILTERS":
      return {
        ...state,
        mode: null,
        expression: null,
      }
    case "UPDATE_FILTERS":
      return {
        ...state,
        ...computeUpdatedExpressionWithNewFilters(
          computeUpdatedFilters(
            state.expression?.filters || [],
            event.dataOp,
            event.previousField
          ),
          state.mode,
          state.expression
        ),
        fields: computeUpdatedFields(state.fields, event.dataOp.field, true),
        order: computeUpdatedOrder(state.order, event.dataOp.field, true),
      }
    case "UPDATE_ORDER":
      return {
        ...state,
        order: updateArrayOrder(state.order, event.source, event.destination),
      }
    case "SEARCH_BY_FIELD":
      if (event.fieldSlugs.length > 1) {
        const filters = event.fieldSlugs.map((slug) => ({
          field: slug,
          operation: QueryOperator.Contains,
          operands: [event.searchValue],
        }))
        return {
          ...state,
          mode: ViewMode.Advanced,
          expression: {
            operator: ExpressionOperator.Or,
            filters,
          },
        }
      }
      return {
        ...state,
        mode: ViewMode.Simple,
        expression: {
          operator: ExpressionOperator.And,
          filters: [
            {
              field: event.fieldSlugs[0],
              operation: QueryOperator.Contains,
              operands: [event.searchValue],
            },
          ],
        },
      }
    case "UPDATE_FIELDS":
      return {
        ...state,
        fields: computeUpdatedFields(
          state.fields,
          event.fieldSlug,
          event.checked
        ),
        order: computeUpdatedOrder(state.order, event.fieldSlug, event.checked),
      }
    case "UPDATE_ADVANCED_FILTERS":
      var fieldSlugsInExpr = getAllFieldSlugsFromExpression(event.expression)
      return {
        ...state,
        mode: ViewMode.Advanced,
        expression: computeUpdatedExpressionWithNewExpression(
          event.expression,
          state.expression
        ),
        fields: computeUpdatedFieldsFromExpression(
          state.fields,
          fieldSlugsInExpr
        ),
        order: computeUpdatedOrderFromExpression(state.order, fieldSlugsInExpr),
      }
  }
}

const computeUpdatedExpressionWithNewFilters = (
  newFilters: Filter[],
  mode?: ViewMode | null,
  expression?: Expression | null
) => {
  // The only scenario this function would be called with Adv Filters applied is order_by filter change,
  // in which case we simply update the level 0 filters with newFilters
  if (mode === ViewMode.Advanced) {
    return {
      mode,
      expression: {
        ...(expression || {}),
        operator: expression?.operator || ExpressionOperator.And,
        filters: newFilters,
      },
    }
  }

  // In non Adv Filters mode, if there are only order_by filters,
  // we should consider no filters are applied from UI point of view
  const noFilters = !newFilters.some(
    (filt) => filt.operation !== QueryOperator.OrderBy
  )
  let newMode = mode
  if (mode && noFilters) {
    newMode = null
  } else if (!mode && !noFilters) {
    newMode = ViewMode.Simple
  }

  if (newFilters.length) {
    return {
      mode: newMode,
      expression: {
        ...(expression || {}),
        operator: expression?.operator || ExpressionOperator.And,
        filters: newFilters,
      },
    }
  } else {
    return {
      mode: null,
      expression: null,
    }
  }
}

const computeUpdatedFilters = (
  viewFunctions: Filter[],
  newFunc: Filter,
  previousField?: string
) => {
  if (!newFunc) {
    return viewFunctions
  }
  const { operation, operands } = newFunc

  if (operation === QueryOperator.OrderBy) {
    viewFunctions = viewFunctions.filter((func) => func.operation !== operation)
    // if order by is without operands then order by needs to be removed
    if (!operands) {
      return viewFunctions
    }
    return [...viewFunctions, newFunc]
  }

  // if there was a field assigned to the same chip then override the function or remove it
  if (previousField) {
    if (!newFunc.operands) {
      // the function exists but the operand has been removed, we remove the function
      return viewFunctions.filter(
        (func) =>
          !(
            func.operation !== QueryOperator.OrderBy &&
            func.field === previousField
          )
      )
    }
    return viewFunctions.map((func) => {
      if (
        func.operation !== QueryOperator.OrderBy &&
        func.field === previousField
      ) {
        return newFunc
      }
      return func
    })
  }

  // if it's a completely new function, we just add it to the list
  return [...viewFunctions, newFunc]
}

export const computeUpdatedFields = (
  fields: VisibleField[],
  fieldSlug: string,
  checked: boolean
) => {
  return fields
    .filter((field) => field.slug !== fieldSlug)
    .concat(checked ? [{ slug: fieldSlug }] : [])
}

export const computeUpdatedOrder = (
  order: FieldOrdering,
  fieldSlug: string,
  fieldEnabled: boolean
) => {
  if (fieldEnabled) {
    if (!order.find((s) => s === fieldSlug)) {
      // Place newly enabled fields at the end of the ordering.
      return order.concat([fieldSlug])
    }
    return order
  }
  return order.filter((o) => o !== fieldSlug)
}

const computeUpdatedExpressionWithNewExpression = (
  newExpression: Expression,
  expression?: Expression | null
): Expression => {
  if (!expression || !expression.filters) {
    return newExpression
  }

  const order_by = expression.filters.filter(
    (filt) => filt.operation === QueryOperator.OrderBy
  )

  if (!order_by) {
    return newExpression
  }

  return {
    ...newExpression,
    filters: [...(newExpression.filters || []), ...order_by],
  }
}

const getAllFieldSlugsFromExpression = (
  expression: Expression
): Set<string> => {
  const fields: Set<string> = new Set()
  expression.filters?.forEach((filt) => fields.add(filt.field))
  expression.expressions?.forEach((expr) =>
    getAllFieldSlugsFromExpression(expr).forEach((f) => fields.add(f))
  )
  return fields
}

const computeUpdatedFieldsFromExpression = (
  fields: VisibleField[],
  fieldSlugsInExpr: Set<string>
): VisibleField[] => {
  return fields
    .filter((field) => !fieldSlugsInExpr.has(field.slug))
    .concat(Array.from(fieldSlugsInExpr, (fieldSlug) => ({ slug: fieldSlug })))
}

const computeUpdatedOrderFromExpression = (
  order: FieldOrdering,
  fieldSlugsInExpr: Set<string>
): FieldOrdering => {
  return order.concat(
    Array.from(fieldSlugsInExpr).filter((s) => !order.includes(s))
  )
}
