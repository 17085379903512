import { makeStyles } from "@material-ui/core"
import { UsefulDocument } from "lib/apihelpers"
import _ from "lodash"
import AddCommentIcon from "@material-ui/icons/AddComment"
import Avatar from "@material-ui/core/Avatar"
import Comment from "components/core/Comment/Comment"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"

import { commentStyles } from "styles/theme"
import { MessageType } from "providers/Snackbar"

const useStyles = makeStyles(commentStyles)

interface Props {
  comments: UsefulDocument[]
  createComment: (comment: string) => Promise<boolean>
  currentUser: any
  deleteComment: (comment: UsefulDocument) => void
  openSnackbar: (
    message: string,
    messageType?: MessageType,
    action?: React.ReactNode
  ) => void
  updateComment: (comment: UsefulDocument) => Promise<void>
}

const divider = <Divider variant="inset" component="li" />
const joinWithDivider = (acc: JSX.Element, x: JSX.Element) => (
  <>
    {acc}
    {divider}
    {x}
  </>
)

/** CommentsUi is the UI for the entire Comments box. */
const CommentsUi = ({
  comments,
  createComment,
  currentUser,
  deleteComment,
  openSnackbar,
  updateComment,
}: Props) => {
  const classes = useStyles()
  const [newCommentText, setNewCommentText] = useState("")
  const resetNewCommentText = () => setNewCommentText("")

  return (
    <List>
      <ListItem alignItems="center" className={classes.listItem}>
        <ListItemAvatar>
          <Avatar src={currentUser.picture} alt={currentUser.email} />
        </ListItemAvatar>
        <ListItemText disableTypography>
          <TextField
            fullWidth
            id="add-case-comment"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={async () => {
                      // TODO: disable button and make input readonly until
                      // we have success or fail
                      if (await createComment(newCommentText)) {
                        resetNewCommentText()
                      }
                    }}
                  >
                    <AddCommentIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            margin="normal"
            multiline
            onChange={(e) => setNewCommentText(e.target.value)}
            placeholder="Add a comment..."
            value={newCommentText}
            variant="outlined"
          />
        </ListItemText>
      </ListItem>
      <Divider variant="inset" component="li" />
      {_.sortBy(comments, ["created"])
        .map((comment) => (
          <Comment
            comment={comment}
            currentUser={currentUser}
            deleteComment={deleteComment}
            key={`comment-${comment.id}`}
            openSnackbar={openSnackbar}
            updateComment={updateComment}
          />
        ))
        .reverse()
        .reduce(joinWithDivider, <></>)}
    </List>
  )
}

export default CommentsUi
