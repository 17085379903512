import {
  Expression,
  Query,
  UiFieldsTable,
} from "components/common/TabularView/types"
import {
  ExpressionOperator,
  FieldDefinition,
  QueryOperator,
  ViewMode,
} from "generated/api"
import {
  openEnvelope,
  sealEnvelope,
} from "components/common/QueryFunctions/envelope"
import { useCallback } from "react"
import { useCodecHash } from "hooks/useCodecHash"
import { viewtable_pb } from "generated/viewtable.pb"

const VIEW_MODE_TO_PROTO_QUERY_MODE = {
  [ViewMode.Simple]: viewtable_pb.CompactQuery.Mode.MODE_SIMPLE,
  [ViewMode.Advanced]: viewtable_pb.CompactQuery.Mode.MODE_ADVANCED,
}

const PROTO_QUERY_MODE_TO_VIEW_MODE = {
  [viewtable_pb.CompactQuery.Mode.MODE_AND]: ViewMode.Simple,
  [viewtable_pb.CompactQuery.Mode.MODE_OR]: ViewMode.Simple,
  [viewtable_pb.CompactQuery.Mode.MODE_SIMPLE]: ViewMode.Simple,
  [viewtable_pb.CompactQuery.Mode.MODE_ADVANCED]: ViewMode.Advanced,
}

const EXPRESSION_OPERATOR_TO_PROTO_OPERATOR = {
  [ExpressionOperator.And]: viewtable_pb.CompactExperssion.Operator.AND,
  [ExpressionOperator.Or]: viewtable_pb.CompactExperssion.Operator.OR,
}

const PROTO_OPERATOR_TO_EXPRESSION_OPERATOR = {
  [viewtable_pb.CompactExperssion.Operator.AND]: ExpressionOperator.And,
  [viewtable_pb.CompactExperssion.Operator.OR]: ExpressionOperator.Or,
}

/**
 * Use the URL fragment (aka hash) as persistence for a Query.
 *
 * Similar to a standard React useState variable, this method returns the
 * current value (or null, if there is no value or if it decodes incorrectly),
 * a setter function, and a function to clear the value.
 */
export const useQueryFromHash = (uiFieldsTable: UiFieldsTable) => {
  const encoder = useCallback(
    (query: Query) => {
      const token = convertQueryToToken(query, uiFieldsTable)
      return sealEnvelope(token)
    },
    [uiFieldsTable]
  )

  const decoder = useCallback(
    (envelope: string) => {
      const contents = openEnvelope(envelope)
      if (contents === null) {
        return null
      }
      return convertTokenToQuery(contents, uiFieldsTable)
    },
    [uiFieldsTable]
  )

  const [refined, setRefined, clearRefined] = useCodecHash(encoder, decoder)
  return [refined, setRefined, clearRefined] as const
}

/**
 * Converts a serialized ViewTableToken into a Query. Returns null if the token is
 * invalid.
 */
export const convertTokenToQuery = (
  token: Uint8Array,
  uiFieldsTable: UiFieldsTable
) => {
  try {
    const vtToken = viewtable_pb.ViewTableToken.decode(token)
    return convertProtoToQuery(vtToken, uiFieldsTable)
  } catch (e) {
    return null
  }
}

/**
 * Converts a Query into a serialized ViewTableToken.
 */
export const convertQueryToToken = (
  query: Query,
  uiFieldsTable: Map<string, FieldDefinition>
) => {
  const vtToken = convertQueryToProto(query, uiFieldsTable)
  return viewtable_pb.ViewTableToken.encode(vtToken).finish()
}

/**
 * Converts a Query into a compact proto-based representation by replacing
 * field slugs with their numeric IDs.
 */
const convertQueryToProto = (query: Query, uiFieldsTable: UiFieldsTable) => {
  const fieldIds = query.order.map((slug) => uiFieldsTable.get(slug)!.id)
  const compactQuery: viewtable_pb.ICompactQuery = {
    order: fieldIds,
  }
  if (query.mode) {
    compactQuery.mode = VIEW_MODE_TO_PROTO_QUERY_MODE[query.mode]
  }
  if (query.expression) {
    compactQuery.expression = convertExpressionToProto(
      query.expression,
      uiFieldsTable
    )
  }
  return viewtable_pb.ViewTableToken.create({ query: compactQuery })
}

/**
 * Converts a query Expression recursively into a compact proto-based representation
 */
const convertExpressionToProto = (
  expression: Expression,
  uiFieldsTable: UiFieldsTable
) => {
  const compactExpr: viewtable_pb.ICompactExperssion = {
    operator: EXPRESSION_OPERATOR_TO_PROTO_OPERATOR[expression.operator],
  }
  if (expression.filters?.length) {
    compactExpr.filters = expression.filters.map((filt) => ({
      field: uiFieldsTable.get(filt.field)!.id,
      operation: filt.operation,
      operands: filt.operands!,
    }))
  }
  if (expression.expressions?.length) {
    compactExpr.expressions = expression.expressions.map((expr) =>
      convertExpressionToProto(expr, uiFieldsTable)
    )
  }
  return compactExpr
}

/**
 * Converts a ViewTableToken into a Query by replacing the numeric IDs with field
 * slugs.
 */
const convertProtoToQuery = (
  vtToken: viewtable_pb.ViewTableToken,
  uiFieldsTable: UiFieldsTable
) => {
  if (!vtToken.query) {
    return null
  }

  const idToSlug = new Map(
    Array.from(uiFieldsTable.entries()).map(([slug, def]) => [def.id, slug])
  )
  const query = vtToken.query

  // The lifecycle of a token is different than the lifecycle of our persisted
  // views. Over time, tokens stored as bookmarks could refer to fields that no
  // longer exist in the collection schema. If the token contains such
  // references, we pretend the token is invalid.
  if (checkProtoRefersToMissingFields(query, idToSlug)) {
    return null
  }

  const visibleSlugs = query.order!.map((fieldId) => idToSlug.get(fieldId)!)
  const uiQuery: Query = {
    fields: visibleSlugs.map((slug) => ({
      slug,
    })),
    order: visibleSlugs,
  }
  if (query.mode) {
    uiQuery.mode = PROTO_QUERY_MODE_TO_VIEW_MODE[query.mode]
  }
  if (query.expression) {
    uiQuery.expression = convertProtoToExpression(query.expression, idToSlug)
  } else if (query.filters?.length) {
    // For backwards compatibility
    uiQuery.expression = {
      operator:
        query.mode === viewtable_pb.CompactQuery.Mode.MODE_OR
          ? ExpressionOperator.Or
          : ExpressionOperator.And,
      filters: query.filters.map((filt) => ({
        field: idToSlug.get(filt.field!)!,
        operation: (filt.operation as unknown) as QueryOperator,
        operands: filt.operands || [],
      })),
    }
  }
  return uiQuery
}

/**
 * Converts a CompactExpression recursively into a query Expression
 */
const convertProtoToExpression = (
  expression: viewtable_pb.ICompactExperssion,
  idToSlug: Map<number, string>
) => {
  const uiExpression: Expression = {
    operator: PROTO_OPERATOR_TO_EXPRESSION_OPERATOR[expression.operator],
  }
  if (expression.filters?.length) {
    uiExpression.filters = expression.filters.map((filt) => ({
      field: idToSlug.get(filt.field!)!,
      operation: (filt.operation as unknown) as QueryOperator,
      operands: filt.operands || [],
    }))
  }
  if (expression.expressions?.length) {
    uiExpression.expressions = expression.expressions.map((expr) =>
      convertProtoToExpression(expr, idToSlug)
    )
  }
  return uiExpression
}

/**
 * Checks if a proto filter list refers to any missing fields.
 */
const checkProtoFiltersReferToMissingFields = (
  filters: viewtable_pb.ICompactFilter[],
  idToSlug: Map<number, string>
): boolean => {
  return filters.map((filt) => filt.field).some((id) => !idToSlug.has(id))
}

/**
 * Checks if a proto expression refers to any missing fields.
 */
const checkProtoExpressionRefersToMissingFields = (
  expression: viewtable_pb.ICompactExperssion,
  idToSlug: Map<number, string>
): boolean => {
  if (
    expression.filters?.length &&
    checkProtoFiltersReferToMissingFields(expression.filters, idToSlug)
  ) {
    return true
  }
  if (expression.expressions?.length) {
    return expression.expressions.some((expr) =>
      checkProtoExpressionRefersToMissingFields(expr, idToSlug)
    )
  }
  return false
}

/**
 * Checks if a proto query refers to any missing fields.
 */
const checkProtoRefersToMissingFields = (
  query: viewtable_pb.ICompactQuery,
  idToSlug: Map<number, string>
): boolean => {
  if ((query.order || []).some((id) => !idToSlug.has(id))) {
    return true
  }
  if (
    query.expression &&
    checkProtoExpressionRefersToMissingFields(query.expression, idToSlug)
  ) {
    return true
  }
  // Fot backwards combatibility
  if (
    query.filters?.length &&
    checkProtoFiltersReferToMissingFields(query.filters, idToSlug)
  ) {
    return true
  }
  return false
}
