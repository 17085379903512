import { BODY_FONT, UI_COLORS } from "styles/theme"
import { createStyles } from "@material-ui/core/styles"

const sharedFocusStyles = {
  backgroundColor: UI_COLORS.blue_900,
  bottom: "-5px",
  content: '""',
  display: "block",
  height: "2px",
  left: 0,
  position: "absolute",
  transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1)",
  width: "100%",
  zIndex: 1,
}

export const currencyInputTheme = () =>
  createStyles({
    input: {
      "fontFamily": BODY_FONT,
      "color": UI_COLORS.black,
      "fontSize": "14px",
      "border": "none",
      "padding": "23px 12px 8px 24px",
      "backgroundColor": "#F6F6F6",
      "&:hover": {
        backgroundColor: "#EBEBEB",
      },
      "borderBottom": "1px solid #777879",
      "borderTopLeftRadius": "4px",
      "borderTopRightRadius": "4px",
      "position": "relative",
      "&:focus": {
        outline: "none",
      },
    },
    formControl: {
      "width": "calc(100% - 16px)",
      "margin": "8px",
      "position": "relative",
      "&:after": {
        transform: "scaleX(0)",
        ...sharedFocusStyles,
      },
    },
    formControlFocus: {
      "width": "calc(100% - 16px)",
      "margin": "8px",
      "position": "relative",
      "&:after": {
        ...sharedFocusStyles,
        transform: "scaleX(1)",
      },
      "& label": {
        color: UI_COLORS.blue_900,
      },
    },
    label: {
      position: "absolute",
      top: "-4px",
      fontSize: "15px",
    },
    currencyPrefix: {
      zIndex: 1,
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "16px",
      marginTop: "-28px",
      marginLeft: "12px",
      width: "5px",
    },
  })
