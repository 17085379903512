import { findDefaultViewRequired } from "lib/navigationHelpers"
import {
  SchemaContext,
  SchemaContextValue,
} from "providers/SchemaProvider/SchemaContext"
import { UiViewTable } from "components/common/TabularView/types"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import { useCurrentOrganization } from "providers/CurrentOrganizationProvider"
import { useCurrentUser } from "providers/CurrentUserProvider"
import { useNavigator } from "providers/Navigator"
import { useParams } from "react-router-dom"
import { ViewDefinitionOutput } from "generated/api"
import React, { FC, useEffect, useMemo, useState } from "react"
import useViewApi from "providers/SchemaProvider/useViewApi"

import { convertBackendSchemaToTables } from "./helpers"
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner"

const findRequestedUiView = (
  uiViewsTable: UiViewTable,
  defaultView: ViewDefinitionOutput,
  viewIdFromUrl?: string
) => {
  if (typeof viewIdFromUrl !== "undefined" && uiViewsTable[viewIdFromUrl]) {
    return uiViewsTable[viewIdFromUrl]
  }
  return uiViewsTable[defaultView.id.toString()]
}

export const SchemaProvider: FC = ({ children }) => {
  const { setDocumentTitle, setBreadcrumbs } = useNavigator()
  const { isRefreshing: userIsRefreshing } = useCurrentUser()
  const { isRefreshing: orgIsRefreshing } = useCurrentOrganization()
  const [isHandlingApiCall, setIsHandlingApiCall] = useState(false)
  const apiCallOutstanding =
    userIsRefreshing || orgIsRefreshing || isHandlingApiCall
  const { collection, getUrlForWorkspace } = useCurrentCollection()
  const { viewId } = useParams<{ viewId?: string }>()
  const defaultView = useMemo(() => findDefaultViewRequired(collection.views), [
    collection.views,
  ])
  const { uiFieldsTable, uiViewsTable } = useMemo(
    () => convertBackendSchemaToTables(collection.views, collection.fields),
    [collection]
  )
  const {
    createViewHandler,
    deleteViewHandler,
    pushViewIdToUrl,
    updateViewHandler,
  } = useViewApi(uiViewsTable, defaultView.id, setIsHandlingApiCall)

  useEffect(() => {
    if (typeof viewId === "undefined") {
      // Hack: On reports pages, useParams will return undefined. Reports pages
      // have no view ID and the views won't change, so we do not attempt to
      // update the navigator state.
      return
    }
    if (apiCallOutstanding) {
      // If this effect fires during an API update, the uiViewsTable may not
      // be consistent with the schema from useCurrentCollection. E.g. a newly
      // created view ID may be present in the URL but not yet in uiViewsTable.
      return
    }
    const requestedView = uiViewsTable[viewId]
    if (typeof requestedView === "undefined") {
      console.warn("requested view not found; redirecting to default view")
      pushViewIdToUrl(defaultView.id.toString())
      return
    }

    setDocumentTitle(requestedView ? requestedView.name : collection.title)
    setBreadcrumbs([
      { path: getUrlForWorkspace(viewId), name: collection.title, root: true },
    ])
  }, [
    apiCallOutstanding,
    collection.title,
    defaultView.id,
    getUrlForWorkspace,
    pushViewIdToUrl,
    setBreadcrumbs,
    setDocumentTitle,
    uiViewsTable,
    viewId,
  ])

  const schemaProviderValues = useMemo((): SchemaContextValue => {
    const baseView = findRequestedUiView(uiViewsTable, defaultView, viewId)
    return {
      baseView,
      createViewHandler,
      deleteViewHandler,
      selectViewById: pushViewIdToUrl,
      uiFieldsTable: uiFieldsTable,
      uiViewsTable: uiViewsTable,
      updateViewHandler,
    }
  }, [
    createViewHandler,
    defaultView,
    deleteViewHandler,
    pushViewIdToUrl,
    uiFieldsTable,
    uiViewsTable,
    updateViewHandler,
    viewId,
  ])

  if (apiCallOutstanding) {
    return <LoadingSpinner />
  }

  return (
    <SchemaContext.Provider value={schemaProviderValues}>
      {children}
    </SchemaContext.Provider>
  )
}
