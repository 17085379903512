import {
  DocumentDeleteMutationVariables,
  useDocumentDeleteMutation,
} from "generated/api"
import { MessageType, useSnackbar } from "providers/Snackbar"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import { useHistory } from "react-router-dom"
import ActionDialog from "../ActionDialog/ActionDialog"
import React, { FC, useCallback } from "react"

const confirmDeleteMessage = (
  <>
    Deleting this request will permanently remove it from the Bail Fund App. It
    will also be excluded from all reports and all views.You will not be able to
    recover it once deleted.
  </>
)
const DELETE_HEADER_MESSAGE = "Are you sure you want to delete this request?"
interface Props {
  documentId: string
  handleCloseDialog: () => void
}

const DeleteDocumentDialog: FC<Props> = (props) => {
  const history = useHistory()
  const {
    collection: { id: collectionId },
  } = useCurrentCollection()
  const [, sendDocumentDelete] = useDocumentDeleteMutation()
  const { openSnackbar } = useSnackbar()

  const deleteDocument = useCallback(async () => {
    const deleteDocumentVars: DocumentDeleteMutationVariables = {
      documentId: props.documentId,
      collectionId: collectionId,
    }

    const response = await sendDocumentDelete(deleteDocumentVars)
    if (response.error) {
      openSnackbar(
        `Failed to delete: ${response.error.message}`,
        MessageType.Error
      )
      return
    }
    openSnackbar("Deleted.")
    history.goBack()
  }, [
    collectionId,
    history,
    openSnackbar,
    props.documentId,
    sendDocumentDelete,
  ])

  return (
    <ActionDialog
      confirmButtonLabel="Delete Request"
      useWarningStyle
      descriptionMessage={confirmDeleteMessage}
      dialogId="delete-request-dialog"
      headerMessage={DELETE_HEADER_MESSAGE}
      onClose={props.handleCloseDialog}
      onConfirm={() => {
        deleteDocument()
      }}
    />
  )
}

export default DeleteDocumentDialog
