import { ErrorUnableToFetchUserInfo } from "components/common/errors/ErrorUnableToFetchUserInfo"
import { Initializing } from "components/common/Initializing/Initializing"
import {
  OrganizationLite,
  UserFeatureFlag,
  useUserInfoQuery,
} from "generated/api"
import React, { createContext, FC, useContext, useMemo } from "react"

type CurrentUserContextProps = {
  // True iff the backend has authenticated the current user.
  loaded: boolean
  // Array of organizations. This may be empty if the user is not in any organizations.
  organizations: Array<OrganizationLite>
  // User flags associated with this user
  userFlags: UserFeatureFlag[]
  // Force a refresh of the user info properties.
  forceRefresh: () => void
  // True when the data is currently being refreshed.
  isRefreshing: boolean
}

export const CurrentUserContext = createContext<CurrentUserContextProps | null>(
  null
)

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext)
  if (context === null) {
    throw Error("useCurrentUser can only be used within a CurrentUserProvider.")
  }
  return context
}

/** Returns feature flag information, if present. */
export const useUserFeatureFlag = (flag: UserFeatureFlag) => {
  const context = useCurrentUser()
  return context.userFlags.includes(flag)
}

export const CurrentUserProvider: FC = ({ children }) => {
  const [userInfoResponse, executeUserInfoQuery] = useUserInfoQuery({
    requestPolicy: "cache-and-network",
  })

  const value: CurrentUserContextProps = useMemo(() => {
    const organizationsList =
      userInfoResponse.data?.userInfo.organizations || []
    const userFlags = userInfoResponse.data?.userInfo.flags || []
    return {
      loaded: !!userInfoResponse.data,
      organizations: organizationsList,
      userFlags: userFlags,
      forceRefresh: () => executeUserInfoQuery(),
      isRefreshing: userInfoResponse.fetching || userInfoResponse.stale,
    }
  }, [
    executeUserInfoQuery,
    userInfoResponse.data,
    userInfoResponse.fetching,
    userInfoResponse.stale,
  ])

  if (value.loaded) {
    return (
      <CurrentUserContext.Provider value={value}>
        {children}
      </CurrentUserContext.Provider>
    )
  } else if (userInfoResponse.error) {
    console.log("GraphQL error: ", userInfoResponse.error)
    return <ErrorUnableToFetchUserInfo />
  } else {
    return <Initializing />
  }
}
