import { useCallback, useMemo } from "react"
import { useHash } from "hooks/useHash"

// Hack: If the value doesn't decode, we should empty the hash so that we avoid
// having invalid values in the URL. This will cause React to emit errors in the
// console but this is much simpler than alternative solutions.
const clearFragmentHack = async () => (window.location.hash = "")

/**
 * Expose the URL fragment (Location.hash) as a state variable for data
 * that is subject to encoding and decoding.
 *
 * Usage is similar to the standard useState() hook, with an additional function
 * returned to allow clearing of the value, and the values may of any type
 * supported by the encode and decode functions.
 *
 * Usage:
 * ```
 * type T = { x: number, y: string }
 * const encode = (v: T) => (`${x}:${y}`)
 * const decode = (v: string) => ({x: v.split(":")[0], y: v.split(":")[1]}
 * const [value, setter, clear] = useCodecHash(encode, decode)
 * setter({x:123, y: "hello"})
 * ```
 *
 * Caller is responsible for implementing encode and decode functions that are
 * safe for use in URLs. decode should return null if there are problems
 * decoding the value.
 */
export const useCodecHash = <P>(
  encode: (v: P) => string,
  decode: (v: string) => P | null
) => {
  const [value, setValue, clearValue] = useHash()
  const setter = useCallback((v: P) => setValue(`#${encode(v)}`), [
    encode,
    setValue,
  ])
  const decodedValue = useMemo(() => {
    if (value.startsWith("#") && value.length > 1) {
      const fragment = value.slice(1)
      const removeUriEncoding = decodeURIComponent(fragment)
      const decoded = decode(removeUriEncoding)
      if (decoded === null) {
        clearFragmentHack()
        return null
      }
      return decoded
    }
    return null
  }, [decode, value])
  return [decodedValue, setter, clearValue] as const
}
