import * as Urql from "urql"
import gql from "graphql-tag"
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any
}

/** Acls describes the actions that the UI should present to customers when operating on this collection. */
export type Acls = {
  __typename?: "Acls"
  /** Allow customers to add a new document to this collection via the UI. */
  createDocument?: Maybe<Scalars["Boolean"]>
  /** Allow customers to update documents in this collection. */
  updateDocument?: Maybe<Scalars["Boolean"]>
  /** Allow customers to delete documents from this collection. */
  deleteDocument?: Maybe<Scalars["Boolean"]>
  /** Allow customers to browse this collection from the navigation menu and interact with the data in the tabular view. */
  navigation?: Maybe<Scalars["Boolean"]>
  /** Allow customers to refine existing views. */
  tabularViewFiltering?: Maybe<Scalars["Boolean"]>
  /** Allow refined views to be saved. If false, user cannot save the refined view. */
  modifyTabularViews?: Maybe<Scalars["Boolean"]>
  /** Allow customer to export the collection's contents as CSV. */
  csvExport?: Maybe<Scalars["Boolean"]>
  /** Allow customer to use the search dialog feature. */
  searchDialog?: Maybe<Scalars["Boolean"]>
}

/** Describes the arguments needed to add a horizontal rule to a well. */
export type AddHorizontalRuleToWellInForm = {
  /** The selector of well. */
  wellSelector: WellSelector
  /** Specifies the row number of well where you want to add the horizontal rule. Starts from 1. */
  row: Scalars["Int"]
  /** Specifies the title of horizontal rule. */
  title?: Maybe<Scalars["String"]>
}

/** Adds a user with a role to an organization */
export type AddUserMutation = {
  __typename?: "AddUserMutation"
  /** True if all changes applied successfully. If false, `errors` will be non-null. */
  ok: Scalars["Boolean"]
  /** Describes any errors in the proposed configuration. Only set if `ok` is false. */
  errors?: Maybe<Array<ValidationError>>
  /** All the key user info */
  user?: Maybe<UserInfo>
}

/** Describes the arguments needed to adds a new well or builtin to a form. */
export type AddWellBuiltinToFormInput = {
  /** The selector of well or builtin. */
  wellBuiltinSelector: WellBuiltinSelector
  /** The position of well or builtin. */
  positionCoordinates: PositionCoordinates
}

/** Describes a file attachment. */
export type Attachment = {
  __typename?: "Attachment"
  /** The ID of the file attachment. */
  id: Scalars["ID"]
  /** The original filename. */
  filename: Scalars["String"]
  /** A URL to download this file. The filename in this path may not match the original filename. */
  url: Scalars["String"]
}

/**
 * Describes the type of Builtins.
 *
 * This is used to retrieve the associated Builtin type.
 */
export enum Builtin {
  Attachments = "ATTACHMENTS",
  Comments = "COMMENTS",
  AttorneyInformation = "ATTORNEY_INFORMATION",
  NestedCollection = "NESTED_COLLECTION",
}

/** Describes the attributes of a builtin. */
export type BuiltinDescriptor = {
  /** The type of builtin. */
  builtin?: Maybe<Builtin>
}

export type Collection = {
  __typename?: "Collection"
  /** Unique ID of the collection. */
  id: Scalars["ID"]
  /** A human readable unique identifier for the collection. */
  slug: Scalars["String"]
  /** Title of the collection. */
  title: Scalars["String"]
  /** List of forms presentation schema forms associated with this collection. */
  forms?: Maybe<Array<Maybe<Scalars["GenericScalar"]>>>
  /** List of PDFs associated with this collection. */
  pdfs?: Maybe<Array<Maybe<Pdf>>>
  fields: Array<FieldDefinition>
  views: Array<ViewDefinitionOutput>
  /** Describes the UI actions that are allowed in this collection. */
  actions?: Maybe<Acls>
  /** Specific collection settings. */
  settings?: Maybe<PschemaSettings>
}

/** Describes the schema of a single collection. */
export type CollectionSchema = {
  __typename?: "CollectionSchema"
  /** The title of the collection. This is displayed only in administrative UIs. */
  title: Scalars["String"]
  /** The unique collection ID. */
  collectionId: Scalars["String"]
  /** The forms defined on this collection. */
  forms?: Maybe<Array<Form>>
  /** The fields defined on this collection. */
  fields?: Maybe<Array<FieldSchema>>
  /** The slug of this collection. */
  slug?: Maybe<Scalars["String"]>
  /** Describes statistics related to this collection. */
  stats?: Maybe<CollectionStats>
}

export type CollectionStats = {
  __typename?: "CollectionStats"
  /** The number of documents in the collection. */
  numDocs: Scalars["Int"]
  /** The creation timestamp of the earliest document. If there are no documents, this will be null. */
  earliest?: Maybe<Scalars["DateTime"]>
  /** The creation timestamp of the newest document. If there are no documents, this will be null. */
  latest?: Maybe<Scalars["DateTime"]>
}

/**
 * Defines a new child document.
 *
 * Child documents are normal documents except they have a field, specified by `childToParentSlug`, which is
 * automatically set to the parent document's ID.
 *
 * Child documents can have children.
 */
export type CreateChildDocumentInput = {
  /** The ID of the collection this document is in. */
  collectionId: Scalars["ID"]
  /** Defines the values to store in the document. */
  fields: Array<DocumentFieldInput>
  /** Create these additional documents as child documents. Child documents contain a field that references the parent document, forming a 1:M relationship between parent and the children. */
  children?: Maybe<Array<CreateChildDocumentInput>>
  /** The slug of the field of this child document that should reference the parent document's ID. */
  childToParentSlug: Scalars["String"]
}

/**
 * Defines a new document.
 *
 * To create a document in a collection, provide `collectionId` and `fields`.
 *
 * To create a child document for an existing document, specify the parent UUID as one of the value in the `fields`.
 */
export type CreateDocumentInput = {
  /** The ID of the collection this document is in. */
  collectionId: Scalars["ID"]
  /** Defines the values to store in the document. */
  fields: Array<DocumentFieldInput>
  /** Create these additional documents as child documents. Child documents contain a field that references the parent document, forming a 1:M relationship between parent and the children. */
  children?: Maybe<Array<CreateChildDocumentInput>>
}

/** Create new bail fund from template */
export type CreateFundMutation = {
  __typename?: "CreateFundMutation"
  /** True if all changes applied successfully. If false, `errors` will be non-null. */
  ok: Scalars["Boolean"]
  /** Describes any errors in the proposed configuration. Only set if `ok` is false. */
  errors?: Maybe<Array<ValidationError>>
}

export type CreateViewMutation = {
  __typename?: "CreateViewMutation"
  view: ViewDefinitionOutput
}

export type DefaultFieldSchemaInput = {
  /** The slug of this field. This is unique within the collection. */
  slug: Scalars["String"]
  /** The PSchemaFieldType field type of the new default field */
  fieldType: PSchemaFieldType
  /** The default options of the field. */
  options: MutableDefaultFieldOptions
}

export type DeleteAllDocumentsInput = {
  /** The ID of the collection to delete all documents from. */
  collectionId: Scalars["ID"]
}

export type DeleteDocumentInput = {
  /** The ID of the collection that this document is in. */
  collectionId: Scalars["ID"]
  /** The ID of the document to delete. */
  id: Scalars["ID"]
  precondition?: Maybe<PreconditionInput>
}

/** The input needed to delete a field_choices item from a field. */
export type DeleteFieldChoicesItemInput = {
  /** The slug of the field to for which we want to delete the option. */
  fieldSlug: Scalars["String"]
  /** The dropdown option to delete */
  itemToDelete: Scalars["String"]
  /** Anything with the deleted value will be set to this instead */
  newItemValue?: Maybe<Scalars["String"]>
}

/** Delete a bail fund */
export type DeleteFundMutation = {
  __typename?: "DeleteFundMutation"
  /** True if all changes applied successfully. If false, `errors` will be non-null. */
  ok: Scalars["Boolean"]
}

export type DeleteViewMutation = {
  __typename?: "DeleteViewMutation"
  id: Scalars["Int"]
}

/**
 * Describes a Document in the collection.
 *
 * Field values can be retrieved using `fields` or `fields_as_dict`. The latter may be more efficient for consumption
 * in some frontend use cases, but `fields` is more easily extendable to additional features.
 */
export type Document = {
  __typename?: "Document"
  /** The ID of the collection this document is in. */
  collectionId: Scalars["ID"]
  /** The ID of this document. This ID is unique across collections. This ID is assigned by the server. */
  id: Scalars["ID"]
  /** The timestamp of when the document was first created. */
  created: Scalars["DateTime"]
  /** The timestamp of when the document was last changed. */
  updated: Scalars["DateTime"]
  /** An opaque string uniquely describing the current version of the document. */
  version: Scalars["String"]
  /** An object containing keys corresponding to field slugs and values corresponding to their values. Keys will always be strings. Values will be one of the following types: null, string, string[], number, or boolean. */
  fieldsAsDict?: Maybe<Scalars["GenericScalar"]>
  /** List of files attached to this Document. */
  attachments?: Maybe<Array<Attachment>>
}

/** Describes a document in a query result. */
export type DocumentEdge = {
  __typename?: "DocumentEdge"
  /** The document. */
  document: Document
}

/**
 * Describes a single (slug, value) pair for a document.
 *
 * If `serverProvidedValue` is set, `value` must be null.
 */
export type DocumentFieldInput = {
  /** The slug of the field to create or update. */
  slug: Scalars["String"]
  /** Set the value of this field to a literal value. The entire value of the field must be specified. To update a value to null, set `value` to `null`. */
  value?: Maybe<Scalars["GenericScalar"]>
  /** Specifies that the server should provide the value for this field. If set, `value` must be null. */
  serverProvidedValue?: Maybe<ServerProvidedValue>
}

/**
 * Describes one document operation.
 *
 * Only one of `createDocument` or `updateDocument` should be set.
 */
export type DocumentOperationInput = {
  createDocument?: Maybe<CreateDocumentInput>
  updateDocument?: Maybe<UpdateDocumentInput>
  deleteDocument?: Maybe<DeleteDocumentInput>
  deleteAllDocuments?: Maybe<DeleteAllDocumentsInput>
}

/** Describes the results of a query for documents. */
export type DocumentsConnection = {
  __typename?: "DocumentsConnection"
  /** The # of total documents satisfying the query. */
  totalCount: Scalars["Int"]
  /** List of documents satisfying the query. */
  documents: Array<DocumentEdge>
  /** If pagination was requested, this will contain the page returned in `documents`. Otherwise, this will be `0`. */
  page: Scalars["Int"]
  /** If pagination was requested, this will contain the pagination size. Otherwise, this will be the same value as `total_count`. */
  rowsPerPage: Scalars["Int"]
}

/** Applies operations to Documents in a single transaction. */
export type DocumentsMutation = {
  __typename?: "DocumentsMutation"
  /** The committed state of all of the documents referenced in this mutation. */
  documents: Array<Maybe<Document>>
}

/** Describes which children should be returned with this document. */
export type DocumentsQueryChildrenInput = {
  /** The collection ID of the children to return. */
  collectionId: Scalars["ID"]
  /** The slug of the field of this child document that should reference the parent document's ID. */
  childToParentSlug: Scalars["String"]
}

/**
 * Describes a query against a collection of documents.
 *
 * Clients are responsible for pagination. In the future, this type will be extended to allow cursor-based pagination.
 */
export type DocumentsQueryInput = {
  ids: Array<Scalars["ID"]>
  /** Include child documents in the query. */
  children?: Maybe<Array<DocumentsQueryChildrenInput>>
}

/** Describes a single document that is the result of a query for duplicate documents. */
export type DuplicateDocument = {
  __typename?: "DuplicateDocument"
  id: Scalars["String"]
  createdAt: Scalars["String"]
}

/** Describes the results of a query for duplicate documents. */
export type DuplicateDocumentResult = {
  __typename?: "DuplicateDocumentResult"
  /** List of documents satisfying the query. */
  documents: Array<DuplicateDocument>
  error?: Maybe<Scalars["String"]>
}

/**
 * ExpressionInput represents conjunction or disjunction of arbitrary filters and/or expressions.
 *
 * This supports conjunctive (AND) or disjunctive (OR) queries on a list of filters and/or expressions.
 */
export type ExpressionInput = {
  /** The operator to apply to `filters` and `expressions`. */
  operator: ExpressionOperator
  /** The filters to evaluate. */
  filters?: Maybe<Array<FilterInput>>
  /** The expressions to evaluate. */
  expressions?: Maybe<Array<ExpressionInput>>
}

export enum ExpressionOperator {
  And = "AND",
  Or = "OR",
}

/** FieldDefinition describes a field. */
export type FieldDefinition = {
  __typename?: "FieldDefinition"
  /** A server-assigned unique identifier for this field. Field IDs are unique within a collection. This concept is distinct from the field ID concept used in form layout nodes. */
  id: Scalars["Int"]
  /** The title of this field. This is displayed in the filter drawer. */
  title: Scalars["String"]
  /** The slug for this field. Slugs are unique within a collection, and can be used instead of `id`field when unique references to a field are required. */
  slug: Scalars["String"]
  /** The field type. */
  fieldType: FieldType
  /** The widget type. */
  widget: FormWidgetType
  /** The default value of this field. This will be a string type, but additional types may be added in the future. */
  defaultValue?: Maybe<Scalars["GenericScalar"]>
  /** Whether or not this field is considered "required". Currently unused. */
  required: Scalars["Boolean"]
  enums?: Maybe<Array<Scalars["String"]>>
  hiddenEnums?: Maybe<Array<Scalars["String"]>>
  /** The list of operators that are valid for queries against this field. */
  availableOperators?: Maybe<Array<QueryOperator>>
}

/**
 * Relates a Form to an instance of a field.
 *
 * `options` may be used to override default options.
 */
export type FieldInstance = {
  __typename?: "FieldInstance"
  /** List of forms in which this field appears. */
  formSlug: Scalars["String"]
  /** The options that apply to this instance of a field. Null values indicate that the actual value is provided from default settings. */
  options?: Maybe<FieldOptions>
}

/**
 * Describes an instance of field configuration.
 *
 * All fields start with options equal to the options designated as default for the field. Nulls indicate that the
 * default value should be used. If non-null, the values provided override the configured default values.
 *
 * Null values on any of these fields means that the actual value should be inherited.
 */
export type FieldOptions = {
  __typename?: "FieldOptions"
  /** The human-readable title for the field. */
  title?: Maybe<Scalars["String"]>
  /** Ordered list of values to show in dropdown widget. */
  choices?: Maybe<Array<Scalars["String"]>>
  /** Ordered list of values to hide in dropdown widget. */
  hiddenChoices?: Maybe<Array<Scalars["String"]>>
  /** If True, this field is required. Currently only respected on intake forms. */
  required?: Maybe<Scalars["Boolean"]>
  /** Help text presented nearby the form field. */
  helper?: Maybe<Scalars["String"]>
  /** Default value to apply on document creation. */
  defaultValue?: Maybe<Scalars["String"]>
  /** The widget type. */
  widget?: Maybe<FormWidgetType>
  /** Opaque ID referencing configuration options for a specific instance of a field. We use an opaque ID so that the frontend UI can be unaware of the specifics of how values are stored in the backend. In thefuture, this may apply to other types of uses of fields, such as reports, or spreadsheet-style UIs, etc. */
  id: Scalars["ID"]
}

/**
 * Describes all of the settings associated with a single field instance.
 *
 * Specific `instances` may override `options` using non-null values in their `FieldInstance.options`.
 */
export type FieldSchema = {
  __typename?: "FieldSchema"
  /** The slug of this field. This is unique within the collection. */
  slug: Scalars["String"]
  /** The field type. */
  fieldType: FieldType
  /** The default field options to apply for this field. */
  options: FieldOptions
  /** All instances of this field. */
  instances?: Maybe<Array<FieldInstance>>
  /** True if this field is allowed to be archived, and false otherwise. */
  archivable: Scalars["Boolean"]
  /** True if this field is archived, false otherwise. */
  archived: Scalars["Boolean"]
}

/**
 * Describes the positional arguments needed to place a field on a form
 *
 * Form Slug will specify the form of which layout will be edited by inserting a field
 * on the given Card, Row and Column.
 */
export type FieldToFormInput = {
  /** The slug of the existing default field. */
  slug: Scalars["String"]
  /** The slug of the existing form where the field needs to be placed. */
  formSlug: Scalars["String"]
  /** Specifies the title of well from the form where you want to add the field. */
  well: Scalars["String"]
  /** Specifies specifier of the row on. */
  rowSpecifier: RowSpecifier
  /** Specifies the row number of well where you want to add field. Starts from 1. */
  row: Scalars["Int"]
  /** Specifies the column number of well where you want to add field. Starts from 1. */
  column?: Maybe<Scalars["Int"]>
}

/**
 * Describes the type of field.
 *
 * This is used primarily to influence filtering/view behavior.
 */
export enum FieldType {
  Boolean = "BOOLEAN",
  Date = "DATE",
  Datetime = "DATETIME",
  Dollar = "DOLLAR",
  Enum = "ENUM",
  ForeignKey = "FOREIGN_KEY",
  List = "LIST",
  Number = "NUMBER",
  Readonlynumber = "READONLYNUMBER",
  Readonlystring = "READONLYSTRING",
  Text = "TEXT",
}

export type FilterInput = {
  field: Scalars["String"]
  operation: QueryOperator
  operands: Array<Scalars["String"]>
}

/** Configuration for a specific feature. */
export type Flag = {
  __typename?: "Flag"
  /** Flag is a short constant identifying the feature under flag. */
  flag: Scalars["String"]
  /** If true, this flag should be respected. If false, it should be ignored. */
  enabled: Scalars["Boolean"]
  /** Arbitrary configuration information associated with the feature under flag. This will always be an Object or null. */
  extra?: Maybe<Scalars["GenericScalar"]>
}

export type Form = {
  __typename?: "Form"
  /** The slug of the form. This is unique within the containing collection. */
  formSlug: Scalars["String"]
  /** The customer-visible title of this form. */
  title: Scalars["String"]
  /** Customizations of the intake form. If unset, this is not an intake form. */
  intakeOptions?: Maybe<IntakeFormOptions>
}

/** Describes the attributes of a form. */
export type FormDescriptor = {
  /** The slug of existing form. */
  formSlug: Scalars["String"]
}

/**
 * Describes the type of field.
 *
 * This is used primarily to influence filtering/view behavior.
 */
export enum FormWidgetType {
  BooleanPositiveCheckbox = "BOOLEAN_POSITIVE_CHECKBOX",
  ChoiceDropdown = "CHOICE_DROPDOWN",
  DatePicker = "DATE_PICKER",
  DateTimePicker = "DATE_TIME_PICKER",
  EmailInput = "EMAIL_INPUT",
  Hidden = "HIDDEN",
  Integer = "INTEGER",
  MoneyNumber = "MONEY_NUMBER",
  MultiSelect = "MULTI_SELECT",
  Number = "NUMBER",
  PhoneInput = "PHONE_INPUT",
  ReadOnlyNumber = "READ_ONLY_NUMBER",
  ReadOnlyString = "READ_ONLY_STRING",
  StringInput = "STRING_INPUT",
  TextArea = "TEXT_AREA",
  ContactReference = "CONTACT_REFERENCE",
  DocumentCreatedTimestamp = "DOCUMENT_CREATED_TIMESTAMP",
  DocumentUpdatedTimestamp = "DOCUMENT_UPDATED_TIMESTAMP",
}

/** The type of a fund */
export enum FundType {
  Criminal = "Criminal",
  Immigration = "Immigration",
  Blank = "Blank",
}

export type IntakeFormOptions = {
  __typename?: "IntakeFormOptions"
  /** Text to appear at the top of the intake form. */
  preamble?: Maybe<Scalars["String"]>
  /** Text to appear at the bottom of the intake form. */
  postamble?: Maybe<Scalars["String"]>
  /** Text to appear on the confirmation page. */
  success?: Maybe<Scalars["String"]>
  /** True if intake form is disabled, otherwise false. */
  disabled?: Maybe<Scalars["Boolean"]>
  /** text to appear when the intake form is disabled. */
  disabledText?: Maybe<Scalars["String"]>
}

export type IntakeFormOptionsInput = {
  /** Text to appear at the top of the intake form. */
  preamble?: Maybe<Scalars["String"]>
  /** Text to appear at the bottom of the intake form. */
  postamble?: Maybe<Scalars["String"]>
  /** Text to appear on the confirmation page. */
  success?: Maybe<Scalars["String"]>
  /** True if intake form is disabled, otherwise false. */
  disabled?: Maybe<Scalars["Boolean"]>
  /** text to appear when the intake form is disabled. */
  disabledText?: Maybe<Scalars["String"]>
}

/** The input needed to delete a field_choices item from a field. */
export type MakeFieldMultiselectInput = {
  /** The slug of the field to for which we want to make it a multiselect the option. */
  fieldSlug: Scalars["String"]
}

/** Describes the arguments needed to update the position of well or builtin. */
export type MoveWellBuiltinInFormInput = {
  /** The selector of well or builtin. */
  wellBuiltinSelector: WellBuiltinSelector
  /** The position of well or builtin. */
  positionCoordinates: PositionCoordinates
}

/**
 * Describes all of the settings associated with default field options which can be updated.
 *
 * The title and required are mandatory fields for creating a new default field options.
 */
export type MutableDefaultFieldOptions = {
  /** The human-readable title for the field. */
  title?: Maybe<Scalars["String"]>
  /** Ordered list of values to show in dropdown widget. */
  choices?: Maybe<Array<Scalars["String"]>>
  /** Ordered list of values to hide in dropdown widget. */
  hiddenChoices?: Maybe<Array<Scalars["String"]>>
  /** If True, this field is required. Currently only respected on intake forms. */
  required?: Maybe<Scalars["Boolean"]>
  /** Help text presented nearby the form field. */
  helper?: Maybe<Scalars["String"]>
  /** Default value to apply on document creation. */
  defaultValue?: Maybe<Scalars["String"]>
  /** The widget type. */
  widget?: Maybe<FormWidgetType>
}

export type Mutations = {
  __typename?: "Mutations"
  /** Adds a user with a role to an organization */
  updateUserData?: Maybe<AddUserMutation>
  createView?: Maybe<CreateViewMutation>
  updateView?: Maybe<UpdateViewMutation>
  deleteView?: Maybe<DeleteViewMutation>
  /** Update flags for an organization. */
  updateFlags?: Maybe<OrganizationFlagsMutation>
  /** Update email address for an organization. */
  updateEmail?: Maybe<OrganizationEmailMutation>
  /**
   * SignLegalDocsMutation marks TOS and PP signed for this user, for all bail funds.
   *
   * TODO(ch5395)
   */
  signLegalDocs?: Maybe<SignLegalDocsMutation>
  /** Applies operations to Documents in a single transaction. */
  documents?: Maybe<DocumentsMutation>
  /** Applies operations to Documents in a single transaction. */
  updateDocumentsAndSchema?: Maybe<UpdateDocumentsAndSchema>
  /** Create new bail fund from template */
  createFund?: Maybe<CreateFundMutation>
  /** Delete a bail fund */
  deleteFund?: Maybe<DeleteFundMutation>
  /**
   * Updates a collection schema.
   *
   * Updates are expressed as a list of changes via the `updates` argument. One or more changes can be requested. All
   * changes will occur in a single transaction. Validation is deferred until after all changes are applied.
   */
  updateCollectionSchema?: Maybe<UpdateCollectionSchema>
  /** Updates the raw collection schema. */
  updateRawCollectionSchema?: Maybe<UpdateRawCollectionSchema>
  /** Updates the organization config. */
  updateRawOrganizationConfig?: Maybe<UpdateRawOrganizationConfig>
}

export type MutationsUpdateUserDataArgs = {
  bailFundSlugs: Array<Scalars["String"]>
  email: Scalars["String"]
  name: Scalars["String"]
  role: UserRole
}

export type MutationsCreateViewArgs = {
  collectionId: Scalars["ID"]
  view: ViewDefinitionInput
}

export type MutationsUpdateViewArgs = {
  collectionId: Scalars["ID"]
  view: ViewDefinitionInput
  viewId: Scalars["Int"]
}

export type MutationsDeleteViewArgs = {
  collectionId: Scalars["ID"]
  viewId: Scalars["Int"]
}

export type MutationsUpdateFlagsArgs = {
  flagName: Scalars["String"]
  flagValue: Scalars["Boolean"]
  organizationId: Scalars["ID"]
}

export type MutationsUpdateEmailArgs = {
  email: Scalars["String"]
  organizationId: Scalars["ID"]
}

export type MutationsDocumentsArgs = {
  operations: Array<DocumentOperationInput>
}

export type MutationsUpdateDocumentsAndSchemaArgs = {
  collectionId: Scalars["ID"]
  update: UpdateDocumentsAndCollectionInput
}

export type MutationsCreateFundArgs = {
  city: Scalars["String"]
  name: Scalars["String"]
  slug: Scalars["String"]
  state: Scalars["String"]
  timezone: Timezone
  type: FundType
}

export type MutationsDeleteFundArgs = {
  slug: Scalars["String"]
}

export type MutationsUpdateCollectionSchemaArgs = {
  collectionId: Scalars["String"]
  dryRun?: Maybe<Scalars["Boolean"]>
  organizationId: Scalars["String"]
  updates?: Maybe<Array<UpdateCollectionSchemaInput>>
}

export type MutationsUpdateRawCollectionSchemaArgs = {
  collectionId: Scalars["String"]
  newPschema: Scalars["String"]
  organizationId: Scalars["String"]
}

export type MutationsUpdateRawOrganizationConfigArgs = {
  newConfig: Scalars["String"]
  organizationId: Scalars["String"]
}

export type Organization = {
  __typename?: "Organization"
  bailFundSlug: Scalars["String"]
  /** The Flex Organization ID. */
  organizationId: Scalars["ID"]
  name: Scalars["String"]
  fullName: Scalars["String"]
  timezone: Scalars["String"]
  legalDocsSigned: Scalars["Boolean"]
  collections: Array<Collection>
  /** The order of collections as they should appear in the navigation elements of the UI. */
  collectionOrder?: Maybe<Array<Scalars["String"]>>
  /** Feature flags for this organization. */
  features?: Maybe<Array<Flag>>
  email?: Maybe<Scalars["String"]>
}

/** Update email address for an organization. */
export type OrganizationEmailMutation = {
  __typename?: "OrganizationEmailMutation"
  /** Returns ok if the operation was successful. */
  ok: Scalars["Boolean"]
}

/** Update flags for an organization. */
export type OrganizationFlagsMutation = {
  __typename?: "OrganizationFlagsMutation"
  /** Returns the info of the given organization. */
  organization: Organization
}

export type OrganizationLite = {
  __typename?: "OrganizationLite"
  bailFundSlug: Scalars["String"]
  /** The Flex Organization ID. */
  organizationId: Scalars["ID"]
  fullName: Scalars["String"]
}

/**
 * Describes the PSchema Field Type of field.
 *
 * This primarily sets the Field type and is used to retrieve associated Form Widget type.
 */
export enum PSchemaFieldType {
  Boolean = "BOOLEAN",
  Date = "DATE",
  Datetime = "DATETIME",
  Dollar = "DOLLAR",
  Enum = "ENUM",
  Number = "NUMBER",
  Text = "TEXT",
  ListOfText = "LIST_OF_TEXT",
}

export type Pdf = {
  __typename?: "Pdf"
  /** The title of the PDF. */
  title: Scalars["String"]
  /** The URL to the fillable PDF. */
  sourcePdfUrl: Scalars["String"]
  fieldMapping?: Maybe<Array<Maybe<PdfFieldMapping>>>
}

export type PdfFieldMapping = {
  __typename?: "PdfFieldMapping"
  /** The name of the field slug that is being mapped. */
  slug: Scalars["String"]
  /** The name of the field in the PDF that `slug` should be mapped to. */
  pdfFieldName?: Maybe<Scalars["String"]>
}

/** Describes the positional coordinates associated with a well or builtin within a form. */
export type PositionCoordinates = {
  /** Specifies the row number of layout where you want to add the well or builtin. Starts from 1. */
  row: Scalars["Int"]
  /** Specifies the column number of layout where you want to add the well or builtin. Starts from 1. */
  column: Scalars["Int"]
}

export type PreconditionInput = {
  /** If set, the operation will fail unless the document being modified has a matching version. Not yet implemented. */
  version?: Maybe<Scalars["String"]>
}

export type PschemaSettings = {
  __typename?: "PschemaSettings"
  /** The slugs of the uids used for searching a record. */
  idSearchSlugs: Array<Scalars["String"]>
  /** The slugs of any fields used for searching client name. */
  nameSearchSlugs: Array<Scalars["String"]>
  /** The name(s) of the fields that are used for checking duplicates. */
  duplicateFieldsChecked?: Maybe<Array<Scalars["String"]>>
  /** The slugs of fields that are used to check if a record is a duplicate */
  duplicateFieldSlugs?: Maybe<Array<Scalars["String"]>>
  /** The slug of the field that indicates whether this record is a duplicate */
  isDuplicateSlug?: Maybe<Scalars["String"]>
  /** The slug that contains the referrer's email address on the intake form */
  referrerEmailSlug?: Maybe<Scalars["String"]>
}

export type Queries = {
  __typename?: "Queries"
  /** Returns a list of all the users of a bailfund. */
  listUsers: Array<Maybe<UserInfo>>
  /** Returns the list of bail funds affiliated with this user. */
  userInfo: User
  /** Returns the info of the given organization. */
  organization: Organization
  /** Returns the results of a query for duplicate documents */
  duplicateQueryResults: DuplicateDocumentResult
  /** Returns the results of a query. */
  documents: DocumentsConnection
  /** Returns the raw (stringified JSON) pschema for the collection. */
  rawCollectionPschema: Scalars["String"]
  /** Describes the schema of the collections. */
  collectionSchemas?: Maybe<Array<CollectionSchema>>
  /** Creates a downloadable bundle describing all schema configs. */
  orgExport?: Maybe<Scalars["String"]>
  /** Returns the raw (stringified JSON) organization config. */
  rawOrganizationConfig: Scalars["String"]
  /** Returns the results of a query. */
  queryResults: DocumentsConnection
}

export type QueriesListUsersArgs = {
  organizationId: Scalars["String"]
}

export type QueriesOrganizationArgs = {
  organizationId: Scalars["ID"]
}

export type QueriesDuplicateQueryResultsArgs = {
  collectionId: Scalars["ID"]
  documentId: Scalars["String"]
}

export type QueriesDocumentsArgs = {
  collectionId: Scalars["ID"]
  query: DocumentsQueryInput
}

export type QueriesRawCollectionPschemaArgs = {
  collectionId: Scalars["ID"]
}

export type QueriesCollectionSchemasArgs = {
  organizationId: Scalars["ID"]
}

export type QueriesOrgExportArgs = {
  organizationId: Scalars["ID"]
}

export type QueriesRawOrganizationConfigArgs = {
  organizationId: Scalars["ID"]
}

export type QueriesQueryResultsArgs = {
  collectionId: Scalars["ID"]
  query: QueryInput
}

export type QueryInput = {
  page: Scalars["Int"]
  rowsPerPage: Scalars["Int"]
  expression?: Maybe<ExpressionInput>
  visibleFieldSlugs?: Maybe<Array<Scalars["String"]>>
}

/** Represents the set of operators that may be used in a query. */
export enum QueryOperator {
  After = "after",
  Before = "before",
  Between = "between",
  Contains = "contains",
  DoesNotContain = "does_not_contain",
  EndsWith = "ends_with",
  GreaterThan = "greater_than",
  IsEmpty = "is_empty",
  IsEqualTo = "is_equal_to",
  IsExactly = "is_exactly",
  IsFalse = "is_false",
  IsNotEmpty = "is_not_empty",
  IsNotExactly = "is_not_exactly",
  IsTrue = "is_true",
  LessThan = "less_than",
  OrderBy = "order_by",
  StartsWith = "starts_with",
}

/** Describes the arguments needed to remove all fields from a well. */
export type RemoveAllFieldsFromWellInForm = {
  /** The description of a well. */
  well: WellDescriptor
  /** The description of form. */
  form: FormDescriptor
}

/**
 * Gives the field slug to be removed from form.
 *
 * Form Slug will specify the form of which layout will be edited for deleting a field from layout.
 */
export type RemoveFieldFromFormInput = {
  /** The slug of the existing default field. */
  slug: Scalars["String"]
  /** The slug of the existing form. */
  formSlug: Scalars["String"]
}

/**
 * Describes the arguments needed to remove a horizontal rule from a well in a form.
 *
 * Row number will specify the the current position of the horizontal rule on the well.
 */
export type RemoveHorizontalRuleFromWellInForm = {
  /** The selector of well. */
  wellSelector: WellSelector
  /** Specifies the row number of well. Starts from 1. */
  row: Scalars["Int"]
}

/** Describes the arguments needed to remove a well or builtin from a form. */
export type RemoveWellBuiltinFromFromInput = {
  /** The description of well. */
  well?: Maybe<WellDescriptor>
  /** The description of builtin. */
  builtin?: Maybe<BuiltinDescriptor>
  /** The description of form. */
  form: FormDescriptor
}

/** Describes the Specifier of Layout row option. */
export enum RowSpecifier {
  After = "AFTER",
  Before = "BEFORE",
  Specific = "SPECIFIC",
}

export type SchemaViewExpressionInput = {
  operator: ExpressionOperator
  filters?: Maybe<Array<SchemaViewFilterInput>>
  expressions?: Maybe<Array<SchemaViewExpressionInput>>
}

export type SchemaViewExpressionOutput = {
  __typename?: "SchemaViewExpressionOutput"
  /** The type of operation this expression represents. */
  operator: ExpressionOperator
  /** The list of single filters this expression has. */
  filters?: Maybe<Array<SchemaViewFilterOutput>>
  /** The list of nested filter expressions this expression has. */
  expressions?: Maybe<Array<SchemaViewExpressionOutput>>
}

export type SchemaViewFilterInput = {
  field: Scalars["String"]
  operation: QueryOperator
  operands: Array<Scalars["String"]>
}

export type SchemaViewFilterOutput = {
  __typename?: "SchemaViewFilterOutput"
  /** The slug of the field that this operation applies to. */
  field: Scalars["String"]
  /** The operation to apply to the field referenced by `field`. */
  operation: QueryOperator
  /** The list of operands to provide to the operator specified by `operation`. */
  operands: Array<Scalars["String"]>
}

export type SchemaViewInput = {
  fields: Array<Scalars["String"]>
  order: Array<Scalars["String"]>
  mode?: Maybe<ViewMode>
  expression?: Maybe<SchemaViewExpressionInput>
}

export type SchemaViewOutput = {
  __typename?: "SchemaViewOutput"
  /** The list of columns that are visible in this view. */
  fields: Array<Scalars["String"]>
  /** The order of the visible columns. */
  order: Array<Scalars["String"]>
  /** The type of filters this view has. */
  mode?: Maybe<ViewMode>
  /** Defines the filtering behavior of this view. */
  expression?: Maybe<SchemaViewExpressionOutput>
}

/** Describes the types of field values that the server can provide when a document is being created or updated. */
export enum ServerProvidedValue {
  /** Server will store an identifier for the current user */
  CurrentUser = "CURRENT_USER",
  /** Server will store the current time. */
  CurrentTimestamp = "CURRENT_TIMESTAMP",
}

/**
 * SignLegalDocsMutation marks TOS and PP signed for this user, for all bail funds.
 *
 * TODO(ch5395)
 */
export type SignLegalDocsMutation = {
  __typename?: "SignLegalDocsMutation"
  ok: Scalars["Boolean"]
}

/** Timezone options for a fund */
export enum Timezone {
  Eastern = "Eastern",
  Central = "Central",
  Mountain = "Mountain",
  Pacific = "Pacific",
  Alaska = "Alaska",
  Hawaii = "Hawaii",
}

/**
 * Updates a collection schema.
 *
 * Updates are expressed as a list of changes via the `updates` argument. One or more changes can be requested. All
 * changes will occur in a single transaction. Validation is deferred until after all changes are applied.
 */
export type UpdateCollectionSchema = {
  __typename?: "UpdateCollectionSchema"
  /** True if all changes applied successfully. If false, `errors` will be non-null. */
  ok: Scalars["Boolean"]
  /** Describes any errors in the proposed configuration. Only set if `ok` is false. */
  errors?: Maybe<Array<ValidationError>>
  /** The schema, after changes (if any) were applied. If there was an error, then this will be the latest persisted value. */
  collectionSchema: CollectionSchema
}

/**
 * Describes a single change to a collection schema.
 *
 * Only one of the values in this type may be set.
 */
export type UpdateCollectionSchemaInput = {
  /** Update the `FieldOption` of a specific field. */
  updateFieldOption?: Maybe<UpdateFieldOptionInput>
  /** Updates descriptive strings attached to a form. */
  updateForm?: Maybe<UpdateFormInput>
  /** Adds a new default field. */
  addDefaultField?: Maybe<DefaultFieldSchemaInput>
  /** Adds an existing default field to an existing form with positional arguments. */
  addFieldToForm?: Maybe<FieldToFormInput>
  /** Archive an existing default field. */
  archiveField?: Maybe<UpdatePschemaSingleFieldInput>
  /** Delete an existing default field. */
  deleteField?: Maybe<UpdatePschemaSingleFieldInput>
  /** Unarchive an existing default field. */
  unarchiveField?: Maybe<UpdatePschemaSingleFieldInput>
  /** Remove an existing field from a form. */
  removeFieldFromForm?: Maybe<RemoveFieldFromFormInput>
  /** Add a new well or builtin to a form. */
  addWellBuiltinToForm?: Maybe<AddWellBuiltinToFormInput>
  /** Removes a well or builtin from a form. */
  removeWellBuiltinFromForm?: Maybe<RemoveWellBuiltinFromFromInput>
  /** Moves a well or builtin to new position in a form. */
  moveWellBuiltinInForm?: Maybe<MoveWellBuiltinInFormInput>
  /** Updates a well title in a form. */
  updateWellBuiltinTitleInForm?: Maybe<UpdateWellBuiltinTitleInForm>
  /** Removes all fields from a well in a form. */
  removeAllFieldsFromWellInForm?: Maybe<RemoveAllFieldsFromWellInForm>
  /** Adds horizontal rule to well in form. */
  addHorizontalRuleToWellInForm?: Maybe<AddHorizontalRuleToWellInForm>
  /** Removes horizontal rule from well in form. */
  removeHorizontalRuleFromWellInForm?: Maybe<RemoveHorizontalRuleFromWellInForm>
  updateReferrerEmailSlugSetting?: Maybe<UpdatePschemaSingleFieldInput>
  updateIdSearchSlugSetting?: Maybe<UpdatePschemaMultiFieldInput>
  updateNameSearchSlugSetting?: Maybe<UpdatePschemaMultiFieldInput>
  updateDuplicateCheckingSetting?: Maybe<UpdatePschemaDuplicateCheckingInput>
}

export type UpdateDocumentInput = {
  /** The ID of the collection that this document is in. */
  collectionId: Scalars["ID"]
  /** The ID of the document to update. */
  id: Scalars["ID"]
  /** Defines the values to update in the document. May specify a partial update. */
  fields: Array<DocumentFieldInput>
  precondition?: Maybe<PreconditionInput>
}

/**
 * Describes a single change to a collection schema.
 *
 * Only one of the values in this type may be set.
 */
export type UpdateDocumentsAndCollectionInput = {
  /** Update the `FieldOption` of a specific field. */
  updateFieldChoicesItem?: Maybe<UpdateFieldChoicesItemInput>
  /** Delete the `FieldOption` of a specific field. */
  deleteFieldChoicesItem?: Maybe<DeleteFieldChoicesItemInput>
  /** Make a enum field a multiselect field. */
  makeFieldAMultiselect?: Maybe<MakeFieldMultiselectInput>
}

/** Applies operations to Documents in a single transaction. */
export type UpdateDocumentsAndSchema = {
  __typename?: "UpdateDocumentsAndSchema"
  /** True if all changes applied successfully. If false, `errors` will be non-null. */
  ok: Scalars["Boolean"]
  /** Describes any errors in the proposed configuration. Only set if `ok` is false. */
  errors?: Maybe<Array<ValidationError>>
  /** The schema, after changes (if any) were applied. If there was an error, then this will be the latest persisted value. */
  collectionSchema: CollectionSchema
}

/** The input needed to update a field_choices item in a field. */
export type UpdateFieldChoicesItemInput = {
  /** The slug of the field to for which we want to delete the option. */
  fieldSlug: Scalars["String"]
  /** The dropdown option to update */
  itemToUpdate: Scalars["String"]
  /** Anything with the deleted value will be set to this instead */
  newItemValue?: Maybe<Scalars["String"]>
}

/**
 * Describes the mutable options for a field.
 *
 * If these options are used as overrides on a specific form, setting the value to null will remove the override.
 */
export type UpdateFieldOptionInput = {
  /** The human-readable title for the field. */
  title?: Maybe<Scalars["String"]>
  /** Ordered list of values to show in dropdown widget. */
  choices?: Maybe<Array<Scalars["String"]>>
  /** Ordered list of values to hide in dropdown widget. */
  hiddenChoices?: Maybe<Array<Scalars["String"]>>
  /** If True, this field is required. Currently only respected on intake forms. */
  required?: Maybe<Scalars["Boolean"]>
  /** Help text presented nearby the form field. */
  helper?: Maybe<Scalars["String"]>
  /** Default value to apply on document creation. */
  defaultValue?: Maybe<Scalars["String"]>
  /** The widget type. */
  widget?: Maybe<FormWidgetType>
  /** Opaque ID referencing configuration options for a specific instance of a field. We use an opaque ID so that the frontend UI can be unaware of the specifics of how values are stored in the backend. In thefuture, this may apply to other types of uses of fields, such as reports, or spreadsheet-style UIs, etc. */
  id: Scalars["ID"]
}

export type UpdateFormInput = {
  /** The slug of the form. This is unique within the containing collection. */
  formSlug: Scalars["String"]
  /** The customer-visible title of this form. */
  title: Scalars["String"]
  /** Customizations of the intake form. If null, this is not an intake form. */
  intakeOptions?: Maybe<IntakeFormOptionsInput>
}

/** Specifies new duplicate checking settings */
export type UpdatePschemaDuplicateCheckingInput = {
  slugsToCheck?: Maybe<Array<Scalars["String"]>>
  /** The slug of the existing default field. */
  slugToUpdate?: Maybe<Scalars["String"]>
}

/** Specifies the new list of field slugs. */
export type UpdatePschemaMultiFieldInput = {
  slugs?: Maybe<Array<Scalars["String"]>>
}

/** Specifies the new field slug. */
export type UpdatePschemaSingleFieldInput = {
  /** The slug of the field. */
  slug: Scalars["String"]
}

/** Updates the raw collection schema. */
export type UpdateRawCollectionSchema = {
  __typename?: "UpdateRawCollectionSchema"
  /** True if all changes applied successfully. If false, `errors` will be non-null. */
  ok: Scalars["Boolean"]
  /** Error message of ok == False */
  error?: Maybe<Scalars["String"]>
  /** The raw string-formatted pschema before changes were applied. */
  oldCollectionSchema: Scalars["String"]
  /** The raw string-formatted pschema after changes were applied. Will be null if there was an error */
  collectionSchema?: Maybe<Scalars["String"]>
}

/** Updates the organization config. */
export type UpdateRawOrganizationConfig = {
  __typename?: "UpdateRawOrganizationConfig"
  /** True if all changes applied successfully. If false, `errors` will be non-null. */
  ok: Scalars["Boolean"]
  /** Error message of ok == False */
  error?: Maybe<Scalars["String"]>
  /** The raw string-formatted pschema before changes were applied. */
  oldOrganizationConfig: Scalars["String"]
  /** The raw string-formatted pschema after changes were applied. Will be null if there was an error */
  organizationConfig?: Maybe<Scalars["String"]>
}

export type UpdateViewMutation = {
  __typename?: "UpdateViewMutation"
  view: ViewDefinitionOutput
}

/** Describes the arguments needed to update the title of a well or builtin. */
export type UpdateWellBuiltinTitleInForm = {
  /** The description of well. */
  well?: Maybe<WellDescriptor>
  /** The description of builtin. */
  builtin?: Maybe<BuiltinDescriptor>
  /** The description of form. */
  form: FormDescriptor
  /** The new title of the well/builtin. */
  newTitle?: Maybe<Scalars["String"]>
}

export type User = {
  __typename?: "User"
  userId: Scalars["String"]
  /** Information about the current user's affiliations with bail funds. */
  organizations?: Maybe<Array<OrganizationLite>>
  /** Feature flags for this user. */
  flags?: Maybe<Array<UserFeatureFlag>>
}

/** UserFeatureFlag describes which frontend features this user has access to. */
export enum UserFeatureFlag {
  CollectionEditor = "COLLECTION_EDITOR",
  UserAdmin = "USER_ADMIN",
}

/** All the key user info. */
export type UserInfo = {
  __typename?: "UserInfo"
  userId: Scalars["ID"]
  email: Scalars["String"]
  name: Scalars["String"]
  createdDate: Scalars["String"]
  role: UserRole
  lastLogin?: Maybe<Scalars["String"]>
  lastUpdated?: Maybe<Scalars["String"]>
  lastPasswordReset?: Maybe<Scalars["String"]>
}

/** The role of a user in an organization. */
export enum UserRole {
  Volunteer = "VOLUNTEER",
  Staff = "STAFF",
  Admin = "ADMIN",
}

/** Describes a single validation error. */
export type ValidationError = {
  __typename?: "ValidationError"
  message: Scalars["String"]
}

export type ViewDefinitionInput = {
  name: Scalars["String"]
  schema: SchemaViewInput
}

export type ViewDefinitionOutput = {
  __typename?: "ViewDefinitionOutput"
  /** Server-assigned identifier for this view. It is unique within the collection. */
  id: Scalars["Int"]
  /** Short label for this view. To be displayed in user-visible view selector. */
  name: Scalars["String"]
  /** If true, this view is immutable and eligible to be chosen as the default view. */
  default: Scalars["Boolean"]
  /** The definition of this view. */
  schema: SchemaViewOutput
}

export enum ViewMode {
  Simple = "SIMPLE",
  Advanced = "ADVANCED",
}

/**
 * Describes the selection of either well or builtin within a form.
 *
 * Only one of well or builtin should be selected.
 */
export type WellBuiltinSelector = {
  /** The description of well. */
  well?: Maybe<WellDescriptor>
  /** The description of builtin. */
  builtin?: Maybe<BuiltinDescriptor>
  /** The description of form. */
  form: FormDescriptor
}

/** Describes the attributes of a well. */
export type WellDescriptor = {
  /** The title of well. */
  wellTitle?: Maybe<Scalars["String"]>
}

/** Describes the selection of a well within a form. */
export type WellSelector = {
  /** The description of a well. */
  well: WellDescriptor
  /** The description of form. */
  form: FormDescriptor
}

export type AttachmentDocumentFieldsFragment = {
  __typename?: "Document"
} & Pick<Document, "collectionId" | "id"> & {
    attachments?: Maybe<
      Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "filename" | "id" | "url"
        >
      >
    >
  }

export type DocumentAttachmentsGetQueryVariables = Exact<{
  collectionId: Scalars["ID"]
  documentId: Scalars["ID"]
}>

export type DocumentAttachmentsGetQuery = { __typename?: "Queries" } & {
  documents: { __typename?: "DocumentsConnection" } & {
    documents: Array<
      { __typename?: "DocumentEdge" } & {
        document: { __typename?: "Document" } & AttachmentDocumentFieldsFragment
      }
    >
  }
}

export type FormFragFragment = { __typename?: "Form" } & Pick<
  Form,
  "formSlug" | "title"
> & {
    intakeOptions?: Maybe<
      { __typename?: "IntakeFormOptions" } & Pick<
        IntakeFormOptions,
        "postamble" | "preamble" | "success" | "disabled" | "disabledText"
      >
    >
  }

export type FieldOpsFragFragment = { __typename?: "FieldOptions" } & Pick<
  FieldOptions,
  | "hiddenChoices"
  | "choices"
  | "defaultValue"
  | "helper"
  | "id"
  | "title"
  | "required"
  | "widget"
>

export type UpdateCollectionSchemaErrorsFragmentFragment = {
  __typename?: "UpdateCollectionSchema"
} & Pick<UpdateCollectionSchema, "ok"> & {
    errors?: Maybe<
      Array<
        { __typename?: "ValidationError" } & Pick<ValidationError, "message">
      >
    >
  }

export type MutateCollectionSchemaAndDocumentsErrorsFragmentFragment = {
  __typename?: "UpdateDocumentsAndSchema"
} & Pick<UpdateDocumentsAndSchema, "ok"> & {
    errors?: Maybe<
      Array<
        { __typename?: "ValidationError" } & Pick<ValidationError, "message">
      >
    >
  }

export type ExportOrganizationSchemasQueryVariables = Exact<{
  organizationId: Scalars["ID"]
}>

export type ExportOrganizationSchemasQuery = { __typename?: "Queries" } & Pick<
  Queries,
  "orgExport"
>

export type GetRawCollectionPschemaQueryVariables = Exact<{
  collectionId: Scalars["ID"]
}>

export type GetRawCollectionPschemaQuery = { __typename?: "Queries" } & Pick<
  Queries,
  "rawCollectionPschema"
>

export type GetRawOrganizationConfigQueryVariables = Exact<{
  organizationId: Scalars["ID"]
}>

export type GetRawOrganizationConfigQuery = { __typename?: "Queries" } & Pick<
  Queries,
  "rawOrganizationConfig"
>

export type DescribeCollectionSchemasQueryVariables = Exact<{
  organizationId: Scalars["ID"]
}>

export type DescribeCollectionSchemasQuery = { __typename?: "Queries" } & {
  collectionSchemas?: Maybe<
    Array<
      { __typename?: "CollectionSchema" } & Pick<
        CollectionSchema,
        "collectionId" | "slug" | "title"
      > & {
          fields?: Maybe<
            Array<
              { __typename?: "FieldSchema" } & Pick<
                FieldSchema,
                "slug" | "archivable" | "archived" | "fieldType"
              > & {
                  instances?: Maybe<
                    Array<
                      { __typename?: "FieldInstance" } & Pick<
                        FieldInstance,
                        "formSlug"
                      > & {
                          options?: Maybe<
                            {
                              __typename?: "FieldOptions"
                            } & FieldOpsFragFragment
                          >
                        }
                    >
                  >
                  options: {
                    __typename?: "FieldOptions"
                  } & FieldOpsFragFragment
                }
            >
          >
          forms?: Maybe<Array<{ __typename?: "Form" } & FormFragFragment>>
          stats?: Maybe<
            { __typename?: "CollectionStats" } & Pick<
              CollectionStats,
              "numDocs" | "earliest" | "latest"
            >
          >
        }
    >
  >
}

export type UpdateFieldOptionChoiceMutationVariables = Exact<{
  collectionId: Scalars["ID"]
  fieldSlug: Scalars["String"]
  itemToUpdate: Scalars["String"]
  newItemValue?: Maybe<Scalars["String"]>
}>

export type UpdateFieldOptionChoiceMutation = { __typename?: "Mutations" } & {
  updateDocumentsAndSchema?: Maybe<
    { __typename?: "UpdateDocumentsAndSchema" } & {
      collectionSchema: { __typename?: "CollectionSchema" } & {
        fields?: Maybe<
          Array<
            { __typename?: "FieldSchema" } & Pick<FieldSchema, "slug"> & {
                instances?: Maybe<
                  Array<
                    { __typename?: "FieldInstance" } & Pick<
                      FieldInstance,
                      "formSlug"
                    > & {
                        options?: Maybe<
                          { __typename?: "FieldOptions" } & FieldOpsFragFragment
                        >
                      }
                  >
                >
                options: { __typename?: "FieldOptions" } & FieldOpsFragFragment
              }
          >
        >
      }
    } & MutateCollectionSchemaAndDocumentsErrorsFragmentFragment
  >
}

export type DeleteFieldOptionChoiceMutationVariables = Exact<{
  collectionId: Scalars["ID"]
  fieldSlug: Scalars["String"]
  itemToDelete: Scalars["String"]
  newItemValue?: Maybe<Scalars["String"]>
}>

export type DeleteFieldOptionChoiceMutation = { __typename?: "Mutations" } & {
  updateDocumentsAndSchema?: Maybe<
    { __typename?: "UpdateDocumentsAndSchema" } & {
      collectionSchema: { __typename?: "CollectionSchema" } & {
        fields?: Maybe<
          Array<
            { __typename?: "FieldSchema" } & Pick<FieldSchema, "slug"> & {
                instances?: Maybe<
                  Array<
                    { __typename?: "FieldInstance" } & Pick<
                      FieldInstance,
                      "formSlug"
                    > & {
                        options?: Maybe<
                          { __typename?: "FieldOptions" } & FieldOpsFragFragment
                        >
                      }
                  >
                >
                options: { __typename?: "FieldOptions" } & FieldOpsFragFragment
              }
          >
        >
      }
    } & MutateCollectionSchemaAndDocumentsErrorsFragmentFragment
  >
}

export type MakeFieldAMultiselectMutationVariables = Exact<{
  collectionId: Scalars["ID"]
  fieldSlug: Scalars["String"]
}>

export type MakeFieldAMultiselectMutation = { __typename?: "Mutations" } & {
  updateDocumentsAndSchema?: Maybe<
    { __typename?: "UpdateDocumentsAndSchema" } & {
      collectionSchema: { __typename?: "CollectionSchema" } & {
        fields?: Maybe<
          Array<
            { __typename?: "FieldSchema" } & Pick<FieldSchema, "slug"> & {
                instances?: Maybe<
                  Array<
                    { __typename?: "FieldInstance" } & Pick<
                      FieldInstance,
                      "formSlug"
                    > & {
                        options?: Maybe<
                          { __typename?: "FieldOptions" } & FieldOpsFragFragment
                        >
                      }
                  >
                >
                options: { __typename?: "FieldOptions" } & FieldOpsFragFragment
              }
          >
        >
      }
    } & MutateCollectionSchemaAndDocumentsErrorsFragmentFragment
  >
}

export type MultiUpdateCollectionSchemaMutationVariables = Exact<{
  collectionId: Scalars["String"]
  organizationId: Scalars["String"]
  updates?: Maybe<Array<UpdateCollectionSchemaInput>>
}>

export type MultiUpdateCollectionSchemaMutation = {
  __typename?: "Mutations"
} & {
  updateCollectionSchema?: Maybe<
    { __typename?: "UpdateCollectionSchema" } & {
      collectionSchema: { __typename?: "CollectionSchema" } & {
        fields?: Maybe<
          Array<{ __typename?: "FieldSchema" } & Pick<FieldSchema, "slug">>
        >
      }
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type UpdateRawCollectionSchemaMutationVariables = Exact<{
  collectionId: Scalars["String"]
  organizationId: Scalars["String"]
  newPschema: Scalars["String"]
}>

export type UpdateRawCollectionSchemaMutation = { __typename?: "Mutations" } & {
  updateRawCollectionSchema?: Maybe<
    { __typename?: "UpdateRawCollectionSchema" } & Pick<
      UpdateRawCollectionSchema,
      "ok" | "error" | "collectionSchema" | "oldCollectionSchema"
    >
  >
}

export type UpdateRawOrganizationConfigMutationVariables = Exact<{
  organizationId: Scalars["String"]
  newConfig: Scalars["String"]
}>

export type UpdateRawOrganizationConfigMutation = {
  __typename?: "Mutations"
} & {
  updateRawOrganizationConfig?: Maybe<
    { __typename?: "UpdateRawOrganizationConfig" } & Pick<
      UpdateRawOrganizationConfig,
      "ok" | "error" | "organizationConfig" | "oldOrganizationConfig"
    >
  >
}

export type ReplaceFormOptionsForIntakeMutationVariables = Exact<{
  formSlug: Scalars["String"]
  collectionId: Scalars["String"]
  organizationId: Scalars["String"]
  post?: Maybe<Scalars["String"]>
  pre?: Maybe<Scalars["String"]>
  success?: Maybe<Scalars["String"]>
  disabled?: Maybe<Scalars["Boolean"]>
  disabledText?: Maybe<Scalars["String"]>
  title: Scalars["String"]
}>

export type ReplaceFormOptionsForIntakeMutation = {
  __typename?: "Mutations"
} & {
  updateCollectionSchema?: Maybe<
    { __typename?: "UpdateCollectionSchema" } & {
      collectionSchema: { __typename?: "CollectionSchema" } & Pick<
        CollectionSchema,
        "slug"
      > & { forms?: Maybe<Array<{ __typename?: "Form" } & FormFragFragment>> }
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type ReplaceFormOptionsForNotIntakeMutationVariables = Exact<{
  formSlug: Scalars["String"]
  collectionId: Scalars["String"]
  organizationId: Scalars["String"]
  title: Scalars["String"]
}>

export type ReplaceFormOptionsForNotIntakeMutation = {
  __typename?: "Mutations"
} & {
  updateCollectionSchema?: Maybe<
    { __typename?: "UpdateCollectionSchema" } & {
      collectionSchema: { __typename?: "CollectionSchema" } & Pick<
        CollectionSchema,
        "slug"
      > & { forms?: Maybe<Array<{ __typename?: "Form" } & FormFragFragment>> }
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type AddDefaultFieldMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  fieldSlug: Scalars["String"]
  fieldType: PSchemaFieldType
  defaultValue?: Maybe<Scalars["String"]>
  choices?: Maybe<Array<Scalars["String"]>>
  title: Scalars["String"]
  required: Scalars["Boolean"]
  helper?: Maybe<Scalars["String"]>
  hiddenChoices?: Maybe<Array<Scalars["String"]>>
}>

export type AddDefaultFieldMutation = { __typename?: "Mutations" } & {
  updateCollectionSchema?: Maybe<
    { __typename?: "UpdateCollectionSchema" } & {
      collectionSchema: { __typename?: "CollectionSchema" } & {
        fields?: Maybe<
          Array<
            { __typename?: "FieldSchema" } & Pick<FieldSchema, "slug"> & {
                options: { __typename?: "FieldOptions" } & FieldOpsFragFragment
                instances?: Maybe<
                  Array<
                    { __typename?: "FieldInstance" } & Pick<
                      FieldInstance,
                      "formSlug"
                    > & {
                        options?: Maybe<
                          { __typename?: "FieldOptions" } & FieldOpsFragFragment
                        >
                      }
                  >
                >
              }
          >
        >
      }
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type AddFieldToFormMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  fieldSlug: Scalars["String"]
  formSlug: Scalars["String"]
  card: Scalars["String"]
  rowSpecifier: RowSpecifier
  row: Scalars["Int"]
  column?: Maybe<Scalars["Int"]>
}>

export type AddFieldToFormMutation = { __typename?: "Mutations" } & {
  updateCollectionSchema?: Maybe<
    { __typename?: "UpdateCollectionSchema" } & {
      collectionSchema: { __typename?: "CollectionSchema" } & {
        fields?: Maybe<
          Array<
            { __typename?: "FieldSchema" } & Pick<FieldSchema, "slug"> & {
                options: { __typename?: "FieldOptions" } & FieldOpsFragFragment
                instances?: Maybe<
                  Array<
                    { __typename?: "FieldInstance" } & Pick<
                      FieldInstance,
                      "formSlug"
                    > & {
                        options?: Maybe<
                          { __typename?: "FieldOptions" } & FieldOpsFragFragment
                        >
                      }
                  >
                >
              }
          >
        >
      }
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type RemoveFieldFromFormMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  fieldSlug: Scalars["String"]
  formSlug: Scalars["String"]
}>

export type RemoveFieldFromFormMutation = { __typename?: "Mutations" } & {
  updateCollectionSchema?: Maybe<
    { __typename?: "UpdateCollectionSchema" } & {
      collectionSchema: { __typename?: "CollectionSchema" } & {
        fields?: Maybe<
          Array<
            { __typename?: "FieldSchema" } & Pick<FieldSchema, "slug"> & {
                options: { __typename?: "FieldOptions" } & FieldOpsFragFragment
                instances?: Maybe<
                  Array<
                    { __typename?: "FieldInstance" } & Pick<
                      FieldInstance,
                      "formSlug"
                    > & {
                        options?: Maybe<
                          { __typename?: "FieldOptions" } & FieldOpsFragFragment
                        >
                      }
                  >
                >
              }
          >
        >
      }
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type ArchiveFieldMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  fieldSlug: Scalars["String"]
}>

export type ArchiveFieldMutation = { __typename?: "Mutations" } & {
  updateCollectionSchema?: Maybe<
    { __typename?: "UpdateCollectionSchema" } & {
      collectionSchema: { __typename?: "CollectionSchema" } & {
        fields?: Maybe<
          Array<
            { __typename?: "FieldSchema" } & Pick<FieldSchema, "slug"> & {
                options: { __typename?: "FieldOptions" } & FieldOpsFragFragment
                instances?: Maybe<
                  Array<
                    { __typename?: "FieldInstance" } & Pick<
                      FieldInstance,
                      "formSlug"
                    > & {
                        options?: Maybe<
                          { __typename?: "FieldOptions" } & FieldOpsFragFragment
                        >
                      }
                  >
                >
              }
          >
        >
      }
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type DeleteFieldMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  fieldSlug: Scalars["String"]
}>

export type DeleteFieldMutation = { __typename?: "Mutations" } & {
  updateCollectionSchema?: Maybe<
    { __typename?: "UpdateCollectionSchema" } & {
      collectionSchema: { __typename?: "CollectionSchema" } & {
        fields?: Maybe<
          Array<
            { __typename?: "FieldSchema" } & Pick<FieldSchema, "slug"> & {
                options: { __typename?: "FieldOptions" } & FieldOpsFragFragment
                instances?: Maybe<
                  Array<
                    { __typename?: "FieldInstance" } & Pick<
                      FieldInstance,
                      "formSlug"
                    > & {
                        options?: Maybe<
                          { __typename?: "FieldOptions" } & FieldOpsFragFragment
                        >
                      }
                  >
                >
              }
          >
        >
      }
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type UnarchiveFieldMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  fieldSlug: Scalars["String"]
}>

export type UnarchiveFieldMutation = { __typename?: "Mutations" } & {
  updateCollectionSchema?: Maybe<
    { __typename?: "UpdateCollectionSchema" } & {
      collectionSchema: { __typename?: "CollectionSchema" } & {
        fields?: Maybe<
          Array<
            { __typename?: "FieldSchema" } & Pick<FieldSchema, "slug"> & {
                options: { __typename?: "FieldOptions" } & FieldOpsFragFragment
                instances?: Maybe<
                  Array<
                    { __typename?: "FieldInstance" } & Pick<
                      FieldInstance,
                      "formSlug"
                    > & {
                        options?: Maybe<
                          { __typename?: "FieldOptions" } & FieldOpsFragFragment
                        >
                      }
                  >
                >
              }
          >
        >
      }
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type AddCardBuiltinToFormMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  cardTitle?: Maybe<Scalars["String"]>
  builtin?: Maybe<Builtin>
  formSlug: Scalars["String"]
  row: Scalars["Int"]
  column: Scalars["Int"]
}>

export type AddCardBuiltinToFormMutation = { __typename?: "Mutations" } & {
  updateCollectionSchema?: Maybe<
    {
      __typename?: "UpdateCollectionSchema"
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type RemoveCardBuiltinFromFormMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  cardTitle?: Maybe<Scalars["String"]>
  builtin?: Maybe<Builtin>
  formSlug: Scalars["String"]
}>

export type RemoveCardBuiltinFromFormMutation = { __typename?: "Mutations" } & {
  updateCollectionSchema?: Maybe<
    {
      __typename?: "UpdateCollectionSchema"
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type MoveCardBuiltinInFormMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  cardTitle?: Maybe<Scalars["String"]>
  builtin?: Maybe<Builtin>
  formSlug: Scalars["String"]
  row: Scalars["Int"]
  column: Scalars["Int"]
}>

export type MoveCardBuiltinInFormMutation = { __typename?: "Mutations" } & {
  updateCollectionSchema?: Maybe<
    {
      __typename?: "UpdateCollectionSchema"
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type UpdateCardTitleInFormMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  existingCardTitle?: Maybe<Scalars["String"]>
  builtin?: Maybe<Builtin>
  newCardTitle: Scalars["String"]
  formSlug: Scalars["String"]
}>

export type UpdateCardTitleInFormMutation = { __typename?: "Mutations" } & {
  updateCollectionSchema?: Maybe<
    {
      __typename?: "UpdateCollectionSchema"
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type RemoveAllFieldsFromCardInFormMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  cardTitle: Scalars["String"]
  formSlug: Scalars["String"]
}>

export type RemoveAllFieldsFromCardInFormMutation = {
  __typename?: "Mutations"
} & {
  updateCollectionSchema?: Maybe<
    {
      __typename?: "UpdateCollectionSchema"
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type AddSeparatorToCardInFormMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  cardTitle: Scalars["String"]
  formSlug: Scalars["String"]
  row: Scalars["Int"]
  separatorTitle?: Maybe<Scalars["String"]>
}>

export type AddSeparatorToCardInFormMutation = { __typename?: "Mutations" } & {
  updateCollectionSchema?: Maybe<
    {
      __typename?: "UpdateCollectionSchema"
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type RemoveSeparatorFromCardInFormMutationVariables = Exact<{
  organizationId: Scalars["String"]
  collectionId: Scalars["String"]
  cardTitle: Scalars["String"]
  formSlug: Scalars["String"]
  row: Scalars["Int"]
}>

export type RemoveSeparatorFromCardInFormMutation = {
  __typename?: "Mutations"
} & {
  updateCollectionSchema?: Maybe<
    {
      __typename?: "UpdateCollectionSchema"
    } & UpdateCollectionSchemaErrorsFragmentFragment
  >
}

export type UpdateOrganizationFlagsMutationVariables = Exact<{
  organizationId: Scalars["ID"]
  flagName: Scalars["String"]
  flagValue: Scalars["Boolean"]
}>

export type UpdateOrganizationFlagsMutation = { __typename?: "Mutations" } & {
  updateFlags?: Maybe<
    { __typename?: "OrganizationFlagsMutation" } & {
      organization: { __typename?: "Organization" } & {
        features?: Maybe<
          Array<{ __typename?: "Flag" } & Pick<Flag, "flag" | "enabled">>
        >
      }
    }
  >
}

export type UpdateOrganizationEmailMutationVariables = Exact<{
  organizationId: Scalars["ID"]
  email: Scalars["String"]
}>

export type UpdateOrganizationEmailMutation = { __typename?: "Mutations" } & {
  updateEmail?: Maybe<
    { __typename?: "OrganizationEmailMutation" } & Pick<
      OrganizationEmailMutation,
      "ok"
    >
  >
}

export type BulkMutateMutationVariables = Exact<{
  ops: Array<DocumentOperationInput>
}>

export type BulkMutateMutation = { __typename?: "Mutations" } & {
  documents?: Maybe<
    { __typename?: "DocumentsMutation" } & {
      documents: Array<
        Maybe<
          { __typename?: "Document" } & Pick<Document, "collectionId" | "id">
        >
      >
    }
  >
}

export type CommentsMutatedViewFragment = {
  __typename?: "DocumentsMutation"
} & {
  documents: Array<
    Maybe<
      { __typename?: "Document" } & Pick<
        Document,
        "collectionId" | "created" | "id" | "fieldsAsDict" | "updated"
      >
    >
  >
}

export type CommentCreateMutationVariables = Exact<{
  bailRequestId: Scalars["GenericScalar"]
  collectionId: Scalars["ID"]
  comment: Scalars["GenericScalar"]
  email: Scalars["GenericScalar"]
  name: Scalars["GenericScalar"]
  picture: Scalars["GenericScalar"]
}>

export type CommentCreateMutation = { __typename?: "Mutations" } & {
  documents?: Maybe<
    { __typename?: "DocumentsMutation" } & CommentsMutatedViewFragment
  >
}

export type CommentUpdateMutationVariables = Exact<{
  collectionId: Scalars["ID"]
  commentId: Scalars["ID"]
  comment: Scalars["GenericScalar"]
}>

export type CommentUpdateMutation = { __typename?: "Mutations" } & {
  documents?: Maybe<
    { __typename?: "DocumentsMutation" } & CommentsMutatedViewFragment
  >
}

export type CommentArchiveMutationVariables = Exact<{
  collectionId: Scalars["ID"]
  commentId: Scalars["ID"]
}>

export type CommentArchiveMutation = { __typename?: "Mutations" } & {
  documents?: Maybe<
    { __typename?: "DocumentsMutation" } & CommentsMutatedViewFragment
  >
}

export type CommentsForBailRequestQueryVariables = Exact<{
  bailRequestCollectionId: Scalars["ID"]
  commentsCollectionId: Scalars["ID"]
  bailRequestId: Scalars["ID"]
}>

export type CommentsForBailRequestQuery = { __typename?: "Queries" } & {
  documents: { __typename?: "DocumentsConnection" } & {
    documents: Array<
      { __typename?: "DocumentEdge" } & {
        document: { __typename?: "Document" } & Pick<
          Document,
          "id" | "collectionId" | "created" | "updated" | "fieldsAsDict"
        >
      }
    >
  }
}

export type DetailsPageDocumentFieldsFragment = {
  __typename?: "Document"
} & Pick<
  Document,
  "collectionId" | "created" | "updated" | "fieldsAsDict" | "id"
> & {
    attachments?: Maybe<
      Array<
        { __typename?: "Attachment" } & Pick<
          Attachment,
          "filename" | "id" | "url"
        >
      >
    >
  }

export type DocumentGetQueryVariables = Exact<{
  collectionId: Scalars["ID"]
  documentId: Scalars["ID"]
  children?: Maybe<Array<DocumentsQueryChildrenInput>>
}>

export type DocumentGetQuery = { __typename?: "Queries" } & {
  documents: { __typename?: "DocumentsConnection" } & {
    documents: Array<
      { __typename?: "DocumentEdge" } & {
        document: {
          __typename?: "Document"
        } & DetailsPageDocumentFieldsFragment
      }
    >
  }
}

export type DocumentUpdateMutationVariables = Exact<{
  operations: Array<DocumentOperationInput>
}>

export type DocumentUpdateMutation = { __typename?: "Mutations" } & {
  documents?: Maybe<
    { __typename?: "DocumentsMutation" } & {
      documents: Array<
        Maybe<{ __typename?: "Document" } & DetailsPageDocumentFieldsFragment>
      >
    }
  >
}

export type DocumentCreateMutationVariables = Exact<{
  collectionId: Scalars["ID"]
  fields: Array<DocumentFieldInput>
  children?: Maybe<Array<CreateChildDocumentInput>>
}>

export type DocumentCreateMutation = { __typename?: "Mutations" } & {
  documents?: Maybe<
    { __typename?: "DocumentsMutation" } & {
      documents: Array<
        Maybe<{ __typename?: "Document" } & DetailsPageDocumentFieldsFragment>
      >
    }
  >
}

export type DocumentDeleteMutationVariables = Exact<{
  collectionId: Scalars["ID"]
  documentId: Scalars["ID"]
}>

export type DocumentDeleteMutation = { __typename?: "Mutations" } & {
  documents?: Maybe<{ __typename: "DocumentsMutation" }>
}

export type CreateNewFundMutationVariables = Exact<{
  name: Scalars["String"]
  slug: Scalars["String"]
  city: Scalars["String"]
  state: Scalars["String"]
  timezone: Timezone
  type: FundType
}>

export type CreateNewFundMutation = { __typename?: "Mutations" } & {
  createFund?: Maybe<
    { __typename?: "CreateFundMutation" } & Pick<CreateFundMutation, "ok"> & {
        errors?: Maybe<
          Array<
            { __typename?: "ValidationError" } & Pick<
              ValidationError,
              "message"
            >
          >
        >
      }
  >
}

export type DeleteExistingFundMutationVariables = Exact<{
  slug: Scalars["String"]
}>

export type DeleteExistingFundMutation = { __typename?: "Mutations" } & {
  deleteFund?: Maybe<
    { __typename?: "DeleteFundMutation" } & Pick<DeleteFundMutation, "ok">
  >
}

export type CreateUserMutationVariables = Exact<{
  bailFundSlugs: Array<Scalars["String"]>
  email: Scalars["String"]
  name: Scalars["String"]
  role: UserRole
}>

export type CreateUserMutation = { __typename?: "Mutations" } & {
  updateUserData?: Maybe<
    { __typename?: "AddUserMutation" } & Pick<AddUserMutation, "ok"> & {
        errors?: Maybe<
          Array<
            { __typename?: "ValidationError" } & Pick<
              ValidationError,
              "message"
            >
          >
        >
        user?: Maybe<
          { __typename?: "UserInfo" } & Pick<
            UserInfo,
            "userId" | "email" | "name" | "role" | "createdDate"
          >
        >
      }
  >
}

export type GetListOfUsersQueryVariables = Exact<{
  organizationId: Scalars["String"]
}>

export type GetListOfUsersQuery = { __typename?: "Queries" } & {
  listUsers: Array<
    Maybe<
      { __typename?: "UserInfo" } & Pick<
        UserInfo,
        | "userId"
        | "email"
        | "name"
        | "createdDate"
        | "lastLogin"
        | "lastUpdated"
        | "lastPasswordReset"
      >
    >
  >
}

export type FullViewFragment = { __typename?: "ViewDefinitionOutput" } & Pick<
  ViewDefinitionOutput,
  "id" | "name" | "default"
> & {
    schema: { __typename?: "SchemaViewOutput" } & Pick<
      SchemaViewOutput,
      "fields" | "order" | "mode"
    > & {
        expression?: Maybe<
          { __typename?: "SchemaViewExpressionOutput" } & Pick<
            SchemaViewExpressionOutput,
            "operator"
          > & {
              filters?: Maybe<
                Array<
                  { __typename?: "SchemaViewFilterOutput" } & Pick<
                    SchemaViewFilterOutput,
                    "field" | "operands" | "operation"
                  >
                >
              >
              expressions?: Maybe<
                Array<
                  { __typename?: "SchemaViewExpressionOutput" } & Pick<
                    SchemaViewExpressionOutput,
                    "operator"
                  > & {
                      filters?: Maybe<
                        Array<
                          { __typename?: "SchemaViewFilterOutput" } & Pick<
                            SchemaViewFilterOutput,
                            "field" | "operands" | "operation"
                          >
                        >
                      >
                      expressions?: Maybe<
                        Array<
                          { __typename?: "SchemaViewExpressionOutput" } & Pick<
                            SchemaViewExpressionOutput,
                            "operator"
                          > & {
                              filters?: Maybe<
                                Array<
                                  {
                                    __typename?: "SchemaViewFilterOutput"
                                  } & Pick<
                                    SchemaViewFilterOutput,
                                    "field" | "operands" | "operation"
                                  >
                                >
                              >
                            }
                        >
                      >
                    }
                >
              >
            }
        >
      }
  }

export type ViewCreateMutationVariables = Exact<{
  collectionId: Scalars["ID"]
  view: ViewDefinitionInput
}>

export type ViewCreateMutation = { __typename?: "Mutations" } & {
  createView?: Maybe<
    { __typename?: "CreateViewMutation" } & {
      view: { __typename?: "ViewDefinitionOutput" } & FullViewFragment
    }
  >
}

export type ViewUpdateMutationVariables = Exact<{
  collectionId: Scalars["ID"]
  viewId: Scalars["Int"]
  view: ViewDefinitionInput
}>

export type ViewUpdateMutation = { __typename?: "Mutations" } & {
  updateView?: Maybe<
    { __typename?: "UpdateViewMutation" } & {
      view: { __typename?: "ViewDefinitionOutput" } & FullViewFragment
    }
  >
}

export type ViewDeleteMutationVariables = Exact<{
  collectionId: Scalars["ID"]
  viewId: Scalars["Int"]
}>

export type ViewDeleteMutation = { __typename?: "Mutations" } & {
  deleteView?: Maybe<
    { __typename?: "DeleteViewMutation" } & Pick<DeleteViewMutation, "id">
  >
}

export type DocumentsForTabularViewQueryVariables = Exact<{
  collectionId: Scalars["ID"]
  query: QueryInput
}>

export type DocumentsForTabularViewQuery = { __typename?: "Queries" } & {
  queryResults: { __typename?: "DocumentsConnection" } & Pick<
    DocumentsConnection,
    "totalCount" | "rowsPerPage" | "page"
  > & {
      documents: Array<
        { __typename?: "DocumentEdge" } & {
          document: { __typename?: "Document" } & Pick<
            Document,
            | "collectionId"
            | "version"
            | "id"
            | "created"
            | "updated"
            | "fieldsAsDict"
          >
        }
      >
    }
}

export type GetDuplicateDocumentsQueryVariables = Exact<{
  collectionId: Scalars["ID"]
  documentId: Scalars["String"]
}>

export type GetDuplicateDocumentsQuery = { __typename?: "Queries" } & {
  duplicateQueryResults: { __typename?: "DuplicateDocumentResult" } & Pick<
    DuplicateDocumentResult,
    "error"
  > & {
      documents: Array<
        { __typename?: "DuplicateDocument" } & Pick<
          DuplicateDocument,
          "id" | "createdAt"
        >
      >
    }
}

export type CompleteFieldFragment = { __typename?: "FieldDefinition" } & Pick<
  FieldDefinition,
  | "availableOperators"
  | "defaultValue"
  | "enums"
  | "fieldType"
  | "widget"
  | "hiddenEnums"
  | "id"
  | "required"
  | "slug"
  | "title"
>

export type CompleteFilterFragment = {
  __typename?: "SchemaViewFilterOutput"
} & Pick<SchemaViewFilterOutput, "field" | "operands" | "operation">

export type CompleteViewFragment = {
  __typename?: "ViewDefinitionOutput"
} & Pick<ViewDefinitionOutput, "default" | "id" | "name"> & {
    schema: { __typename?: "SchemaViewOutput" } & Pick<
      SchemaViewOutput,
      "fields" | "order" | "mode"
    > & {
        expression?: Maybe<
          { __typename?: "SchemaViewExpressionOutput" } & Pick<
            SchemaViewExpressionOutput,
            "operator"
          > & {
              filters?: Maybe<
                Array<
                  {
                    __typename?: "SchemaViewFilterOutput"
                  } & CompleteFilterFragment
                >
              >
              expressions?: Maybe<
                Array<
                  { __typename?: "SchemaViewExpressionOutput" } & Pick<
                    SchemaViewExpressionOutput,
                    "operator"
                  > & {
                      filters?: Maybe<
                        Array<
                          {
                            __typename?: "SchemaViewFilterOutput"
                          } & CompleteFilterFragment
                        >
                      >
                      expressions?: Maybe<
                        Array<
                          { __typename?: "SchemaViewExpressionOutput" } & Pick<
                            SchemaViewExpressionOutput,
                            "operator"
                          > & {
                              filters?: Maybe<
                                Array<
                                  {
                                    __typename?: "SchemaViewFilterOutput"
                                  } & CompleteFilterFragment
                                >
                              >
                            }
                        >
                      >
                    }
                >
              >
            }
        >
      }
  }

export type OrganizationQueryVariables = Exact<{
  organizationId: Scalars["ID"]
}>

export type OrganizationQuery = { __typename?: "Queries" } & {
  organization: { __typename?: "Organization" } & Pick<
    Organization,
    | "bailFundSlug"
    | "email"
    | "organizationId"
    | "name"
    | "fullName"
    | "timezone"
    | "legalDocsSigned"
    | "collectionOrder"
  > & {
      features?: Maybe<
        Array<
          { __typename?: "Flag" } & Pick<Flag, "enabled" | "extra" | "flag">
        >
      >
      collections: Array<
        { __typename?: "Collection" } & Pick<
          Collection,
          "forms" | "id" | "slug" | "title"
        > & {
            actions?: Maybe<
              { __typename?: "Acls" } & Pick<
                Acls,
                "navigation" | "deleteDocument" | "searchDialog"
              >
            >
            fields: Array<
              { __typename?: "FieldDefinition" } & CompleteFieldFragment
            >
            pdfs?: Maybe<
              Array<
                Maybe<
                  { __typename?: "Pdf" } & Pick<
                    Pdf,
                    "title" | "sourcePdfUrl"
                  > & {
                      fieldMapping?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "PdfFieldMapping" } & Pick<
                              PdfFieldMapping,
                              "pdfFieldName" | "slug"
                            >
                          >
                        >
                      >
                    }
                >
              >
            >
            views: Array<
              { __typename?: "ViewDefinitionOutput" } & CompleteViewFragment
            >
            settings?: Maybe<
              { __typename?: "PschemaSettings" } & Pick<
                PschemaSettings,
                | "nameSearchSlugs"
                | "idSearchSlugs"
                | "duplicateFieldsChecked"
                | "duplicateFieldSlugs"
                | "isDuplicateSlug"
                | "referrerEmailSlug"
              >
            >
          }
      >
    }
}

export type SignDocsMutationVariables = Exact<{ [key: string]: never }>

export type SignDocsMutation = { __typename?: "Mutations" } & {
  signLegalDocs?: Maybe<
    { __typename?: "SignLegalDocsMutation" } & Pick<SignLegalDocsMutation, "ok">
  >
}

export type UserInfoQueryVariables = Exact<{ [key: string]: never }>

export type UserInfoQuery = { __typename?: "Queries" } & {
  userInfo: { __typename?: "User" } & Pick<User, "userId" | "flags"> & {
      organizations?: Maybe<
        Array<
          { __typename?: "OrganizationLite" } & Pick<
            OrganizationLite,
            "bailFundSlug" | "organizationId" | "fullName"
          >
        >
      >
    }
}

export const AttachmentDocumentFieldsFragmentDoc = gql`
  fragment attachmentDocumentFields on Document {
    attachments {
      filename
      id
      url
    }
    collectionId
    id
  }
`
export const FormFragFragmentDoc = gql`
  fragment FormFrag on Form {
    formSlug
    intakeOptions {
      postamble
      preamble
      success
      disabled
      disabledText
    }
    title
  }
`
export const FieldOpsFragFragmentDoc = gql`
  fragment FieldOpsFrag on FieldOptions {
    hiddenChoices
    choices
    defaultValue
    helper
    id
    title
    required
    widget
  }
`
export const UpdateCollectionSchemaErrorsFragmentFragmentDoc = gql`
  fragment UpdateCollectionSchemaErrorsFragment on UpdateCollectionSchema {
    ok
    errors {
      message
    }
  }
`
export const MutateCollectionSchemaAndDocumentsErrorsFragmentFragmentDoc = gql`
  fragment MutateCollectionSchemaAndDocumentsErrorsFragment on UpdateDocumentsAndSchema {
    ok
    errors {
      message
    }
  }
`
export const CommentsMutatedViewFragmentDoc = gql`
  fragment CommentsMutatedView on DocumentsMutation {
    documents {
      collectionId
      created
      id
      fieldsAsDict
      updated
    }
  }
`
export const DetailsPageDocumentFieldsFragmentDoc = gql`
  fragment detailsPageDocumentFields on Document {
    collectionId
    created
    updated
    attachments {
      filename
      id
      url
    }
    fieldsAsDict
    id
  }
`
export const FullViewFragmentDoc = gql`
  fragment FullView on ViewDefinitionOutput {
    id
    name
    default
    schema {
      fields
      order
      mode
      expression {
        operator
        filters {
          field
          operands
          operation
        }
        expressions {
          operator
          filters {
            field
            operands
            operation
          }
          expressions {
            operator
            filters {
              field
              operands
              operation
            }
          }
        }
      }
    }
  }
`
export const CompleteFieldFragmentDoc = gql`
  fragment CompleteField on FieldDefinition {
    availableOperators
    defaultValue
    enums
    fieldType
    widget
    hiddenEnums
    id
    required
    slug
    title
  }
`
export const CompleteFilterFragmentDoc = gql`
  fragment CompleteFilter on SchemaViewFilterOutput {
    field
    operands
    operation
  }
`
export const CompleteViewFragmentDoc = gql`
  fragment CompleteView on ViewDefinitionOutput {
    default
    id
    name
    schema {
      fields
      order
      mode
      expression {
        operator
        filters {
          ...CompleteFilter
        }
        expressions {
          operator
          filters {
            ...CompleteFilter
          }
          expressions {
            operator
            filters {
              ...CompleteFilter
            }
          }
        }
      }
    }
  }
  ${CompleteFilterFragmentDoc}
`
export const DocumentAttachmentsGetDocument = gql`
  query documentAttachmentsGet($collectionId: ID!, $documentId: ID!) {
    documents(collectionId: $collectionId, query: { ids: [$documentId] }) {
      documents {
        document {
          ...attachmentDocumentFields
        }
      }
    }
  }
  ${AttachmentDocumentFieldsFragmentDoc}
`

export function useDocumentAttachmentsGetQuery(
  options: Omit<
    Urql.UseQueryArgs<DocumentAttachmentsGetQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<DocumentAttachmentsGetQuery>({
    query: DocumentAttachmentsGetDocument,
    ...options,
  })
}
export const ExportOrganizationSchemasDocument = gql`
  query exportOrganizationSchemas($organizationId: ID!) {
    orgExport(organizationId: $organizationId)
  }
`

export function useExportOrganizationSchemasQuery(
  options: Omit<
    Urql.UseQueryArgs<ExportOrganizationSchemasQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<ExportOrganizationSchemasQuery>({
    query: ExportOrganizationSchemasDocument,
    ...options,
  })
}
export const GetRawCollectionPschemaDocument = gql`
  query getRawCollectionPschema($collectionId: ID!) {
    rawCollectionPschema(collectionId: $collectionId)
  }
`

export function useGetRawCollectionPschemaQuery(
  options: Omit<
    Urql.UseQueryArgs<GetRawCollectionPschemaQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<GetRawCollectionPschemaQuery>({
    query: GetRawCollectionPschemaDocument,
    ...options,
  })
}
export const GetRawOrganizationConfigDocument = gql`
  query getRawOrganizationConfig($organizationId: ID!) {
    rawOrganizationConfig(organizationId: $organizationId)
  }
`

export function useGetRawOrganizationConfigQuery(
  options: Omit<
    Urql.UseQueryArgs<GetRawOrganizationConfigQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<GetRawOrganizationConfigQuery>({
    query: GetRawOrganizationConfigDocument,
    ...options,
  })
}
export const DescribeCollectionSchemasDocument = gql`
  query describeCollectionSchemas($organizationId: ID!) {
    collectionSchemas(organizationId: $organizationId) {
      collectionId
      fields {
        instances {
          formSlug
          options {
            ...FieldOpsFrag
          }
        }
        options {
          ...FieldOpsFrag
        }
        slug
        archivable
        archived
        fieldType
      }
      forms {
        ...FormFrag
      }
      slug
      stats {
        numDocs
        earliest
        latest
      }
      title
    }
  }
  ${FieldOpsFragFragmentDoc}
  ${FormFragFragmentDoc}
`

export function useDescribeCollectionSchemasQuery(
  options: Omit<
    Urql.UseQueryArgs<DescribeCollectionSchemasQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<DescribeCollectionSchemasQuery>({
    query: DescribeCollectionSchemasDocument,
    ...options,
  })
}
export const UpdateFieldOptionChoiceDocument = gql`
  mutation updateFieldOptionChoice(
    $collectionId: ID!
    $fieldSlug: String!
    $itemToUpdate: String!
    $newItemValue: String
  ) {
    updateDocumentsAndSchema(
      collectionId: $collectionId
      update: {
        updateFieldChoicesItem: {
          fieldSlug: $fieldSlug
          itemToUpdate: $itemToUpdate
          newItemValue: $newItemValue
        }
      }
    ) {
      collectionSchema {
        fields {
          instances {
            formSlug
            options {
              ...FieldOpsFrag
            }
          }
          options {
            ...FieldOpsFrag
          }
          slug
        }
      }
      ...MutateCollectionSchemaAndDocumentsErrorsFragment
    }
  }
  ${FieldOpsFragFragmentDoc}
  ${MutateCollectionSchemaAndDocumentsErrorsFragmentFragmentDoc}
`

export function useUpdateFieldOptionChoiceMutation() {
  return Urql.useMutation<
    UpdateFieldOptionChoiceMutation,
    UpdateFieldOptionChoiceMutationVariables
  >(UpdateFieldOptionChoiceDocument)
}
export const DeleteFieldOptionChoiceDocument = gql`
  mutation deleteFieldOptionChoice(
    $collectionId: ID!
    $fieldSlug: String!
    $itemToDelete: String!
    $newItemValue: String
  ) {
    updateDocumentsAndSchema(
      collectionId: $collectionId
      update: {
        deleteFieldChoicesItem: {
          fieldSlug: $fieldSlug
          itemToDelete: $itemToDelete
          newItemValue: $newItemValue
        }
      }
    ) {
      collectionSchema {
        fields {
          instances {
            formSlug
            options {
              ...FieldOpsFrag
            }
          }
          options {
            ...FieldOpsFrag
          }
          slug
        }
      }
      ...MutateCollectionSchemaAndDocumentsErrorsFragment
    }
  }
  ${FieldOpsFragFragmentDoc}
  ${MutateCollectionSchemaAndDocumentsErrorsFragmentFragmentDoc}
`

export function useDeleteFieldOptionChoiceMutation() {
  return Urql.useMutation<
    DeleteFieldOptionChoiceMutation,
    DeleteFieldOptionChoiceMutationVariables
  >(DeleteFieldOptionChoiceDocument)
}
export const MakeFieldAMultiselectDocument = gql`
  mutation makeFieldAMultiselect($collectionId: ID!, $fieldSlug: String!) {
    updateDocumentsAndSchema(
      collectionId: $collectionId
      update: { makeFieldAMultiselect: { fieldSlug: $fieldSlug } }
    ) {
      collectionSchema {
        fields {
          instances {
            formSlug
            options {
              ...FieldOpsFrag
            }
          }
          options {
            ...FieldOpsFrag
          }
          slug
        }
      }
      ...MutateCollectionSchemaAndDocumentsErrorsFragment
    }
  }
  ${FieldOpsFragFragmentDoc}
  ${MutateCollectionSchemaAndDocumentsErrorsFragmentFragmentDoc}
`

export function useMakeFieldAMultiselectMutation() {
  return Urql.useMutation<
    MakeFieldAMultiselectMutation,
    MakeFieldAMultiselectMutationVariables
  >(MakeFieldAMultiselectDocument)
}
export const MultiUpdateCollectionSchemaDocument = gql`
  mutation multiUpdateCollectionSchema(
    $collectionId: String!
    $organizationId: String!
    $updates: [UpdateCollectionSchemaInput!]
  ) {
    updateCollectionSchema(
      collectionId: $collectionId
      organizationId: $organizationId
      updates: $updates
    ) {
      collectionSchema {
        fields {
          slug
        }
      }
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useMultiUpdateCollectionSchemaMutation() {
  return Urql.useMutation<
    MultiUpdateCollectionSchemaMutation,
    MultiUpdateCollectionSchemaMutationVariables
  >(MultiUpdateCollectionSchemaDocument)
}
export const UpdateRawCollectionSchemaDocument = gql`
  mutation updateRawCollectionSchema(
    $collectionId: String!
    $organizationId: String!
    $newPschema: String!
  ) {
    updateRawCollectionSchema(
      collectionId: $collectionId
      organizationId: $organizationId
      newPschema: $newPschema
    ) {
      ok
      error
      collectionSchema
      oldCollectionSchema
    }
  }
`

export function useUpdateRawCollectionSchemaMutation() {
  return Urql.useMutation<
    UpdateRawCollectionSchemaMutation,
    UpdateRawCollectionSchemaMutationVariables
  >(UpdateRawCollectionSchemaDocument)
}
export const UpdateRawOrganizationConfigDocument = gql`
  mutation updateRawOrganizationConfig(
    $organizationId: String!
    $newConfig: String!
  ) {
    updateRawOrganizationConfig(
      organizationId: $organizationId
      newConfig: $newConfig
    ) {
      ok
      error
      organizationConfig
      oldOrganizationConfig
    }
  }
`

export function useUpdateRawOrganizationConfigMutation() {
  return Urql.useMutation<
    UpdateRawOrganizationConfigMutation,
    UpdateRawOrganizationConfigMutationVariables
  >(UpdateRawOrganizationConfigDocument)
}
export const ReplaceFormOptionsForIntakeDocument = gql`
  mutation replaceFormOptionsForIntake(
    $formSlug: String!
    $collectionId: String!
    $organizationId: String!
    $post: String
    $pre: String
    $success: String
    $disabled: Boolean
    $disabledText: String
    $title: String!
  ) {
    updateCollectionSchema(
      collectionId: $collectionId
      organizationId: $organizationId
      updates: [
        {
          updateForm: {
            formSlug: $formSlug
            intakeOptions: {
              postamble: $post
              preamble: $pre
              success: $success
              disabled: $disabled
              disabledText: $disabledText
            }
            title: $title
          }
        }
      ]
    ) {
      collectionSchema {
        forms {
          ...FormFrag
        }
        slug
      }
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${FormFragFragmentDoc}
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useReplaceFormOptionsForIntakeMutation() {
  return Urql.useMutation<
    ReplaceFormOptionsForIntakeMutation,
    ReplaceFormOptionsForIntakeMutationVariables
  >(ReplaceFormOptionsForIntakeDocument)
}
export const ReplaceFormOptionsForNotIntakeDocument = gql`
  mutation replaceFormOptionsForNotIntake(
    $formSlug: String!
    $collectionId: String!
    $organizationId: String!
    $title: String!
  ) {
    updateCollectionSchema(
      collectionId: $collectionId
      organizationId: $organizationId
      updates: [{ updateForm: { formSlug: $formSlug, title: $title } }]
    ) {
      collectionSchema {
        forms {
          ...FormFrag
        }
        slug
      }
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${FormFragFragmentDoc}
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useReplaceFormOptionsForNotIntakeMutation() {
  return Urql.useMutation<
    ReplaceFormOptionsForNotIntakeMutation,
    ReplaceFormOptionsForNotIntakeMutationVariables
  >(ReplaceFormOptionsForNotIntakeDocument)
}
export const AddDefaultFieldDocument = gql`
  mutation addDefaultField(
    $organizationId: String!
    $collectionId: String!
    $fieldSlug: String!
    $fieldType: PSchemaFieldType!
    $defaultValue: String
    $choices: [String!]
    $title: String!
    $required: Boolean!
    $helper: String
    $hiddenChoices: [String!]
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [
        {
          addDefaultField: {
            slug: $fieldSlug
            fieldType: $fieldType
            options: {
              choices: $choices
              required: $required
              title: $title
              helper: $helper
              defaultValue: $defaultValue
              hiddenChoices: $hiddenChoices
            }
          }
        }
      ]
    ) {
      collectionSchema {
        fields {
          slug
          options {
            ...FieldOpsFrag
          }
          instances {
            formSlug
            options {
              ...FieldOpsFrag
            }
          }
        }
      }
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${FieldOpsFragFragmentDoc}
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useAddDefaultFieldMutation() {
  return Urql.useMutation<
    AddDefaultFieldMutation,
    AddDefaultFieldMutationVariables
  >(AddDefaultFieldDocument)
}
export const AddFieldToFormDocument = gql`
  mutation addFieldToForm(
    $organizationId: String!
    $collectionId: String!
    $fieldSlug: String!
    $formSlug: String!
    $card: String!
    $rowSpecifier: RowSpecifier!
    $row: Int!
    $column: Int
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [
        {
          addFieldToForm: {
            slug: $fieldSlug
            formSlug: $formSlug
            well: $card
            rowSpecifier: $rowSpecifier
            row: $row
            column: $column
          }
        }
      ]
    ) {
      collectionSchema {
        fields {
          slug
          options {
            ...FieldOpsFrag
          }
          instances {
            formSlug
            options {
              ...FieldOpsFrag
            }
          }
        }
      }
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${FieldOpsFragFragmentDoc}
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useAddFieldToFormMutation() {
  return Urql.useMutation<
    AddFieldToFormMutation,
    AddFieldToFormMutationVariables
  >(AddFieldToFormDocument)
}
export const RemoveFieldFromFormDocument = gql`
  mutation removeFieldFromForm(
    $organizationId: String!
    $collectionId: String!
    $fieldSlug: String!
    $formSlug: String!
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [
        { removeFieldFromForm: { slug: $fieldSlug, formSlug: $formSlug } }
      ]
    ) {
      collectionSchema {
        fields {
          slug
          options {
            ...FieldOpsFrag
          }
          instances {
            formSlug
            options {
              ...FieldOpsFrag
            }
          }
        }
      }
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${FieldOpsFragFragmentDoc}
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useRemoveFieldFromFormMutation() {
  return Urql.useMutation<
    RemoveFieldFromFormMutation,
    RemoveFieldFromFormMutationVariables
  >(RemoveFieldFromFormDocument)
}
export const ArchiveFieldDocument = gql`
  mutation archiveField(
    $organizationId: String!
    $collectionId: String!
    $fieldSlug: String!
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [{ archiveField: { slug: $fieldSlug } }]
    ) {
      collectionSchema {
        fields {
          slug
          options {
            ...FieldOpsFrag
          }
          instances {
            formSlug
            options {
              ...FieldOpsFrag
            }
          }
        }
      }
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${FieldOpsFragFragmentDoc}
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useArchiveFieldMutation() {
  return Urql.useMutation<ArchiveFieldMutation, ArchiveFieldMutationVariables>(
    ArchiveFieldDocument
  )
}
export const DeleteFieldDocument = gql`
  mutation deleteField(
    $organizationId: String!
    $collectionId: String!
    $fieldSlug: String!
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [{ deleteField: { slug: $fieldSlug } }]
    ) {
      collectionSchema {
        fields {
          slug
          options {
            ...FieldOpsFrag
          }
          instances {
            formSlug
            options {
              ...FieldOpsFrag
            }
          }
        }
      }
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${FieldOpsFragFragmentDoc}
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useDeleteFieldMutation() {
  return Urql.useMutation<DeleteFieldMutation, DeleteFieldMutationVariables>(
    DeleteFieldDocument
  )
}
export const UnarchiveFieldDocument = gql`
  mutation unarchiveField(
    $organizationId: String!
    $collectionId: String!
    $fieldSlug: String!
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [{ unarchiveField: { slug: $fieldSlug } }]
    ) {
      collectionSchema {
        fields {
          slug
          options {
            ...FieldOpsFrag
          }
          instances {
            formSlug
            options {
              ...FieldOpsFrag
            }
          }
        }
      }
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${FieldOpsFragFragmentDoc}
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useUnarchiveFieldMutation() {
  return Urql.useMutation<
    UnarchiveFieldMutation,
    UnarchiveFieldMutationVariables
  >(UnarchiveFieldDocument)
}
export const AddCardBuiltinToFormDocument = gql`
  mutation addCardBuiltinToForm(
    $organizationId: String!
    $collectionId: String!
    $cardTitle: String
    $builtin: Builtin
    $formSlug: String!
    $row: Int!
    $column: Int!
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [
        {
          addWellBuiltinToForm: {
            wellBuiltinSelector: {
              well: { wellTitle: $cardTitle }
              builtin: { builtin: $builtin }
              form: { formSlug: $formSlug }
            }
            positionCoordinates: { row: $row, column: $column }
          }
        }
      ]
    ) {
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useAddCardBuiltinToFormMutation() {
  return Urql.useMutation<
    AddCardBuiltinToFormMutation,
    AddCardBuiltinToFormMutationVariables
  >(AddCardBuiltinToFormDocument)
}
export const RemoveCardBuiltinFromFormDocument = gql`
  mutation removeCardBuiltinFromForm(
    $organizationId: String!
    $collectionId: String!
    $cardTitle: String
    $builtin: Builtin
    $formSlug: String!
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [
        {
          removeWellBuiltinFromForm: {
            well: { wellTitle: $cardTitle }
            builtin: { builtin: $builtin }
            form: { formSlug: $formSlug }
          }
        }
      ]
    ) {
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useRemoveCardBuiltinFromFormMutation() {
  return Urql.useMutation<
    RemoveCardBuiltinFromFormMutation,
    RemoveCardBuiltinFromFormMutationVariables
  >(RemoveCardBuiltinFromFormDocument)
}
export const MoveCardBuiltinInFormDocument = gql`
  mutation moveCardBuiltinInForm(
    $organizationId: String!
    $collectionId: String!
    $cardTitle: String
    $builtin: Builtin
    $formSlug: String!
    $row: Int!
    $column: Int!
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [
        {
          moveWellBuiltinInForm: {
            wellBuiltinSelector: {
              well: { wellTitle: $cardTitle }
              builtin: { builtin: $builtin }
              form: { formSlug: $formSlug }
            }
            positionCoordinates: { row: $row, column: $column }
          }
        }
      ]
    ) {
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useMoveCardBuiltinInFormMutation() {
  return Urql.useMutation<
    MoveCardBuiltinInFormMutation,
    MoveCardBuiltinInFormMutationVariables
  >(MoveCardBuiltinInFormDocument)
}
export const UpdateCardTitleInFormDocument = gql`
  mutation updateCardTitleInForm(
    $organizationId: String!
    $collectionId: String!
    $existingCardTitle: String
    $builtin: Builtin
    $newCardTitle: String!
    $formSlug: String!
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [
        {
          updateWellBuiltinTitleInForm: {
            well: { wellTitle: $existingCardTitle }
            builtin: { builtin: $builtin }
            newTitle: $newCardTitle
            form: { formSlug: $formSlug }
          }
        }
      ]
    ) {
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useUpdateCardTitleInFormMutation() {
  return Urql.useMutation<
    UpdateCardTitleInFormMutation,
    UpdateCardTitleInFormMutationVariables
  >(UpdateCardTitleInFormDocument)
}
export const RemoveAllFieldsFromCardInFormDocument = gql`
  mutation removeAllFieldsFromCardInForm(
    $organizationId: String!
    $collectionId: String!
    $cardTitle: String!
    $formSlug: String!
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [
        {
          removeAllFieldsFromWellInForm: {
            well: { wellTitle: $cardTitle }
            form: { formSlug: $formSlug }
          }
        }
      ]
    ) {
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useRemoveAllFieldsFromCardInFormMutation() {
  return Urql.useMutation<
    RemoveAllFieldsFromCardInFormMutation,
    RemoveAllFieldsFromCardInFormMutationVariables
  >(RemoveAllFieldsFromCardInFormDocument)
}
export const AddSeparatorToCardInFormDocument = gql`
  mutation addSeparatorToCardInForm(
    $organizationId: String!
    $collectionId: String!
    $cardTitle: String!
    $formSlug: String!
    $row: Int!
    $separatorTitle: String
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [
        {
          addHorizontalRuleToWellInForm: {
            wellSelector: {
              well: { wellTitle: $cardTitle }
              form: { formSlug: $formSlug }
            }
            row: $row
            title: $separatorTitle
          }
        }
      ]
    ) {
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useAddSeparatorToCardInFormMutation() {
  return Urql.useMutation<
    AddSeparatorToCardInFormMutation,
    AddSeparatorToCardInFormMutationVariables
  >(AddSeparatorToCardInFormDocument)
}
export const RemoveSeparatorFromCardInFormDocument = gql`
  mutation removeSeparatorFromCardInForm(
    $organizationId: String!
    $collectionId: String!
    $cardTitle: String!
    $formSlug: String!
    $row: Int!
  ) {
    updateCollectionSchema(
      organizationId: $organizationId
      collectionId: $collectionId
      updates: [
        {
          removeHorizontalRuleFromWellInForm: {
            wellSelector: {
              well: { wellTitle: $cardTitle }
              form: { formSlug: $formSlug }
            }
            row: $row
          }
        }
      ]
    ) {
      ...UpdateCollectionSchemaErrorsFragment
    }
  }
  ${UpdateCollectionSchemaErrorsFragmentFragmentDoc}
`

export function useRemoveSeparatorFromCardInFormMutation() {
  return Urql.useMutation<
    RemoveSeparatorFromCardInFormMutation,
    RemoveSeparatorFromCardInFormMutationVariables
  >(RemoveSeparatorFromCardInFormDocument)
}
export const UpdateOrganizationFlagsDocument = gql`
  mutation updateOrganizationFlags(
    $organizationId: ID!
    $flagName: String!
    $flagValue: Boolean!
  ) {
    updateFlags(
      organizationId: $organizationId
      flagName: $flagName
      flagValue: $flagValue
    ) {
      organization {
        features {
          flag
          enabled
        }
      }
    }
  }
`

export function useUpdateOrganizationFlagsMutation() {
  return Urql.useMutation<
    UpdateOrganizationFlagsMutation,
    UpdateOrganizationFlagsMutationVariables
  >(UpdateOrganizationFlagsDocument)
}
export const UpdateOrganizationEmailDocument = gql`
  mutation updateOrganizationEmail($organizationId: ID!, $email: String!) {
    updateEmail(organizationId: $organizationId, email: $email) {
      ok
    }
  }
`

export function useUpdateOrganizationEmailMutation() {
  return Urql.useMutation<
    UpdateOrganizationEmailMutation,
    UpdateOrganizationEmailMutationVariables
  >(UpdateOrganizationEmailDocument)
}
export const BulkMutateDocument = gql`
  mutation bulkMutate($ops: [DocumentOperationInput!]!) {
    documents(operations: $ops) {
      documents {
        collectionId
        id
      }
    }
  }
`

export function useBulkMutateMutation() {
  return Urql.useMutation<BulkMutateMutation, BulkMutateMutationVariables>(
    BulkMutateDocument
  )
}
export const CommentCreateDocument = gql`
  mutation commentCreate(
    $bailRequestId: GenericScalar!
    $collectionId: ID!
    $comment: GenericScalar!
    $email: GenericScalar!
    $name: GenericScalar!
    $picture: GenericScalar!
  ) {
    documents(
      operations: [
        {
          createDocument: {
            collectionId: $collectionId
            fields: [
              { slug: "bail_request_id", value: $bailRequestId }
              { slug: "comment", value: $comment }
              { slug: "date_posted", serverProvidedValue: CURRENT_TIMESTAMP }
              { slug: "email", value: $email }
              { slug: "name", value: $name }
              { slug: "picture", value: $picture }
              { slug: "username", serverProvidedValue: CURRENT_USER }
            ]
          }
        }
      ]
    ) {
      ...CommentsMutatedView
    }
  }
  ${CommentsMutatedViewFragmentDoc}
`

export function useCommentCreateMutation() {
  return Urql.useMutation<
    CommentCreateMutation,
    CommentCreateMutationVariables
  >(CommentCreateDocument)
}
export const CommentUpdateDocument = gql`
  mutation commentUpdate(
    $collectionId: ID!
    $commentId: ID!
    $comment: GenericScalar!
  ) {
    documents(
      operations: [
        {
          updateDocument: {
            collectionId: $collectionId
            id: $commentId
            fields: [
              { slug: "date_edited", serverProvidedValue: CURRENT_TIMESTAMP }
              { slug: "comment", value: $comment }
            ]
          }
        }
      ]
    ) {
      ...CommentsMutatedView
    }
  }
  ${CommentsMutatedViewFragmentDoc}
`

export function useCommentUpdateMutation() {
  return Urql.useMutation<
    CommentUpdateMutation,
    CommentUpdateMutationVariables
  >(CommentUpdateDocument)
}
export const CommentArchiveDocument = gql`
  mutation commentArchive($collectionId: ID!, $commentId: ID!) {
    documents(
      operations: [
        {
          updateDocument: {
            collectionId: $collectionId
            id: $commentId
            fields: [{ slug: "archived", value: true }]
          }
        }
      ]
    ) {
      ...CommentsMutatedView
    }
  }
  ${CommentsMutatedViewFragmentDoc}
`

export function useCommentArchiveMutation() {
  return Urql.useMutation<
    CommentArchiveMutation,
    CommentArchiveMutationVariables
  >(CommentArchiveDocument)
}
export const CommentsForBailRequestDocument = gql`
  query commentsForBailRequest(
    $bailRequestCollectionId: ID!
    $commentsCollectionId: ID!
    $bailRequestId: ID!
  ) {
    documents(
      collectionId: $bailRequestCollectionId
      query: {
        ids: [$bailRequestId]
        children: [
          {
            collectionId: $commentsCollectionId
            childToParentSlug: "bail_request_id"
          }
        ]
      }
    ) {
      documents {
        document {
          id
          collectionId
          created
          updated
          fieldsAsDict
        }
      }
    }
  }
`

export function useCommentsForBailRequestQuery(
  options: Omit<
    Urql.UseQueryArgs<CommentsForBailRequestQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<CommentsForBailRequestQuery>({
    query: CommentsForBailRequestDocument,
    ...options,
  })
}
export const DocumentGetDocument = gql`
  query documentGet(
    $collectionId: ID!
    $documentId: ID!
    $children: [DocumentsQueryChildrenInput!]
  ) {
    documents(
      collectionId: $collectionId
      query: { ids: [$documentId], children: $children }
    ) {
      documents {
        document {
          ...detailsPageDocumentFields
        }
      }
    }
  }
  ${DetailsPageDocumentFieldsFragmentDoc}
`

export function useDocumentGetQuery(
  options: Omit<Urql.UseQueryArgs<DocumentGetQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<DocumentGetQuery>({
    query: DocumentGetDocument,
    ...options,
  })
}
export const DocumentUpdateDocument = gql`
  mutation documentUpdate($operations: [DocumentOperationInput!]!) {
    documents(operations: $operations) {
      documents {
        ...detailsPageDocumentFields
      }
    }
  }
  ${DetailsPageDocumentFieldsFragmentDoc}
`

export function useDocumentUpdateMutation() {
  return Urql.useMutation<
    DocumentUpdateMutation,
    DocumentUpdateMutationVariables
  >(DocumentUpdateDocument)
}
export const DocumentCreateDocument = gql`
  mutation documentCreate(
    $collectionId: ID!
    $fields: [DocumentFieldInput!]!
    $children: [CreateChildDocumentInput!]
  ) {
    documents(
      operations: [
        {
          createDocument: {
            collectionId: $collectionId
            fields: $fields
            children: $children
          }
        }
      ]
    ) {
      documents {
        ...detailsPageDocumentFields
      }
    }
  }
  ${DetailsPageDocumentFieldsFragmentDoc}
`

export function useDocumentCreateMutation() {
  return Urql.useMutation<
    DocumentCreateMutation,
    DocumentCreateMutationVariables
  >(DocumentCreateDocument)
}
export const DocumentDeleteDocument = gql`
  mutation documentDelete($collectionId: ID!, $documentId: ID!) {
    documents(
      operations: [
        { deleteDocument: { collectionId: $collectionId, id: $documentId } }
      ]
    ) {
      __typename
    }
  }
`

export function useDocumentDeleteMutation() {
  return Urql.useMutation<
    DocumentDeleteMutation,
    DocumentDeleteMutationVariables
  >(DocumentDeleteDocument)
}
export const CreateNewFundDocument = gql`
  mutation createNewFund(
    $name: String!
    $slug: String!
    $city: String!
    $state: String!
    $timezone: Timezone!
    $type: FundType!
  ) {
    createFund(
      name: $name
      slug: $slug
      city: $city
      state: $state
      timezone: $timezone
      type: $type
    ) {
      errors {
        message
      }
      ok
    }
  }
`

export function useCreateNewFundMutation() {
  return Urql.useMutation<
    CreateNewFundMutation,
    CreateNewFundMutationVariables
  >(CreateNewFundDocument)
}
export const DeleteExistingFundDocument = gql`
  mutation deleteExistingFund($slug: String!) {
    deleteFund(slug: $slug) {
      ok
    }
  }
`

export function useDeleteExistingFundMutation() {
  return Urql.useMutation<
    DeleteExistingFundMutation,
    DeleteExistingFundMutationVariables
  >(DeleteExistingFundDocument)
}
export const CreateUserDocument = gql`
  mutation createUser(
    $bailFundSlugs: [String!]!
    $email: String!
    $name: String!
    $role: UserRole!
  ) {
    updateUserData(
      bailFundSlugs: $bailFundSlugs
      email: $email
      name: $name
      role: $role
    ) {
      errors {
        message
      }
      ok
      user {
        userId
        email
        name
        role
        createdDate
      }
    }
  }
`

export function useCreateUserMutation() {
  return Urql.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument
  )
}
export const GetListOfUsersDocument = gql`
  query getListOfUsers($organizationId: String!) {
    listUsers(organizationId: $organizationId) {
      userId
      email
      name
      createdDate
      lastLogin
      lastUpdated
      lastPasswordReset
    }
  }
`

export function useGetListOfUsersQuery(
  options: Omit<Urql.UseQueryArgs<GetListOfUsersQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<GetListOfUsersQuery>({
    query: GetListOfUsersDocument,
    ...options,
  })
}
export const ViewCreateDocument = gql`
  mutation viewCreate($collectionId: ID!, $view: ViewDefinitionInput!) {
    createView(collectionId: $collectionId, view: $view) {
      view {
        ...FullView
      }
    }
  }
  ${FullViewFragmentDoc}
`

export function useViewCreateMutation() {
  return Urql.useMutation<ViewCreateMutation, ViewCreateMutationVariables>(
    ViewCreateDocument
  )
}
export const ViewUpdateDocument = gql`
  mutation viewUpdate(
    $collectionId: ID!
    $viewId: Int!
    $view: ViewDefinitionInput!
  ) {
    updateView(collectionId: $collectionId, viewId: $viewId, view: $view) {
      view {
        ...FullView
      }
    }
  }
  ${FullViewFragmentDoc}
`

export function useViewUpdateMutation() {
  return Urql.useMutation<ViewUpdateMutation, ViewUpdateMutationVariables>(
    ViewUpdateDocument
  )
}
export const ViewDeleteDocument = gql`
  mutation viewDelete($collectionId: ID!, $viewId: Int!) {
    deleteView(collectionId: $collectionId, viewId: $viewId) {
      id
    }
  }
`

export function useViewDeleteMutation() {
  return Urql.useMutation<ViewDeleteMutation, ViewDeleteMutationVariables>(
    ViewDeleteDocument
  )
}
export const DocumentsForTabularViewDocument = gql`
  query documentsForTabularView($collectionId: ID!, $query: QueryInput!) {
    queryResults(collectionId: $collectionId, query: $query) {
      totalCount
      rowsPerPage
      page
      documents {
        document {
          collectionId
          version
          id
          created
          updated
          fieldsAsDict
        }
      }
    }
  }
`

export function useDocumentsForTabularViewQuery(
  options: Omit<
    Urql.UseQueryArgs<DocumentsForTabularViewQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<DocumentsForTabularViewQuery>({
    query: DocumentsForTabularViewDocument,
    ...options,
  })
}
export const GetDuplicateDocumentsDocument = gql`
  query getDuplicateDocuments($collectionId: ID!, $documentId: String!) {
    duplicateQueryResults(
      collectionId: $collectionId
      documentId: $documentId
    ) {
      documents {
        id
        createdAt
      }
      error
    }
  }
`

export function useGetDuplicateDocumentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GetDuplicateDocumentsQueryVariables>,
    "query"
  > = {}
) {
  return Urql.useQuery<GetDuplicateDocumentsQuery>({
    query: GetDuplicateDocumentsDocument,
    ...options,
  })
}
export const OrganizationDocument = gql`
  query organization($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      bailFundSlug
      features {
        enabled
        extra
        flag
      }
      email
      organizationId
      name
      fullName
      timezone
      legalDocsSigned
      collectionOrder
      collections {
        actions {
          navigation
          deleteDocument
          searchDialog
        }
        fields {
          ...CompleteField
        }
        forms
        id
        pdfs {
          title
          sourcePdfUrl
          fieldMapping {
            pdfFieldName
            slug
          }
        }
        slug
        title
        views {
          ...CompleteView
        }
        settings {
          nameSearchSlugs
          idSearchSlugs
          duplicateFieldsChecked
          duplicateFieldSlugs
          isDuplicateSlug
          referrerEmailSlug
        }
      }
    }
  }
  ${CompleteFieldFragmentDoc}
  ${CompleteViewFragmentDoc}
`

export function useOrganizationQuery(
  options: Omit<Urql.UseQueryArgs<OrganizationQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<OrganizationQuery>({
    query: OrganizationDocument,
    ...options,
  })
}
export const SignDocsDocument = gql`
  mutation signDocs {
    signLegalDocs {
      ok
    }
  }
`

export function useSignDocsMutation() {
  return Urql.useMutation<SignDocsMutation, SignDocsMutationVariables>(
    SignDocsDocument
  )
}
export const UserInfoDocument = gql`
  query userInfo {
    userInfo {
      userId
      flags
      organizations {
        bailFundSlug
        organizationId
        fullName
      }
    }
  }
`

export function useUserInfoQuery(
  options: Omit<Urql.UseQueryArgs<UserInfoQueryVariables>, "query"> = {}
) {
  return Urql.useQuery<UserInfoQuery>({ query: UserInfoDocument, ...options })
}
