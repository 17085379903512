import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "styles/index.css"
import * as Sentry from "@sentry/react"
import { ErrorUnhandledAtTopLevel } from "components/common/errors/ErrorUnhandledAtTopLevel"
import { HOTLOAD_ENABLED } from "lib/settings"
import { initSentry } from "lib/sentry"
import { unregister as unregisterServiceWorker } from "lib/serviceWorker"
import App from "components/core/App/App"
import React from "react"
import ReactDOM from "react-dom"

// Per Material-UI documentation, it will use 4 weights.
// https://material-ui.com/components/typography/#install-with-npm
import "@fontsource/roboto" // Defaults to 400
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

initSentry()

const reactRoot = document.getElementById("root")
const renderApp = () => {
  return ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<ErrorUnhandledAtTopLevel />}>
      <App />
    </Sentry.ErrorBoundary>,
    reactRoot
  )
}

if (HOTLOAD_ENABLED && module.hot) {
  module.hot.accept("components/core/App/App", renderApp)
}
renderApp()

unregisterServiceWorker()
