/*eslint-disable*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const viewtable_pb = $root.viewtable_pb = (() => {

    /**
     * Namespace viewtable_pb.
     * @exports viewtable_pb
     * @namespace
     */
    const viewtable_pb = {};

    viewtable_pb.CompactFilter = (function() {

        /**
         * Properties of a CompactFilter.
         * @memberof viewtable_pb
         * @interface ICompactFilter
         * @property {number} field CompactFilter field
         * @property {string} operation CompactFilter operation
         * @property {Array.<string>|null} [operands] CompactFilter operands
         */

        /**
         * Constructs a new CompactFilter.
         * @memberof viewtable_pb
         * @classdesc Represents a CompactFilter.
         * @implements ICompactFilter
         * @constructor
         * @param {viewtable_pb.ICompactFilter=} [properties] Properties to set
         */
        function CompactFilter(properties) {
            this.operands = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompactFilter field.
         * @member {number} field
         * @memberof viewtable_pb.CompactFilter
         * @instance
         */
        CompactFilter.prototype.field = 0;

        /**
         * CompactFilter operation.
         * @member {string} operation
         * @memberof viewtable_pb.CompactFilter
         * @instance
         */
        CompactFilter.prototype.operation = "";

        /**
         * CompactFilter operands.
         * @member {Array.<string>} operands
         * @memberof viewtable_pb.CompactFilter
         * @instance
         */
        CompactFilter.prototype.operands = $util.emptyArray;

        /**
         * Creates a new CompactFilter instance using the specified properties.
         * @function create
         * @memberof viewtable_pb.CompactFilter
         * @static
         * @param {viewtable_pb.ICompactFilter=} [properties] Properties to set
         * @returns {viewtable_pb.CompactFilter} CompactFilter instance
         */
        CompactFilter.create = function create(properties) {
            return new CompactFilter(properties);
        };

        /**
         * Encodes the specified CompactFilter message. Does not implicitly {@link viewtable_pb.CompactFilter.verify|verify} messages.
         * @function encode
         * @memberof viewtable_pb.CompactFilter
         * @static
         * @param {viewtable_pb.ICompactFilter} message CompactFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompactFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.field);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.operation);
            if (message.operands != null && message.operands.length)
                for (let i = 0; i < message.operands.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.operands[i]);
            return writer;
        };

        /**
         * Decodes a CompactFilter message from the specified reader or buffer.
         * @function decode
         * @memberof viewtable_pb.CompactFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {viewtable_pb.CompactFilter} CompactFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompactFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.viewtable_pb.CompactFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.field = reader.int32();
                    break;
                case 2:
                    message.operation = reader.string();
                    break;
                case 3:
                    if (!(message.operands && message.operands.length))
                        message.operands = [];
                    message.operands.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("field"))
                throw $util.ProtocolError("missing required 'field'", { instance: message });
            if (!message.hasOwnProperty("operation"))
                throw $util.ProtocolError("missing required 'operation'", { instance: message });
            return message;
        };

        return CompactFilter;
    })();

    viewtable_pb.CompactExperssion = (function() {

        /**
         * Properties of a CompactExperssion.
         * @memberof viewtable_pb
         * @interface ICompactExperssion
         * @property {viewtable_pb.CompactExperssion.Operator} operator CompactExperssion operator
         * @property {Array.<viewtable_pb.ICompactFilter>|null} [filters] CompactExperssion filters
         * @property {Array.<viewtable_pb.ICompactExperssion>|null} [expressions] CompactExperssion expressions
         */

        /**
         * Constructs a new CompactExperssion.
         * @memberof viewtable_pb
         * @classdesc Represents a CompactExperssion.
         * @implements ICompactExperssion
         * @constructor
         * @param {viewtable_pb.ICompactExperssion=} [properties] Properties to set
         */
        function CompactExperssion(properties) {
            this.filters = [];
            this.expressions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompactExperssion operator.
         * @member {viewtable_pb.CompactExperssion.Operator} operator
         * @memberof viewtable_pb.CompactExperssion
         * @instance
         */
        CompactExperssion.prototype.operator = 0;

        /**
         * CompactExperssion filters.
         * @member {Array.<viewtable_pb.ICompactFilter>} filters
         * @memberof viewtable_pb.CompactExperssion
         * @instance
         */
        CompactExperssion.prototype.filters = $util.emptyArray;

        /**
         * CompactExperssion expressions.
         * @member {Array.<viewtable_pb.ICompactExperssion>} expressions
         * @memberof viewtable_pb.CompactExperssion
         * @instance
         */
        CompactExperssion.prototype.expressions = $util.emptyArray;

        /**
         * Creates a new CompactExperssion instance using the specified properties.
         * @function create
         * @memberof viewtable_pb.CompactExperssion
         * @static
         * @param {viewtable_pb.ICompactExperssion=} [properties] Properties to set
         * @returns {viewtable_pb.CompactExperssion} CompactExperssion instance
         */
        CompactExperssion.create = function create(properties) {
            return new CompactExperssion(properties);
        };

        /**
         * Encodes the specified CompactExperssion message. Does not implicitly {@link viewtable_pb.CompactExperssion.verify|verify} messages.
         * @function encode
         * @memberof viewtable_pb.CompactExperssion
         * @static
         * @param {viewtable_pb.ICompactExperssion} message CompactExperssion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompactExperssion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.operator);
            if (message.filters != null && message.filters.length)
                for (let i = 0; i < message.filters.length; ++i)
                    $root.viewtable_pb.CompactFilter.encode(message.filters[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.expressions != null && message.expressions.length)
                for (let i = 0; i < message.expressions.length; ++i)
                    $root.viewtable_pb.CompactExperssion.encode(message.expressions[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CompactExperssion message from the specified reader or buffer.
         * @function decode
         * @memberof viewtable_pb.CompactExperssion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {viewtable_pb.CompactExperssion} CompactExperssion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompactExperssion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.viewtable_pb.CompactExperssion();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.operator = reader.int32();
                    break;
                case 2:
                    if (!(message.filters && message.filters.length))
                        message.filters = [];
                    message.filters.push($root.viewtable_pb.CompactFilter.decode(reader, reader.uint32()));
                    break;
                case 3:
                    if (!(message.expressions && message.expressions.length))
                        message.expressions = [];
                    message.expressions.push($root.viewtable_pb.CompactExperssion.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("operator"))
                throw $util.ProtocolError("missing required 'operator'", { instance: message });
            return message;
        };

        /**
         * Operator enum.
         * @name viewtable_pb.CompactExperssion.Operator
         * @enum {number}
         * @property {number} AND=0 AND value
         * @property {number} OR=1 OR value
         */
        CompactExperssion.Operator = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "AND"] = 0;
            values[valuesById[1] = "OR"] = 1;
            return values;
        })();

        return CompactExperssion;
    })();

    viewtable_pb.CompactQuery = (function() {

        /**
         * Properties of a CompactQuery.
         * @memberof viewtable_pb
         * @interface ICompactQuery
         * @property {Array.<number>|null} [order] CompactQuery order
         * @property {viewtable_pb.CompactQuery.Mode|null} [mode] CompactQuery mode
         * @property {Array.<viewtable_pb.ICompactFilter>|null} [filters] CompactQuery filters
         * @property {viewtable_pb.ICompactExperssion|null} [expression] CompactQuery expression
         */

        /**
         * Constructs a new CompactQuery.
         * @memberof viewtable_pb
         * @classdesc Represents a CompactQuery.
         * @implements ICompactQuery
         * @constructor
         * @param {viewtable_pb.ICompactQuery=} [properties] Properties to set
         */
        function CompactQuery(properties) {
            this.order = [];
            this.filters = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompactQuery order.
         * @member {Array.<number>} order
         * @memberof viewtable_pb.CompactQuery
         * @instance
         */
        CompactQuery.prototype.order = $util.emptyArray;

        /**
         * CompactQuery mode.
         * @member {viewtable_pb.CompactQuery.Mode|null|undefined} mode
         * @memberof viewtable_pb.CompactQuery
         * @instance
         */
        CompactQuery.prototype.mode = null;

        /**
         * CompactQuery filters.
         * @member {Array.<viewtable_pb.ICompactFilter>} filters
         * @memberof viewtable_pb.CompactQuery
         * @instance
         */
        CompactQuery.prototype.filters = $util.emptyArray;

        /**
         * CompactQuery expression.
         * @member {viewtable_pb.ICompactExperssion|null|undefined} expression
         * @memberof viewtable_pb.CompactQuery
         * @instance
         */
        CompactQuery.prototype.expression = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CompactQuery _mode.
         * @member {"mode"|undefined} _mode
         * @memberof viewtable_pb.CompactQuery
         * @instance
         */
        Object.defineProperty(CompactQuery.prototype, "_mode", {
            get: $util.oneOfGetter($oneOfFields = ["mode"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CompactQuery _expression.
         * @member {"expression"|undefined} _expression
         * @memberof viewtable_pb.CompactQuery
         * @instance
         */
        Object.defineProperty(CompactQuery.prototype, "_expression", {
            get: $util.oneOfGetter($oneOfFields = ["expression"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CompactQuery instance using the specified properties.
         * @function create
         * @memberof viewtable_pb.CompactQuery
         * @static
         * @param {viewtable_pb.ICompactQuery=} [properties] Properties to set
         * @returns {viewtable_pb.CompactQuery} CompactQuery instance
         */
        CompactQuery.create = function create(properties) {
            return new CompactQuery(properties);
        };

        /**
         * Encodes the specified CompactQuery message. Does not implicitly {@link viewtable_pb.CompactQuery.verify|verify} messages.
         * @function encode
         * @memberof viewtable_pb.CompactQuery
         * @static
         * @param {viewtable_pb.ICompactQuery} message CompactQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompactQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order != null && message.order.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.order.length; ++i)
                    writer.int32(message.order[i]);
                writer.ldelim();
            }
            if (message.mode != null && Object.hasOwnProperty.call(message, "mode"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.mode);
            if (message.filters != null && message.filters.length)
                for (let i = 0; i < message.filters.length; ++i)
                    $root.viewtable_pb.CompactFilter.encode(message.filters[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.expression != null && Object.hasOwnProperty.call(message, "expression"))
                $root.viewtable_pb.CompactExperssion.encode(message.expression, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CompactQuery message from the specified reader or buffer.
         * @function decode
         * @memberof viewtable_pb.CompactQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {viewtable_pb.CompactQuery} CompactQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompactQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.viewtable_pb.CompactQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.order && message.order.length))
                        message.order = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.order.push(reader.int32());
                    } else
                        message.order.push(reader.int32());
                    break;
                case 2:
                    message.mode = reader.int32();
                    break;
                case 3:
                    if (!(message.filters && message.filters.length))
                        message.filters = [];
                    message.filters.push($root.viewtable_pb.CompactFilter.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.expression = $root.viewtable_pb.CompactExperssion.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Mode enum.
         * @name viewtable_pb.CompactQuery.Mode
         * @enum {number}
         * @property {number} MODE_AND=0 MODE_AND value
         * @property {number} MODE_OR=1 MODE_OR value
         * @property {number} MODE_SIMPLE=2 MODE_SIMPLE value
         * @property {number} MODE_ADVANCED=3 MODE_ADVANCED value
         */
        CompactQuery.Mode = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "MODE_AND"] = 0;
            values[valuesById[1] = "MODE_OR"] = 1;
            values[valuesById[2] = "MODE_SIMPLE"] = 2;
            values[valuesById[3] = "MODE_ADVANCED"] = 3;
            return values;
        })();

        return CompactQuery;
    })();

    viewtable_pb.ViewTableToken = (function() {

        /**
         * Properties of a ViewTableToken.
         * @memberof viewtable_pb
         * @interface IViewTableToken
         * @property {viewtable_pb.ICompactQuery|null} [query] ViewTableToken query
         */

        /**
         * Constructs a new ViewTableToken.
         * @memberof viewtable_pb
         * @classdesc Represents a ViewTableToken.
         * @implements IViewTableToken
         * @constructor
         * @param {viewtable_pb.IViewTableToken=} [properties] Properties to set
         */
        function ViewTableToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ViewTableToken query.
         * @member {viewtable_pb.ICompactQuery|null|undefined} query
         * @memberof viewtable_pb.ViewTableToken
         * @instance
         */
        ViewTableToken.prototype.query = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ViewTableToken _query.
         * @member {"query"|undefined} _query
         * @memberof viewtable_pb.ViewTableToken
         * @instance
         */
        Object.defineProperty(ViewTableToken.prototype, "_query", {
            get: $util.oneOfGetter($oneOfFields = ["query"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ViewTableToken instance using the specified properties.
         * @function create
         * @memberof viewtable_pb.ViewTableToken
         * @static
         * @param {viewtable_pb.IViewTableToken=} [properties] Properties to set
         * @returns {viewtable_pb.ViewTableToken} ViewTableToken instance
         */
        ViewTableToken.create = function create(properties) {
            return new ViewTableToken(properties);
        };

        /**
         * Encodes the specified ViewTableToken message. Does not implicitly {@link viewtable_pb.ViewTableToken.verify|verify} messages.
         * @function encode
         * @memberof viewtable_pb.ViewTableToken
         * @static
         * @param {viewtable_pb.IViewTableToken} message ViewTableToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ViewTableToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.viewtable_pb.CompactQuery.encode(message.query, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ViewTableToken message from the specified reader or buffer.
         * @function decode
         * @memberof viewtable_pb.ViewTableToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {viewtable_pb.ViewTableToken} ViewTableToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ViewTableToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.viewtable_pb.ViewTableToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.query = $root.viewtable_pb.CompactQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ViewTableToken;
    })();

    return viewtable_pb;
})();

export { $root as default };
