import { CircularProgress } from "@material-ui/core"
import React from "react"

const LoadingSpinner = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress />
    </div>
  )
}

export default LoadingSpinner
