import React, { FC, useEffect } from "react"

import { Initializing } from "components/common/Initializing/Initializing"
import { useAuth0 } from "@auth0/auth0-react"

// RequireAuth0Login ensures that the user is authenticated before rendering the children.
export const RequireAuth0Login: FC = ({ children }) => {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!isLoading && !error && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          logoutParams: { returnTo: window.location },
        },
      })
    }
  }, [isLoading, error, isAuthenticated, loginWithRedirect])

  if (isLoading) {
    return <Initializing />
  }
  if (error) {
    return <div>Oops... {error.message}</div>
  }
  if (isAuthenticated) {
    return <>{children}</>
  }
  // useEffect will trigger loginWithRedirect
  return <Initializing />
}
