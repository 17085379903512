import { EmptyFilter } from "components/core/Workspaces/FilterChip/FilterChip"
import { Expression, Filter } from "components/common/TabularView/types"
import { ExpressionOperator } from "generated/api"
import { isEqual } from "lodash"
import { NewFilter } from "./AdvancedFilters"

interface State {
  expression?: Expression | null
  newFilter?: NewFilter
}

// `groupPosition` is an array of indexes of the nested expressions.
// e.g. if adding a filter at level 0, we set it to `[]`
// e.g. if adding a filter to the 2nd filter group's 3rd filter group, we set it to `[1, 2]`
type Action =
  | { type: "ADD_FILTER"; groupPosition: number[] }
  | { type: "ADD_FILTER_GROUP"; groupPosition: number[] }
  | { type: "REMOVE_NEW_FILTER" }
  | {
      type: "UPDATE_FILTER"
      filter: Filter
      previousField?: string | null
      groupPosition: number[]
    }
  | { type: "TOGGLE_OPERATOR"; groupPosition: number[] }
  | { type: "CLEAR_ALL_FILTERS" }
  | { type: "CLEAN_UP" }

type UpdateArgs = { filter: Filter; previousField?: string | null }

const emptyFilter: EmptyFilter = {
  field: null,
  operation: null,
  operands: null,
}

export const expressionReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "ADD_FILTER":
      return {
        ...state,
        expression: state.expression || {
          operator: ExpressionOperator.And,
        },
        newFilter: {
          filter: emptyFilter,
          groupPosition: action.groupPosition,
        },
      }
    case "ADD_FILTER_GROUP":
      if (state.expression) {
        return {
          ...state,
          expression: updateExpression(
            state.expression,
            action.groupPosition,
            addFilterGroupToExpression
          ),
          newFilter: {
            filter: emptyFilter,
            groupPosition: [
              ...action.groupPosition,
              getNextGroupPosition(state.expression, action.groupPosition),
            ],
          },
        }
      }
      return {
        ...state,
        expression: {
          operator: ExpressionOperator.And,
          expressions: [
            {
              operator: ExpressionOperator.And,
            },
          ],
        },
        newFilter: {
          filter: emptyFilter,
          groupPosition: [...action.groupPosition, 0],
        },
      }
    case "REMOVE_NEW_FILTER":
      return {
        ...state,
        newFilter: undefined,
      }
    case "UPDATE_FILTER":
      if (state.expression) {
        return {
          ...state,
          expression: updateExpression(
            state.expression,
            action.groupPosition,
            updateFilterInExpression,
            { filter: action.filter, previousField: action.previousField }
          ),
        }
      }
      return state
    case "TOGGLE_OPERATOR":
      if (state.expression) {
        return {
          ...state,
          expression: updateExpression(
            state.expression,
            action.groupPosition,
            toggleOperator
          ),
        }
      }
      return state
    case "CLEAR_ALL_FILTERS":
      return {
        ...state,
        expression: null,
      }
    case "CLEAN_UP":
      if (state.expression) {
        return {
          ...state,
          expression: cleanUpExpression(state.expression, [], state.newFilter),
        }
      }
      return state
  }
}

const getNextGroupPosition = (
  expression: Expression,
  groupPosition: number[]
): number => {
  if (groupPosition.length === 0) {
    return (expression.expressions || []).length
  }

  if (
    !expression.expressions ||
    expression.expressions.length <= groupPosition[0]
  ) {
    throw Error("incorrect filter group index")
  }

  return getNextGroupPosition(
    expression.expressions[groupPosition[0]],
    groupPosition.slice(1)
  )
}

const updateExpression = (
  expression: Expression,
  groupPosition: number[],
  updateFunction: (expression: Expression, args?: UpdateArgs) => Expression,
  updateArgs?: UpdateArgs
): Expression => {
  if (groupPosition.length === 0) {
    return updateFunction(expression, updateArgs)
  }

  if (
    !expression.expressions ||
    expression.expressions.length <= groupPosition[0]
  ) {
    throw Error("incorrect filter group index")
  }

  return {
    ...expression,
    expressions: [
      ...expression.expressions.slice(0, groupPosition[0]),
      updateExpression(
        expression.expressions[groupPosition[0]],
        groupPosition.slice(1),
        updateFunction,
        updateArgs
      ),
      ...expression.expressions.slice(groupPosition[0] + 1),
    ],
  }
}

const updateFilterInExpression = (
  expression: Expression,
  args?: UpdateArgs
): Expression => {
  if (!args) {
    return expression
  }

  const { filter, previousField } = args

  if (expression.filters) {
    // If there was a field assigned to the same chip then override the filter or remove it
    if (previousField) {
      // If the filter exists but the operands has been removed, we remove the filter
      if (!filter.operands) {
        return {
          ...expression,
          filters: expression.filters.filter(
            (filt) => filt.field !== previousField
          ),
        }
      } else {
        return {
          ...expression,
          filters: expression.filters.map((filt) => {
            if (filt.field === previousField) {
              return filter
            }
            return filt
          }),
        }
      }
    } else {
      // If it's a completely new filter, we just add it to the list
      return {
        ...expression,
        filters: [...expression.filters, filter],
      }
    }
  }

  // If there was no filter exists in this expression, create the filter list
  return {
    ...expression,
    filters: [filter],
  }
}

const addFilterGroupToExpression = (expression: Expression): Expression => {
  return {
    ...expression,
    expressions: [
      ...(expression.expressions || []),
      {
        operator: ExpressionOperator.And,
      },
    ],
  }
}

const toggleOperator = (expression: Expression): Expression => {
  return {
    ...expression,
    operator:
      expression.operator === ExpressionOperator.Or
        ? ExpressionOperator.And
        : ExpressionOperator.Or,
  }
}

const cleanUpExpression = (
  expression: Expression,
  groupPosition: number[],
  newFilter?: NewFilter
): Expression | null => {
  if (expression.expressions) {
    const cleanedExpressions: Expression[] = []
    expression.expressions.forEach((expr, index) => {
      const cleaned = cleanUpExpression(
        expr,
        [...groupPosition, index],
        newFilter
      )
      if (cleaned) {
        cleanedExpressions.push(cleaned)
      }
    })
    expression.expressions = cleanedExpressions
  }

  // If there's a new filter present in the group, we should not clean up the expression
  if (newFilter && isEqual(groupPosition, newFilter.groupPosition)) {
    return expression
  }

  // Otherwise if there isn't any filter or expression, we clean up the current expression
  if (
    (!expression.filters || expression.filters.length === 0) &&
    (!expression.expressions || expression.expressions.length === 0)
  ) {
    return null
  }

  return expression
}
