import { Collection, Organization } from "generated/api"
import { createContext, useContext } from "react"
import { documentUrlById, viewUrlById } from "lib/navigationHelpers"

export type CollectionHierarchy = {
  collectionId: string
  childToParentSlug: string
}

export type CurrentCollectionValue = {
  collection: Collection
  getUrlForDocument: (documentId: string) => string
  getUrlForWorkspace: (viewId: string | number) => string
  collectionHierarchies: Array<CollectionHierarchy>
}

export const CurrentCollectionContext = createContext<CurrentCollectionValue | null>(
  null
)

// Returns the currently selected Collection, or throws an exception if invoked
// outside of the scope of a CurrentCollectionContext.Provider.
export const useCurrentCollection = () => {
  const context = useContext(CurrentCollectionContext)
  if (context === null) {
    throw Error(
      "context must be consumed within a provider of CurrentCollectionContext"
    )
  }
  return context
}

/**
 * Constructs the CurrentCollectionValue.
 *
 * This method is exposed so that the various providers of CurrentCollectionContext can use the same logic
 * to construct URLs.
 */
export const makeCurrentCollectionValue = (
  organization: Organization,
  collection: Collection
): CurrentCollectionValue => ({
  collection: collection,
  getUrlForDocument: (documentId: string) =>
    documentUrlById(organization, collection, documentId),
  getUrlForWorkspace: (viewId: string | number) =>
    viewUrlById(organization, collection, viewId),
  collectionHierarchies: [],
})
