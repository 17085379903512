/**
 * Inventory of all of the LocalStorage keys used by the application.
 *
 * If adding new LocalStorage capabilities, please use
 * a hook-based pattern on @rehooks/local-storage instead.
 *
 * Note: The auth0 SDK will also use LocalStorage keys prefixed with
 * "@@auth0spajs@@".
 */
export enum Keys {
  RowsPerPage = 1,
  WelcomedUser = 2,
}
