import {
  CreateChildDocumentInput,
  CreateDocumentInput,
  DocumentCreateMutation,
  DocumentCreateMutationVariables,
  DocumentFieldInput,
  DocumentOperationInput,
  DocumentUpdateMutation,
  DocumentUpdateMutationVariables,
  Scalars,
} from "generated/api"
import { FieldValueType } from "lib/apihelpers"
import { OperationResult } from "urql/core"

export enum InputType {
  Attachments = "attachments",
  AttorneyInformation = "attorney_information",
  BooleanCheckbox = "boolean_checkbox",
  ContactReference = "contact_reference",
  Currency = "currency",
  Date = "date",
  DateTime = "date_time",
  DocumentCreatedTimestamp = "document_created_timestamp",
  DocumentUpdatedTimestamp = "document_updated_timestamp",
  Email = "email",
  Integer = "integer",
  Multiselect = "multi_select",
  NestedCollection = "nested_collection",
  Notes = "notes",
  Number = "number",
  Phone = "phone",
  ReadOnly = "read_only",
  Select = "select",
  Text = "text",
  Time = "time",
}

export interface SelectOption {
  label: string
  value: string
  hidden?: boolean
}

export interface InputElement {
  element: Element.Input
  label: string
  options?: SelectOption[]
  required: boolean
  slug: string
  type: InputType
}

export enum LayoutType {
  Column = "column",
  Columns = "columns",
  ExpansionPanel = "expansionPanel",
  HorizontalRule = "horizontalRule",
}

export interface LayoutElement {
  element: Element.Layout
  children: Array<InputElement | LayoutElement>
  label?: string
  slug: string
  type: LayoutType
}

export interface Form {
  layout: InputElement | LayoutElement
}

export interface Schema {
  form: Form
}

export enum Element {
  Input = "input",
  Layout = "layout",
}

/**
 * Input elements can register this hook to modify the API request that is
 * generated when the user clicks "Save" on a new document.
 */
export type HookForCreate = (
  apiRequest: CreateDocumentInput
) => {
  children: CreateChildDocumentInput[]
  fields: Array<DocumentFieldInput>
  collectionId: Scalars["ID"]
}

/**
 * Input elements can register this hook to modify the API request that is
 * generated when the user clicks "Save" on an existing document.
 */
export type HookForUpdate = (
  requestId: string,
  operations: DocumentOperationInput[]
) => DocumentOperationInput[]

/**
 * Input elements can register this hook to add validation error messages.
 * Implementations generally should return a new value that concatenates
 * `previous` with any validation errors that currently apply.
 */
export type HookForErrors = (previous: string) => string

/**
 * Input elements register this hook to inform the UI whether or not the user
 * has made any changes that are worthy of triggering a "Save".
 */
export type HookForChangesMade = () => boolean

/**
 * ReplaceHooks defines the function that an input element can invoke to update
 * the API hooks.
 */
export type ReplaceHooksType = (
  key: string,
  create: HookForCreate,
  update: HookForUpdate,
  getErrors: HookForErrors,
  isChangesMade: HookForChangesMade
) => void

/**
 * RemoveHooks defines the function that input elements can use to remove their
 * API hooks.
 */
export type RemoveHooksType = (key: string) => void

/**
 * The return type of the handleSaveBailRequest handler. See the implementation
 * in Detail.js for an explanation.
 */
export type OnSaveBailRequestType = () => Promise<
  | boolean
  | OperationResult<DocumentCreateMutation, DocumentCreateMutationVariables>
  | OperationResult<DocumentUpdateMutation, DocumentUpdateMutationVariables>
>

/** Convenience types for consumers to refine their prop types. */
export type OnChangeType = (
  slug: string,
  value: boolean | null | string | number
) => void

/**
 * OnChange and validator types for date and time values.
 *
 * MUI components are typed to allow undefined, though it does not appear to be
 * generated by the components we are using.
 */
export type OnChangeDateType = (slug: string, value?: string | null) => void
export type OnChangeDateTimeType = OnChangeDateType
export type ValidateDateType = (
  label: string,
  slug: string,
  value?: string | null
) => void
export type ValidateDateTimeType = ValidateDateType

/**
 * OnChange type for multiselect fields. The value is the string corresponding
 * to the chip that the customer clicked on. This is effectively a toggle.
 */
export type OnChangeMultiselectType = (slug: string, value: string) => void

export type ValidateIntegerType = (
  label: string,
  slug: string,
  value: string
) => void

/**
 * Handlers defines the callbacks that form field instances may use to update
 * or inspect the current document(s) state, or refresh API request mutation
 * callbacks.
 */
export interface Handlers {
  onChange: OnChangeType
  onChangeDate: OnChangeDateType
  onChangeDateTime: OnChangeDateTimeType
  onChangeMultiselect: OnChangeMultiselectType
  onSaveBailRequest?: OnSaveBailRequestType
  removeHooks?: RemoveHooksType
  replaceHooks?: ReplaceHooksType
  validateDate: ValidateDateType
  validateDateTime: ValidateDateTimeType
  validateInteger: ValidateIntegerType
}

export type HookMap = {
  [key: string]: {
    createHandler: HookForCreate
    updateHandler: HookForUpdate
    validationErrorsHandler: HookForErrors
    changesMadeHandler: HookForChangesMade
  }
}

export type FormValidationError = {
  helperText: string
  label: string
}

export type ValidationErrorsMap = {
  [slug: string]: FormValidationError
}

// TODO: migrate to same type as UsefulDocument.fields
export type FieldValuesMap = {
  [slug: string]: FieldValueType
}

export type VisibleFieldValuesMap = FieldValuesMap

export type NestedFormProperties = {
  isDeleted: boolean
  onDeleteChild: () => void
  onRestoreDeleteChild: () => void
  status: "New" | "Modified" | null
}
