import { CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Backdrop from "@material-ui/core/Backdrop"
import React from "react"

import { initializingStyles } from "styles/theme"

const useStyles = makeStyles(initializingStyles)

/**
 * Initializing displays a circular spinner. This is intended to be used when
 * we are waiting for the app to initialize, and before we're showing the
 * standard UI.
 */
export const Initializing = () => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress />
    </Backdrop>
  )
}
