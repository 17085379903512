import { FieldDefinition, PschemaSettings } from "generated/api"
import { makeStyles } from "@material-ui/core"
import { Slugs } from "lib/Search"
import CloseIcon from "@material-ui/icons/Close"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import React, { FC, useReducer } from "react"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import Typography from "@material-ui/core/Typography"

import { getCleanSearchTitle } from "../helpers"
import { searchDialogStyles } from "styles/theme"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import SearchPanel from "./SearchPanel"

const useStyles = makeStyles(searchDialogStyles)
const INITIAL_TAB_INDEX = 0

const getSearchOptions = (
  bailFundFields: Array<FieldDefinition>,
  settings?: PschemaSettings | null
) => {
  if (!settings) {
    return []
  }
  const idSearchTitle = getCleanSearchTitle(bailFundFields, settings)

  return [
    {
      slug: Slugs.CLIENT_NAME,
      allSlugsToSearch: settings.nameSearchSlugs,
      textContent: {
        buttonLabel: "Find Client",
        dialogContent: "Search for a client by entering their name below.",
        inputLabel: "Client Name",
      },
    },
    {
      slug: settings.idSearchSlugs[0],
      allSlugsToSearch: settings.idSearchSlugs,
      textContent: {
        buttonLabel: "Find Client",
        dialogContent: `Search for a client by entering their ${idSearchTitle} below.`,
        inputLabel: idSearchTitle,
      },
    },
  ]
}

interface Props {
  onClose: () => void
  searchByField: (fieldSlugs: string[], searchValue: string) => void
  open: boolean
}

interface State {
  queries: { [slug: string]: string | undefined }
  tab: number
}

type Action =
  | { type: "UPDATE_SEARCH_QUERY"; slug: string; value: string }
  | { type: "CHANGE_TAB"; tab: number }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "UPDATE_SEARCH_QUERY":
      return {
        ...state,
        queries: { ...state.queries, [action.slug]: action.value },
      }
    case "CHANGE_TAB":
      return { ...state, tab: action.tab }
  }
}

export const SearchDialog: FC<Props> = (props) => {
  const classes = useStyles()
  const {
    collection: { fields, settings },
  } = useCurrentCollection()
  const [state, dispatch] = useReducer(reducer, {
    queries: {},
    tab: INITIAL_TAB_INDEX,
  })
  const configs = getSearchOptions(fields, settings)

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="find-client-dialog"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="find-client-dialog-title" disableTypography>
        <Grid container justify="space-between">
          <Typography variant="h5">Find Client</Typography>
          <IconButton size="small" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>

      {settings ? (
        <Tabs
          value={state.tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, newValue) =>
            dispatch({ type: "CHANGE_TAB", tab: newValue })
          }
          aria-label="Find Client"
          centered
        >
          {configs.map((config, tabIndex) => (
            <Tab
              key={tabIndex}
              className={classes.tab}
              label={config.textContent.inputLabel}
            />
          ))}
        </Tabs>
      ) : (
        <div>
          The fund needs further setup to support this search, please contact an
          admin.
        </div>
      )}

      {settings &&
        configs.map((config, tabIndex) => (
          <SearchPanel
            key={tabIndex}
            tabIndex={tabIndex}
            selectedTab={state.tab}
            onChange={(event) =>
              dispatch({
                type: "UPDATE_SEARCH_QUERY",
                slug: config.slug,
                value: event.target.value,
              })
            }
            onEnter={() => {
              props.searchByField(
                config.allSlugsToSearch
                  ? config.allSlugsToSearch
                  : [config.slug],
                state.queries[config.slug] || ""
              )
              props.onClose()
            }}
            query={state.queries[config.slug] || ""}
            textContent={config.textContent}
          />
        ))}
    </Dialog>
  )
}

export default SearchDialog
