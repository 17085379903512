import {
  Expression,
  Filter,
  Query,
  UiFieldsTable,
} from "components/common/TabularView/types"
import { FieldDefinition, QueryOperator } from "generated/api"
import { makeStyles } from "@material-ui/core/styles"
import { tabularViewStyles } from "styles/theme"
import { toggleOrderByOperand } from "lib/tableHelpers"
import { Tooltip } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import TableCell from "@material-ui/core/TableCell"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Typography from "@material-ui/core/Typography"
const useStyles = makeStyles(tabularViewStyles)
interface HeaderRowProps {
  enableSorting?: boolean
  loading: boolean
  offsetTableHeader?: boolean
  query: Query
  sortColumn: (filterOp: Filter) => void
  uiFieldsTable: UiFieldsTable
}
interface CustomTableCellProps {
  enableSorting?: boolean
  field: FieldDefinition
  expression?: Expression | null
  loading: boolean
  offsetTableHeader?: boolean
  panelWrapperOffset: number
  sortColumn: (filterOp: Filter) => void
}
const CustomTableCell = ({
  enableSorting,
  field,
  expression,
  loading,
  offsetTableHeader,
  panelWrapperOffset,
  sortColumn,
}: CustomTableCellProps) => {
  const classes = useStyles()
  const [showTooltipHeader, setShowTooltipHeader] = useState(false)

  const orderByFieldFilter = expression?.filters?.find(
    (func: Filter) =>
      func.field === field.slug && func.operation === QueryOperator.OrderBy
  )
  const hasOrderBy = orderByFieldFilter?.operation !== undefined
  const cellRef = (node: any) => {
    if (node === null) {
      return
    }
    const cellWidth = node.getBoundingClientRect().width
    if (cellWidth >= 288) {
      setShowTooltipHeader(true)
    }
    // reduces maxWidth required to show a tooltip if the header cell is currently sorted to account for the sortLabelIcon
    else if (hasOrderBy && cellWidth >= 250) {
      setShowTooltipHeader(true)
    } else {
      setShowTooltipHeader(false)
    }
  }
  // TableSortLabel wants "asc", "desc", or undefined. If given anything
  // else, it will emit a console error.
  const operand =
    hasOrderBy && orderByFieldFilter?.operands?.length
      ? (orderByFieldFilter.operands[0] as "asc" | "desc")
      : undefined
  const toggleOperand = toggleOrderByOperand(operand)
  const cell = (
    <TableCell
      key={field.slug}
      className={classes.tableHeaderCell}
      style={{
        cursor: enableSorting ? "pointer" : "inherit",
        paddingLeft: enableSorting ? "16px" : "",
        top: offsetTableHeader ? panelWrapperOffset : 0,
      }}
      onClick={() => {
        if (enableSorting) {
          sortColumn({
            field: field.slug,
            operation: QueryOperator.OrderBy,
            operands: toggleOperand ? [toggleOperand] : null,
          })
        }
      }}
    >
      <div className={classes.tableHeaderText}>
        <Typography variant="caption" ref={cellRef}>
          {loading ? "" : field.title}
        </Typography>
        {enableSorting && (
          <TableSortLabel
            hideSortIcon
            active={hasOrderBy}
            direction={operand}
          />
        )}
      </div>
    </TableCell>
  )

  return showTooltipHeader ? (
    <Tooltip interactive title={field.title} placement="top-start">
      {cell}
    </Tooltip>
  ) : (
    <>{cell}</>
  )
}
const HeaderRow = ({
  enableSorting,
  loading,
  offsetTableHeader,
  query,
  sortColumn,
  uiFieldsTable,
}: HeaderRowProps) => {
  const [panelWrapperOffset, setPanelWrapperOffset] = useState(0)
  useEffect(() => {
    if (!offsetTableHeader) {
      return
    }
    const panelWrapper = document.getElementById("controlPanelWrapper")
    const offset = panelWrapper?.offsetHeight
    if (panelWrapper) {
      // todo: this isn't very react-ish, we should use a ref instead.
      // todo: we should make it clearer where these numbers are coming from
      setPanelWrapperOffset(offset ? offset + 56 : 50)
    }
  }, [loading, query, offsetTableHeader])
  if (loading) {
    return null
  }
  const { order, expression } = query
  return (
    <>
      {order.map((slug, index) => {
        const field = uiFieldsTable.get(slug)
        if (!field) {
          console.warn(
            "Unexpected: missing FieldDefinition for slug",
            slug,
            "Known fields: ",
            Array.from(uiFieldsTable.keys()).sort()
          )
          return <TableCell key={slug || index} />
        }
        return (
          <CustomTableCell
            key={index}
            enableSorting={enableSorting}
            field={field}
            expression={expression}
            loading={loading}
            panelWrapperOffset={panelWrapperOffset}
            offsetTableHeader={offsetTableHeader}
            sortColumn={sortColumn}
          />
        )
      })}
    </>
  )
}
export default HeaderRow
