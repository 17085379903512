import { makeStyles } from "@material-ui/core/styles"
import React, { FC } from "react"
import TextField from "@material-ui/core/TextField"

import { requestFieldStyles } from "styles/theme"

const FORM_VARIANT = "filled"

const useStyles = makeStyles((theme) => requestFieldStyles(theme))

interface Props {
  error: boolean
  helperText: string
  isDisabled: boolean
  label: string
  onChange: (slug: string, value: string) => void
  path: string
  slug: string
  validationHandler: (label: string, slug: string, value: string) => void
  value: string
}

const NumberInput: FC<Props> = ({
  error,
  helperText,
  isDisabled,
  label,
  onChange,
  path,
  slug,
  validationHandler,
  value,
}) => {
  const classes = useStyles()

  return (
    <TextField
      className={classes.formControlFull}
      disabled={isDisabled}
      error={error}
      helperText={helperText}
      id={path + slug}
      InputLabelProps={{
        classes: {
          root: classes.label,
          shrink: classes.shrink,
        },
      }}
      InputProps={{
        classes: {
          input: classes.input,
          underline: classes.underline,
        },
      }}
      key={slug}
      label={label}
      onChange={(event) => {
        onChange(slug, event.target.value)
        validationHandler(label, slug, event.target.value)
      }}
      value={value}
      variant={FORM_VARIANT}
    />
  )
}
export default NumberInput
