import { Collection, Organization, UserFeatureFlag } from "generated/api"
import { Collections } from "lib/Collections"
import { findDefaultView, viewUrlById } from "lib/navigationHelpers"
import { REFERRAL_FORM_BASE_URL } from "lib/settings"
import { useAuth0 } from "@auth0/auth0-react"
import { useCurrentOrganization } from "providers/CurrentOrganizationProvider"
import {
  useCurrentUser,
  useUserFeatureFlag,
} from "providers/CurrentUserProvider"
import { useHistory } from "react-router-dom"
import { useNavigator } from "providers/Navigator"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import OrganizationSelector from "components/core/App/OrganizationSelector"
import React from "react"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"

/**
 * Specifies an ordering for organizations that are not yet configured with
 * one.
 *
 * TODO: remove when all funds have a collection order set.
 */
const getFallbackCollectionOrder = (collections: Collection[]) => {
  const ordering = [Collections.BAILREQUESTS]
  if (collections.find((coll) => coll.slug === Collections.CONTACTS)) {
    ordering.push(Collections.CONTACTS)
  }
  return ordering
}

/**
 * For organizations that don't have their `collection_order` setup, use the old
 * behavior.
 */
const computeLinksForCollections = (organization: Organization) => {
  const sortOrder =
    organization.collectionOrder && organization.collectionOrder.length > 0
      ? organization.collectionOrder
      : getFallbackCollectionOrder(organization.collections)

  const collections = new Map(
    organization.collections.map((coll) => [coll.slug, coll])
  )
  return sortOrder
    .map((collectionSlug) => {
      const collection = collections.get(collectionSlug)
      if (!collection) {
        throw Error(`unable to find ${collectionSlug} in list of collections`)
      }
      return collection
    })
    .filter((collection) => collection.actions?.navigation)
    .map((collection) => {
      const view = findDefaultView(collection.views)
      if (!view) {
        throw Error(
          `unable to find a default view for collection ${collection.slug}`
        )
      }
      return {
        title: collection.title,
        href: viewUrlById(organization, collection, view.id),
      }
    })
}

// Determine which links should appear in the menu.
const generateLinks = (
  organization: Organization,
  collectionEditorEnabled: boolean
) => {
  const links = computeLinksForCollections(organization)
  if (collectionEditorEnabled) {
    links.push({
      title: "Collection Editor",
      href: `/o/${organization.organizationId}/collection-editor`,
    })
    links.push({
      title: "Fund Management",
      href: "/fund-admin",
    })
  }
  links.push({
    title: "Users",
    href: `/o/${organization.organizationId}/user-admin`,
  })
  return links
}

// The URL that the user will be redirected to after Auth0's "Logout" process
// completes.
//
// We redirect the user *back* to the current application so that the Auth0
// login experience will redirect them back to the right application. This is
// particularly important for review apps, because if this is not set, users
// will be redirected to a random review instance.
const logoutUrl = () => {
  return `${window.location.protocol}//${window.location.host}`
}

const AppMenu = () => {
  const history = useHistory()
  const { isMenuOpen, openMenu, closeMenu } = useNavigator()
  const { logout, user } = useAuth0()
  const { organization, selectBailFundById } = useCurrentOrganization()
  const { organizations } = useCurrentUser()
  const userCanViewMultipleOrganizations = organizations.length > 1
  const collectionEditorEnabled = useUserFeatureFlag(
    UserFeatureFlag.CollectionEditor
  )
  const handleAccountChange = (e: React.ChangeEvent<{ value: string }>) => {
    if (organization.organizationId !== e.target.value) {
      selectBailFundById(e.target.value)
    }
  }

  const links = React.useMemo(
    () => generateLinks(organization, collectionEditorEnabled),
    [organization, collectionEditorEnabled]
  )

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      open={isMenuOpen}
      onOpen={openMenu}
      onClose={closeMenu}
      data-testid="backdrop"
    >
      <Box mx={2} mt={3}>
        <List>
          <ListItem disableGutters>
            <ListItemIcon>
              <Avatar src={user && user.picture} alt={user && user.email} />
            </ListItemIcon>
          </ListItem>
          <ListItem disableGutters>
            <ListItemText>{user && user.email}</ListItemText>
          </ListItem>
          {userCanViewMultipleOrganizations ? (
            <ListItem>
              <OrganizationSelector
                organizations={organizations}
                selectedOrganization={organization}
                onChange={handleAccountChange}
              />
            </ListItem>
          ) : null}
        </List>
      </Box>
      <Divider />
      <Box my={2}>
        <div role="presentation" onClick={closeMenu} onKeyDown={closeMenu}>
          <List>
            {links.map((link, idx) => (
              <ListItem
                button
                key={idx}
                onClick={() => {
                  // If the user is already on the target page, don't
                  // navigate them. This saves a refresh.
                  if (window.location.pathname === link.href) {
                    return
                  }
                  history.push(link.href)
                }}
              >
                <ListItemText
                  primary={link.title}
                  primaryTypographyProps={{
                    variant: "subtitle1",
                    style: {
                      fontWeight: 700,
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Box>
      <Divider />
      <Box my={2}>
        <div role="presentation">
          <List>
            {[
              {
                title: "Privacy Policy",
                href: `${REFERRAL_FORM_BASE_URL}/privacy-policy`,
              },
              {
                title: "Terms of Use",
                href: `${REFERRAL_FORM_BASE_URL}/terms-of-use`,
              },
            ].map((link, idx) => (
              <ListItem
                button
                component="a"
                key={idx}
                href={link.href}
                target="_blank"
              >
                <ListItemText
                  primary={link.title}
                  primaryTypographyProps={{
                    variant: "subtitle1",
                    color: "textSecondary",
                    style: {
                      fontWeight: 700,
                    },
                  }}
                />
              </ListItem>
            ))}
            <ListItem
              button
              onClick={(e) => {
                e.preventDefault()
                logout({ logoutParams: { returnTo: logoutUrl() } })
              }}
            >
              <ListItemText
                primary="Log out"
                primaryTypographyProps={{
                  variant: "subtitle1",
                  color: "textSecondary",
                  style: {
                    fontWeight: 700,
                  },
                }}
              />
            </ListItem>
          </List>
        </div>
      </Box>
    </SwipeableDrawer>
  )
}

export default AppMenu
