import { ERROR_ORGANIZATION_MISSING_COLLECTION } from "lib/errorMessages"
import { ErrorGeneric } from "components/common/errors/ErrorGeneric"
import React from "react"

export const ErrorOrganizationMissingCollection = ({
  label,
}: {
  label: string
}) => {
  console.warn("ErrorOrganizationMissingCollection: ", label)
  return <ErrorGeneric message={ERROR_ORGANIZATION_MISSING_COLLECTION} />
}
