import * as Sentry from "@sentry/react"
import { DocumentDetailsProvider } from "providers/DocumentDetailsProvider"
import { ERROR_DEFAULT } from "lib/errorMessages"
import { ErrorCollectionNoViews } from "components/common/errors/ErrorCollectionNoViews"
import { ErrorGeneric } from "components/common/errors/ErrorGeneric"
import { ErrorOrganizationMissingFallbackViews } from "components/common/errors/ErrorOrganizationMissingFallbackViews"
import { ErrorPageNotFound } from "components/common/errors/ErrorPageNotFound"
import {
  findDefaultPath,
  findDefaultView,
  viewUrlById,
} from "lib/navigationHelpers"
import { GLOBAL_BANNER } from "components/core/AppBar/helpers"
import { Initializing } from "components/common/Initializing/Initializing"
import { Redirect, Switch, useRouteMatch } from "react-router-dom"
import { SentryRoute as Route } from "lib/sentry"
import { SchemaProvider } from "providers/SchemaProvider/SchemaProvider"
import { UrlCollectionProvider } from "providers/UrlCollectionProvider"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import { useCurrentOrganization } from "providers/CurrentOrganizationProvider"
import { useCurrentUser } from "providers/CurrentUserProvider"
import { WorkspacesProvider } from "../../providers/WorkspacesProvider"
import AppBarBasic from "components/core/AppBar/AppBarBasic"
import AppBarWorkspace from "components/core/AppBar/AppBarWorkspace"
import DetailPage from "components/core/DetailPage/DetailPage"
import GlobalBanner from "components/core/AppBar/GlobalBanner"
import React, { Suspense, useState } from "react"
import Workspaces from "components/core/Workspaces/Workspaces"

const CollectionEditor = React.lazy(
  () =>
    import(
      /* webpackChunkName: "collectioneditor" */ "../core/CollectionEditor/CollectionEditor"
    )
)

const UserAdmin = React.lazy(
  () =>
    import(/* webpackChunkName: "useradmin" */ "../core/UserAdmin/UserAdmin")
)

const FundAdmin = React.lazy(
  () =>
    import(/* webpackChunkName: "fundadmin" */ "../core/FundAdmin/FundAdmin")
)

interface RoutesProps {
  banner: React.ReactChild
}

export const BailFundRoutes = () => {
  const [isBannerEnabled, setIsBannerEnabled] = useState(
    GLOBAL_BANNER.isActivated
  )

  const renderBanner = () => (
    <GlobalBanner
      copy={GLOBAL_BANNER.copy}
      isEnabled={isBannerEnabled}
      toggleBanner={setIsBannerEnabled}
    />
  )

  return (
    <Sentry.ErrorBoundary fallback={<ErrorGeneric message={ERROR_DEFAULT} />}>
      <Switch>
        <Route path="/o/:organizationId">
          <RoutesForOrganization banner={renderBanner()} />
        </Route>
        <Route exact path="/fund-admin">
          <AppBarBasic banner={renderBanner()} hideOrgName />
          <Suspense fallback={<Initializing />}>
            <FundAdmin />
          </Suspense>
        </Route>
        <Route exact path="/error/test-error-boundary">
          <TestErrorBoundaryView />
        </Route>
        <Route exact path="/">
          <RedirectToBestDefaultView />
        </Route>
        <Route>
          <ErrorPageNotFound />
        </Route>
      </Switch>
    </Sentry.ErrorBoundary>
  )
}

/** Routes for Flex under /o/O. */
const RoutesForOrganization = ({ banner }: RoutesProps) => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.url}/collection-editor`}>
        <AppBarBasic banner={banner} />
        <Suspense fallback={<Initializing />}>
          <CollectionEditor />
        </Suspense>
      </Route>
      <Route path={`${match.url}/user-admin`}>
        <AppBarBasic banner={banner} hideOrgName />
        <Suspense fallback={<Initializing />}>
          <UserAdmin />
        </Suspense>
      </Route>
      <Route path={`${match.url}/c/:collectionId`}>
        <RoutesForCollection banner={banner} />
      </Route>
      <Route exact path={match.url}>
        <RedirectToBestDefaultView />
      </Route>
      <Route>
        <AppBarBasic banner={banner} />
        <ErrorPageNotFound />
      </Route>
    </Switch>
  )
}

/** Routes for Flex under /o/O/c/C. */
const RoutesForCollection = ({ banner }: RoutesProps) => {
  const match = useRouteMatch()
  return (
    <UrlCollectionProvider>
      <Switch>
        {/* The /d path allows the add-new-bail-request flow. */}
        <Route path={[`${match.url}/d/:documentId`, `${match.url}/d`]}>
          <AppBarBasic banner={banner} />
          <DocumentDetailsProvider>
            <DetailPage />
          </DocumentDetailsProvider>
        </Route>
        <Route path={`${match.url}/w`}>
          <RoutesForWorkspace banner={banner} />
        </Route>
        {/* supporting old workspace dev url */}
        <Route exact path={`${match.url}/dev/workspace-ui`}>
          <Redirect to={`${match.url}/w`} />
        </Route>
        <Route exact path={match.url}>
          <RedirectToDefaultViewForCollection />
        </Route>
        <Route>
          <AppBarBasic banner={banner} />
          <ErrorPageNotFound />
        </Route>
      </Switch>
    </UrlCollectionProvider>
  )
}

/** Routes for Workspace. */
const RoutesForWorkspace = ({ banner }: RoutesProps) => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.url}`}>
        <RedirectToDefaultViewForCollection />
      </Route>
      <Route path={`${match.url}/:viewId`}>
        <SchemaProvider>
          <WorkspacesProvider>
            <AppBarWorkspace banner={banner} />
            <Workspaces />
          </WorkspacesProvider>
        </SchemaProvider>
      </Route>
    </Switch>
  )
}

/**
 * Redirect the user to the best landing page for /. The "best" landing page is
 * the one that is associated with the first organization on their account.
 */
const RedirectToBestDefaultView = () => {
  const { organizations } = useCurrentUser()
  if (!organizations[0]) {
    throw Error("Unexpected: CurrentUserProvider provided unexpected state.")
  }
  const { organization, isRefreshing } = useCurrentOrganization()
  if (isRefreshing) {
    return <Initializing />
  }
  const defaultPath = findDefaultPath(organization)
  if (defaultPath === null) {
    return <ErrorOrganizationMissingFallbackViews />
  }
  return <Redirect to={defaultPath} />
}

/** Redirect to the default view for the current organization. */
const RedirectToDefaultViewForCollection = () => {
  const { organization } = useCurrentOrganization()
  const { collection } = useCurrentCollection()
  const defaultView = findDefaultView(collection.views)
  let url

  if (defaultView === null) {
    return <ErrorCollectionNoViews />
  }

  url = viewUrlById(organization, collection, defaultView.id)

  return <Redirect to={url} />
}

/**
 * Entirely for testing errors in non-local environments.
 * This will appear in Sentry.
 */
const TestErrorBoundaryView = () => {
  throw Error("Error thrown by TestErrorBoundaryView")
}
