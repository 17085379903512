import { createStyles, Theme } from "@material-ui/core/styles"
import { UI_COLORS } from "styles/theme"

export const workspaceDashboardStyles = (theme: Theme) =>
  createStyles({
    workspaceContainer: {
      marginTop: theme.spacing(8),
      flexGrow: 1,
      height: "calc(100vh - 64px)", // TODO: Probably a better flexbox grid method for this
      background: UI_COLORS.white,
    },
    railContainer: {
      boxShadow: "inset -1px 0px 0px rgba(33, 33, 33, 0.06)",
      background: UI_COLORS.white,
    },
    content: {
      flexGrow: 1,
      background: UI_COLORS.white,
    },
    table: {
      padding: theme.spacing(2),
    },
    slideOutPanel: {
      boxShadow: "inset -1px 0px 0px rgba(33, 33, 33, 0.06)",
    },
    dialogButton: {
      textTransform: "none",
    },
    dialogActions: {
      padding: "24px 0 16px 0",
    },
    deleteButton: {
      "backgroundColor": UI_COLORS.red_900,
      "textTransform": "none",
      "color": UI_COLORS.white,
      "&:hover": {
        backgroundColor: "#ba4942",
      },
    },
    input: {
      width: "312px",
    },
  })
