import {
  Element,
  Handlers,
  InputElement,
  LayoutElement,
  NestedFormProperties,
  ValidationErrorsMap,
  VisibleFieldValuesMap,
} from "lib/forms/types"
import { FieldValueType } from "lib/apihelpers"
import InputElementFactory from "components/core/InputElementFactory/InputElementFactory"
import LayoutElementFactory from "components/core/LayoutElementFactory/LayoutElementFactory"
import React from "react"

export interface GeneratedFormState {
  collectionId: string
  createdTimestamp?: string
  disableForm: boolean
  documentId?: string
  documentState: VisibleFieldValuesMap
  getValueForSlug?: (slug: string) => FieldValueType | undefined
  updatedTimestamp?: string
  validationErrors: ValidationErrorsMap
}

/**
 * Coerce the current value into a type that is safe for the input
 * components. They will emit console errors if the input types do not
 * correspond to their expected types.
 */
const inputElementSafeValue = (v: FieldValueType | undefined) => {
  // There are two sources of null and undefined:
  //
  // null: The API returns null for any fields that don't have values.
  // undefined: In the create-document case, the Document is empty, so all
  // reads of its fields are undefined.
  //
  // "" is interpreted as an empty field value by the input components.
  if (v === null || v === undefined) {
    return ""
  }
  return v
}

/**
 * Recursively transforms the UI Schema into a tree of JSX objects that will
 * render the form.
 *
 * @param state Object containing state that must be passed to the JSX elements.
 * @param handlers Object containing handlers to be passed to the JSX elements.
 * @param node The LayoutNode.
 * @param nestedProperties The properties needed when form is nested
 * @param path The path taken through the tree. This is used to generate unique,
 * debuggable keys for elements.
 * @returns React.Component
 */
export const createJSX = (
  state: GeneratedFormState,
  handlers: Handlers,
  node: InputElement | LayoutElement,
  nestedProperties: NestedFormProperties | null,
  path: Array<string>,
  allCardsExpanded: boolean
): JSX.Element => {
  const path_as_slug = path.join("_")
  const childrenReturnValues: Array<JSX.Element> = []

  if (node.element === Element.Layout) {
    for (let i = 0; i < node.children.length; i++) {
      childrenReturnValues.push(
        createJSX(
          state,
          handlers,
          node.children[i],
          nestedProperties,
          path.concat("" + i),
          allCardsExpanded
        )
      )
    }

    return (
      <LayoutElementFactory
        key={`${node.slug}_layout_element_${path_as_slug}`}
        allCardsExpanded={allCardsExpanded}
        label={node.label}
        nestedProperties={nestedProperties}
        slug={node.slug}
        type={node.type}
      >
        {childrenReturnValues}
      </LayoutElementFactory>
    )
  }

  return (
    <InputElementFactory
      allCardsExpanded={allCardsExpanded}
      collectionId={state.collectionId}
      createdTimestamp={state.createdTimestamp}
      documentId={state.documentId}
      getValueForSlug={state.getValueForSlug}
      handlers={handlers}
      isDisabled={state.disableForm}
      key={`${node.slug}_input_element_${path_as_slug}`}
      label={node.label}
      options={node.options}
      path={`${path_as_slug}_`}
      slug={node.slug}
      type={node.type}
      updatedTimestamp={state.updatedTimestamp}
      validationError={state.validationErrors[node.slug]}
      value={inputElementSafeValue(state.documentState[node.slug])}
    />
  )
}
