import { CardBoxType } from "./CardEditor/LayoutGenerator"
import React from "react"

export const REMOVE_CARD_HEADER = "Remove Card"
export const MOVE_CARD_HEADER = "Reposition Card"
export const ARCHIVE_FIELD_HEADER = "Archive Field"
export const UNARCHIVE_FIELD_HEADER = "Unarchive Field"
export const REMOVE_ALL_FIELDS_FROM_CARD = "Remove All Fields From Card"
export const REMOVE_FIELD_HEADER = "Remove Field"
export const REMOVE_FIELD_OPTION_HEADER = "WARNING: Remove Field Option"
export const EDIT_FIELD_OPTION_HEADER = "WARNING: Edit Field Option"
export const REMOVE_SEPARATOR_FROM_CARD = "Remove Selected Separator From Card"
export const DELETE_COMMENT_CONFIRMATION = "Delete Comment?"
export const DELETE_ARCHIVES_CONFIRMATION = "Delete All Archived Fields?"
export const CONVERT_FIELD_TYPE_HEADER = "Change Field Type to Multiselect?"

export const getArchiveFieldMessage = (fieldSlug: string) => (
  <>
    Archive field <em>{fieldSlug}</em>? This field can be unarchived from the
    field editor.
  </>
)

export const getUnarchiveFieldMessage = (fieldSlug: string) => (
  <>
    Unarchive field <em>{fieldSlug}</em>?
  </>
)

export const getRemoveFieldOptionMessage = (option: string) => (
  <>
    Are you sure you want to delete the option "<strong>{option}</strong>" from
    the available options on this field? If you confirm, all values for this
    field in existing bail requests will be set to empty.
  </>
)

export const getEditExistingFieldOptionMessage = (
  option: string,
  newValue: string
) => (
  <>
    Are you sure you want to change the option "<strong>{option}</strong>" to "
    <strong>{newValue}</strong>"? If you confirm, any other unsaved changes to
    the field will not be saved and all values for this field in existing bail
    requests will be set to the new value.
  </>
)

export const getRemoveFieldFromFormMessage = (
  fieldSlug: string,
  formSlug: string
) => (
  <>
    This action will remove the field <em>{fieldSlug}</em> from the{" "}
    <em>{formSlug}</em> form layout.
  </>
)

export const getRemoveCardFromFormMessage = (
  cardTitle: string,
  formSlug: string,
  type: CardBoxType
) => (
  <>
    Are you sure you want to remove the {type === "Card" ? "custom" : "builtin"}{" "}
    card <em>{cardTitle}</em> from the <em>{formSlug}</em> form?
  </>
)

export const getMoveCardMessage = (
  cardTitle: string,
  formSlug: string,
  type: CardBoxType
) => (
  <>
    Move the {type === "Card" ? "custom" : "builtin"} card <em>{cardTitle}</em>{" "}
    to new location on the <em>{formSlug}</em> form?
  </>
)

export const getRemoveAllFieldsFromCardMessage = (
  cardTitle: string,
  formSlug: string
) => (
  <>
    Remove all fields from the card <em>{cardTitle}</em> in <em>{formSlug}</em>{" "}
    form?. You can add fields again from <em>Add Default Field to this Form</em>{" "}
    flow.
  </>
)

export const getRemoveSeparatorFromCardMessage = (
  cardTitle: string,
  formSlug: string
) => (
  <>
    Remove selected separator from the card <em>{cardTitle}</em> in{" "}
    <em>{formSlug}</em> form?. You can add separator back again from{" "}
    <em>Add Field or Separator to Form</em> flow.
  </>
)

export const getConfirmDeleteCommentMessage = () => (
  <>
    Are you sure you want to delete this comment? Once it has been deleted, it
    can not be recovered.
  </>
)

export const getConfirmDeleteArchivesMessage = () => (
  <>
    Are you sure you want to delete all archived fields? If you confirm, all
    fields and any associated data will be PERMANENTLY REMOVED.
  </>
)

export const getConvertFieldTypeMessage = () => (
  <>
    Are you sure you want to convert this field from a dropdown to a
    multiselect? If you confirm, this change can not be undone.
  </>
)
