import { Expression, UiView } from "components/common/TabularView/types"
import {
  SchemaViewExpressionInput,
  ViewDefinitionInput,
  ViewMode,
} from "generated/api"
import React from "react"
import Typography from "@material-ui/core/Typography"

export const renderBold = (value: any) => {
  return (
    <Typography variant="body2" style={{ fontWeight: 700 }}>
      {value}
    </Typography>
  )
}

// Converts the in-memory view model to a value compatible with our GQL
// ViewDefinitionInput type by removing the id and default fields and replacing
// the materialized field definitions to just the slugs.
export const convertUiViewToViewDefinitionInputType = (
  view: UiView
): ViewDefinitionInput => ({
  name: view.name,
  schema: {
    fields: view.query.fields.map((field) => field.slug),
    order: view.query.order,
    // If the view only has an order_by, no mode was set from the UI point of view,
    // but we need to set the mode to simple before sending to backend
    mode:
      !view.query.mode && view.query.expression
        ? ViewMode.Simple
        : view.query.mode,
    expression: view.query.expression
      ? convertQueryExpressionToSchemaExpressionInput(view.query.expression)
      : view.query.expression,
  },
})

const convertQueryExpressionToSchemaExpressionInput = (
  expression: Expression
): SchemaViewExpressionInput => ({
  operator: expression.operator,
  filters: expression.filters
    ? expression.filters.map((filt) => ({
        operation: filt.operation,
        operands: filt.operands || [],
        field: filt.field,
      }))
    : expression.filters,
  expressions: expression.expressions
    ? expression.expressions.map(convertQueryExpressionToSchemaExpressionInput)
    : expression.expressions,
})

// Determines the next operand to apply when the customer clicks on a column
// header.
// TODO: when we have a proper type definition for the `functions` field of a
// view, we should use the type of its operand field.
export const toggleOrderByOperand = (
  operand?: "asc" | "desc"
): "asc" | "desc" | null => {
  switch (operand) {
    case "asc":
      return "desc"
    case "desc":
      return null
    default:
      return "asc"
  }
}
