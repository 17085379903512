import {
  Element,
  InputElement,
  InputType,
  LayoutElement,
  LayoutType,
  Schema,
  SelectOption,
} from "lib/forms/types"
import { presentation_schema } from "generated/pschema"
import {
  UNSET_SELECT_EMDASH_LABEL,
  UNSET_SELECT_EMDASH_VALUE,
} from "lib/forms/constants"

// UNSET_SELECT_OPTION is selectable in all dropdowns as a value to indicate
// that the customer wishes no selection to be made.
const UNSET_SELECT_OPTION = {
  label: UNSET_SELECT_EMDASH_LABEL,
  value: UNSET_SELECT_EMDASH_VALUE,
}

// Maps the backend's notion of a "widget" to the InputType widgets supported by the UI.
const pschemaTypeToInputType: Record<string, InputType> = {
  Boolean: InputType.BooleanCheckbox,
  Choice: InputType.Select,
  ContactReference: InputType.ContactReference,
  Date: InputType.Date,
  DateTime: InputType.DateTime,
  DocumentCreatedTimestamp: InputType.DocumentCreatedTimestamp,
  DocumentUpdatedTimestamp: InputType.DocumentUpdatedTimestamp,
  Email: InputType.Email,
  Integer: InputType.Integer,
  Money: InputType.Currency,
  MultiSelect: InputType.Multiselect,
  Number: InputType.Number,
  ReadOnlyNumber: InputType.ReadOnly,
  ReadOnlyString: InputType.ReadOnly,
  String: InputType.Text,
}

/** Converts a BackendFieldDef to a list of SelectOptions. */
export const convertBackendFieldChoicesToSelectOptions = (
  opts: presentation_schema.FormField
): Array<SelectOption> => {
  const selectOptions = new Array<SelectOption>()
  if (opts.field_options && !opts.field_options.field_choices) {
    return selectOptions
  }
  if (opts.field_options.widget === "Choice") {
    selectOptions.push(UNSET_SELECT_OPTION)
  }

  selectOptions.push(
    ...(opts.field_options.field_choices || []).map((fc) => ({
      label: fc,
      value: fc,
      hidden: false,
    }))
  )
  selectOptions.push(
    ...(opts.field_options.hidden_field_choices || []).map((fc) => ({
      label: fc,
      value: fc,
      hidden: true,
    }))
  )
  return selectOptions
}

/** Converts the backend's concept of a widget to the UI's InputType. */
const convertBackendWidgetTypeToUiSchemaInputType = (
  opts: presentation_schema.FormField
): InputType => {
  const widget = opts.field_options.widget
  if (typeof widget === "undefined") {
    throw Error(`unexpected: ${opts} is missing widget.`)
  }
  const inputType = pschemaTypeToInputType[widget]
  if (inputType) {
    return inputType
  }
  throw Error(`input type is not yet supported: ${widget}`)
}

/**
 * Converts a node to a LayoutElement or InputElement.
 *
 * This does a DFS on the presentation_schema.LayoutNode and translates objects to the UI's
 * model classes.
 */
const convert = (
  fieldIdToFieldDef: Map<string, presentation_schema.FormField>,
  node: presentation_schema.LayoutNode
): LayoutElement | InputElement => {
  if (node.form_field_ref) {
    const opts = fieldIdToFieldDef.get(node.form_field_ref.field_id)!
    const inputType = convertBackendWidgetTypeToUiSchemaInputType(opts)
    const fieldChoices = convertBackendFieldChoicesToSelectOptions(opts)
    return {
      element: Element.Input,
      label: opts!.field_options.title || "",
      options: fieldChoices,
      required: opts.field_options.required || false,
      slug: opts.slug,
      type: inputType,
    }
  }
  if (node.builtin) {
    if (node.builtin.attachments) {
      return {
        element: Element.Layout,
        children: [
          {
            element: Element.Input,
            label: "",
            required: false,
            slug: "attachments",
            type: InputType.Attachments,
          },
        ],
        label: node.builtin.title || "Attachments",
        slug: "attachments-expansion-panel",
        type: LayoutType.ExpansionPanel,
      }
    }
    if (node.builtin.notes) {
      return {
        element: Element.Layout,
        children: [
          {
            element: Element.Input,
            label: "",
            required: false,
            slug: "notes",
            type: InputType.Notes,
          },
        ],
        label: node.builtin.title || "Comments",
        slug: "case-notes-expansion-panel",
        type: LayoutType.ExpansionPanel,
      }
    }
    if (node.builtin.attorney_information) {
      return {
        element: Element.Layout,
        children: [
          {
            element: Element.Input,
            label: "",
            required: false,
            slug: "attorney_information",
            type: InputType.AttorneyInformation,
          },
        ],
        label: node.builtin.title || "Attorney Directory",
        slug: "attorney-information-expansion-panel",
        type: LayoutType.ExpansionPanel,
      }
    }
    if (node.builtin.nested_collection) {
      return {
        element: Element.Layout,
        children: [
          {
            element: Element.Input,
            label: "",
            required: false,
            slug: node.builtin.nested_collection.collection_slug,
            type: InputType.NestedCollection,
          },
        ],
        // The title inside the nested_collection is legacy, all builtins have the title property as of 2/10/22
        label: node.builtin.title || node.builtin.nested_collection.title,
        slug: "nested-collection-expansion-panel",
        type: LayoutType.ExpansionPanel,
      }
    }
    throw Error(
      `Support for builtin type ${JSON.stringify(
        node.builtin
      )} is not yet implemented.`
    )
  }
  if (node.horizontal_rule) {
    const title = node.horizontal_rule.title
    return {
      element: Element.Layout,
      children: [],
      label: title ? title : undefined,
      slug: "",
      type: LayoutType.HorizontalRule,
    }
  }

  const children = node.children!.map((c) => convert(fieldIdToFieldDef, c))
  if (node.well) {
    return {
      element: Element.Layout,
      children: children,
      label: node.well.title,
      slug: "",
      type: LayoutType.ExpansionPanel,
    }
  }
  if (node.columns) {
    return {
      element: Element.Layout,
      children: children,
      label: "",
      slug: "",
      type: LayoutType.Columns,
    }
  }
  if (node.column) {
    return {
      element: Element.Layout,
      children: children,
      label: "",
      slug: "",
      type: LayoutType.Column,
    }
  }
  throw Error(`unhandled backend node type: ${node}`)
}

/** Creates a map of field_id to BackendFieldDef. */
export const makeFieldRefToFieldOptionsMap = (
  fields: Array<presentation_schema.FormField>
): Map<string, presentation_schema.FormField> => {
  const map = new Map<string, presentation_schema.FormField>()
  fields.forEach((field) => map.set(field.field_id, field))
  return map
}

/**
 * Converts the backend's "form" to the TypeScript types we use to generate
 * the JSX.
 */
export const convertBackendFormToUiSchema = (
  formSpec: presentation_schema.Form
): Schema => {
  const fieldIdToFieldDef = makeFieldRefToFieldOptionsMap(formSpec.field_defs)
  const converted = convert(fieldIdToFieldDef, formSpec.layout)
  // TODO: wire through PDF support
  return {
    form: { layout: converted },
  }
}

export const convertBackendFormToDefaultValues = (
  formSpec: presentation_schema.Form
) => {
  return Object.fromEntries(
    formSpec.field_defs
      .filter(
        (backendFieldDef) =>
          backendFieldDef.field_options.default_value &&
          backendFieldDef.field_options.default_value !== ""
      )
      .map((backendFieldDef) => [
        backendFieldDef.slug,
        backendFieldDef.field_options.default_value!,
      ])
  )
}
