import { ExpressionOperator, QueryOperator, ViewMode } from "generated/api"
import { UiView, VisibleField } from "components/common/TabularView/types"

const baseField: Omit<VisibleField, "slug"> = {}
export const ATTORNEY_DATA_VIEW: UiView = {
  id: Infinity,
  name: "Attorney List",
  default: true,
  query: {
    mode: ViewMode.Simple,
    fields: [
      {
        ...baseField,
        slug: "name",
        title: "Name",
      },
      {
        ...baseField,
        slug: "email",
        title: "Email",
      },
      {
        ...baseField,
        slug: "primary_phone",
        title: "Primary Phone",
      },
      {
        ...baseField,
        slug: "address",
        title: "Address",
      },
      {
        ...baseField,
        slug: "city",
        title: "City",
      },
      {
        ...baseField,
        slug: "state",
        title: "State",
      },
      {
        ...baseField,
        slug: "zip_code",
        title: "Zip Code",
      },
      {
        ...baseField,
        slug: "division",
        title: "Division",
      },
    ],
    order: [
      "name",
      "email",
      "primary_phone",
      "address",
      "city",
      "state",
      "zip_code",
      "division",
    ],
    expression: {
      operator: ExpressionOperator.And,
      filters: [
        {
          field: "type",
          operation: QueryOperator.IsExactly,
          operands: ["Attorney"],
        },
      ],
    },
  },
}
export const VOLUNTEER_DATA_VIEW: UiView = {
  id: Infinity,
  name: "Volunteer List",
  default: true,
  query: {
    mode: ViewMode.Simple,
    fields: [
      {
        ...baseField,
        slug: "name",
        title: "Name",
      },
    ],
    order: ["name"],
    expression: {
      operator: ExpressionOperator.And,
      filters: [
        {
          field: "type",
          operation: QueryOperator.IsExactly,
          operands: ["Volunteer"],
        },
      ],
    },
  },
}
