import { useLocation } from "react-router-dom"
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

export type Breadcrumb = {
  path?: string
  name: string
  // root determines whether the button in the upper left is a hamburger icon
  // or an arrow.
  root?: boolean
}

export type NavigatorContextValue = {
  breadcrumbs: Breadcrumb[]
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
  setDocumentTitle: (title: string) => void
  isMenuOpen: boolean
  openMenu: () => void
  closeMenu: () => void
  selectedView: PanelMode
  setSelectedView: (mode: PanelMode) => void
}

export type PanelMode = "workspaces" | "columns" | "closed"

const NavigatorContext = createContext<NavigatorContextValue | null>(null)

export const useNavigator = () => {
  const context = useContext(NavigatorContext)
  if (context === null) {
    throw Error("useNavigator must be used within a NavigatorProvider.")
  }
  return context
}

const setDocumentTitle = (title: string) => {
  if (document.title !== title) {
    document.title = title
  }
}

export const NavigatorProvider: FC = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [selectedView, setSelectedView] = useState<PanelMode>("closed")

  const handleSetBreadcrumbs = useCallback(
    (inputBreadcrumbs: Breadcrumb[]) => {
      const isEqual =
        JSON.stringify(inputBreadcrumbs) === JSON.stringify(breadcrumbs)
      if (!isEqual) {
        setBreadcrumbs(inputBreadcrumbs)
      }
    },
    [breadcrumbs]
  )

  const value: NavigatorContextValue = useMemo(
    () => ({
      breadcrumbs,
      closeMenu: () => setIsMenuOpen(false),
      isMenuOpen,
      openMenu: () => setIsMenuOpen(true),
      setBreadcrumbs: handleSetBreadcrumbs,
      setDocumentTitle,
      selectedView,
      setSelectedView,
    }),
    [breadcrumbs, handleSetBreadcrumbs, isMenuOpen, selectedView]
  )

  return (
    <NavigatorContext.Provider value={value}>
      {children}
    </NavigatorContext.Provider>
  )
}

export function useQueryParams() {
  return new URLSearchParams(useLocation().search)
}
