import {
  DocumentsConnection,
  DocumentsForTabularViewQueryVariables,
  ExpressionInput,
  FilterInput,
} from "generated/api"
import {
  Expression,
  Filter,
  Query,
  Row,
} from "components/common/TabularView/types"

export const convertFilterExpressionToFilterInput = (
  filter: Filter
): FilterInput => {
  if (filter.field == null) {
    throw Error("Bug: cannot convert placeholder query to FilterInput.")
  }
  return {
    field: filter.field,
    operation: filter.operation,
    operands: filter.operands!,
  }
}

export const convertFilterToFilterInput = (dataOp: Filter): FilterInput => ({
  operation: dataOp.operation,
  field: dataOp.field,
  operands: dataOp.operands || [],
})

export const convertQueryExpressionToExpressionInput = (
  expression: Expression
): ExpressionInput => ({
  operator: expression.operator,
  filters: expression.filters
    ? expression.filters.map(convertFilterToFilterInput)
    : expression.filters,
  expressions: expression.expressions
    ? expression.expressions.map(convertQueryExpressionToExpressionInput)
    : expression.expressions,
})

export const createQuery = (
  collectionId: string,
  pagination: { page: number; rowsPerPage: number },
  query: Query
): DocumentsForTabularViewQueryVariables => ({
  collectionId: collectionId,
  query: {
    visibleFieldSlugs: query.order,
    page: pagination.page,
    rowsPerPage: pagination.rowsPerPage,
    expression: query.expression
      ? convertQueryExpressionToExpressionInput(query.expression)
      : query.expression,
  },
})

export const convertQueryResponseToRowItems = (
  conn: DocumentsConnection
): Row[] =>
  conn.documents.map((edge) => ({
    ...edge.document.fieldsAsDict,
    __document_created: edge.document.created,
    __document_updated: edge.document.updated,
    uuid: edge.document.id,
  })) || []
