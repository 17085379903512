import Box from "@material-ui/core/Box"
import React, { FC, ReactNode } from "react"
import Typography from "@material-ui/core/Typography"

interface Props {
  children: ReactNode
  value: number
  index: number
}

const TabPanel: FC<Props> = ({ children, index, value }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </Typography>
)

export default TabPanel
