import { createContext, useContext } from "react"
import {
  UiFieldsTable,
  UiView,
  UiViewTable,
} from "components/common/TabularView/types"
import { UpdateViewType } from "./useViewApi"

export type SchemaContextValue = {
  baseView: UiView
  createViewHandler: (view: UiView) => void
  deleteViewHandler: (viewId: string) => void
  selectViewById: (viewId: string) => void
  uiFieldsTable: UiFieldsTable
  uiViewsTable: UiViewTable
  updateViewHandler: (view: UiView, updateViewType?: UpdateViewType) => void
}

export const SchemaContext = createContext<SchemaContextValue | null>(null)

export const useSchema = () => {
  const context = useContext(SchemaContext)
  if (context === null) {
    throw Error("must be wrapped in <SchemaProvider />")
  }
  return context
}
