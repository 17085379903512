import { Button } from "@material-ui/core"
import { MessageType, useSnackbar } from "providers/Snackbar"
import { useServerVersion } from "providers/ServerVersionProvider"
import React, { FC, useEffect } from "react"

const handleReload = () => window.location.reload()

const UPGRADE_BUTTON = (
  <Button style={{ color: "#F6CE67" }} onClick={handleReload}>
    UPDATE APP
  </Button>
)

const RequireLatestVersion: FC = ({ children }) => {
  const { openSnackbar, isOpen } = useSnackbar()
  const { softwareUpdateAvailable } = useServerVersion()

  useEffect(() => {
    // We only open the snackbar if one isn't open already. This will cause the
    // upgrade message to appear only when a user-initiated snackbar isn't
    // taking precedence.
    if (!isOpen && softwareUpdateAvailable) {
      openSnackbar(
        "A new version of the app is available.",
        MessageType.Error,
        UPGRADE_BUTTON
      )
    }
  }, [isOpen, openSnackbar, softwareUpdateAvailable])

  return <>{children}</>
}

export default RequireLatestVersion
