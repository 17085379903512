import { AxiosClientProvider } from "providers/AxiosClientProvider"
import { BailFundApp } from "components/core/App/BailFundApp"
import { BrowserRouter } from "react-router-dom"
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
  useTheme,
} from "@material-ui/core/styles"
import { CurrentOrganizationProvider } from "providers/CurrentOrganizationProvider"
import { CurrentUserProvider } from "providers/CurrentUserProvider"
import { getTheme } from "styles/theme"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { NavigatorProvider } from "providers/Navigator"
import { RequireAuth0Login } from "providers/RequireAuth0Login"
import { RequireOrganization } from "providers/RequireOrganization"
import { RequireUserHasOrganizations } from "providers/RequireUserHasOrganizations"
import { ServerVersionProvider } from "providers/ServerVersionProvider"
import { SnackbarProvider } from "providers/Snackbar"
import { UrqlProvider } from "providers/Urql"
import Auth0ProviderWithHistory from "providers/Auth0ProviderWithHistory"
import CssBaseline from "@material-ui/core/CssBaseline"
import MomentUtils from "@date-io/moment"
import React from "react"
import RequireLatestVersion from "providers/RequireLatestVersion"

const generateClassName = createGenerateClassName({
  productionPrefix: "bfa",
  disableGlobal: true,
})

export default () => {
  const theme = useTheme()

  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={getTheme(theme)}>
            <CssBaseline />
            <RequireAuth0Login>
              <ServerVersionProvider>
                <UrqlProvider>
                  <CurrentUserProvider>
                    <RequireUserHasOrganizations>
                      <SnackbarProvider>
                        <CurrentOrganizationProvider>
                          <RequireOrganization>
                            <AxiosClientProvider>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <NavigatorProvider>
                                  <RequireLatestVersion>
                                    <BailFundApp />
                                  </RequireLatestVersion>
                                </NavigatorProvider>
                              </MuiPickersUtilsProvider>
                            </AxiosClientProvider>
                          </RequireOrganization>
                        </CurrentOrganizationProvider>
                      </SnackbarProvider>
                    </RequireUserHasOrganizations>
                  </CurrentUserProvider>
                </UrqlProvider>
              </ServerVersionProvider>
            </RequireAuth0Login>
          </ThemeProvider>
        </StylesProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  )
}
