/*eslint-disable*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const envelope_pb = $root.envelope_pb = (() => {

    /**
     * Namespace envelope_pb.
     * @exports envelope_pb
     * @namespace
     */
    const envelope_pb = {};

    envelope_pb.Envelope = (function() {

        /**
         * Properties of an Envelope.
         * @memberof envelope_pb
         * @interface IEnvelope
         * @property {number} checksum Envelope checksum
         * @property {boolean} compressed Envelope compressed
         * @property {Uint8Array} payload Envelope payload
         */

        /**
         * Constructs a new Envelope.
         * @memberof envelope_pb
         * @classdesc Represents an Envelope.
         * @implements IEnvelope
         * @constructor
         * @param {envelope_pb.IEnvelope=} [properties] Properties to set
         */
        function Envelope(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Envelope checksum.
         * @member {number} checksum
         * @memberof envelope_pb.Envelope
         * @instance
         */
        Envelope.prototype.checksum = 0;

        /**
         * Envelope compressed.
         * @member {boolean} compressed
         * @memberof envelope_pb.Envelope
         * @instance
         */
        Envelope.prototype.compressed = false;

        /**
         * Envelope payload.
         * @member {Uint8Array} payload
         * @memberof envelope_pb.Envelope
         * @instance
         */
        Envelope.prototype.payload = $util.newBuffer([]);

        /**
         * Creates a new Envelope instance using the specified properties.
         * @function create
         * @memberof envelope_pb.Envelope
         * @static
         * @param {envelope_pb.IEnvelope=} [properties] Properties to set
         * @returns {envelope_pb.Envelope} Envelope instance
         */
        Envelope.create = function create(properties) {
            return new Envelope(properties);
        };

        /**
         * Encodes the specified Envelope message. Does not implicitly {@link envelope_pb.Envelope.verify|verify} messages.
         * @function encode
         * @memberof envelope_pb.Envelope
         * @static
         * @param {envelope_pb.IEnvelope} message Envelope message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Envelope.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.checksum);
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.compressed);
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.payload);
            return writer;
        };

        /**
         * Decodes an Envelope message from the specified reader or buffer.
         * @function decode
         * @memberof envelope_pb.Envelope
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {envelope_pb.Envelope} Envelope
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Envelope.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.envelope_pb.Envelope();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.checksum = reader.uint32();
                    break;
                case 2:
                    message.compressed = reader.bool();
                    break;
                case 3:
                    message.payload = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("checksum"))
                throw $util.ProtocolError("missing required 'checksum'", { instance: message });
            if (!message.hasOwnProperty("compressed"))
                throw $util.ProtocolError("missing required 'compressed'", { instance: message });
            if (!message.hasOwnProperty("payload"))
                throw $util.ProtocolError("missing required 'payload'", { instance: message });
            return message;
        };

        return Envelope;
    })();

    return envelope_pb;
})();

export { $root as default };
