import React, { useState } from "react"

import { contextMenuStyles } from "./styles"
import { makeStyles } from "@material-ui/core/styles"
import { useSchema } from "providers/SchemaProvider/SchemaContext"
import DeleteDialog from "../DeleteDialog/DeleteDialog"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import More from "@material-ui/icons/MoreVert"
import SaveUpdateDialog from "../SaveUpdateDialog/SaveUpdateDialog"
import Typography from "@material-ui/core/Typography"

interface Props {
  disabled?: boolean
  id: number
}

type DialogMode = "update" | "delete" | "closed"

type OnCloseEventTypeHack = { stopPropagation?: () => void }

const useStyles = makeStyles(contextMenuStyles)

const ContextMenu = ({ disabled, id }: Props) => {
  const classes = useStyles()
  const { uiViewsTable } = useSchema()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [dialog, setDialog] = useState<DialogMode>("closed")

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: OnCloseEventTypeHack) => {
    event.stopPropagation && event.stopPropagation()
    setAnchorEl(null)
  }

  const handleUpdate = (event: React.MouseEvent<HTMLElement>) => {
    setDialog("update")
    handleClose(event)
  }

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    setDialog("delete")
    handleClose(event)
  }

  const handleDialogClose = () => {
    setDialog("closed")
  }

  return (
    <div>
      <IconButton
        size="small"
        aria-controls="simple-menu"
        aria-haspopup="true"
        disabled={disabled}
        onClick={handleClick}
      >
        <More />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleUpdate}>
          <Typography variant="body2">Rename</Typography>
        </MenuItem>
        <MenuItem className={classes.deleteMenu} onClick={handleDelete}>
          <Typography variant="body2">Delete</Typography>
        </MenuItem>
      </Menu>
      <DeleteDialog
        open={dialog === "delete"}
        onClose={handleDialogClose}
        selectedWorkspace={uiViewsTable[id]}
      />
      <SaveUpdateDialog
        open={dialog === "update"}
        onClose={handleDialogClose}
        selectedWorkspace={uiViewsTable[id]}
      />
    </div>
  )
}

export default ContextMenu
