import { Breadcrumb } from "providers/Navigator"
import { useHistory } from "react-router-dom"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import React, { FC } from "react"

interface AppBarActionProps {
  breadcrumbs: Breadcrumb[]
  openMenu: () => void
  isMenuOpen: boolean
  getCrumb: (
    breadcrumbs: Breadcrumb[],
    idxFromEnd: number,
    selector?: (x: any) => any
  ) => Breadcrumb | undefined
}

const AppBarAction: FC<AppBarActionProps> = ({
  breadcrumbs,
  openMenu,
  isMenuOpen,
  getCrumb,
}) => {
  const history = useHistory()
  const currentCrumb = getCrumb(breadcrumbs, -1)
  let actionComponent
  if (!currentCrumb || currentCrumb.root) {
    actionComponent = (
      <IconButton
        color="inherit"
        onClick={openMenu}
        aria-expanded={isMenuOpen}
        aria-label="menu"
      >
        <MenuIcon />
      </IconButton>
    )
  } else {
    actionComponent = (
      <IconButton
        aria-label="back"
        color="inherit"
        onClick={() => {
          history.goBack()
        }}
      >
        <ArrowBackIcon />
      </IconButton>
    )
  }
  return <>{actionComponent}</>
}

export default AppBarAction
