import { makeStyles } from "@material-ui/core/styles"
import { REFERRAL_FORM_BASE_URL } from "lib/settings"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import React from "react"
import Typography from "@material-ui/core/Typography"

import { privacyPolicyAgreementStyles } from "styles/theme"

const useStyles = makeStyles(privacyPolicyAgreementStyles)

interface Props {
  signLegalDocs: () => void
}

const PrivacyPolicyAgreement = ({ signLegalDocs }: Props) => {
  const classes = useStyles()

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid
        className={classes.legalContainer}
        item
        container
        justify="center"
        alignItems="center"
      >
        <Box py={10} px={5}>
          <Grid item>
            <Box mt={3} mb={2}>
              <Typography className={classes.legalTitle} variant="h6">
                Privacy and Terms
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Typography className={classes.legalBody} variant="body1">
              By clicking "I Agree", you agree to all of the conditions set
              forth in the Terms of Use and Privacy Policy linked below.
            </Typography>
          </Grid>
          <Grid item container direction="column" alignItems="center">
            <Box my={3}>
              <Grid item>
                <a
                  className={classes.legalLink}
                  href={`${REFERRAL_FORM_BASE_URL}/privacy-policy`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </Grid>
              <Grid item>
                <a
                  className={classes.legalLink}
                  href={`${REFERRAL_FORM_BASE_URL}/terms-of-use`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              className={clsx(classes.legalBody, classes.legalButton)}
              variant="contained"
              color="primary"
              onClick={() => signLegalDocs()}
            >
              I Agree
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PrivacyPolicyAgreement
