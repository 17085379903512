import { SOURCE_VERSION } from "lib/settings"
import React, { createContext, FC, useContext, useState } from "react"

export const HTTP_RESPONSE_VERSION_HEADER = "x-source-version"

type ObservedVersion = string | null

export type ServerVersionValue = {
  observedVersion: ObservedVersion
  setObservedVersion: (version: string) => void
  softwareUpdateAvailable: boolean
}

export const ServerVersionContext = createContext<ServerVersionValue | null>(
  null
)

export const useServerVersion = () => {
  const context = useContext(ServerVersionContext)
  if (context === null) {
    throw Error(
      "useServerVersion can only be used within a ServerVersionProvider."
    )
  }
  return context
}

/**
 * ServerVersionProvider stores the latest observed version of the software.
 *
 * Child components can use the values exposed on the context to update the
 * latest version observed (e.g. during API interactions) or to display a
 * "new version available" message to the customer.
 */
export const ServerVersionProvider: FC = ({ children }) => {
  const [version, setVersion] = useState<ObservedVersion>(null)

  const value: ServerVersionValue = React.useMemo(
    () => ({
      observedVersion: version,
      setObservedVersion: setVersion,
      softwareUpdateAvailable: version !== null && version !== SOURCE_VERSION,
    }),
    [version, setVersion]
  )
  return (
    <ServerVersionContext.Provider value={value}>
      {children}
    </ServerVersionContext.Provider>
  )
}
