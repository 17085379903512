import { BackdropProps } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import CloseIcon from "@material-ui/icons/Close"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import React, { FC, useCallback, useState } from "react"
import Typography from "@material-ui/core/Typography"
import useStyles from "./styles"

interface Props {
  confirmButtonLabel: string
  onClose: () => void
  onConfirm: () => void
  headerMessage: string
  descriptionMessage: JSX.Element
  dialogId: string
  useWarningStyle?: boolean
  backdropProps?: Partial<BackdropProps>
  disableConfirm?: boolean
}

const ActionDialog: FC<Props> = ({
  confirmButtonLabel,
  onClose,
  onConfirm,
  headerMessage,
  descriptionMessage,
  dialogId,
  useWarningStyle,
  backdropProps,
  disableConfirm,
}) => {
  const classes = useStyles()
  const [disableButton, setDisableButton] = useState(false)

  const handleConfirm = useCallback(async () => {
    setDisableButton(true)
    onConfirm()
  }, [onConfirm])

  return (
    <>
      {
        <Dialog
          open={true}
          onClose={onClose}
          aria-labelledby={dialogId}
          maxWidth="sm"
          fullWidth
          BackdropProps={backdropProps}
        >
          <DialogTitle id={dialogId} disableTypography>
            <div className={classes.dialogTitle}>
              <Typography variant="h5">{headerMessage}</Typography>
              <IconButton size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText color="textPrimary">
              {descriptionMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disableElevation
              className={classes.cancelButton}
              color="default"
              onClick={onClose}
              variant="contained"
              disabled={disableButton}
            >
              Cancel
            </Button>
            <Button
              disableElevation
              className={`${classes.confirmButton} ${
                useWarningStyle ? classes.warningButton : ""
              }`}
              color="primary"
              onClick={handleConfirm}
              variant="contained"
              disabled={disableButton || disableConfirm}
            >
              {confirmButtonLabel}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
}

export default ActionDialog
