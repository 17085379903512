import { detailPageStyles } from "styles/theme"
import { DuplicateDocument, useGetDuplicateDocumentsQuery } from "generated/api"
import { Link } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import DuplicateChip from "components/common/chips/DuplicateChip"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import React, { FC, useMemo } from "react"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => detailPageStyles(theme))

interface Props {
  collectionId: string
  documentId: string
  urlParser: (documentId: string) => string
  duplicateFieldsChecked: Array<string>
}

const DuplicateRequests: FC<Props> = (props) => {
  const { collectionId, documentId, duplicateFieldsChecked, urlParser } = props
  const classes = useStyles()
  const [duplicatesQuery] = useGetDuplicateDocumentsQuery({
    variables: { collectionId, documentId },
  })

  const duplicates = useMemo(() => {
    const data = duplicatesQuery.data?.duplicateQueryResults?.documents || []
    const sortedDuplicates = data.sort((a, b) =>
      a.createdAt.localeCompare(b.createdAt)
    )
    return sortedDuplicates
  }, [duplicatesQuery.data])

  const isDuplicate = !!duplicates?.length
  const showDuplicates = isDuplicate && duplicateFieldsChecked

  if (!showDuplicates) {
    return null
  }

  return (
    <>
      <DuplicateChip duplicateFieldsChecked={duplicateFieldsChecked} />
      <Typography className={classes.duplicateLinks}>
        <strong>{"Similar Request(s): "}</strong>
        {duplicates.map((duplicate: DuplicateDocument) => {
          const duplicateDate = new Date(duplicate.createdAt).toLocaleString()
          return (
            <span key={duplicate.id}>
              <Link
                target="_blank"
                rel="noreferrer noopener"
                href={urlParser(duplicate.id)}
                underline="always"
                className={classes.duplicateLink}
              >
                {`Submitted ${duplicateDate}`}
                <OpenInNewIcon fontSize="small" />
              </Link>
            </span>
          )
        })}
      </Typography>
    </>
  )
}

export default React.memo(DuplicateRequests)
