import { environmentChipStyles } from "styles/theme"
import {
  GITHUB_REPO,
  HEROKU_PR_NUMBER,
  SOURCE_VERSION,
  UNKNOWN_SOURCE_VERSION,
} from "lib/settings"
import { useEnvironment } from "hooks/useEnvironment"
import { UserFeatureFlag } from "generated/api"
import { useUserFeatureFlag } from "providers/CurrentUserProvider"
import { withStyles } from "@material-ui/core/styles"
import MuiChip from "@material-ui/core/Chip"
import React from "react"

const EnvChip = withStyles((theme) => environmentChipStyles(theme))(MuiChip)

export default function EnvironmentChip() {
  const { appName, isProduction } = useEnvironment()
  const collectionEditorEnabled = useUserFeatureFlag(
    UserFeatureFlag.CollectionEditor
  )
  const hasPR = HEROKU_PR_NUMBER
  const hasSourceVer = SOURCE_VERSION !== UNKNOWN_SOURCE_VERSION
  const url = `https://github.com/${GITHUB_REPO}`
  const showChip = collectionEditorEnabled || !isProduction

  const handleClick = () => {
    if (hasPR) {
      window.open(`${url}/pull/${HEROKU_PR_NUMBER}`)
    }
    if (hasSourceVer) {
      window.open(`${url}/commit/${SOURCE_VERSION}`)
    }
  }

  return showChip ? (
    <EnvChip
      size="small"
      label={appName}
      onClick={handleClick}
      clickable={!!hasPR || hasSourceVer}
    />
  ) : null
}
