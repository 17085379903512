import { styled } from "@material-ui/core/styles"
import { UI_COLORS } from "styles/theme"
import Box from "@material-ui/core/Box"
import CloseIcon from "@material-ui/icons/Close"
import React from "react"
import Typography from "@material-ui/core/Typography"

interface Props {
  copy: string
  isEnabled: boolean
  toggleBanner: React.Dispatch<React.SetStateAction<boolean>>
}

const Banner = styled(Box)({
  display: "flex",
  padding: "8px 0 8px 25px",
  color: UI_COLORS.white,
  background: UI_COLORS.blue_900,
})

const Icon = styled(CloseIcon)({
  width: "24px",
  height: "24px",
  marginRight: "8px",
  cursor: "pointer",
})

const Text = styled(Typography)({
  width: "100%",
})

const GlobalBanner = ({ copy, isEnabled, toggleBanner }: Props) => {
  return isEnabled ? (
    <Banner>
      <Text>{copy}</Text>
      <Icon onClick={() => toggleBanner(false)} />
    </Banner>
  ) : null
}

export default GlobalBanner
