import { ReactComponent as AdvancedFiltersSvg } from "./AdvancedFilters.svg"
import { filterChipWrapperStyles } from "./styles"
import { IconButton, makeStyles, SvgIcon, Tooltip } from "@material-ui/core"
import { useSchema } from "providers/SchemaProvider/SchemaContext"
import { useWorkspaces } from "providers/WorkspacesProvider"
import { ViewMode } from "generated/api"
import ActionDialog from "components/core/ActionDialog/ActionDialog"
import ClearIcon from "@material-ui/icons/Clear"
import FilterChip, { EmptyFilter } from "../FilterChip/FilterChip"
import FilterChipLabelOnly from "../FilterChip/FilterChipLabelOnly"
import FilterIcon from "@material-ui/icons/FilterList"
import React, { FC, useEffect, useState } from "react"

const useStyles = makeStyles(filterChipWrapperStyles)

interface Props {
  openAdvancedFilters: () => void
}

const FilterChipWrapper: FC<Props> = ({ openAdvancedFilters }) => {
  const classes = useStyles()
  const { baseView } = useSchema()
  const {
    updateViewFilters,
    removeAllFilters,
    fieldsWithActiveFilters,
    visibleFilters,
    currentView,
  } = useWorkspaces()
  const [fieldOfOpenChip, setFieldOfOpenChip] = useState<string | null>(null)
  const [newFilter, setNewFilter] = useState<EmptyFilter | undefined>(undefined)
  const [confirmRemoveAllFilters, setConfirmRemoveAllFilters] = useState(false)

  useEffect(() => {
    setNewFilter(undefined)
  }, [baseView.id])

  const handleFilterChipClick = (field: string) => {
    setFieldOfOpenChip((prev) => (prev === field ? null : field))
  }

  const simpleFiltersApplied = currentView.query.mode === ViewMode.Simple
  const advFiltersApplied = currentView.query.mode === ViewMode.Advanced

  const simpleFiltersButton = (
    <IconButton
      aria-label="simple-filters"
      className={classes.filterButton}
      disabled={!!newFilter || advFiltersApplied}
      onClick={() => {
        setNewFilter({ field: null, operation: null, operands: null })
        setFieldOfOpenChip("")
      }}
    >
      <FilterIcon />
    </IconButton>
  )
  const advFiltersButton = (
    <IconButton
      aria-label="advanced-filter"
      className={classes.filterButton}
      disabled={simpleFiltersApplied}
      onClick={() => openAdvancedFilters()}
    >
      <SvgIcon component={AdvancedFiltersSvg} />
    </IconButton>
  )

  const clearSimpleFilters = (
    <div className={classes.clearSimpleFiltersWrapper}>
      <Tooltip title="Remove All Filters" placement="top">
        <IconButton
          aria-label="delete-simple-filters"
          className={classes.clearSimpleFilters}
          onClick={() => setConfirmRemoveAllFilters(true)}
        >
          <ClearIcon className={classes.clearSimpleFiltersIcon} />
        </IconButton>
      </Tooltip>
    </div>
  )

  const removeAllFiltersDialog = (
    title: string,
    description: string,
    onConfirm: () => void
  ) => (
    <ActionDialog
      confirmButtonLabel="Remove"
      descriptionMessage={<>{description}</>}
      dialogId="remove-all-filters-dialog"
      headerMessage={title}
      onClose={() => setConfirmRemoveAllFilters(false)}
      onConfirm={() => {
        setConfirmRemoveAllFilters(false)
        onConfirm()
      }}
      useWarningStyle
    />
  )

  return (
    <div className={classes.root}>
      <div className={classes.filterButtonGroup}>
        {advFiltersApplied ? (
          <Tooltip
            title="Advanced filter must be removed prior to applying simple filters."
            placement="top-start"
          >
            <span>{simpleFiltersButton}</span>
          </Tooltip>
        ) : (
          simpleFiltersButton
        )}
        {simpleFiltersApplied ? (
          <Tooltip
            title="Simple filters must be removed prior to applying an advanced filter."
            placement="top-start"
          >
            <span>{advFiltersButton}</span>
          </Tooltip>
        ) : (
          advFiltersButton
        )}
      </div>
      {simpleFiltersApplied &&
        ((visibleFilters.length > 0 && newFilter) ||
          visibleFilters.length > 1) &&
        clearSimpleFilters}
      <div className={classes.filterChipWrapper}>
        {advFiltersApplied && (
          <FilterChipLabelOnly
            label="Advanced Filters Applied"
            onDelete={() => setConfirmRemoveAllFilters(true)}
          />
        )}
        {simpleFiltersApplied &&
          visibleFilters.map((oneFilter, index) => (
            <FilterChip
              key={`${oneFilter.field}:${index}`}
              onClick={() => handleFilterChipClick(oneFilter.field)}
              onDelete={() =>
                updateViewFilters(
                  {
                    field: oneFilter.field,
                    operation: oneFilter.operation,
                    operands: null,
                  },
                  oneFilter.field
                )
              }
              filter={oneFilter}
              active={fieldOfOpenChip === oneFilter.field}
              fieldsWithActiveFilters={fieldsWithActiveFilters}
              onCompleteEmptyChip={() => setNewFilter(undefined)}
              onUpdateFieldOfOpenChip={(field: string) =>
                setFieldOfOpenChip(field)
              }
              updateViewFilters={updateViewFilters}
            />
          ))}
        {newFilter && (
          <FilterChip
            key={`empty_filter`}
            onClick={() => handleFilterChipClick("")}
            onDelete={() => setNewFilter(undefined)}
            filter={newFilter}
            active={fieldOfOpenChip === ""}
            fieldsWithActiveFilters={fieldsWithActiveFilters}
            onCompleteEmptyChip={() => setNewFilter(undefined)}
            onUpdateFieldOfOpenChip={(field: string) =>
              setFieldOfOpenChip(field)
            }
            updateViewFilters={updateViewFilters}
          />
        )}
        {confirmRemoveAllFilters &&
          simpleFiltersApplied &&
          removeAllFiltersDialog(
            "Remove All Filters",
            "This change cannot be reversed. Are you sure you want to clear all filters you’ve added?",
            () => {
              removeAllFilters()
              setNewFilter(undefined)
            }
          )}
        {confirmRemoveAllFilters &&
          advFiltersApplied &&
          removeAllFiltersDialog(
            "Remove the Advanced Filter",
            "This action cannot be reversed. Are you sure you want to completely remove the current advanced filter? It cannot be recovered after you remove it.",
            () => removeAllFilters()
          )}
      </div>
    </div>
  )
}

export default FilterChipWrapper
