import { AxiosInstance, AxiosResponse } from "axios"
import { Expression, Query } from "components/common/TabularView/types"
import { ExpressionOperator } from "generated/api"
import { useAxiosClient } from "providers/AxiosClientProvider"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import fileDownload from "js-file-download"
import React, { useEffect, useMemo, useReducer } from "react"

const FAKE_PROGRESS_CAP = 50
const FAKE_PROGRESS_INCREMENT_PERCENT = 1
const FAKE_PROGRESS_INTERVAL_MS = 250

// This defines the order of promises of API Calls for fetching results of each report.
// This is used as order of processing the returned single Promise array of results when all the API calls have completed.
const ORDER_OF_FETCHED_REPORTS = ["courthouse", "referrals", "surety"]

const onDownloadProgress = function (e: any) {
  // Determine the total # of bytes we expect to transfer.
  // Chrome will set total=0 in the presence of a non-identity
  // Content-Encoding. We allow the x-uncompressed-length response header
  // to override e.total if it is present.
  let total = e.total
  if (total === 0) {
    let uncompressedLength = e.target.getResponseHeader("x-uncompressed-length")
    if (uncompressedLength !== null) {
      uncompressedLength = parseInt(uncompressedLength, 10)
      if (!isNaN(uncompressedLength)) {
        total = uncompressedLength
      }
    }
  }
}

const convertExpressionToRequestData = (expression: Expression): any => ({
  operator: expression.operator === ExpressionOperator.Or ? "or" : "and",
  filters: expression.filters,
  expressions: expression.expressions
    ? expression.expressions.map(convertExpressionToRequestData)
    : expression.expressions,
})

const fetchCSV = async (
  dispatch: React.Dispatch<Action>,
  axios: AxiosInstance,
  headers: any,
  query: Query | undefined,
  fieldOrder: string[] | undefined,
  downloadType:
    | "fetchAllColumns"
    | "fetchSelectedColumns"
    | "fetchCustomReports"
) => {
  dispatch({ type: "started" })

  const onError = (error: any) => {
    if (
      error.response &&
      error.response.status === 400 &&
      error.response.data.detail
    ) {
      dispatch({ type: "error", message: error.response.data.detail })
    } else {
      dispatch({ type: "error", message: error.message })
    }
  }

  const customReportRequest = (customReportType: string) => {
    return axios({
      method: "get",
      url: `/custom_report_csv?slug=${customReportType}`,
      headers,
      onDownloadProgress: onDownloadProgress,
    })
  }

  if (
    downloadType === "fetchAllColumns" ||
    downloadType === "fetchSelectedColumns"
  ) {
    if (fieldOrder && !query) {
      throw Error(`query is mandatory but it was not provided`)
    }
    return axios({
      method: "post",
      url: "/csv/",
      data: {
        data: {
          expression: query?.expression
            ? convertExpressionToRequestData(query.expression)
            : query?.expression,
          fieldOrder: fieldOrder || [],
          downloadType: downloadType,
        },
      },
      headers,
      onDownloadProgress: onDownloadProgress,
    })
      .then((response: AxiosResponse) => {
        fileDownload(response.data, "export.csv")
        dispatch({ type: "success" })
      })
      .catch((error: any) => {
        onError(error)
      })
  } else if (downloadType === "fetchCustomReports") {
    // Promise.all() method takes an iterable array of promises as an input, and returns a single Promise that resolves to an array of the results of the input promises.
    return Promise.all(
      ORDER_OF_FETCHED_REPORTS.map((reportName) =>
        customReportRequest(reportName)
      )
    )
      .then((results) => {
        results.forEach((result, indexofType) => {
          fileDownload(
            result.data,
            `${ORDER_OF_FETCHED_REPORTS[indexofType]}.csv`
          )
        })
        dispatch({ type: "success" })
      })
      .catch((error: any) => {
        onError(error)
      })
  }
}

export type State =
  // The user has not asked for an export.
  | { state: "idle" }
  // The user has asked for an export and there is an API request outstanding.
  | { state: "waiting-for-progress"; progress: number }
  // The fake progress bar has hit FAKE_PROGRESS_CAP.
  | { state: "fake-progress-cap"; progress: number }
  // We have received a progress event, indicating some bytes were transferred.
  | { state: "progressing"; progress: number }
  // There was an error.
  | { state: "error"; message: string }
  // The fetch has completed. Clients should transition to IDLE state once
  // state is consumed.
  | { state: "success"; progress: 100 }

type Action =
  // To be invoked when the user has had a chance to acknowledge the successful
  // download.
  | { type: "reset" }
  // To be invoked when we expect to start a backend request.
  | { type: "started" }
  // To be invoked when we want to represent fake progress.
  | { type: "fake-progress" }
  // To be invoked when we have measurable progress (i.e. we know the total # of
  // bytes to expect). Payload should be % complete (e.g. .5 for 50%).
  | { type: "real-progress"; progress: number }
  // To be invoked when we have finished writing the file locally.
  | { type: "success" }
  // To be invoked when there is an error during download.
  | { type: "error"; message: string }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "started":
      return { state: "waiting-for-progress", progress: 0 }
    case "real-progress": {
      let progress = 0
      if (
        state.state === "waiting-for-progress" ||
        state.state === "progressing" ||
        state.state === "fake-progress-cap"
      ) {
        progress = state.progress
      }
      const remaining = 100 - progress
      const newProgress = progress + Math.round(action.progress * remaining)
      return {
        state: "progressing",
        progress: newProgress,
      }
    }
    case "fake-progress":
      if (state.state === "waiting-for-progress") {
        const progress = Math.min(
          FAKE_PROGRESS_CAP,
          state.progress + FAKE_PROGRESS_INCREMENT_PERCENT
        )
        if (progress > FAKE_PROGRESS_CAP) {
          return { state: "fake-progress-cap", progress: state.progress }
        }
        return {
          state: "waiting-for-progress",
          progress: progress,
        }
      }

      // this is added to solve a race condition bug, where the download completes,
      // the effect from useCsvHook hasn't yet unmounted, and the interval for
      // reupping fake progress activates, trying to update the fake-progress while
      // in a success state and throwing an error
      if (state.state === "success") {
        return { ...state }
      }
      throw Error(`action ${action.type} unexpected in state ${state.state}`)
    case "error":
      return { state: "error", message: action.message }
    case "success": {
      if (
        state.state === "progressing" ||
        state.state === "waiting-for-progress" ||
        state.state === "fake-progress-cap"
      ) {
        return { state: "success", progress: 100 }
      }
      throw Error(`action ${action.type} unexpected in state ${state.state}`)
    }
    case "reset":
      return { state: "idle" }
  }
}

interface CsvState {
  /**
   * The current state of the CSV download.
   */
  state: State
  /**
   * Initiates the CSV download.
   *
   * @param query The query to run.
   * @param orderedFields The order of the columns as they should appear in the
   * CSV. If undefined, all columns will be downloaded.
   */
  start: (
    query: Query | undefined,
    orderedFields: string[] | undefined,
    downloadType:
      | "fetchAllColumns"
      | "fetchSelectedColumns"
      | "fetchCustomReports"
  ) => Promise<void>
  /**
   * Reset the CSV state machine. This should be called after the `error` or
   * `success` states are observed by the user.
   */
  reset: () => void
}

export const useCsvHook = (): CsvState => {
  const [state, dispatch] = useReducer(reducer, { state: "idle" })
  const { collection } = useCurrentCollection()
  const headers = useMemo(() => ({ "x-collection": collection.id }), [
    collection,
  ])

  const axiosClient = useAxiosClient()

  // Generate "fake progress" when we are in a waiting-for-progress state.
  useEffect(() => {
    if (state.state !== "waiting-for-progress") {
      return
    }
    const intervalId = setInterval(
      () => dispatch({ type: "fake-progress" }),
      FAKE_PROGRESS_INTERVAL_MS
    )
    return () => clearInterval(intervalId)
  }, [dispatch, state])

  const start = React.useCallback(
    (
      query: Query | undefined,
      fieldOrder: string[] | undefined,
      downloadType
    ): Promise<void> =>
      fetchCSV(dispatch, axiosClient, headers, query, fieldOrder, downloadType),
    [axiosClient, headers]
  )

  return React.useMemo(
    () => ({
      state,
      start,
      reset: () => dispatch({ type: "reset" }),
    }),
    [state, start, dispatch]
  )
}
