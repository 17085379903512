import { advancedFiltersStyles, AdvancedFiltersStylesProps } from "./styles"
import { Button, Grid, makeStyles } from "@material-ui/core"
import { Expression, Filter } from "components/common/TabularView/types"
import { NewFilter } from "./AdvancedFilters"
import AddFiltersButtonGroup from "./AddFilterButtonGroup"
import FilterChip from "components/core/Workspaces/FilterChip/FilterChip"
import isEqual from "lodash/isEqual"
import React, { FC } from "react"

const useStyles = makeStyles(advancedFiltersStyles)

const BranchLine: FC<AdvancedFiltersStylesProps> = (props) => {
  const classes = useStyles(props)
  return (
    <div className={classes.lineContainer}>
      <div className={classes.verticalLine} />
      <div className={classes.horizontalLine} />
    </div>
  )
}

interface Props {
  groupPosition?: number[]
  expression: Expression
  fieldsWithActiveFilters: Set<string>
  toggleOperator: (groupPosition?: number[]) => void
  // Props for filter chips
  newFilter?: NewFilter
  fieldOfOpenChip: string | null
  addFilter: (groupPosition?: number[]) => void
  deleteNewFilter: () => void
  updateFieldOfOpenChip: (field: string) => void
  onCompleteEmptyChip: () => void
  onUpdateFieldOfOpenChip: (field: string) => void
  updateFilter: (
    filter: Filter,
    previousField?: string | null,
    groupPosition?: number[]
  ) => void
  deleteFilter: (filter: Filter, groupPosition?: number[]) => void
  // Props for filter groups
  addFilterGroup: (groupPosition?: number[]) => void
}

const FilterGroup: FC<Props> = ({
  groupPosition = [],
  expression,
  fieldsWithActiveFilters,
  toggleOperator,
  // Props for filter chips
  newFilter,
  fieldOfOpenChip,
  addFilter,
  deleteNewFilter,
  updateFieldOfOpenChip,
  onCompleteEmptyChip,
  onUpdateFieldOfOpenChip,
  updateFilter,
  deleteFilter,
  // Props for filter groups
  addFilterGroup,
}) => {
  const level = groupPosition.length
  const classes = useStyles({ level })

  const operatorValue =
    expression.operator.charAt(0).toLocaleUpperCase() +
    expression.operator.slice(1).toLocaleLowerCase()

  const updateViewFilters = (filter: Filter, previousField?: string | null) => {
    updateFilter(filter, previousField, groupPosition)
  }

  const newFilterInGroup =
    newFilter && isEqual(newFilter.groupPosition, groupPosition)

  return (
    <Grid className={classes.filterGroup} container direction="column">
      <Grid container item wrap="nowrap">
        <Button
          aria-label={`operator_${operatorValue}`}
          classes={{
            root: classes.operatorButton,
          }}
          onClick={() => toggleOperator(groupPosition)}
          variant="outlined"
        >
          {operatorValue}
        </Button>
        <AddFiltersButtonGroup
          level={level}
          disabled={!!newFilter}
          onAddFilterClick={() => addFilter(groupPosition)}
          onAddFilterGroupClick={() => addFilterGroup(groupPosition)}
        />
      </Grid>
      {expression.filters?.map((filt, index) => (
        <Grid container item key={`${filt.field}_${index}`} wrap="nowrap">
          <BranchLine
            lastElementInGroup={
              expression.filters?.length === index + 1 &&
              !expression.expressions?.length &&
              !newFilterInGroup
            }
            level={level}
          />
          <FilterChip
            onClick={() => updateFieldOfOpenChip(filt.field)}
            onDelete={() => deleteFilter(filt, groupPosition)}
            filter={filt}
            active={fieldOfOpenChip === filt.field}
            fieldsWithActiveFilters={fieldsWithActiveFilters}
            onCompleteEmptyChip={onCompleteEmptyChip}
            onUpdateFieldOfOpenChip={onUpdateFieldOfOpenChip}
            updateViewFilters={updateViewFilters}
          />
        </Grid>
      ))}
      {newFilter && newFilterInGroup && (
        <Grid container item wrap="nowrap">
          <BranchLine
            lastElementInGroup={!expression.expressions?.length}
            level={level}
          />
          <FilterChip
            onClick={() => updateFieldOfOpenChip("")}
            onDelete={() => deleteNewFilter()}
            filter={newFilter.filter}
            active={fieldOfOpenChip === ""}
            fieldsWithActiveFilters={fieldsWithActiveFilters}
            onCompleteEmptyChip={onCompleteEmptyChip}
            onUpdateFieldOfOpenChip={onUpdateFieldOfOpenChip}
            updateViewFilters={updateViewFilters}
          />
        </Grid>
      )}
      {expression.expressions?.map((expr, index) => (
        <Grid container item key={`expr_${index}`} wrap="nowrap">
          <BranchLine
            lastElementInGroup={expression.expressions?.length === index + 1}
            level={level}
          />
          <div className={classes.filterGroupContainer}>
            <FilterGroup
              groupPosition={[...groupPosition, index]}
              expression={expr}
              fieldsWithActiveFilters={fieldsWithActiveFilters}
              toggleOperator={toggleOperator}
              // Props for filter chips
              newFilter={newFilter}
              fieldOfOpenChip={fieldOfOpenChip}
              addFilter={addFilter}
              deleteNewFilter={deleteNewFilter}
              updateFieldOfOpenChip={updateFieldOfOpenChip}
              onCompleteEmptyChip={onCompleteEmptyChip}
              onUpdateFieldOfOpenChip={onUpdateFieldOfOpenChip}
              updateFilter={updateFilter}
              deleteFilter={deleteFilter}
              // Props for filter groups
              addFilterGroup={addFilterGroup}
            />
          </div>
        </Grid>
      ))}
    </Grid>
  )
}

export default FilterGroup
