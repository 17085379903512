import {
  AutocompleteInput,
  DateInput,
  EnumsInput,
  NumberInput,
  SelectInput,
} from "./FilterMenuInputs"
import {
  canAddEnumOperand,
  canAddStringOperand,
  convertStringToDate,
  DATE_FORMAT_FOR_BE,
  DATE_FORMAT_FOR_FE,
  isBetweenDatesOperation,
  isBetweenDollarOrNumberOperation,
  isOperandsEmpty,
  isResetDisabled,
  isSingleDateOperation,
  isSingleDollarOrNumberOperation,
} from "./helpers"
import { EmptyFilter } from "components/core/Workspaces/FilterChip/FilterChip"
import { FieldType, QueryOperator } from "generated/api"
import { Filter } from "components/common/TabularView/types"
import { FilterMenuStyles } from "./styles"
import { FormHelperText, InputBase } from "@material-ui/core"
import { getOperationOptions } from "components/core/Workspaces/FilterChip/FilterMenu/OperationOptions"
import { makeStyles } from "@material-ui/core/styles"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { useSchema } from "providers/SchemaProvider/SchemaContext"
import Button from "@material-ui/core/Button"
import difference from "lodash/difference"
import moment from "moment"
import React, { createRef, RefObject, useEffect } from "react"
import sortBy from "lodash/sortBy"

const useStyles = makeStyles(FilterMenuStyles)

// VISIBLE_OPERATIONS defines the set of operations we allow in the Workspace
// UI
const VISIBLE_OPERATIONS = new Set([
  QueryOperator.After,
  QueryOperator.Before,
  QueryOperator.Between,
  QueryOperator.Contains,
  QueryOperator.DoesNotContain,
  QueryOperator.GreaterThan,
  QueryOperator.IsEmpty,
  QueryOperator.IsEqualTo,
  QueryOperator.IsExactly,
  QueryOperator.IsFalse,
  QueryOperator.IsNotEmpty,
  QueryOperator.IsNotExactly,
  QueryOperator.IsTrue,
  QueryOperator.LessThan,
])

interface Props {
  onCloseFieldsMenu: () => void
  onCompleteEmptyChip: () => void
  onUpdateFieldOfOpenChip: (field: string) => void
  openFieldsMenu: boolean
  validOrEmptyFilter: Filter | EmptyFilter
  fieldsWithActiveFilters: Set<string>
  updateViewFilters: (data: Filter, previousField?: string) => void
}

const FilterMenu = ({
  onCloseFieldsMenu,
  onCompleteEmptyChip,
  onUpdateFieldOfOpenChip,
  openFieldsMenu,
  validOrEmptyFilter,
  fieldsWithActiveFilters,
  updateViewFilters,
}: Props) => {
  const classes = useStyles()
  const { uiFieldsTable } = useSchema()

  const inputRef: RefObject<HTMLInputElement> = createRef()

  useEffect(() => {
    if (openFieldsMenu) {
      inputRef.current?.focus()
    }
  })

  const fieldType =
    validOrEmptyFilter.field &&
    uiFieldsTable.get(validOrEmptyFilter.field)?.fieldType

  const availableEnums =
    validOrEmptyFilter.field &&
    uiFieldsTable.get(validOrEmptyFilter.field)?.enums

  const hiddenEnums =
    validOrEmptyFilter.field &&
    uiFieldsTable.get(validOrEmptyFilter.field)?.hiddenEnums

  // NOTE: Could be helpful to add this to the API.
  // See: https://github.com/emergentworks/bailfundapp/pull/219
  const visibleEnums = difference(availableEnums, hiddenEnums ?? [])

  const availableOperators =
    validOrEmptyFilter.field &&
    uiFieldsTable.get(validOrEmptyFilter.field)?.availableOperators

  const sortedFieldsList = sortBy(
    Array.from(uiFieldsTable.values()),
    "title"
  ).filter((field) => field.id > 0)

  const handleChangeField = (
    value: string,
    validOrEmptyFilter: Filter | EmptyFilter
  ) => {
    let updatedFilter: Filter
    // Two cases: The current filter is null/null/null, which means that the
    // customer just clicked "add new filter". In this case, we are transitioning
    // from an incomplete filter to a fully populated one.
    // if field changes but the type is the same as the previous filter then persist the operation & operands from the filter
    const field = uiFieldsTable.get(value)

    if (!field) {
      throw Error(`Cannot select field, slug "${value}" does not exist`)
    }

    if (
      fieldType === field.fieldType &&
      (fieldType === FieldType.List || fieldType === FieldType.Enum)
    ) {
      updatedFilter = {
        field: value,
        operation: validOrEmptyFilter.operation!,
        operands: [],
      }
    } else if (fieldType === field.fieldType && validOrEmptyFilter.operands) {
      updatedFilter = {
        field: value,
        operation: validOrEmptyFilter.operation,
        operands: validOrEmptyFilter.operands,
      }
    } else {
      updatedFilter = {
        field: value,
        operation: field.availableOperators![0],
        operands: [],
      }
    }
    if (!validOrEmptyFilter.field) {
      onCompleteEmptyChip()
      onUpdateFieldOfOpenChip(value)
    } else {
      onUpdateFieldOfOpenChip(value)
    }
    updateViewFilters(
      updatedFilter,
      validOrEmptyFilter.field ? validOrEmptyFilter.field : undefined
    )
  }

  const handleChangeOperation = (value: QueryOperator, validFilter: Filter) => {
    //the field stays the same, and the operation changes to the value they selected from the field.
    let updatedFilter: Filter
    switch (true) {
      case value === QueryOperator.IsEmpty ||
        value === QueryOperator.IsNotEmpty: {
        // reset operands to empty if new operation doesn't require operands
        updatedFilter = {
          field: validFilter.field,
          operation: value,
          operands: [],
        }
        break
      }
      case validFilter.operation === "between": {
        // if changing from a (date or number) field that accepts two inputs, only persist the first input
        updatedFilter = {
          field: validFilter.field,
          operation: value,
          operands: validFilter?.operands?.slice(0, -1) || [],
        }
        break
      }
      default: {
        // otherwise, persist the operands
        updatedFilter = {
          field: validFilter.field,
          operation: value,
          operands: validFilter.operands,
        }
      }
    }
    updateViewFilters(updatedFilter, validFilter.field)
  }
  const handleChangeOperand = (
    value: string,
    checked: boolean,
    validFilter: Filter
  ) => {
    let updatedFilter: Filter

    // if setting checked to true, add the value to the operands
    if (checked) {
      updatedFilter = {
        field: validFilter.field,
        operation: validFilter.operation,
        operands: [...(validFilter.operands as string[]), value],
      }
    } else {
      //if setting checked to false, remove the value from the operands
      updatedFilter = {
        field: validFilter.field,
        operation: validFilter.operation,
        operands: validFilter.operands!.filter((operand) => operand !== value),
      }
    }

    updateViewFilters(updatedFilter, validFilter.field)
  }

  const handleChangeStringInput = (value: string[], validFilter: Filter) => {
    const updatedFilter: Filter = {
      field: validFilter.field,
      operation: validFilter.operation,
      operands: value,
    }
    updateViewFilters(updatedFilter, validFilter.field)
  }

  const handleChangeDate = (
    date: MaterialUiPickersDate,
    validFilter: Filter
  ) => {
    const prevOperands = validFilter.operands!
    const emptyDate = !date
    const invalidDate = !date?.isValid()

    if (emptyDate) {
      updateViewFilters(
        {
          field: validFilter.field,
          operation: validFilter.operation,
          operands: [],
        },
        validFilter.field
      )
      return
    }

    if (invalidDate) {
      handleChangeStringInput(prevOperands, validFilter)
      return
    }

    const converted = moment(date, DATE_FORMAT_FOR_FE, true).format(
      DATE_FORMAT_FOR_BE
    )
    handleChangeStringInput([converted], validFilter)
  }

  const betweenValidNumbers =
    validOrEmptyFilter.operands &&
    parseInt(validOrEmptyFilter.operands[0]) <
      parseInt(validOrEmptyFilter.operands[1])

  const handleBetweenChange = (
    value: MaterialUiPickersDate | string,
    cycleBoundary: "Start" | "End",
    validFilter: Filter
  ) => {
    const newValue =
      typeof value === "string"
        ? value
        : moment(value, DATE_FORMAT_FOR_FE, true).format(DATE_FORMAT_FOR_BE)

    const prevOperands = validFilter.operands!
    const isFirstValue = cycleBoundary === "Start"
    const emptyValue = !value

    const newOperands = () => {
      if (isFirstValue) {
        return emptyValue
          ? []
          : prevOperands?.length <= 1
          ? [newValue]
          : [newValue, prevOperands[1]]
      } else {
        return emptyValue ? [prevOperands[0]] : [prevOperands[0], newValue]
      }
    }

    handleChangeStringInput(newOperands(), validFilter)
  }

  const resetFilter = (validFilter: Filter) => {
    const field = uiFieldsTable.get(validFilter.field)
    if (!field) {
      return
    }

    updateViewFilters(
      {
        field: validFilter.field,
        operation: field.availableOperators![0],
        operands: [],
      },
      validFilter.field
    )
  }

  const emptyOperands = isOperandsEmpty(validOrEmptyFilter.operands)

  return (
    <div className={classes.filterContainer}>
      <AutocompleteInput
        disableClearable // fieldSelection can't be empty
        filter={validOrEmptyFilter}
        openFieldsMenu={openFieldsMenu}
        onChange={(event, option) => {
          onCloseFieldsMenu()
          handleChangeField(option?.slug as string, validOrEmptyFilter)
        }}
        options={sortedFieldsList.filter(
          (field) =>
            !(
              fieldsWithActiveFilters.has(field.slug) &&
              field.slug !== validOrEmptyFilter.field
            )
        )}
        value={sortedFieldsList.find(
          (field) => field.slug === validOrEmptyFilter.field
        )}
      />
      {validOrEmptyFilter.field && (
        <SelectInput
          filter={validOrEmptyFilter}
          onChange={(value) => {
            if (validOrEmptyFilter.field) {
              handleChangeOperation(value as QueryOperator, validOrEmptyFilter)
            }
          }}
        >
          {availableOperators &&
            availableOperators
              .filter((operation) => VISIBLE_OPERATIONS.has(operation))
              .map((operation) => {
                const selectedOption =
                  operation === validOrEmptyFilter.operation
                    ? classes.selectedOption
                    : ""
                return getOperationOptions(
                  operation,
                  `${classes.selectOptions} ${selectedOption}`
                )
              })}
        </SelectInput>
      )}
      {fieldType &&
        validOrEmptyFilter.operation &&
        isSingleDateOperation(
          fieldType,
          validOrEmptyFilter,
          validOrEmptyFilter.operation
        ) && (
          <DateInput
            onChange={(date) => {
              if (validOrEmptyFilter.field) {
                handleChangeDate(date, validOrEmptyFilter)
              }
            }}
            value={
              !emptyOperands
                ? convertStringToDate(
                    validOrEmptyFilter?.operands
                      ? validOrEmptyFilter.operands[0]
                      : ""
                  )
                : null
            }
          />
        )}
      {fieldType &&
        validOrEmptyFilter.operation &&
        isBetweenDatesOperation(
          fieldType,
          validOrEmptyFilter,
          validOrEmptyFilter.operation
        ) && (
          <>
            <DateInput
              placeholder={"Start Date"}
              value={
                !emptyOperands
                  ? convertStringToDate(
                      validOrEmptyFilter.operands
                        ? validOrEmptyFilter.operands[0]
                        : ""
                    )
                  : null
              }
              onChange={(date) => {
                if (validOrEmptyFilter.field) {
                  handleBetweenChange(date, "Start", validOrEmptyFilter)
                }
              }}
              maxDate={
                validOrEmptyFilter.operands
                  ? validOrEmptyFilter.operands[1]
                  : ""
              }
              maxDateMessage={"date should be before End date"}
            />
            <DateInput
              placeholder={"End Date"}
              disabled={emptyOperands}
              minDate={
                validOrEmptyFilter.operands
                  ? validOrEmptyFilter.operands[0]
                  : ""
              }
              minDateMessage={"date should be later than Start date"}
              value={
                validOrEmptyFilter.operands?.length === 2
                  ? convertStringToDate(
                      validOrEmptyFilter.operands
                        ? validOrEmptyFilter.operands[1]
                        : ""
                    )
                  : null
              }
              onChange={(date) => {
                if (validOrEmptyFilter.field) {
                  handleBetweenChange(date, "End", validOrEmptyFilter)
                }
              }}
            />
          </>
        )}
      {fieldType &&
        visibleEnums &&
        validOrEmptyFilter.field &&
        canAddEnumOperand(fieldType, visibleEnums, validOrEmptyFilter) && (
          <EnumsInput
            availableEnums={visibleEnums}
            filter={validOrEmptyFilter}
            onChangeOperand={(value, checked) => {
              if (validOrEmptyFilter.field) {
                handleChangeOperand(value, checked, validOrEmptyFilter)
              }
            }}
          />
        )}
      {fieldType &&
        validOrEmptyFilter.field &&
        validOrEmptyFilter.operands &&
        isSingleDollarOrNumberOperation(fieldType, validOrEmptyFilter) && (
          <NumberInput
            isDollar={fieldType === "DOLLAR"}
            onChange={(value) => {
              const newValue = value === "" ? [] : [value]
              handleChangeStringInput(newValue, validOrEmptyFilter)
            }}
            validOrEmptyFilter={validOrEmptyFilter}
            value={emptyOperands ? "" : validOrEmptyFilter.operands[0]}
          />
        )}

      {fieldType &&
        validOrEmptyFilter.field &&
        validOrEmptyFilter.operands &&
        isBetweenDollarOrNumberOperation(fieldType, validOrEmptyFilter) && (
          <>
            <NumberInput
              isDollar={fieldType === "DOLLAR"}
              onChange={(value) => {
                handleBetweenChange(value, "Start", validOrEmptyFilter)
              }}
              placeholder={"Min"}
              validOrEmptyFilter={validOrEmptyFilter}
              value={emptyOperands ? "" : validOrEmptyFilter.operands[0]}
            />
            <NumberInput
              isDollar={fieldType === "DOLLAR"}
              onChange={(value) => {
                handleBetweenChange(value, "End", validOrEmptyFilter)
              }}
              placeholder={"Max"}
              validOrEmptyFilter={validOrEmptyFilter}
              value={
                validOrEmptyFilter.operands.length > 1
                  ? validOrEmptyFilter.operands[1]
                  : ""
              }
              disabled={
                validOrEmptyFilter.operands && !validOrEmptyFilter.operands[0]
              }
            />

            <FormHelperText classes={{ root: classes.helperText }}>
              {!betweenValidNumbers &&
                !isNaN(parseInt(validOrEmptyFilter.operands[1])) &&
                "Must be larger than above amount"}
            </FormHelperText>
          </>
        )}

      {fieldType &&
        validOrEmptyFilter.field &&
        canAddStringOperand(fieldType, validOrEmptyFilter) && (
          <InputBase
            classes={{
              root: classes.textInput,
            }}
            onChange={(event) => {
              if (validOrEmptyFilter.field) {
                handleChangeStringInput(
                  [event.target.value],
                  validOrEmptyFilter
                )
              }
            }}
            value={validOrEmptyFilter.operands}
          />
        )}

      <Button
        className={classes.resetFilterButton}
        variant="text"
        disableElevation
        disabled={isResetDisabled(uiFieldsTable, validOrEmptyFilter)}
        onClick={() => {
          if (validOrEmptyFilter.field) {
            resetFilter(validOrEmptyFilter)
          }
        }}
      >
        Reset Filter
      </Button>
    </div>
  )
}

export default FilterMenu
