import { Collections } from "lib/Collections"
import { ErrorMalformedOrganization } from "components/common/errors/ErrorMalformedOrganization"
import { ErrorOrganizationNotFound } from "components/common/errors/ErrorOrganizationNotFound"
import { ErrorPageNotFound } from "components/common/errors/ErrorPageNotFound"
import { ErrorUnableToFetchOrganization } from "components/common/errors/ErrorUnableToFetchOrganization"
import { Flag } from "lib/Flags"
import { Initializing } from "components/common/Initializing/Initializing"
import { MessageType, useSnackbar } from "providers/Snackbar"
import {
  Organization,
  OrganizationLite,
  useOrganizationQuery,
} from "generated/api"
import { organizationUrl } from "lib/navigationHelpers"
import { useCurrentUser } from "providers/CurrentUserProvider"
import { useHistory, useLocation } from "react-router-dom"
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

const CurrentOrganizationContext = createContext<CurrentOrganizationContextValue | null>(
  null
)

type CurrentOrganizationContextValue = {
  loaded: boolean
  desiredOrganizationId: string
  organization: Organization
  selectBailFundById: (value: string) => void
  isRefreshing: boolean
}

/** Provides information about the current organization. */
export const useCurrentOrganization = () => {
  const context = useContext(CurrentOrganizationContext)
  if (context === null) {
    throw Error(
      "useCurrentOrganization cannot be used outside of a CurrentOrganizationProvider."
    )
  }
  return context
}

/** Returns feature flag information, if present. */
export const useFeatureFlag = (flag: Flag) => {
  const {
    organization: { features },
  } = useCurrentOrganization()

  const found = features?.find((f) => f.flag === flag)
  if (found && found.enabled) {
    return found
  }
  return undefined
}

/** Provides the collection of contacts. */
export const useContactsCollection = () => {
  return useCollection(Collections.CONTACTS)
}

/** Provides the collection of court appearances. */
export const useCourtAppearancesCollection = () => {
  return useCollection(Collections.COURTAPPEARANCES)
}

/** Provides the collection of notes. */
export const useCommentsCollection = () => {
  return useCollection(Collections.COMMENTS)
}

export const useCollectionOrNull = (slug: string) => {
  const { organization } = useCurrentOrganization()
  return findCollection(organization, slug)
}

/**
 * CurrentOrganizationProvider exposes information about the current
 * organization.
 */
export const CurrentOrganizationProvider: FC = ({ children }) => {
  const { organizations } = useCurrentUser()
  const history = useHistory()
  const location = useLocation()
  const { openSnackbar } = useSnackbar()

  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>(
    ""
  )

  const { status, organizationId } = resolveOrganization(
    organizations,
    location.pathname
  )

  const [organizationResponse] = useOrganizationQuery({
    variables: {
      organizationId: organizationId,
    },
    requestPolicy: "cache-and-network",
  })

  const handleSelectBailFundId = useCallback(
    (newOrganizationId) => {
      const organization = organizations.find(
        (org) => org.organizationId === newOrganizationId
      )
      if (!organization) {
        throw Error(
          `User chose ${newOrganizationId} but it is not in their organizations list.`
        )
      }
      setSelectedOrganizationId(organization.organizationId)
      const path = organizationUrl(organization)
      history.push(path)
    },
    [setSelectedOrganizationId, history, organizations]
  )

  const value = useMemo((): CurrentOrganizationContextValue | null => {
    const data = organizationResponse.data
    const error = organizationResponse.error

    if (error) {
      openSnackbar(error.message, MessageType.Error)
    }
    if (!data) {
      return null
    }
    const organization = data.organization
    const organizationId = selectedOrganizationId || organization.organizationId
    return {
      loaded: !!data,
      desiredOrganizationId: organizationId,
      organization: organization,
      selectBailFundById: handleSelectBailFundId,
      isRefreshing: organizationResponse.fetching || organizationResponse.stale,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organizationResponse.data,
    organizationResponse.error,
    organizationResponse.fetching,
    organizationResponse.stale,
    selectedOrganizationId,
    handleSelectBailFundId,
  ])
  switch (status) {
    case "malformed":
      return <ErrorMalformedOrganization />
    case "not-found":
      return <ErrorPageNotFound />
    case "no-access":
      return <ErrorOrganizationNotFound />
    case "ok":
      if (value && value.loaded) {
        return (
          <CurrentOrganizationContext.Provider value={value}>
            <>{children}</>
          </CurrentOrganizationContext.Provider>
        )
      } else if (organizationResponse.error) {
        console.log("GraphQL error: ", organizationResponse.error)
        return <ErrorUnableToFetchOrganization />
      } else {
        return <Initializing />
      }
  }
}

const useCollection = (slug: string) => {
  const { organization } = useCurrentOrganization()
  return findCollectionOrError(organization, slug)
}

const findCollection = (organization: Organization, slug: string) => {
  return organization.collections.find((coll) => coll.slug === slug)
}

export const findCollectionOrError = (
  organization: Organization,
  slug: string
) => {
  const collection = findCollection(organization, slug)
  if (collection === undefined) {
    throw Error(`The "${slug}" collection does not exist in this organization.`)
  }
  return collection
}

type ResolveStatus = "ok" | "malformed" | "no-access" | "not-found"

interface Resolved {
  // status indicates how to handle this specific request.
  status: ResolveStatus
  // organizationId indicates the organization that applies to this request. In
  // cases where status != ok, this organization is to be used for generating
  // error messages or redirect links.
  organizationId: string
}

/**
 * Chooses an Organization based on the current location path and the user's
 * list of organizations.
 */
const resolveOrganization = (
  organizations: OrganizationLite[],
  pathname: string
): Resolved => {
  // Always default to the user's first organization. This is determined by the
  // backend.
  const fallback = organizations[0].organizationId

  // If we start with /o/, the user must be associated with that org or we fail.
  if (isFlexPathPrefix(pathname)) {
    const orgId = pathname.split("/", 3)[2]
    if (!orgId) {
      return { status: "malformed", organizationId: fallback }
    }
    const org = organizations.find((org) => org.organizationId === orgId)
    if (!org) {
      return { status: "no-access", organizationId: fallback }
    }
    return { status: "ok", organizationId: orgId }
  }

  // If the request is not /o/, or a legacy path, choose the first organization
  // in the list.
  return { status: "ok", organizationId: fallback }
}

// Returns true iff the request is for Flex features.
function isFlexPathPrefix(pathname: string) {
  return pathname.startsWith("/o/")
}
