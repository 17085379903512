import {
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
  Provider,
} from "urql"
import { fromPromise, fromValue, map, mergeMap, pipe } from "wonka"

import { devtoolsExchange } from "@urql/devtools"
import { GRAPHQL_API_BASE_URL } from "lib/settings"
import {
  HTTP_RESPONSE_VERSION_HEADER,
  useServerVersion,
} from "providers/ServerVersionProvider"
import { useAuth0 } from "@auth0/auth0-react"
import React, { useMemo } from "react"

const isPromise = (value) => value && typeof value.then === "function"

/**
 * fetchOptionsExchange is a recipe from the Urql documentation that allows the exchanges to be
 * asynchronous. We need it to be asynchronous so that the JWT can be acquired at the time of request, and not the
 * time of urql client initialization.
 */
export const fetchOptionsExchange = (fn) => ({ forward }) => (ops$) => {
  return pipe(
    ops$,
    mergeMap((operation) => {
      const result = fn(operation.context.fetchOptions)
      return pipe(
        isPromise(result) ? fromPromise(result) : fromValue(result),
        map((fetchOptions) => ({
          ...operation,
          context: { ...operation.context, fetchOptions },
        }))
      )
    }),
    forward
  )
}

/**
 * makeVersionAwareFetch returns a wrapper around window.fetch that detects
 * the server software version and passes is to setObservedVersion.
 */
const makeVersionAwareFetch = (setObservedVersion) => (resource, init) =>
  fetch(resource, init).then((response) => {
    const serverVersion = response.headers.get(HTTP_RESPONSE_VERSION_HEADER)
    if (serverVersion) {
      setObservedVersion(serverVersion)
    }
    return response
  })

/**
 * UrqlProvider configures URQL with the user's current JWT.
 *
 * Children use Urql's native hooks to make GraphQL calls (useQuery,
 * useMutation).
 */
export const UrqlProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()
  const { setObservedVersion } = useServerVersion()

  const client = useMemo(
    () =>
      createClient({
        url: GRAPHQL_API_BASE_URL,
        fetch: makeVersionAwareFetch(setObservedVersion),
        exchanges: [
          devtoolsExchange,
          dedupExchange,
          cacheExchange,
          fetchOptionsExchange(async (fetchOptions) => {
            return Promise.resolve({
              ...fetchOptions,
              headers: {
                Authorization: "Bearer " + (await getAccessTokenSilently()),
              },
            })
          }),
          fetchExchange,
        ],
      }),
    [getAccessTokenSilently, setObservedVersion]
  )

  return <Provider value={client}>{children}</Provider>
}
