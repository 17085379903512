import { EmptyFilter } from "components/core/Workspaces/FilterChip/FilterChip"
import { FieldDefinition, QueryOperator } from "generated/api"
import { Filter } from "components/common/TabularView/types"
import moment from "moment"

export const convertStringToDate = (dateFromBE: string) => moment(dateFromBE)

export const DATE_FORMAT_FOR_FE = "MM/DD/YYYY"

export const DATE_FORMAT_FOR_BE = "YYYY-MM-DD"

export const isOperationNotRequiringOperand = (filter: Filter) =>
  filter.operation === "is_empty" || filter.operation === "is_not_empty"

export const canAddDateOperand = (fieldType: string, filter: Filter) =>
  ["DATE", "DATETIME"].includes(fieldType) &&
  !isOperationNotRequiringOperand(filter)

export const isBetweenDatesOperation = (
  fieldType: string,
  filter: Filter,
  operation: QueryOperator
) => canAddDateOperand(fieldType, filter) && operation === "between"

export const isSingleDateOperation = (
  fieldType: string,
  filter: Filter,
  operation: QueryOperator
) => canAddDateOperand(fieldType, filter) && operation !== "between"

export const canAddEnumOperand = (
  fieldType: string,
  enumOptions: string[],
  filter: Filter
) =>
  (fieldType === "ENUM" || fieldType === "LIST") &&
  enumOptions.length !== 0 &&
  !isOperationNotRequiringOperand(filter)

export const canAddStringOperand = (fieldType: string, filter: Filter) =>
  ![
    "ENUM",
    "LIST",
    "DATE",
    "DATETIME",
    "DOLLAR",
    "BOOLEAN",
    "NUMBER",
    "READONLYNUMBER",
  ].includes(fieldType) && !isOperationNotRequiringOperand(filter)

export const canAddDollarOrNumberOperand = (
  fieldType: string,
  filter: Filter
) =>
  ["NUMBER", "DOLLAR", "READONLYNUMBER"].includes(fieldType) &&
  !isOperationNotRequiringOperand(filter)

export const isSingleDollarOrNumberOperation = (
  fieldType: string,
  filter: Filter
) =>
  canAddDollarOrNumberOperand(fieldType, filter) &&
  filter.operation !== "between"

export const isBetweenDollarOrNumberOperation = (
  fieldType: string,
  filter: Filter
) =>
  canAddDollarOrNumberOperand(fieldType, filter) &&
  filter.operation === "between"

export const getDefaultFieldOperations = (field: FieldDefinition) => {
  if (field.availableOperators?.length) {
    return field.availableOperators[0]
  }
  throw Error(
    `Unexpected: field "${field.slug}" is missing availableOperators.`
  )
}

export const isOperandsEmpty = (operands: string[] | null) => {
  if (operands === null) {
    return true
  }
  if (operands.length === 0) {
    return true
  }
  return operands[0].length === 0
}

export const isResetDisabled = (
  fieldsTable: Map<string, FieldDefinition>,
  filter: Filter | EmptyFilter
): boolean => {
  if (!filter.field) {
    return true
  }
  const field = fieldsTable.get(filter.field!)

  if (!field) {
    return true
  }
  return (
    getDefaultFieldOperations(field) === filter.operation &&
    !isOperationNotRequiringOperand(filter) &&
    isOperandsEmpty(filter.operands)
  )
}
