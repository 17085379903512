import { genericErrorStyles } from "styles/theme"
import { makeStyles } from "@material-ui/core/styles"
import { useAuth0 } from "@auth0/auth0-react"
import Link from "@material-ui/core/Link"
import React, { FC } from "react"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(genericErrorStyles)

interface Props {
  message: string
  submessage?: string
}

const logoutUrl = () => {
  return `${window.location.protocol}//${window.location.host}`
}

export const ErrorGeneric: FC<Props> = ({ message, submessage }) => {
  const classes = useStyles()
  const { logout } = useAuth0()

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.heading} variant="h1">
        {message}
      </Typography>
      <Typography variant="body1">
        {submessage ? (
          <>
            {submessage}
            <br />
          </>
        ) : null}
      </Typography>
      <div className={classes.links}>
        <Link
          target="_self"
          variant="body1"
          underline="none"
          href="/"
          className={classes.link}
        >
          Return Home
        </Link>
        <br />
        <Link
          target="_self"
          variant="body1"
          underline="none"
          onClick={() => logout({ logoutParams: { returnTo: logoutUrl() } })}
          className={classes.link}
        >
          Log Out
        </Link>
      </div>
    </div>
  )
}
