import { Filter, Query, Row, UiFieldsTable } from "./types"
import { formatNumber } from "lib/display/formatters"
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core"
import { handleClickNavigation } from "lib/eventHandlers"
import { makeStyles } from "@material-ui/core/styles"
import { tabularViewStyles } from "styles/theme"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import { useHistory } from "react-router-dom"
import HeaderRow from "./components/HeaderRow/HeaderRow"
import React, { MouseEvent, useCallback } from "react"
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions"
import TableRows from "./components/TableRows/TableRows"

const ROWS_PER_PAGE_OPTIONS = [10, 25, 100]

type PaginatedTableProps = {
  changePage: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void
  changeRowsPerPage: (rowsPerPage: string) => void
  onNavigate: () => void
  enablePagination?: boolean
  enableSorting?: boolean
  offsetTableHeader?: boolean
  page: number
  query: Query
  rowToUrl?: (row: Row) => string
  rows: Row[] | null // null when in loading state
  rowsCount: number
  rowsPerPage: number
  sortColumn: (filterOp: Filter) => void
  uiFieldsTable: UiFieldsTable
}

const useStyles = makeStyles(tabularViewStyles)

export const PaginatedTable = ({
  changePage,
  changeRowsPerPage,
  onNavigate,
  enablePagination,
  enableSorting,
  offsetTableHeader,
  page,
  query,
  rowToUrl,
  rows,
  rowsCount,
  rowsPerPage,
  sortColumn,
  uiFieldsTable,
}: PaginatedTableProps) => {
  const { getUrlForDocument } = useCurrentCollection()
  const history = useHistory()
  const classes = useStyles()
  const loading = rows === null

  const onRowClick = useCallback(
    (click: React.MouseEvent<HTMLElement>, row: Row, url: string) => {
      if (!row) {
        return
      }
      const selectedText = window.getSelection()?.toString()
      if (!selectedText) {
        onNavigate()
        handleClickNavigation(click, history, url)
      }
    },
    [history, onNavigate]
  )

  return (
    <>
      <div
        className={
          !loading ? classes.tableWrapper : classes.tableWrapperLoading
        }
      >
        <Table aria-label="sticky table" stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <HeaderRow
                enableSorting={enableSorting}
                loading={loading}
                offsetTableHeader={offsetTableHeader}
                query={query}
                sortColumn={sortColumn}
                uiFieldsTable={uiFieldsTable}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRows
              uiFieldsTable={uiFieldsTable}
              handleRowClick={(clickEvent, row) =>
                onRowClick(
                  clickEvent,
                  row,
                  rowToUrl ? rowToUrl(row) : getUrlForDocument(row.uuid)
                )
              }
              query={query}
              rows={rows}
            />
          </TableBody>
        </Table>
        {rowsCount === 0 && !loading && (
          <Grid
            alignItems="center"
            className={classes.noRowsGrid}
            container
            justify="space-around"
            wrap="nowrap"
          >
            <Grid item>
              <Typography variant="body1">Sorry, no matching rows.</Typography>
            </Grid>
          </Grid>
        )}
      </div>
      {!loading && enablePagination && (
        <TablePagination
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={rowsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={({ from, to, count }) => {
            const fromFmt = formatNumber(from)
            const toFmt = formatNumber(to)
            const countFmt = formatNumber(count)
            return `${fromFmt}-${to === -1 ? countFmt : toFmt} of ${countFmt}`
          }}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          onChangePage={changePage}
          onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
          className={classes.tablePagination}
        />
      )}
    </>
  )
}
