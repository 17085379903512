import { createStyles, makeStyles } from "@material-ui/core/styles"
import { UI_COLORS } from "styles/theme"

export const actionDialogStyles = {
  confirmButton: {
    marginBottom: "15px",
    marginRight: "15px",
  },
  cancelButton: {
    marginBottom: "15px",
    marginRight: "5px",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  warningButton: {
    "backgroundColor": UI_COLORS.red_900,
    "&:hover": {
      backgroundColor: "#ba4942",
    },
  },
}
const ActionDialogStyles = () => createStyles(actionDialogStyles)

export default makeStyles(ActionDialogStyles)
