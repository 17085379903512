import { createStyles, Theme } from "@material-ui/core/styles"
import { UI_COLORS } from "styles/theme"

export const filterChipStyles = (theme: Theme) =>
  createStyles({
    filterChipWrapper: {
      position: "relative",
    },
    root: {
      "border": `1px solid ${UI_COLORS.black_50}`,
      "borderRadius": "4px",
      "padding": "10px 10px",
      "height": "40px",
      "marginRight": "8px",
      "marginTop": 0,
      "&:hover": {
        fontWeight: "bolder",
        background: UI_COLORS.black_50,
      },
      "&:focus": {
        backgroundColor: `${UI_COLORS.black_50} !important`,
      },
      "background": UI_COLORS.black_50,
      "maxWidth": "250px",
    },
    activeChip: {
      border: `1px solid ${UI_COLORS.black_500}`,
      background: UI_COLORS.black_50,
    },
    filterChipList: {
      listStyle: "none",
      position: "relative",
    },
    icon: {
      order: 3,
      color: UI_COLORS.black_900,
    },
    deleteIcon: {
      "order": 1,
      "background": UI_COLORS.black_400,
      "borderRadius": "50px",
      "color": UI_COLORS.black_50,
      "padding": "2px",
      "width": "14px",
      "height": "14px",
      "margin": "2px",

      "&:hover": {
        background: UI_COLORS.black_600,
        color: UI_COLORS.black_50,
      },
    },
    label: {
      "order": 2,
      "fontWeight": 500,
      "color": UI_COLORS.black_900,
      "fontSize": theme.typography.body2.fontSize,
      "padding": "0px",
      "paddingLeft": "7px",
      "& span": {
        "&:nth-of-type(2)": {
          color: UI_COLORS.blue_900,
        },
      },
      "maxWidth": "195px",
    },
    fieldTitleLabel: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      minWidth: 0,
      maxWidth: "120px",
    },
    fieldVisibleOperands: {
      "maxWidth": "74px",
      "minWidth": 0,
      "display": "flex",
      "& span:only-child": {
        //for when there are no additional operands, eg is_empty
        minWidth: 0,
        maxWidth: "74px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    singleOperand: {
      maxWidth: "74px",
      minWidth: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    enumList: {
      display: "flex",
    },
    firstOperand: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      minWidth: 0,
    },
    hiddenOperands: {
      flexShrink: 0,
    },
    chipTextWrapper: {
      display: "flex",
    },
  })
