import { createMuiTheme } from "@material-ui/core"
import { KeyboardDateTimePicker } from "@material-ui/pickers"
import { makeStyles } from "@material-ui/core/styles"
import { OnChangeDateTimeType, ValidateDateTimeType } from "lib/forms/types"
import { ThemeProvider } from "@material-ui/styles"
import React, { FC } from "react"

import { requestFieldStyles } from "styles/theme"

const FORM_VARIANT = "filled"

const useStyles = makeStyles((theme) => requestFieldStyles(theme))

const materialTheme = createMuiTheme({
  palette: {
    primary: { main: "#21704F" },
  },
})

interface Props {
  error: boolean
  helperText: string
  isDisabled: boolean
  label: string
  onChange: OnChangeDateTimeType
  slug: string
  validationHandler: ValidateDateTimeType
  value?: string | null
}

const DateTimeInput: FC<Props> = ({
  error,
  helperText,
  isDisabled,
  label,
  onChange,
  slug,
  validationHandler,
  value,
}) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={materialTheme}>
      <KeyboardDateTimePicker
        autoOk
        className={classes.formControlFull}
        disabled={isDisabled}
        error={error}
        format="MM/DD/YYYY hh:mm a"
        helperText={helperText}
        InputLabelProps={{
          classes: {
            root: classes.label,
            shrink: classes.shrink,
          },
        }}
        InputProps={{
          classes: {
            input: classes.input,
            root: classes.filledInput,
            underline: classes.underline,
          },
        }}
        inputVariant={FORM_VARIANT}
        KeyboardButtonProps={{
          "aria-label": `Change ${label}`,
        }}
        label={label}
        margin="normal"
        name={slug}
        onChange={(unused, value) => {
          onChange(slug, value)
          validationHandler(label, slug, value)
        }}
        value={value ? value : null}
      />
    </ThemeProvider>
  )
}
export default DateTimeInput
