import { CURRENCY_VALUE_REGEXP, INTEGER_VALUE_REGEXP } from "lib/regexes"
import { FieldValueType } from "lib/apihelpers"
import { ValidationErrorsMap } from "lib/forms/types"
import moment from "moment"

const ALLOWED_DATE_FORMATS = ["MM/DD/YYYY", "YYYY-MM-DD"]
const EARLIEST_ALLOWED_YEAR = moment([1900])

/**
 * Toggles a value's presence in the string[] used to represent the value of a
 * multi-select list.
 */
export const toggleMultiselectValue = (
  prevState: FieldValueType,
  option: string
) => {
  if (!Array.isArray(prevState)) {
    return [option]
  }
  if (prevState.includes(option)) {
    return prevState.filter((x) => x !== option)
  }
  const sorted = [...prevState, option].sort((a, b) => a.localeCompare(b))
  return Array.from(new Set<string>(sorted))
}

/** Generates a callback for updating the ValidationErrorsMap. */
export const makeValidator = (
  invalidPredicate: () => boolean,
  slug: string,
  label: string,
  helperText: string
) => (prevState: ValidationErrorsMap): ValidationErrorsMap => {
  if (invalidPredicate()) {
    return {
      ...prevState,
      [slug]: { helperText, label },
    }
  }
  const { [slug]: unused, ...rest } = prevState
  return rest
}

export const makeCurrencyValidator = (
  label: string,
  slug: string,
  value: string
) =>
  makeValidator(
    () => !!value && !CURRENCY_VALUE_REGEXP.test(value),
    slug,
    label,
    "Only dollar amounts with at most 2 decimals are allowed."
  )

export const makeIntegerValidator = (
  label: string,
  slug: string,
  value: string
) =>
  makeValidator(
    () => !!value && !INTEGER_VALUE_REGEXP.test(value),
    slug,
    label,
    "Only digits are allowed."
  )

export const makeDateValidator = (
  label: string,
  slug: string,
  value: string | null | undefined
) =>
  makeValidator(
    () => {
      // We need to parse by both the input element's natural type
      // (in our case, MM/DD/YYYY) *and* the ideal format (YYYY-MM-DD)
      // because we don't know whether React has applied the
      // setModifiedDocState() state changes from onChangeDateHandler yet.
      const parsed = moment(value, ALLOWED_DATE_FORMATS, true)
      return !!(
        value &&
        (value.includes("_") ||
          !parsed.isValid() ||
          (parsed.isValid() &&
            !parsed.isSameOrAfter(EARLIEST_ALLOWED_YEAR, "year")))
      )
    },
    slug,
    label,
    "Invalid date."
  )

export const makeDateTimeValidator = (
  label: string,
  slug: string,
  value: string | null | undefined
) =>
  makeValidator(
    () =>
      !!(
        value &&
        (value.includes("_") ||
          !moment(value, "MM/DD/YYYY hh:mm a", true).isValid() ||
          !moment(value, "MM/DD/YYYY hh:mm a", true).isSameOrAfter(
            "01/01/1900",
            "year"
          ))
      ),
    slug,
    label,
    "Invalid date or time."
  )
