import React, { createContext, FC, useContext } from "react"

type Props = {
  displayFunctionMap: Map<string, (documentId: string) => string>
}
const defaultFunc = () => (v: string) => {
  return String(v)
}

export const ForeignKeyDisplayProviderContext = createContext<
  (slug: any) => (v: string) => string
>(defaultFunc)

export const useForeignKeyDisplay = () =>
  useContext(ForeignKeyDisplayProviderContext)

export const ForeignKeyDisplayProvider: FC<Props> = ({
  children,
  displayFunctionMap,
}) => {
  const fkDisplayNameLookup = React.useCallback(
    (fieldSlug) => {
      const fn = displayFunctionMap.get(fieldSlug)
      return fn ? fn : (v: string) => v
    },
    [displayFunctionMap]
  )

  return (
    <ForeignKeyDisplayProviderContext.Provider value={fkDisplayNameLookup}>
      {children}
    </ForeignKeyDisplayProviderContext.Provider>
  )
}
