import {
  CollectionHierarchy,
  CurrentCollectionContext,
  makeCurrentCollectionValue,
} from "providers/CurrentCollectionContext"
import { Collections } from "lib/Collections"
import { ErrorOrganizationMissingCollection } from "components/common/errors/ErrorOrganizationMissingCollection"
import {
  useCollectionOrNull,
  useCurrentOrganization,
} from "providers/CurrentOrganizationProvider"
import { useParams } from "react-router-dom"
import React, { FC } from "react"

const CHILD_TO_PARENT_SLUG = "bail_request_id"

/**
 * UrlCollectionProvider provides information about the currently selected
 * collection by examining the URL.
 *
 * UrlCollectionProvider sets the CurrentCollectionContext value.
 *
 * See also: SpecificCollectionProvider
 */
export const UrlCollectionProvider: FC = ({ children }) => {
  const { organization } = useCurrentOrganization()
  const { collectionId } = useParams()
  const { id: courtAppearancesCollectionId } = useCollectionOrNull(
    Collections.COURTAPPEARANCES
  ) || { id: null }

  if (!collectionId || collectionId === "") {
    throw Error(
      "UrlCollectionProvider should not be used in routes that do not have a collectionId parameter."
    )
  }

  const chosenCollection = organization.collections.find(
    (coll) => coll.id === collectionId
  )
  if (chosenCollection === undefined) {
    return <ErrorOrganizationMissingCollection label={collectionId} />
  }

  // TODO: take the collectionHierarchies from the BE once the saving Hierarchy of Collections is implemented
  const getCollectionHierarchies = (): Array<CollectionHierarchy> => {
    if (chosenCollection.slug === Collections.BAILREQUESTS) {
      return courtAppearancesCollectionId
        ? [
            {
              collectionId: courtAppearancesCollectionId,
              childToParentSlug: CHILD_TO_PARENT_SLUG,
            },
          ]
        : []
    }
    return []
  }

  const value = makeCurrentCollectionValue(organization, chosenCollection)
  value.collectionHierarchies = getCollectionHierarchies()
  return (
    <CurrentCollectionContext.Provider value={value}>
      {children}
    </CurrentCollectionContext.Provider>
  )
}
