import {
  CreateChildDocumentInput,
  CreateDocumentInput,
  DocumentFieldInput,
  DocumentOperationInput,
} from "generated/api"
import {
  FieldValuesMap,
  Handlers as FormHandlers,
  NestedFormProperties,
  OnChangeDateTimeType,
  OnChangeDateType,
  OnChangeMultiselectType,
  OnChangeType,
  RemoveHooksType,
  ReplaceHooksType,
  ValidateDateTimeType,
  ValidateDateType,
  ValidateIntegerType,
} from "lib/forms/types"
import { GeneratedFormState } from "lib/forms/formLayoutToJsxConverter"
import {
  makeDateTimeValidator,
  makeDateValidator,
  makeIntegerValidator,
  toggleMultiselectValue,
} from "components/core/FlexForm/helpers"
import { makeStyles } from "@material-ui/core"
import { maybeToEmpty, UsefulDocument } from "lib/apihelpers"
import { nestedCollectionStyles } from "styles/theme"
import { SLUG_FOR_NESTED_FORM } from "lib/Collections"
import { useCollectionOrNull } from "providers/CurrentOrganizationProvider"
import { useDocumentDetails } from "providers/DocumentDetailsProvider"
import _ from "lodash"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import FlexForm from "components/core/FlexForm/FlexForm"
import moment from "moment"
import React, { useEffect, useState } from "react"

const useStyles = makeStyles(nestedCollectionStyles)
const BAIL_REQUESTS_FK = "bail_request_id"

interface Props {
  allCardsExpanded: boolean
  collectionSlug: string
  removeHooks: RemoveHooksType
  replaceHooks: ReplaceHooksType
}

enum ChildExistence {
  NEW = "new",
  SAVED = "saved",
  UPDATED = "updated",
}

type ChildStatus = {
  existence: ChildExistence
  isDeleted: boolean
}

interface ChildValue {
  formState: GeneratedFormState
  status: ChildStatus
}

const convertUsefulDocsToChildValue = (
  usefulDocs: UsefulDocument[]
): ChildValue[] =>
  usefulDocs.map((usefulDoc) => {
    const existingFormState: GeneratedFormState = {
      collectionId: usefulDoc.collectionId,
      disableForm: false,
      documentState: Object.fromEntries(usefulDoc.fields),
      validationErrors: {},
      documentId: usefulDoc.id,
    }
    return {
      formState: existingFormState,
      status: {
        existence: ChildExistence.SAVED,
        isDeleted: false,
      },
    }
  })

const getDocumentStateToSlugValue = (
  documentState: FieldValuesMap
): DocumentFieldInput[] =>
  Object.entries(documentState).map(([slug, value]) => ({
    slug,
    value,
  }))

const isChildModified = (child: ChildValue) => {
  return (
    (child.status.existence === ChildExistence.NEW &&
      !child.status.isDeleted) ||
    (child.status.existence === ChildExistence.SAVED &&
      child.status.isDeleted) ||
    child.status.existence === ChildExistence.UPDATED
  )
}

const maybeToEmptyFieldValue = (childValue: ChildValue) => {
  childValue.formState.documentState = Object.fromEntries(
    Object.entries(childValue.formState.documentState).map(([k, v]) => [
      k,
      maybeToEmpty(v),
    ])
  )
  return childValue
}

const NestedCollection = ({
  allCardsExpanded,
  collectionSlug,
  removeHooks,
  replaceHooks,
}: Props) => {
  const classes = useStyles()
  const [children, setChildren] = useState<Array<ChildValue>>([])
  const collection = useCollectionOrNull(collectionSlug)
  if (!collection) {
    throw Error("Nested collection slug is not affiliated to any collection.")
  }
  const formDescriptor = collection.forms!.find(
    (form) => form.slug === SLUG_FOR_NESTED_FORM
  )
  if (!formDescriptor) {
    throw Error(
      `Nested collection does not have a form with slug ${SLUG_FOR_NESTED_FORM}.`
    )
  }

  const {
    childDocuments: mapOfAllUsefulDocs,
    document: parentDocument,
  } = useDocumentDetails()

  useEffect(() => {
    if (!parentDocument) {
      return
    }
    if (!mapOfAllUsefulDocs.has(collection.id)) {
      throw Error(
        "Collection is not associated has a hierarchy with the Parent Collection."
      )
    }
    setChildren(
      convertUsefulDocsToChildValue(mapOfAllUsefulDocs.get(collection.id)!)
    )
  }, [mapOfAllUsefulDocs, collection.id, parentDocument])

  const addNewChild = () => {
    const newFormState: GeneratedFormState = {
      collectionId: collection.id,
      disableForm: false,
      documentState: {},
      validationErrors: {},
    }
    setChildren((prevState) => [
      {
        formState: newFormState,
        status: {
          existence: ChildExistence.NEW,
          isDeleted: false,
        },
      },
      ...prevState,
    ])
  }

  useEffect(() => {
    const updateApiRequestHandler = (
      requestId: string,
      operations: DocumentOperationInput[]
    ) => {
      children
        .filter(
          (child: ChildValue) =>
            !child.status.isDeleted &&
            child.status.existence === ChildExistence.NEW
        )
        .map(maybeToEmptyFieldValue)
        .reverse()
        .forEach((child: ChildValue) => {
          const fkFieldInput: DocumentFieldInput = {
            slug: BAIL_REQUESTS_FK,
            value: requestId,
          }
          const createDocument: DocumentOperationInput = {
            createDocument: {
              collectionId: collection.id,
              fields: [fkFieldInput].concat(
                getDocumentStateToSlugValue(child.formState.documentState)
              ),
            },
          }
          operations.push(createDocument)
        })

      children
        .filter(
          (child: ChildValue) =>
            !child.status.isDeleted &&
            child.status.existence === ChildExistence.UPDATED
        )
        .map(maybeToEmptyFieldValue)
        .forEach((child: ChildValue) => {
          const fkFieldInput: DocumentFieldInput = {
            slug: BAIL_REQUESTS_FK,
            value: requestId,
          }
          if (!child.formState.documentId) {
            throw Error(`New Child Document cannot be here`)
          }
          const updateDocument: DocumentOperationInput = {
            updateDocument: {
              id: child.formState.documentId,
              collectionId: collection.id,
              fields: [fkFieldInput].concat(
                getDocumentStateToSlugValue(child.formState.documentState)
              ),
            },
          }
          operations.push(updateDocument)
        })

      children
        .filter(
          (child: ChildValue) =>
            child.status.isDeleted &&
            child.status.existence !== ChildExistence.NEW
        )
        .filter((child: ChildValue) => child.formState.documentId)
        .forEach((child: ChildValue) => {
          const deleteDocument: DocumentOperationInput = {
            deleteDocument: {
              id: child.formState.documentId!,
              collectionId: collection.id,
            },
          }
          operations.push(deleteDocument)
        })

      setChildren((prevState) => {
        return prevState.map((child) => {
          child.formState.disableForm = true
          return child
        })
      })

      return operations
    }

    const createBailRequestHandler = (apiRequest: CreateDocumentInput) => {
      const allChildren: CreateChildDocumentInput[] = []
      children
        .filter(
          (child: ChildValue) =>
            !child.status.isDeleted &&
            child.status.existence === ChildExistence.NEW
        )
        .map(maybeToEmptyFieldValue)
        .reverse()
        .forEach((child: ChildValue) => {
          const children = {
            collectionId: collection.id,
            childToParentSlug: BAIL_REQUESTS_FK,
            fields: getDocumentStateToSlugValue(child.formState.documentState),
          }
          allChildren.push(children)
        })

      setChildren((prevState) => {
        return prevState.map((child) => {
          child.formState.disableForm = true
          return child
        })
      })

      return {
        ...apiRequest,
        children: allChildren,
      }
    }

    const getValidationErrorsHandler = (existingErrorString: string) => {
      const collector: string[] = []
      children.forEach((child: ChildValue, index: number) => {
        if (child.status.isDeleted) {
          return
        }
        const errors = Object.values(child.formState.validationErrors)
        if (_.isEmpty(errors)) {
          return
        }
        collector.push(`For ${collection.title} ${index + 1}:`)
        collector.push(
          errors.map((err) => `${err.label} - ${err.helperText}`).join("\n")
        )
      })
      return existingErrorString + collector.join("\n")
    }

    const isChangesMadeHandler = () =>
      children.filter(isChildModified).length > 0

    replaceHooks(
      collectionSlug,
      createBailRequestHandler,
      updateApiRequestHandler,
      getValidationErrorsHandler,
      isChangesMadeHandler
    )
    return () => {
      removeHooks(collectionSlug)
    }
  }, [
    children,
    collection.id,
    removeHooks,
    replaceHooks,
    collectionSlug,
    collection.title,
  ])

  return (
    <>
      <Button
        onClick={addNewChild}
        color="primary"
        className={classes.addChildButton}
      >
        {"ADD NEW " + collection.title}
      </Button>
      {_.times(children.length, (idx) => {
        const child = children[idx]

        const handleChange: OnChangeType = (slug, value) =>
          setChildren((prevState) => {
            return prevState.map((child, i: number) => {
              if (i === idx) {
                child.formState.documentState = {
                  ...child.formState.documentState,
                  [slug]: value,
                }
                if (child.status.existence === ChildExistence.SAVED) {
                  child.status.existence = ChildExistence.UPDATED
                } else if (child.status.existence === ChildExistence.UPDATED) {
                  const persistedDocs = mapOfAllUsefulDocs.get(collection.id)!
                  const existingUsefulDoc = _.find(persistedDocs, {
                    id: child.formState.documentId,
                  })!
                  const isPersisted =
                    maybeToEmpty(value) === existingUsefulDoc.fields.get(slug)
                  child.status.existence = isPersisted
                    ? ChildExistence.SAVED
                    : ChildExistence.UPDATED
                }
              }
              return child
            })
          })

        const handleChangeDate: OnChangeDateType = (slug, value) => {
          if (typeof value === "undefined" || value === null) {
            handleChange(slug, null)
            return
          }
          const converted = moment(value, "M/D/YYYY", true)
          value = converted.isValid() ? converted.format("YYYY-MM-DD") : value
          handleChange(slug, value)
        }

        const handleChangeDateTime: OnChangeDateTimeType = (slug, value) => {
          if (typeof value === "undefined" || value === null) {
            handleChange(slug, null)
            return
          }
          const converted = moment(value, "MM/DD/YYYY hh:mm a", true)
          value = converted.isValid() ? converted.toISOString() : value
          handleChange(slug, value)
        }

        const handleChangeMultiselect: OnChangeMultiselectType = (
          slug: string,
          value
        ) =>
          setChildren((prevState) => {
            return prevState.map((child, i: number) => {
              if (i === idx) {
                const updated = toggleMultiselectValue(
                  child.formState.documentState[slug],
                  value
                )
                child.formState.documentState = {
                  ...child.formState.documentState,
                  [slug]: updated,
                }
                if (child.status.existence === ChildExistence.SAVED) {
                  child.status.existence = ChildExistence.UPDATED
                } else if (child.status.existence === ChildExistence.UPDATED) {
                  const persistedDocs = mapOfAllUsefulDocs.get(collection.id)!
                  const existingUsefulDoc = _.find(persistedDocs, {
                    id: child.formState.documentId,
                  })!
                  const isPersisted = _.isEqual(
                    updated,
                    existingUsefulDoc.fields.get(slug)
                  )
                  child.status.existence = isPersisted
                    ? ChildExistence.SAVED
                    : ChildExistence.UPDATED
                }
              }
              return child
            })
          })

        const integerValidationHandler: ValidateIntegerType = (
          label,
          slug,
          value
        ) =>
          setChildren((prevState) => {
            return prevState.map((child, i: number) => {
              if (i === idx) {
                const validator = makeIntegerValidator(label, slug, value)
                child.formState.validationErrors = validator(
                  child.formState.validationErrors
                )
              }
              return child
            })
          })

        const dateValidationHandler: ValidateDateType = (label, slug, value) =>
          setChildren((prevState) => {
            return prevState.map((child, i: number) => {
              if (i === idx) {
                const validator = makeDateValidator(label, slug, value)
                child.formState.validationErrors = validator(
                  child.formState.validationErrors
                )
              }
              return child
            })
          })

        const dateTimeValidationHandler: ValidateDateTimeType = (
          label,
          slug,
          value
        ) =>
          setChildren((prevState) => {
            return prevState.map((child, i: number) => {
              if (i === idx) {
                const validator = makeDateTimeValidator(label, slug, value)
                child.formState.validationErrors = validator(
                  child.formState.validationErrors
                )
              }
              return child
            })
          })

        const handleDeleteChild = () =>
          setChildren((prevState) => {
            return prevState.map((child, i: number) => {
              if (i === idx) {
                child.status.isDeleted = true
                child.formState.disableForm = true
              }
              return child
            })
          })

        const handleRestoreDeleteChild = () =>
          setChildren((prevState) => {
            return prevState.map((child, i: number) => {
              if (i === idx) {
                child.status.isDeleted = false
                child.formState.disableForm = false
              }
              return child
            })
          })

        const handlers: FormHandlers = {
          onChange: handleChange,
          onChangeDate: handleChangeDate,
          onChangeDateTime: handleChangeDateTime,
          onChangeMultiselect: handleChangeMultiselect,
          validateDate: dateValidationHandler,
          validateDateTime: dateTimeValidationHandler,
          validateInteger: integerValidationHandler,
        }
        const nestedProperties: NestedFormProperties = {
          isDeleted: child.status.isDeleted,
          onDeleteChild: handleDeleteChild,
          onRestoreDeleteChild: handleRestoreDeleteChild,
          status:
            child.status.existence === ChildExistence.NEW
              ? "New"
              : child.status.existence === ChildExistence.UPDATED
              ? "Modified"
              : null,
        }
        return (
          <React.Fragment key={idx + "_fragment"}>
            <Divider className={classes.divider} />
            <FlexForm
              state={child.formState}
              handlers={handlers}
              descriptor={formDescriptor}
              nestedProperties={nestedProperties}
              path={[`nested collection ${collectionSlug} ${idx}`]}
              allCardsExpanded={allCardsExpanded}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}

export default NestedCollection
