import { Flag } from "lib/Flags"
import { makeStyles } from "@material-ui/core/styles"
import { MessageType, useSnackbar } from "providers/Snackbar"
import { UiView } from "components/common/TabularView/types"
import { useCsvHook } from "components/core/Workspaces/DownloadFormatDialog/CsvHook"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import { useFeatureFlag } from "providers/CurrentOrganizationProvider"
import Button from "@material-ui/core/Button"
import CloseIcon from "@material-ui/icons/Close"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import LinearProgress from "@material-ui/core/LinearProgress"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import React, { useEffect } from "react"
import Typography from "@material-ui/core/Typography"

import { downloadFormatDialogStyles } from "styles/theme"

const useStyles = makeStyles(downloadFormatDialogStyles)

interface Props {
  currentView: UiView
  downloadFormatDialog: boolean
  onSetVisibility: (openOrClose: boolean) => void
}

export const DownloadFormatDialog = (props: Props) => {
  const classes = useStyles()
  const [radioValue, setRadioValue] = React.useState("export_columns_displayed")
  const [error, setError] = React.useState(false)
  const { openSnackbar } = useSnackbar()
  const { state: csvState, start: csvStart, reset: csvReset } = useCsvHook()
  const hasCustomReportFlag = useFeatureFlag(Flag.CustomReportDownload)
  const { collection } = useCurrentCollection()
  const isBailRequestsCollection = collection.slug === "bailrequests"
  // Respond to csvState changes that trigger snackbars and dialog visibility.
  useEffect(() => {
    if (csvState.state === "success") {
      // Wait a little bit before showing success so that the user can admire
      // whatever success indicator is presented.
      const timerId = setTimeout(() => {
        openSnackbar("Export Is Complete")
        props.onSetVisibility(false)
        csvReset()
      }, 700)
      return () => clearTimeout(timerId)
    }

    if (csvState.state === "error") {
      openSnackbar(`Export Failed: ${csvState.message}`, MessageType.Error)
      props.onSetVisibility(false)
      csvReset()
    }
  }, [csvReset, csvState, openSnackbar, props])

  const startFetchCsv = React.useCallback(async () => {
    if (radioValue === "export_entire_db") {
      await csvStart(undefined, undefined, "fetchAllColumns")
    } else if (radioValue === "export_columns_displayed") {
      await csvStart(
        props.currentView.query,
        props.currentView.query.order,
        "fetchSelectedColumns"
      )
    } else if (radioValue === "export_custom_reports") {
      await csvStart(undefined, undefined, "fetchCustomReports")
    }
  }, [radioValue, props.currentView, csvStart])

  const handleRadioChange = (event: any) => {
    setRadioValue(event.target.value)
    setError(false)
  }

  return (
    <>
      {
        <Dialog
          open={props.downloadFormatDialog}
          onClose={() => props.onSetVisibility(false)}
          aria-labelledby="download-format-dialog"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="download-format-dialog-title" disableTypography>
            <Grid container justify="space-between">
              <Typography variant="h5">
                {csvState.state !== "idle"
                  ? "Exporting Your File"
                  : "Select Export Format"}
              </Typography>
              <IconButton
                size="small"
                onClick={() => props.onSetVisibility(false)}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {"progress" in csvState ? (
              <>
                <LinearProgress
                  variant={"determinate"}
                  value={csvState.progress}
                />
                <br />
              </>
            ) : csvState.state === "idle" ? (
              <>
                <FormControl component="fieldset" error={error}>
                  <RadioGroup
                    aria-label="export_choices"
                    name="export_choices"
                    value={radioValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="export_entire_db"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Export Entire Database"
                    />
                    <FormControlLabel
                      value="export_columns_displayed"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label="Export Columns Currently Displayed"
                    />
                    {hasCustomReportFlag && isBailRequestsCollection && (
                      <FormControlLabel
                        value="export_custom_reports"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked,
                            }}
                          />
                        }
                        label="Export Custom Reports"
                      />
                    )}
                  </RadioGroup>
                </FormControl>
                <DialogActions>
                  <Button
                    onClick={() => props.onSetVisibility(false)}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => startFetchCsv()}
                    variant="contained"
                    color="primary"
                  >
                    Export
                  </Button>
                </DialogActions>
              </>
            ) : null}
          </DialogContent>
        </Dialog>
      }
    </>
  )
}

export default DownloadFormatDialog
