export const ERROR_ACCOUNT_NOT_LINKED_TO_ORGANIZATION =
  "Your account is not configured properly. Please contact support."
export const ERROR_ATTACHMENTS_UNAVAILABLE =
  "Sorry, attachments are not available right now."
export const ERROR_ATTORNEY_INFO_UNAVAILABLE =
  "Sorry, attorney information is not available right now."
export const ERROR_COLLECTION_MISSING_VIEW =
  "Your collection does not have the requested view."
export const ERROR_COLLECTION_NO_VIEWS =
  "The currently selected collection does not have any views."
export const ERROR_COMMENTS_UNAVAILABLE =
  "Sorry, comments are not available right now."
export const ERROR_DEFAULT = "An unexpected error occurred."
export const ERROR_DEFAULT_SUBMESSAGE =
  "We're sorry. Something went wrong. Our engineers have been notified."
export const ERROR_DOCUMENT_NOT_FOUND = "Document not found."
export const ERROR_DOCUMENT_NOT_FOUND_SUBMESSAGE =
  "We are sorry, but we could not find this document."
export const ERROR_FEATURE_UNAVAILABLE =
  "This feature is temporarily unavailable."
export const ERROR_FORM_MISSING =
  "Oops! Your organization doesn't have this form."
export const ERROR_DURING_QUERY =
  "We're having a hard time fetching rows for this collection."
export const ERROR_MALFORMED_ORGANIZATION = "The URL you requested is invalid."
export const ERROR_NO_VIEW_ID_PROVIDED =
  "View Id is needed to retrieve this page."
export const ERROR_ORGANIZATION_MISSING_COLLECTION =
  "Your organization does not have the requested collection."
export const ERROR_ORGANIZATION_MISSING_FALLBACK_VIEWS =
  "The organization does not have any views defined on default collections. " +
  "This is a configuration problem."
export const ERROR_ORGANIZATION_NOT_FOUND = "Organization not found."
export const ERROR_PAGE_NOT_FOUND = "Page not found."
export const ERROR_PAGE_NOT_FOUND_SUBMESSAGE =
  "We are sorry, but we could not locate the page you are looking for."
export const ERROR_PERMISSION = "You do not have permission to view this page."
export const ERROR_SIGNING_TOS =
  "We are sorry, but we are unable to record your consent to the terms."
export const ERROR_UNABLE_TO_FETCH_USER_INFO =
  "We're having a hard time fetching data about you. Please try again later."
export const ERROR_UNABLE_TO_FETCH_ORGANIZATION =
  "We're having a hard time fetching data about the organization. Please try again later."
