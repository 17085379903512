import { DATE_FORMAT_FOR_BE, DATE_FORMAT_FOR_FE } from "./FilterMenu/helpers"
import { Filter } from "components/common/TabularView/types"
import moment from "moment"

const extraSpace = String.fromCharCode(32)
export const getOperationSymbol = (filter: Filter) => {
  const operation = filter.operation
  switch (operation) {
    case "after":
      return `${extraSpace}>`
    case "before":
      return `${extraSpace}<`
    case "between":
      return ":"
    case "contains":
      return `:`
    case "does_not_contain":
      return `${extraSpace}!=`
    case "greater_than":
      return `${extraSpace}>`
    case "is_equal_to":
      return `${extraSpace}=`
    case "is_exactly":
      return `${extraSpace}=`
    case "is_not_exactly":
      return `${extraSpace}≠`
    case "less_than":
      return `${extraSpace}<`
    case "is_empty":
      return `:`
    case "is_not_empty":
      return `:`
    case "is_true":
      return `:`
    case "is_false":
      return `:`
    default:
      return ""
  }
}

export const formatDisplayDate = (date: string) => {
  return moment(date, DATE_FORMAT_FOR_BE, true).format(DATE_FORMAT_FOR_FE)
}

export const requiredOperands = (filter: Filter) => {
  switch (filter.operation) {
    case "is_empty":
      return false
    case "is_not_empty":
      return false
    case "is_true":
      return false
    case "is_false":
      return false
    default:
      return true
  }
}
