import { API_IDENTIFIER, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "lib/settings"
import { Auth0Provider } from "@auth0/auth0-react"
import React, { FC } from "react"

const Auth0ProviderWithHistory: FC = ({ children }) => {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: API_IDENTIFIER,
      }}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
