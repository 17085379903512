import { documentUrlById } from "lib/navigationHelpers"
import { makeStyles } from "@material-ui/core/styles"
import { Row } from "components/common/TabularView/types"
import {
  useContactsCollection,
  useCurrentOrganization,
} from "providers/CurrentOrganizationProvider"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import React, { FC } from "react"
import SelectInput from "components/common/inputs/SelectInput"
import TextField from "@material-ui/core/TextField"

import { requestFieldStyles } from "styles/theme"

type Props = {
  attorneys: Row[]
  isDisabled: boolean
  onChange: (documentId: string) => void
  selectedAttorneyInfo: Row
}

const useStyles = makeStyles(requestFieldStyles)
const FORM_VARIANT = "filled"

const AttorneyInformationUi: FC<Props> = ({
  attorneys,
  isDisabled,
  onChange,
  selectedAttorneyInfo,
}) => {
  const classes = useStyles()
  // TODO: replace with useCurrentCollection.
  const contactsCollection = useContactsCollection()
  const { organization } = useCurrentOrganization()
  const options = attorneys.map((option) => ({
    label: option.name!.toString(),
    value: option.uuid,
  }))

  const urlForContactDetailPage = (selectedAttorneyInfo: Row) => {
    if (selectedAttorneyInfo.uuid === "" || !contactsCollection) {
      return
    }
    return (
      documentUrlById(
        organization,
        contactsCollection,
        selectedAttorneyInfo.uuid
      ) + "?closeOnSave=true"
    )
  }

  const AttorneyInformationTextField: FC<{ label: string; value: any }> = ({
    label,
    value,
  }) => {
    return (
      <TextField
        className={classes.formControlFull}
        disabled
        fullWidth
        InputProps={{
          disableUnderline: true,
          readOnly: true,
          classes: { input: `${classes.input} ${classes.readOnly}` },
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
            shrink: classes.shrink,
          },
        }}
        label={label}
        value={value}
        variant={FORM_VARIANT}
      />
    )
  }
  return (
    <Grid container key={"grid_container"}>
      {selectedAttorneyInfo && selectedAttorneyInfo.uuid !== "" ? (
        <Link
          className={classes.editContactDetails}
          href={urlForContactDetailPage(selectedAttorneyInfo)}
          target="_blank"
          variant="body2"
        >
          Edit Details in Contact Directory
        </Link>
      ) : null}

      <Grid item key={"grid_item_1"} xs={12} sm={12}>
        <SelectInput
          isDisabled={isDisabled}
          label="Attorney Name"
          options={options}
          onChange={(unused: string, value: string) => onChange(value)}
          path={"attorney_information_builtin_"}
          slug="attorney_name"
          value={selectedAttorneyInfo["uuid"]}
        />
      </Grid>
      <Grid item key={"grid_item_2"} xs={12} sm={12}>
        <AttorneyInformationTextField
          key="email"
          label={"Email"}
          value={selectedAttorneyInfo ? selectedAttorneyInfo["email"] : ""}
        />
      </Grid>
      <Grid item key={"grid_item_3"} xs={12} sm={6}>
        <AttorneyInformationTextField
          key="primary_phone"
          label={"Primary Phone"}
          value={
            selectedAttorneyInfo ? selectedAttorneyInfo["primary_phone"] : ""
          }
        />
      </Grid>
      <Grid item key={"grid_item_4"} xs={12} sm={6}>
        <AttorneyInformationTextField
          key="primary_phone_ext"
          label={"Primary Phone Ext"}
          value={
            selectedAttorneyInfo
              ? selectedAttorneyInfo["primary_phone_ext"]
              : ""
          }
        />
      </Grid>
      <Grid item key={"grid_item_5"} xs={12} sm={6}>
        <AttorneyInformationTextField
          key="second_phone"
          label={"Second Phone"}
          value={
            selectedAttorneyInfo ? selectedAttorneyInfo["second_phone"] : ""
          }
        />
      </Grid>
      <Grid item key={"grid_item_6"} xs={12} sm={6}>
        <AttorneyInformationTextField
          key="second_phone_ext"
          label={"Second Phone Ext"}
          value={
            selectedAttorneyInfo ? selectedAttorneyInfo["second_phone_ext"] : ""
          }
        />
      </Grid>
      <Grid item key={"grid_item_7"} xs={12} sm={12}>
        <AttorneyInformationTextField
          key="notes"
          label={"Notes"}
          value={selectedAttorneyInfo ? selectedAttorneyInfo["notes"] : ""}
        />
      </Grid>
    </Grid>
  )
}

export default AttorneyInformationUi
