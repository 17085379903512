import { useSchema } from "providers/SchemaProvider/SchemaContext"
import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import { UiView } from "components/common/TabularView/types"
import { workspaceDashboardStyles } from "../styles"
import Button from "@material-ui/core/Button"
import CloseIcon from "@material-ui/icons/Close"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

interface DeleteProps {
  onClose: () => void
  open: boolean
  selectedWorkspace: UiView
}

const useStyles = makeStyles(workspaceDashboardStyles)

const DeleteDialog = ({ onClose, open, selectedWorkspace }: DeleteProps) => {
  const classes = useStyles()
  const { deleteViewHandler } = useSchema()

  const handleDelete = () => {
    deleteViewHandler(`${selectedWorkspace.id}`)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="subtitle2">Delete Workspace</Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          This change will be permanent. Are you sure you want to delete the
          workspace <b>{selectedWorkspace.name}</b>?
          {/* TODO: Show workspace name in this dialog */}
        </Typography>
        <DialogActions>
          <Button
            className={classes.dialogButton}
            variant="contained"
            disableElevation
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.deleteButton}
            variant="contained"
            disableElevation
            onClick={handleDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteDialog
