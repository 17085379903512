import { OnChangeType } from "lib/forms/types"
import { Row } from "components/common/TabularView/types"
import Grid from "@material-ui/core/Grid"
import React, { FC } from "react"
import SelectInput from "components/common/inputs/SelectInput"

type Props = {
  contacts: Row[]
  isDisabled: boolean
  label: string
  onChange: OnChangeType
  selectedContactId: string
  slug: string
}
const ContactReferenceUi: FC<Props> = ({
  contacts,
  isDisabled,
  label,
  onChange,
  selectedContactId,
  slug,
}) => {
  const options = contacts.map((option) => ({
    label: option.name ? (option.name as string) : "",
    value: option.uuid,
  }))

  return (
    <Grid item key={"contact_reference_" + slug} xs={12}>
      <SelectInput
        isDisabled={isDisabled}
        label={label}
        options={options}
        onChange={(slug, value) => onChange(slug, value)}
        path={"contact_reference_"}
        slug={slug}
        value={selectedContactId}
      />
    </Grid>
  )
}

export default ContactReferenceUi
