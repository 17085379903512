import { useEffect, useState } from "react"

import {
  ENVIRONMENT,
  HEROKU_APP_NAME,
  HEROKU_BRANCH,
  IS_PRODUCTION,
} from "lib/settings"

export enum AppName {
  Development = "development",
  Production = "production",
  Sandbox = "sandbox",
  Staging = "staging",
}

export enum Environment {
  Development = "development",
  Production = "production",
  Staging = "staging",
}

export const useEnvironment = () => {
  const [env, setEnv] = useState("")
  const [appName, setAppName] = useState("")
  const isDevelopment = ENVIRONMENT === Environment.Development
  const isProduction = IS_PRODUCTION
  const isSandbox = HEROKU_APP_NAME.includes("sandbox")
  const isReviewApp = !!HEROKU_BRANCH

  useEffect(() => {
    switch (ENVIRONMENT) {
      case Environment.Development:
        setEnv(Environment.Development)
        setAppName(AppName.Development)
        break
      case Environment.Production:
        setEnv(Environment.Production)
        setAppName(AppName.Production)
        break
      case Environment.Staging:
        setEnv(Environment.Staging)
        if (isReviewApp) {
          setAppName(HEROKU_BRANCH)
        } else if (isSandbox) {
          setAppName(AppName.Sandbox)
        } else {
          setAppName(AppName.Staging)
        }
        break
    }
  }, [isReviewApp, isSandbox])
  return { env, appName, isProduction, isDevelopment }
}
