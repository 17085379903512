import { FieldValueType, UsefulDocument } from "lib/apihelpers"
import Linkify from "react-linkify"

import { format8601Date, fromNow } from "lib/display/formatters"
import { makeStyles } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import { commentStyles } from "styles/theme"
import { MessageType } from "providers/Snackbar"

const FORM_VARIANT = "filled"

const useStyles = makeStyles(commentStyles)

type TimestampsProps = Record<
  "dateCreated" | "dateEdited",
  FieldValueType | undefined
>

const Timestamps = ({ dateCreated, dateEdited }: TimestampsProps) => (
  <Typography
    color="textSecondary"
    variant="caption"
    style={{ marginBottom: "1em" }}
  >
    {`${format8601Date(dateCreated)} (${fromNow(dateCreated)})`}
    {dateEdited ? (
      <>
        <br />
        Edited - {format8601Date(dateEdited)} ({fromNow(dateEdited)})
      </>
    ) : null}
  </Typography>
)

interface Props {
  comment: UsefulDocument
  currentUser: any
  deleteComment: (comment: UsefulDocument) => void
  openSnackbar: (
    message: string,
    messageType?: MessageType,
    action?: React.ReactNode
  ) => void
  updateComment: (comment: UsefulDocument) => Promise<void>
}

/** Comment is the UI for a single comment. */
const Comment = ({
  comment,
  currentUser,
  deleteComment,
  openSnackbar,
  updateComment,
}: Props) => {
  const classes = useStyles()
  const [commentOptionsEl, setCommentOptionsEl] = useState<Element | null>(null)
  const [editMode, setEditMode] = useState(false)
  const [commentText, setCommentText] = useState<string>(
    (comment.fields.get("comment") || "") as string
  )

  const handleCommentOptionsClick = (
    e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>
  ) => setCommentOptionsEl(e.currentTarget)
  const handleCommentOptionsClose = (
    e: React.MouseEvent<HTMLLIElement> | React.MouseEvent<HTMLAnchorElement>
  ) => {
    // Extracts the "data-comment-action" attribute from the enclosing
    // element.
    const { commentAction } = e.currentTarget.dataset
    switch (commentAction) {
      case "edit":
        setEditMode(true)
        break
      case "delete":
        setEditMode(false)
        deleteComment(comment)
        break
      default:
        break
    }
    setCommentOptionsEl(null)
  }
  const handleCommentEditCancel = () => {
    setEditMode(false)
  }
  const handleCommentEditSave = () => {
    setEditMode(false)
    if (commentText.length > 0) {
      comment.fields.set("comment", commentText)
      updateComment(comment)
    } else {
      openSnackbar("Cannot save a blank comment.", MessageType.Error)
    }
  }

  const currentUsername = currentUser.sub.replace("|", ".")
  const isCurrentUser = currentUsername === comment.fields.get("username")

  let commentDiv
  if (editMode && isCurrentUser) {
    commentDiv = (
      <>
        <TextField
          multiline
          autoFocus
          fullWidth
          label="Edit your comment"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          margin="normal"
          variant={FORM_VARIANT}
        />
        <Grid container justify="flex-end" spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCommentEditCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCommentEditSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </>
    )
  } else if (comment.fields.get("archived")) {
    commentDiv = (
      <Typography
        className={classes.commentText}
        variant="body2"
        color="textSecondary"
      >
        Deleted
      </Typography>
    )
  } else {
    commentDiv = (
      <Typography
        className={classes.commentText}
        color="textPrimary"
        component="span"
        variant="body2"
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              href={decoratedHref}
              target="_blank"
              rel="noopener noreferrer"
              key={key}
            >
              {decoratedText}
            </a>
          )}
        >
          <pre className={classes.pre}>{commentText.trim()}</pre>
        </Linkify>
      </Typography>
    )
  }

  const dateCreated = comment.fields.get("date_posted")
  const dateEdited = comment.fields.get("date_edited")

  const picture = comment.fields.get("picture")
    ? (comment.fields.get("picture") as string)
    : undefined
  const email = comment.fields.get("email")
    ? (comment.fields.get("email") as string)
    : undefined
  const name = comment.fields.get("name")
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar src={picture} alt={email} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <>
              <Grid container wrap="nowrap">
                <Grid item xs={11} container direction="column">
                  <Typography color="textPrimary" variant="body2">
                    {name || email}
                  </Typography>
                  <Timestamps
                    dateCreated={dateCreated}
                    dateEdited={dateEdited}
                  />
                </Grid>

                {isCurrentUser && !comment.fields.get("archived") && (
                  <Grid item>
                    <IconButton
                      id={`comment-${comment.id}-button`}
                      edge="end"
                      aria-label={`comment-${comment.id}`}
                      onClick={handleCommentOptionsClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id={`comment-${comment.id}-select`}
                      anchorEl={commentOptionsEl}
                      open={Boolean(commentOptionsEl)}
                      onClose={handleCommentOptionsClose}
                      elevation={2}
                    >
                      <MenuItem
                        data-comment-action="edit"
                        onClick={handleCommentOptionsClose}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        data-comment-action="delete"
                        onClick={handleCommentOptionsClose}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Grid>
                )}
              </Grid>
            </>
          }
          secondary={commentDiv}
        />
      </ListItem>
    </>
  )
}

export default Comment
