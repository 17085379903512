import { Close } from "@material-ui/icons"
import { IconButton, Snackbar } from "@material-ui/core"
import React, { createContext, FC, useContext, useReducer } from "react"

type SnackbarContextValue = {
  // Opens a snackbar with a message. Optionally, caller can override the
  // default Snackbar action= attribute.
  openSnackbar: (
    message: string,
    messageType?: MessageType,
    action?: React.ReactNode
  ) => void
  // Close the snackbar. Snackbar messages automatically disappear after 6secs,
  // but this is exposed to allow more custom control over the snackbar.
  closeSnackbar: () => void
  // True when the snackbar is currently displayed, and false when it is not.
  isOpen: boolean
}

const SnackbarContext = createContext<SnackbarContextValue | null>(null)

export const useSnackbar = () => {
  const context = useContext(SnackbarContext)
  if (context === null) {
    throw Error("useSnackbar can only be used within a SnackbarProvider.")
  }
  return context
}

export enum MessageType {
  Error = "ERROR",
  Success = "SUCCESS",
}

type SnackbarMessage = {
  key: number
  message: string
  messageType: MessageType
  action: React.ReactNode | null
}

type State = {
  isOpen: boolean
  messageQueue: SnackbarMessage[]
  messageInfo?: SnackbarMessage
}

type Action =
  | {
      type: "OPEN_SNACKBAR"
      message: string
      action: React.ReactNode | null
      messageType?: MessageType
    }
  | { type: "CLOSE_SNACKBAR" }
  | { type: "EXIT_MESSAGE" }
  | { type: "SET_NEW_SNACK" }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "CLOSE_SNACKBAR":
      return { ...state, isOpen: false }
    case "OPEN_SNACKBAR":
      return {
        ...state,
        isOpen: true,
        messageQueue: [
          ...state.messageQueue,
          {
            message: action.message,
            key: new Date().getTime(),
            action: action.action,
            messageType: action.messageType || MessageType.Success,
          },
        ],
      }
    case "EXIT_MESSAGE":
      return { ...state, messageInfo: undefined }
    case "SET_NEW_SNACK":
      return {
        isOpen: true,
        messageQueue: state.messageQueue.slice(1),
        messageInfo: state.messageQueue[0],
      }
  }
}

export const SnackbarProvider: FC = ({ children }) => {
  const [state, send] = useReducer(reducer, {
    isOpen: false,
    messageQueue: [],
    messageInfo: undefined,
  })
  const handleClose = () => send({ type: "CLOSE_SNACKBAR" })
  const handleExited = () => send({ type: "EXIT_MESSAGE" })

  React.useEffect(() => {
    if (state.messageQueue.length && !state.messageInfo) {
      // Set a new snack when we don't have an active one
      send({ type: "SET_NEW_SNACK" })
    } else if (state.messageQueue.length && state.messageInfo && state.isOpen) {
      // Close an active snack when there are still messages in the queue
      send({ type: "CLOSE_SNACKBAR" })
    }
  }, [state])

  const value: SnackbarContextValue = {
    openSnackbar: (message, messageType, action) =>
      send({
        type: "OPEN_SNACKBAR",
        message,
        action: action ? action : null,
        messageType,
      }),
    closeSnackbar: handleClose,
    isOpen: state.isOpen,
  }

  const action =
    state.isOpen && state.messageInfo?.action ? (
      state.messageInfo.action
    ) : (
      <IconButton
        key="close"
        color="inherit"
        onClick={handleClose}
        size={"medium"}
      >
        <Close />
      </IconButton>
    )
  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        action={action}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={
          state.messageInfo?.messageType === MessageType.Error ? null : 6000
        }
        key={state.messageInfo ? state.messageInfo.key : undefined}
        message={state.messageInfo ? state.messageInfo.message : undefined}
        onClose={(_e, reason) => {
          if (
            state.messageInfo?.messageType === MessageType.Error &&
            reason === "clickaway"
          ) {
            return
          }
          handleClose()
        }}
        onExited={handleExited}
        open={state.isOpen}
      />
    </SnackbarContext.Provider>
  )
}
