import { History } from "history"
import React from "react"

export const handleClickNavigation = (
  event: React.MouseEvent<HTMLElement>,
  history: History,
  url: string
) => {
  if (event.metaKey || event.ctrlKey) {
    const newTab = window.open(url, "_blank")
    newTab?.focus()
  } else {
    history.push(url)
  }
}
