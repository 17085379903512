import { Autocomplete } from "@material-ui/lab"
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Input,
  Popover,
  Select,
  TextField,
} from "@material-ui/core"
import { CURRENCY_VALUE_REGEXP } from "lib/regexes"
import { DATE_FORMAT_FOR_FE } from "./helpers"
import { EmptyFilter } from "components/core/Workspaces/FilterChip/FilterChip"
import { FieldDefinition } from "generated/api"
import { Filter } from "components/common/TabularView/types"
import { FilterMenuStyles } from "./styles"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { makeStyles } from "@material-ui/core/styles"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { ParsableDate } from "@material-ui/pickers/constants/prop-types"
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded"
import React, {
  ChangeEvent,
  createRef,
  ReactNode,
  RefObject,
  useEffect,
  useState,
} from "react"

interface AutocompleteProps {
  children?: ReactNode
  disableClearable: boolean
  filter: Filter | EmptyFilter
  onChange: (event: ChangeEvent<{}>, option?: FieldDefinition | null) => void
  openFieldsMenu: boolean
  options: FieldDefinition[]
  value: FieldDefinition | undefined
}
interface SelectProps {
  children: any
  filter: Filter
  onChange: (value: string) => void
}
interface DateProps {
  disabled?: boolean
  maxDate?: ParsableDate
  maxDateMessage?: string
  minDate?: ParsableDate
  minDateMessage?: string
  onChange: (date: MaterialUiPickersDate) => void
  placeholder?: string
  value: ParsableDate
}

interface EnumProps {
  availableEnums: "" | string[] | undefined
  filter: Filter
  onChangeOperand: (value: string, checked: boolean) => void
}

interface NumberProps {
  disabled?: boolean
  isDollar: boolean
  onChange: (value: string) => void
  placeholder?: string
  validOrEmptyFilter: Filter
  value: string
}

const useStyles = makeStyles(FilterMenuStyles)

export const AutocompleteInput = ({
  disableClearable,
  filter,
  onChange,
  openFieldsMenu,
  options,
  value,
}: AutocompleteProps) => {
  const classes = useStyles()
  const inputRef: RefObject<HTMLInputElement> = createRef()
  useEffect(() => {
    if (openFieldsMenu) {
      inputRef.current?.focus()
    }
  })

  return (
    <Autocomplete
      classes={{
        endAdornment: classes.autoCompleteEndAdornment,
        input: classes.autoCompleteInput,
        inputRoot: classes.autoCompleteInputRoot,
        listbox: classes.autoCompleteListbox,
        option: classes.selectOptions,
        paper: classes.autoCompletePaper,
        popper: classes.autoCompletePopper,
      }}
      className={classes.selectAutoComplete}
      disableClearable={disableClearable}
      fullWidth
      getOptionLabel={(option) => option.title}
      getOptionSelected={(option) => option.slug === filter.field}
      onChange={(event, option) => onChange(event, option)}
      openOnFocus={openFieldsMenu}
      options={options}
      popupIcon={<ArrowDropDownRoundedIcon />}
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          {...params}
          variant="filled"
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      selectOnFocus={false}
      size={"small"}
      value={value}
    />
  )
}

export const SelectInput = ({ children, filter, onChange }: SelectProps) => {
  const classes = useStyles()
  const anchorElOverride = () => {
    return undefined
  }
  return (
    <Select
      classes={{
        select: classes.selectInput,
      }}
      onChange={(event) => onChange(event.target.value as string)}
      MenuProps={{
        classes: {
          paper: classes.selectMenu,
        },
        getContentAnchorEl: anchorElOverride(),
        anchorOrigin: { vertical: 40, horizontal: -10 },
      }}
      value={filter.operation}
      disableUnderline
      IconComponent={ArrowDropDownRoundedIcon}
    >
      {children}
    </Select>
  )
}

export const DateInput = ({
  disabled,
  maxDate,
  maxDateMessage,
  minDate,
  minDateMessage,
  onChange,
  placeholder,
  value,
}: DateProps) => {
  const classes = useStyles()
  return (
    <KeyboardDatePicker
      autoOk
      className={classes.dateEntry}
      clearable
      disabled={disabled}
      KeyboardButtonProps={{
        "aria-label": `Change date`,
      }}
      value={value}
      onChange={onChange}
      format={DATE_FORMAT_FOR_FE}
      InputProps={{
        classes: {
          input: classes.dateRoot,
        },
        disableUnderline: true,
      }}
      maxDate={maxDate}
      minDate={minDate}
      maxDateMessage={maxDateMessage}
      minDateMessage={minDateMessage}
      placeholder={placeholder}
    />
  )
}

export const EnumsInput = ({
  availableEnums,
  filter,
  onChangeOperand,
}: EnumProps) => {
  const classes = useStyles()
  const [
    displayOperands,
    setDisplayOperands,
  ] = useState<HTMLSpanElement | null>(null)

  const handleDisplayOperands = (event: React.MouseEvent<HTMLSpanElement>) => {
    setDisplayOperands(event.currentTarget)
  }
  return (
    <>
      <span
        className={classes.operandContainer}
        aria-describedby={"Modify validFilter options"}
        onClick={handleDisplayOperands}
      >
        {filter.operands &&
          filter?.operands?.length >= 1 &&
          filter?.operands?.join(", ")}

        {filter.operands && filter?.operands?.length === 0 && (
          <span> Select </span>
        )}

        <ArrowDropDownRoundedIcon />
      </span>
      <Popover
        open={Boolean(displayOperands)}
        onClose={() => setDisplayOperands(null)}
        anchorEl={displayOperands}
        anchorOrigin={{ vertical: 40, horizontal: -10 }}
        data-testid="filter-popover"
      >
        <FormGroup className={classes.multiSelect}>
          {availableEnums &&
            availableEnums.map((option) => {
              return (
                <FormControlLabel
                  key={option}
                  classes={{ label: classes.multiSelectLabel }}
                  control={
                    <Checkbox
                      color="primary"
                      name={option}
                      value={String(option)}
                      onChange={(event, checked) => {
                        onChangeOperand(event.target.value, checked)
                      }}
                      checked={filter?.operands?.includes(option)}
                    />
                  }
                  label={option}
                />
              )
            })}
        </FormGroup>
      </Popover>
    </>
  )
}

export const NumberInput = ({
  disabled,
  isDollar,
  onChange,
  placeholder,
  validOrEmptyFilter,
  value,
}: NumberProps) => {
  const classes = useStyles()

  return (
    <Input
      disableUnderline
      startAdornment={isDollar ? "$" : null}
      classes={{
        root: classes.numberInput,
      }}
      onChange={(event) => {
        if (validOrEmptyFilter.field) {
          if (
            !!event.target.value && // allow empty during data entry
            !CURRENCY_VALUE_REGEXP.test(event.target.value)
          ) {
            return
          }
          onChange(event.target.value)
        }
      }}
      type="number"
      value={value}
      disabled={disabled}
      placeholder={placeholder}
    />
  )
}
