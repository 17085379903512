import React, { FC } from "react"

import { Initializing } from "components/common/Initializing/Initializing"
import { useCurrentOrganization } from "providers/CurrentOrganizationProvider"

/**
 * RequireOrganization requires that the user be a part of an organization
 * before we render the children.
 */
export const RequireOrganization: FC = ({ children }) => {
  const { organization } = useCurrentOrganization()

  if (organization) {
    return <>{children}</>
  }
  return <Initializing />
}
