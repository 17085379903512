import { FieldDefinition, FieldType } from "generated/api"
import {
  format8601Date,
  formatCurrency,
  formatNumber,
} from "lib/display/formatters"
import { makeStyles } from "@material-ui/core/styles"
import { Row } from "components/common/TabularView/types"
import { tabularViewStyles } from "styles/theme"
import { useForeignKeyDisplay } from "providers/ForeignKeyDisplayProvider"
import React, { useState } from "react"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(tabularViewStyles)

const formatList = (classes: Record<string, string>, value: string[]) => {
  if (value.length === 0) {
    return ""
  }
  if (value.length === 1) {
    return value[0]
  }
  return (
    <>
      {value[0]}
      <span> +{value.length - 1}</span>
    </>
  )
}

type TableCellProps = {
  field?: FieldDefinition
  row: Row
}

const showTime = (slug: string) => {
  // Only show the time component on certain well-known fields.
  // TODO: Consider removing this special case behavior because Flex's backend
  // supports both date and datetime types.
  return [
    "date_requested", // MBF's "date bail requested" field, pre-Flex
    "date_bail_requested", // MBF's "date bail requested" timestamp, w/flex.
    "__document_created",
    "__document_updated",
  ].includes(slug)
}

export const TableCell = ({ field, row }: TableCellProps) => {
  const classes = useStyles()
  const fkDisplayNameLookup = useForeignKeyDisplay()
  const [showTooltip, setShowTooltip] = useState(false)
  const cellRef = (node: any) => {
    if (node === null) {
      return
    }
    const cellWidth = node.getBoundingClientRect().width
    if (cellWidth >= 288) {
      setShowTooltip(true)
    }
  }

  if (!field) {
    return null
  }

  const value = row[field.slug]
  let formatted

  if (value === null) {
    return value
  }

  switch (field.fieldType) {
    case FieldType.ForeignKey:
      formatted = fkDisplayNameLookup(field.slug)(value as string)
      break
    case FieldType.Dollar:
      formatted = formatCurrency(value, 2)
      break
    case FieldType.Datetime:
      formatted = value ? format8601Date(value, showTime(field.slug)) : ""
      break
    case FieldType.Date:
      formatted = value ? format8601Date(value, false) : ""
      break
    case FieldType.Boolean:
      formatted = value ? "Yes" : "No"
      break
    case FieldType.List:
      formatted = formatList(classes, value as string[])
      break
    case FieldType.Number:
      formatted = formatNumber(value)
      break
    default:
      formatted = value
  }

  const cellText = (
    <Typography variant="body2" className={classes.tableCellText} ref={cellRef}>
      {formatted}
    </Typography>
  )
  if (field.fieldType === FieldType.List) {
    const valueAsArray = value as string[]
    if (valueAsArray.length > 1) {
      return (
        <Tooltip title={valueAsArray.join(", ")} placement="top-start">
          {cellText}
        </Tooltip>
      )
    } else {
      return cellText
    }
  }

  return showTooltip ? (
    <Tooltip title={value} placement="top-start">
      {cellText}
    </Tooltip>
  ) : (
    cellText
  )
}
