import { appBarWorkspaceStyles } from "styles/theme"
import { Box, Button, IconButton, Tooltip } from "@material-ui/core"
import { convertBackendFormToDefaultValues } from "lib/forms/pschemaFormToUiFormConverter"
import { Forms } from "lib/Forms"
import { getCrumb } from "./helpers"
import { Keys } from "lib/localStorage"
import { makeStyles } from "@material-ui/core/styles"
import { useAuth0 } from "@auth0/auth0-react"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import { useCurrentOrganization } from "providers/CurrentOrganizationProvider"
import { useCurrentUser } from "providers/CurrentUserProvider"
import { useHistory } from "react-router-dom"
import { useLocalStorage } from "@rehooks/local-storage"
import { useNavigator } from "providers/Navigator"
import { useSchema } from "providers/SchemaProvider/SchemaContext"
import { useSnackbar } from "providers/Snackbar"
import { useWorkspaces } from "providers/WorkspacesProvider"
import AppBar from "@material-ui/core/AppBar"
import AppBarAction from "components/core/AppBar/AppBarAction"
import ContextMenu from "components/core/Workspaces/ContextMenu/ContextMenu"
import DownloadFormatDialog from "components/core/Workspaces/DownloadFormatDialog/DownloadFormatDialog"
import EnvironmentChip from "components/common/chips/EnvironmentChip"
import Grid from "@material-ui/core/Grid"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import React, { useEffect, useState } from "react"
import SaveIcon from "@material-ui/icons/SaveAlt"
import SaveNewDialog from "components/core/Workspaces/SaveNewDialog/SaveNewDialog"
import SearchDialog from "components/core/Workspaces/SearchDialog/SearchDialog"
import SearchIcon from "@material-ui/icons/Search"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(appBarWorkspaceStyles)

interface Props {
  banner: React.ReactChild
}

export const AppBarWorkspace = ({ banner }: Props) => {
  const classes = useStyles()
  const { breadcrumbs, openMenu, isMenuOpen } = useNavigator()
  const { openSnackbar } = useSnackbar()
  const { user } = useAuth0()
  const { organizations } = useCurrentUser()
  const { collection, getUrlForDocument } = useCurrentCollection()
  const history = useHistory()
  const {
    currentView,
    isViewChanged,
    advancedFiltersActive,
    searchByField,
  } = useWorkspaces()
  const {
    organization: { fullName },
    desiredOrganizationId,
    isRefreshing,
  } = useCurrentOrganization()
  const { updateViewHandler } = useSchema()
  const [welcomedUser, setWelcomedUser] = useLocalStorage<boolean>(
    String(Keys.WelcomedUser),
    false
  )
  const desiredOrg = isRefreshing
    ? organizations.find((o) => o.organizationId === desiredOrganizationId)
    : null
  const orgFullName = desiredOrg ? desiredOrg.fullName : fullName

  const [downloadFormatDialog, setDownloadFormatDialog] = useState(false)
  const handleSetVisibilityDownloadFormatDialog = (visible: boolean) => {
    setDownloadFormatDialog(visible)
  }

  const [showSaveViewDialog, setShowSaveViewDialog] = useState(false)
  const [clientSearchDialog, setClientSearchDialog] = useState(false)

  const updateCurrentView = () => {
    updateViewHandler({ ...currentView, name: currentView.name })
  }

  const addNewDocumentHandler = () => {
    const formDescriptor = collection.forms!.find(
      (form) => form.slug === Forms.DETAILS
    )
    if (formDescriptor) {
      history.push({
        pathname: getUrlForDocument(""),
        state: {
          newRequestData: {
            ...convertBackendFormToDefaultValues(formDescriptor),
          },
        },
      })
      return
    }
    throw Error("Collection is missing a form definition.")
  }

  useEffect(() => {
    if (user && !welcomedUser) {
      setWelcomedUser(true)
      openSnackbar(`Welcome, ${user.name}!`)
    }
  }, [user, openSnackbar, welcomedUser, setWelcomedUser])

  const disableAllActions = advancedFiltersActive
  const enableSearch = !!collection.actions?.searchDialog

  return (
    <nav>
      <AppBar elevation={0} className={classes.root}>
        <Toolbar disableGutters variant="dense" className={classes.toolbar}>
          <DownloadFormatDialog
            currentView={currentView}
            downloadFormatDialog={downloadFormatDialog}
            onSetVisibility={handleSetVisibilityDownloadFormatDialog}
          />
          <Grid item container>
            <Grid item container justify="flex-start" alignItems="center">
              <AppBarAction
                breadcrumbs={breadcrumbs}
                openMenu={openMenu}
                isMenuOpen={isMenuOpen}
                getCrumb={getCrumb}
              />
              {organizations.length > 1 && (
                <Typography variant="h1">
                  {`${orgFullName}: ${currentView.name}`}
                </Typography>
              )}
              {!currentView.default && (
                <div className={classes.appBarContextMenu}>
                  <ContextMenu
                    disabled={disableAllActions}
                    id={currentView.id}
                  />
                </div>
              )}
              <EnvironmentChip />
              <Box ml="auto">
                <Tooltip title="Add New Request" placement="top">
                  <IconButton
                    className={classes.iconButton}
                    disabled={disableAllActions}
                    onClick={addNewDocumentHandler}
                    size="small"
                    data-testid="add-new-request"
                  >
                    <PersonAddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Export to CSV" placement="top">
                  <IconButton
                    className={classes.iconButton}
                    disabled={disableAllActions}
                    onClick={() => setDownloadFormatDialog(true)}
                    size="small"
                    data-testid="export-csv"
                  >
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
                {enableSearch && (
                  <Tooltip title="Find Client" placement="top">
                    <IconButton
                      className={classes.iconButton}
                      disabled={disableAllActions}
                      onClick={() => setClientSearchDialog(true)}
                      size="small"
                      data-testid="search-requests"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Button
                  classes={{
                    root: classes.updateButton,
                    disabled: classes.disabled,
                  }}
                  disabled={!isViewChanged || disableAllActions}
                  disableElevation
                  onClick={updateCurrentView}
                  variant="contained"
                >
                  Update
                </Button>
                <Button
                  classes={{
                    root: classes.saveAsNewButton,
                    disabled: classes.disabled,
                  }}
                  color="primary"
                  disabled={!isViewChanged || disableAllActions}
                  disableElevation
                  onClick={() => setShowSaveViewDialog(true)}
                  variant="contained"
                >
                  Save As New
                </Button>
                {showSaveViewDialog && (
                  <SaveNewDialog onClose={() => setShowSaveViewDialog(false)} />
                )}
                {enableSearch && (
                  <SearchDialog
                    open={clientSearchDialog}
                    onClose={() => setClientSearchDialog(false)}
                    searchByField={searchByField}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {banner}
    </nav>
  )
}

export default AppBarWorkspace
