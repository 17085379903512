import { CircularProgress, Grid } from "@material-ui/core"
import {
  CollectionHierarchy,
  useCurrentCollection,
} from "./CurrentCollectionContext"
import { detailPageStyles } from "../styles/theme"
import { DocumentsQueryChildrenInput, useDocumentGetQuery } from "generated/api"
import { ErrorDocumentNotFound } from "components/common/errors/ErrorDocumentNotFound"
import { makeStyles } from "@material-ui/core/styles"
import { toUseful, UsefulDocument } from "../lib/apihelpers"
import { useParams } from "react-router-dom"
import React, { createContext, FC, useContext, useMemo } from "react"

type DocumentDetailsContextPros = {
  document: UsefulDocument | null
  childDocuments: Map<string, Array<UsefulDocument>>
}

export const DocumentDetailsContext = createContext<DocumentDetailsContextPros | null>(
  null
)

export const useDocumentDetails = () => {
  const context = useContext(DocumentDetailsContext)
  if (context === null) {
    throw Error(
      "useDocumentDetails can only be used within a DocumentDetailsProvider."
    )
  }
  return context
}

const useStyles = makeStyles(detailPageStyles)

const EMPTY_VALUE = {
  document: null,
  childDocuments: new Map(),
}

export const DocumentDetailsProvider: FC = ({ children }) => {
  const classes = useStyles()
  const { collection, collectionHierarchies } = useCurrentCollection()
  const { documentId } = useParams()

  const childrenCollection: Array<DocumentsQueryChildrenInput> = collectionHierarchies.map(
    (hierarchy: CollectionHierarchy) => {
      const children: DocumentsQueryChildrenInput = {
        collectionId: hierarchy.collectionId,
        childToParentSlug: hierarchy.childToParentSlug,
      }
      return children
    }
  )

  // We would like to be able to use a cache-and-network request policy
  // here because it would create a better user experience: the detail page
  // would load more quickly, therefore allowing the customer to get their
  // bearings on the page even though the page may not yet have the latest data.
  //
  // Urql provides us a `stale` boolean, which would allow us to do something
  // elegant, such as disabling all the form fields until the latest data was
  // assuredly fetched. However, due to the current component's behavior of
  // copying the first fetched result set into component state, implementing
  // that would not be a quick fix.
  const [fetchedDocuments] = useDocumentGetQuery({
    variables: {
      collectionId: collection.id,
      documentId: documentId,
      children: childrenCollection,
    },
    pause: documentId === undefined, // only fetch if we have a documentId
    requestPolicy: "network-only",
  })

  const value: DocumentDetailsContextPros = useMemo(() => {
    if (fetchedDocuments.data && documentId) {
      const documentsInCollection = fetchedDocuments.data!.documents.documents.filter(
        (conn) => conn.document.collectionId === collection.id
      )
      if (documentsInCollection.length === 0) {
        return EMPTY_VALUE
      }
      const requestDocument = toUseful(documentsInCollection[0].document)
      const docComparator = (docA: UsefulDocument, docB: UsefulDocument) => {
        if (docA.created > docB.created) {
          return -1
        }
        if (docA.created < docB.created) {
          return 1
        }
        return 0
      }

      const map: Map<string, Array<UsefulDocument>> = new Map<
        string,
        Array<UsefulDocument>
      >(
        collectionHierarchies.map((hierarchy) => {
          const usefulDocs = fetchedDocuments
            .data!.documents.documents.filter(
              (conn) => conn.document.collectionId === hierarchy.collectionId
            )
            .map((doc) => toUseful(doc.document))
          return [hierarchy.collectionId, usefulDocs.sort(docComparator)]
        })
      )
      return {
        document: requestDocument,
        childDocuments: map,
      }
    }
    return EMPTY_VALUE
  }, [fetchedDocuments, collection.id, collectionHierarchies, documentId])

  // There was an error fetching the document. Assume it is a "not found".
  if (fetchedDocuments.error) {
    console.log("graphql error:", fetchedDocuments.error.message)
    return <ErrorDocumentNotFound />
  }

  // If we're still waiting for results, we have nothing to do.
  if (fetchedDocuments.fetching) {
    return (
      <Grid
        alignItems="center"
        className={classes.loading}
        container
        justify="center"
      >
        <CircularProgress />
      </Grid>
    )
  }

  if (!value.document && documentId) {
    return <ErrorDocumentNotFound />
  }

  return (
    <DocumentDetailsContext.Provider value={value}>
      {children}
    </DocumentDetailsContext.Provider>
  )
}
