import { Chip, Tooltip } from "@material-ui/core"
import { detailPageStyles } from "styles/theme"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

const useStyles = makeStyles((theme) => detailPageStyles(theme))

export default function DuplicateChip({
  duplicateFieldsChecked,
}: {
  duplicateFieldsChecked: Array<string>
}) {
  const classes = useStyles()
  const duplicateFieldsCheckedString = duplicateFieldsChecked.join(" and ")
  const duplicateTitle = `Duplicate ${duplicateFieldsCheckedString}`
  const duplicateTooltip = `This record is potentially a duplicate of another record with the same ${duplicateFieldsCheckedString}`

  return (
    <Tooltip title={duplicateTooltip} placement="top">
      <Chip
        className={classes.duplicateChip}
        label={duplicateTitle}
        size="small"
      />
    </Tooltip>
  )
}
