import { Autocomplete } from "@material-ui/lab"
import { FormControl, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { maybeToEmpty } from "lib/apihelpers"
import { requestFieldStyles } from "styles/theme"
import { SelectOption } from "lib/forms/types"

import React from "react"
const FORM_VARIANT = "filled"
const useStyles = makeStyles((theme) => requestFieldStyles(theme))
interface Props {
  isDisabled: boolean
  label: string
  onChange: (slug: string, value: string) => void
  options: SelectOption[]
  path: string
  slug: string
  value: string
}
const NO_OPTION_SELECTED = {
  label: "",
  value: "",
}

const getValue = (value: string, options: Array<SelectOption>) => {
  if (!value) {
    return NO_OPTION_SELECTED
  }
  return options.find((option: SelectOption) => option.value === value)
}

const SelectInput = ({
  isDisabled,
  label,
  onChange,
  options,
  path,
  slug,
  value,
}: Props) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControlFull}>
      <Autocomplete
        classes={{
          option: classes.selectOptions,
          inputRoot: classes.autoCompleteInputRoot,
          input: classes.autoCompleteInput,
          listbox: classes.autoCompleteListbox,
          popper: classes.autoCompletePopper,
        }}
        className={classes.selectAutoComplete}
        disabled={isDisabled}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option) => option.value === value}
        id={path + slug}
        onChange={(event, option) => {
          onChange(slug, maybeToEmpty(option === null ? "" : option.value))
        }}
        options={options.filter(
          (option) => option.hidden !== true || option.value === value
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant={FORM_VARIANT}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
        size={"small"}
        value={getValue(value, options)}
      />
    </FormControl>
  )
}
export default SelectInput
