import { useSchema } from "providers/SchemaProvider/SchemaContext"
import React, { useEffect, useState } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { requestFieldStyles } from "styles/theme"
import { UiView } from "components/common/TabularView/types"
import { useWorkspaces } from "providers/WorkspacesProvider"
import { workspaceDashboardStyles } from "../styles"
import Button from "@material-ui/core/Button"
import CloseIcon from "@material-ui/icons/Close"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

interface Props {
  onClose: () => void
  open: boolean
  selectedWorkspace: UiView
}

const useStyles = makeStyles(workspaceDashboardStyles)
const useFieldStyles = makeStyles(requestFieldStyles)

const SaveUpdateDialog = ({ onClose, open, selectedWorkspace }: Props) => {
  const classes = useStyles()
  const fieldClasses = useFieldStyles()
  const [workspaceName, setWorkspaceName] = useState(selectedWorkspace.name)
  const { updateViewHandler } = useSchema()
  const { validateViewName } = useWorkspaces()
  const validationMessage = validateViewName(
    workspaceName,
    selectedWorkspace.id
  )

  const handleRename = () => {
    updateViewHandler(
      {
        ...selectedWorkspace,
        name: workspaceName,
      },
      "Rename"
    )
    onClose()
  }

  useEffect(() => {
    setWorkspaceName(selectedWorkspace.name)
  }, [selectedWorkspace])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="subtitle1">Edit Workspace</Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="filled"
          label="Workspace name"
          value={workspaceName}
          onChange={(e) => setWorkspaceName(e.target.value)}
          error={!!validationMessage}
          helperText={validationMessage}
          InputLabelProps={{
            classes: {
              root: fieldClasses.label,
              shrink: fieldClasses.shrink,
            },
          }}
          InputProps={{
            classes: {
              input: `${fieldClasses.input} ${classes.input}`,
              underline: fieldClasses.underline,
              focused: fieldClasses.focused,
              disabled: fieldClasses.disabled,
              multiline: fieldClasses.multiline,
            },
          }}
        />
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.dialogButton}
            variant="contained"
            color="primary"
            disableElevation
            disabled={
              !!validationMessage ||
              workspaceName.trim() === selectedWorkspace.name
            }
            onClick={handleRename}
          >
            Save Changes
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default SaveUpdateDialog
