import { CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import DeleteIcon from "@material-ui/icons/Delete"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Link from "@material-ui/core/Link"
import React, { useCallback, useRef, useState } from "react"
import TextField from "@material-ui/core/TextField"

import { AttachmentsApi } from "lib/attachments_api"
import { attachmentStyles } from "styles/theme"
import { MessageType, useSnackbar } from "providers/Snackbar"
import { useAxiosClient } from "providers/AxiosClientProvider"
import { useDocumentAttachmentsGetQuery } from "generated/api"
import ActionDialog from "../ActionDialog/ActionDialog"

const DELETE_HEADER_MESSAGE = "Are you sure you want to delete this attachment?"

const confirmDeleteMessage = (
  <>
    Deleting this attachment will permanently remove it. You will not be able to
    recover it once deleted.
  </>
)
// We use "cache-and-network" because it avoids an intermediate render
// where .fetching is true, which looks better on the page.
const REFRESH_REQUEST_POLICY = "cache-and-network"

const useStyles = makeStyles(attachmentStyles)
interface Props {
  collectionId: string
  requestId: string
}

export const Attachments = ({ collectionId, requestId }: Props) => {
  const { openSnackbar } = useSnackbar()
  const attachmentRef = useRef<any>()
  const axiosClient = useAxiosClient()
  const classes = useStyles()
  // GraphQL does not support file attachments, so we will use requery
  // to refresh the data after the file attachment API calls complete.
  const [documents, requery] = useDocumentAttachmentsGetQuery({
    variables: { documentId: requestId, collectionId: collectionId },
  })

  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<string | false>(
    false
  )

  const uploadFiles = useCallback(async () => {
    const attachmentData: File = attachmentRef.current.files[0]
    attachmentRef.current.value = null
    if (!attachmentData) {
      // no file selected
      return
    }
    setIsAttachmentLoading(true)
    try {
      const response = await AttachmentsApi(axiosClient).uploadAttachment(
        collectionId,
        requestId,
        attachmentData
      )
      if (response.ok) {
        openSnackbar(`Successfully uploaded ${attachmentData.name}`)
        requery({ requestPolicy: REFRESH_REQUEST_POLICY })
        return
      }
      openSnackbar(
        `Failed to upload attachment: ${response.payload}`,
        MessageType.Error
      )
    } finally {
      setIsAttachmentLoading(false)
    }
  }, [axiosClient, collectionId, openSnackbar, requery, requestId])

  const handleDeleteAttachment = useCallback(
    async (attachmentId, filename) => {
      setIsAttachmentLoading(true)
      try {
        const response = await AttachmentsApi(axiosClient).deleteAttachment(
          collectionId,
          requestId,
          attachmentId
        )
        if (response.ok) {
          openSnackbar(`Successfully deleted ${filename}`)
          requery({ requestPolicy: REFRESH_REQUEST_POLICY })
          return
        }
        openSnackbar(
          `Failed to delete attachment: ${response.payload}`,
          MessageType.Error
        )
      } finally {
        setIsAttachmentLoading(false)
      }
    },
    [axiosClient, collectionId, openSnackbar, requery, requestId]
  )

  if (documents.error) {
    console.warn(documents.error)
    return <b>File attachments are temporarily unavailable.</b>
  }
  if (documents.fetching) {
    return <CircularProgress />
  }

  const attachments =
    documents.data!.documents.documents[0].document.attachments || []
  return (
    <>
      {isAttachmentLoading && (
        <div className={classes.loadingOverlay}>
          <CircularProgress />
        </div>
      )}
      {attachments.length === 0 ? (
        <></>
      ) : (
        attachments.map((attachment) => (
          <Box
            key={attachment.id}
            ml={1}
            mt={1}
            className={isAttachmentLoading ? classes.loadingOpacity : ""}
          >
            <Grid container justify="space-between">
              <Grid item>
                <Link
                  color="textPrimary"
                  href={attachment.url}
                  target="_blank"
                  underline="none"
                  variant="body1"
                >
                  {attachment.filename}
                </Link>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => setOpenDeleteDialog(attachment.id)}
                  className={classes.iconButton}
                  disabled={openDeleteDialog !== false}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
            {openDeleteDialog === attachment.id && (
              <ActionDialog
                confirmButtonLabel="Delete Attachment"
                useWarningStyle
                descriptionMessage={confirmDeleteMessage}
                dialogId="remove-attachment-dialog"
                headerMessage={DELETE_HEADER_MESSAGE}
                onClose={() => setOpenDeleteDialog(false)}
                onConfirm={() => {
                  handleDeleteAttachment(attachment.id, attachment.filename)
                  setOpenDeleteDialog(false)
                }}
              />
            )}
          </Box>
        ))
      )}
      <Box mt={2}>
        <TextField
          fullWidth
          id="attachment_url"
          inputRef={attachmentRef}
          margin="normal"
          placeholder="Attachment URL"
          type="file"
          variant="outlined"
          onChange={uploadFiles}
        />
      </Box>
    </>
  )
}

export default Attachments
