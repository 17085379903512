import * as Sentry from "@sentry/browser"

interface Result<T> {
  ok: boolean
  payload?: T
}

export function makeSuccess<T>(payload?: T): Result<T> {
  return { ok: true, payload }
}

export function makeFailure<T>(payload?: T): Result<T> {
  return { ok: false, payload }
}

export function reportError(error: any) {
  console.error(error)
  Sentry.captureException(error)
}
