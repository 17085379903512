import { ATTORNEY_DATA_VIEW } from "lib/contacts/views"
import { BasicQueryProvider } from "providers/BasicQueryProvider"
import { OnChangeType } from "lib/forms/types"
import { useContactsCollection } from "providers/CurrentOrganizationProvider"
import AttorneyInformation from "components/core/AttorneyInformation/AttorneyInformation"
import React, { FC } from "react"

type Props = {
  attorneyId: string | null
  isDisabled: boolean
  onChange: OnChangeType
}

const AttorneyInformationBuiltin: FC<Props> = ({
  attorneyId,
  isDisabled,
  onChange,
}) => {
  const contactsCollection = useContactsCollection()

  return (
    <BasicQueryProvider
      view={ATTORNEY_DATA_VIEW}
      collectionId={contactsCollection.id}
    >
      <AttorneyInformation
        attorneyId={attorneyId}
        isDisabled={isDisabled}
        onChange={onChange}
      />
    </BasicQueryProvider>
  )
}

export default AttorneyInformationBuiltin
