import {
  convertQueryResponseToRowItems,
  createQuery,
} from "lib/document_queries/document_queries"
import { DEFAULT_ROWS_PER_PAGE } from "components/common/QueryFunctions/constants"
import { ErrorQuerying } from "components/common/errors/ErrorQuerying"
import { Keys } from "lib/localStorage"
import { makeStyles } from "@material-ui/core/styles"
import { PaginatedTable } from "components/common/TabularView/PaginatedTable"
import { PanelMode } from "providers/Navigator"
import { QueryOperator, useDocumentsForTabularViewQuery } from "generated/api"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import { useHistory, useLocation } from "react-router-dom"
import { useLocalStorage } from "@rehooks/local-storage"
import { useSchema } from "providers/SchemaProvider/SchemaContext"
import { useWorkspaces } from "providers/WorkspacesProvider"
import { workspaceDashboardStyles } from "styles/theme"
import ActionBar from "./ActionBar/ActionBar"
import AdvancedFilters from "./AdvancedFilters/AdvancedFilters"
import ColumnManagement from "./ColumnManagement/ColumnManagement"
import NavigationRail from "./NavigationRail/NavigationRail"
import React, { MouseEvent, useMemo, useState } from "react"
import WorkspaceManagement from "./WorkspaceManagement/WorkspaceManagement"

const useStyles = makeStyles(workspaceDashboardStyles)
const FIRST_PAGE = 0

const Workspaces = () => {
  const classes = useStyles()
  const { collection } = useCurrentCollection()
  const { uiFieldsTable } = useSchema()
  const {
    currentView,
    updateViewFilters,
    advancedFiltersActive,
    setAdvancedFiltersActive,
    selectedView,
    setSelectedView,
  } = useWorkspaces()
  const history = useHistory()
  const location = useLocation()
  const [rowsPerPage, setRowsPerPage] = useLocalStorage(
    String(Keys.RowsPerPage),
    DEFAULT_ROWS_PER_PAGE
  )
  const [searchedValueWorkspace, setSearchedValueWorkspace] = useState("")
  const [searchedValueColumn, setSearchedValueColumn] = useState<string>("")
  const scrollRef = React.useRef<HTMLDivElement>(null)
  const searchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )
  const baseURL = location.pathname
  const requestedPage = Number(searchParams.get("page") ?? FIRST_PAGE)

  const handleChangePage = (page: number) => {
    history.push(`${baseURL}?page=${page}${location.hash}`)
  }

  const handleChangeSearchValueWorkspace = (value: string) =>
    setSearchedValueWorkspace(value)

  const handleChangeSearchValueColumn = (value: string) =>
    setSearchedValueColumn(value)

  const handleActionBarToggleChange = (
    event: MouseEvent,
    newSelectedView: PanelMode
  ) => {
    setSelectedView(newSelectedView)
  }

  const [{ data, error, fetching }] = useDocumentsForTabularViewQuery({
    variables: createQuery(
      collection.id,
      {
        page: requestedPage,
        rowsPerPage,
      },
      currentView.query
    ),
    requestPolicy: "cache-and-network",
  })
  const computedRows = useMemo(
    () =>
      fetching || !data?.queryResults
        ? null
        : convertQueryResponseToRowItems(data?.queryResults!),
    [data, fetching]
  )
  const currentPage = useMemo(() => {
    const queryPage = data?.queryResults.page
    return !fetching && queryPage ? queryPage : FIRST_PAGE
  }, [data, fetching])

  const onNavigate = () => {
    if (scrollRef.current && !fetching) {
      const scrollPosition = scrollRef.current.scrollTop
      history.push(
        `${baseURL}?page=${currentPage}&pos=${scrollPosition}${location.hash}`
      )
    }
  }

  if (error) {
    return <ErrorQuerying details={error.message} />
  }

  let advancedFilters = currentView.query.expression
    ? {
        ...currentView.query.expression,
        filters: currentView.query.expression.filters?.filter(
          (filt) => filt.operation !== QueryOperator.OrderBy
        ),
      }
    : currentView.query.expression
  if (
    !advancedFilters?.filters?.length &&
    !advancedFilters?.expressions?.length
  ) {
    advancedFilters = null
  }

  if (!fetching && scrollRef.current) {
    scrollRef.current?.scrollTo(0, Number(searchParams.get("pos")))
  }

  return (
    <div className={classes.workspaceContainer}>
      <div className={classes.railContainer}>
        <NavigationRail />
      </div>
      <div className={classes.slideOutPanel}>
        {selectedView === "workspaces" && (
          <WorkspaceManagement
            onChangeSearchedValue={handleChangeSearchValueWorkspace}
            searchedValue={searchedValueWorkspace}
          />
        )}
        {selectedView === "columns" && (
          <ColumnManagement
            onChangeSearchedValue={handleChangeSearchValueColumn}
            searchedValue={searchedValueColumn}
          />
        )}
      </div>
      {advancedFiltersActive ? (
        <div className={classes.contentScrollDisabled}>
          <AdvancedFilters
            expression={advancedFilters}
            closeAdvancedFilters={() => setAdvancedFiltersActive(false)}
          />
        </div>
      ) : (
        <div>
          <ActionBar
            onChange={handleActionBarToggleChange}
            selectedView={selectedView}
            openAdvancedFilters={() => {
              setSelectedView("closed")
              setAdvancedFiltersActive(true)
            }}
          />
          <div ref={scrollRef} className={classes.content}>
            <PaginatedTable
              uiFieldsTable={uiFieldsTable}
              changePage={(_, newPage) => handleChangePage(newPage)}
              changeRowsPerPage={(rowsPerPage) => {
                setRowsPerPage(Number(rowsPerPage))
                handleChangePage(FIRST_PAGE)
              }}
              onNavigate={onNavigate}
              page={currentPage}
              rows={computedRows}
              rowsCount={data?.queryResults.totalCount || 0}
              rowsPerPage={rowsPerPage}
              sortColumn={updateViewFilters}
              query={currentView.query}
              enableSorting
              enablePagination
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Workspaces
