import { CommentsBuiltin } from "components/core/Comment/CommentsBuiltin"
import { FeatureErrorBoundary } from "components/common/errors/FeatureErrorBoundary"
import { makeStyles } from "@material-ui/core/styles"
import Attachments from "components/core/Attachments/Attachments"
import AttorneyInformationBuiltin from "components/core/AttorneyInformation/AttorneyInformationBuiltin"
import BooleanCheckbox from "components/common/inputs/BooleanCheckbox"
import ContactReferenceDropdown from "components/core/ContactReference/ContactReferenceDropdown"
import CurrencyInput from "components/common/inputs/CurrencyInput"
import DateInput from "components/common/inputs/DateInput"
import DateTimeInput from "components/common/inputs/DateTimeInput"
import Grid from "@material-ui/core/Grid"
import MultiSelect from "components/common/inputs/MultiSelect"
import NestedCollection from "components/core/NestedCollection/NestedCollection"
import NumberInput from "components/common/inputs/NumberInput"
import ReadOnlyTextField from "components/common/inputs/ReadOnlyTextField"
import SelectInput from "components/common/inputs/SelectInput"
import TextInput from "components/common/inputs/TextInput"

import {
  ERROR_ATTACHMENTS_UNAVAILABLE,
  ERROR_ATTORNEY_INFO_UNAVAILABLE,
  ERROR_COMMENTS_UNAVAILABLE,
} from "lib/errorMessages"
import { FieldValueType } from "lib/apihelpers"
import { formatTimestamp } from "lib/display/formatters"
import {
  FormValidationError,
  Handlers,
  InputType,
  SelectOption,
} from "lib/forms/types"
import { requestFieldStyles } from "styles/theme"
import React from "react"

const useStyles = makeStyles((theme) => requestFieldStyles(theme))

const SLUG_ATTORNEY_ID = "attorney_id"

interface Props {
  allCardsExpanded: boolean
  collectionId: string
  createdTimestamp?: string
  documentId?: string
  getValueForSlug?: (slug: string) => FieldValueType | undefined
  handlers: Handlers
  isDisabled: boolean
  label: string
  options?: SelectOption[]
  path: string
  slug: string
  type: InputType
  updatedTimestamp?: string
  validationError?: FormValidationError
  value: any // TODO: use FieldValueTypes
}

const InputElementFactory = ({
  allCardsExpanded,
  collectionId,
  createdTimestamp,
  documentId,
  getValueForSlug,
  handlers,
  handlers: { onChange, onChangeDate, onChangeDateTime, onChangeMultiselect },
  isDisabled,
  label,
  options,
  path,
  slug,
  type,
  updatedTimestamp,
  validationError,
  value,
}: Props) => {
  const classes = useStyles()
  const helperText = validationError ? validationError.helperText : ""
  const error = !!validationError
  const newCheckboxValue = value === "true" || value === true

  switch (type) {
    case InputType.Email:
    case InputType.Phone:
    case InputType.Text:
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <TextInput
            isDisabled={isDisabled}
            label={label}
            onChange={onChange}
            path={path}
            slug={slug}
            value={value}
          />
        </Grid>
      )
    case InputType.Number:
    case InputType.Integer:
      // TODO: enforce bounds. For now, this is only enforced server side.
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <NumberInput
            error={error}
            helperText={helperText}
            isDisabled={isDisabled}
            label={label}
            onChange={onChange}
            path={path}
            slug={slug}
            validationHandler={handlers.validateInteger}
            value={value}
          />
        </Grid>
      )
    case InputType.Currency:
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <CurrencyInput
            isDisabled={isDisabled}
            label={label}
            onChange={onChange}
            path={path}
            slug={slug}
            value={value}
          />
        </Grid>
      )
    case InputType.Select:
      options = options || []
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <SelectInput
            isDisabled={isDisabled}
            label={label}
            options={options}
            onChange={onChange}
            path={path}
            slug={slug}
            value={value}
          />
        </Grid>
      )
    case InputType.BooleanCheckbox:
      return (
        <Grid
          className={classes.verticalCenter}
          item
          key={slug + "grid"}
          xs={12}
        >
          <BooleanCheckbox
            isDisabled={isDisabled}
            label={label}
            onChange={onChange}
            slug={slug}
            value={newCheckboxValue}
          />
        </Grid>
      )
    case InputType.Multiselect:
      options = options || []
      return (
        <Grid
          className={classes.multiSelectOuter}
          item
          key={slug + "grid"}
          xs={12}
        >
          <MultiSelect
            onChange={onChangeMultiselect}
            isDisabled={isDisabled}
            options={options}
            slug={slug}
            value={value}
          />
        </Grid>
      )
    case InputType.ReadOnly:
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <ReadOnlyTextField
            path={path}
            classes={classes}
            label={label}
            slug={slug}
            value={value}
          />
        </Grid>
      )
    case InputType.Date:
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <DateInput
            onChange={onChangeDate}
            error={error}
            helperText={helperText}
            isDisabled={isDisabled}
            label={label}
            slug={slug}
            validationHandler={handlers.validateDate}
            value={value}
          />
        </Grid>
      )
    case InputType.DateTime:
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <DateTimeInput
            error={error}
            helperText={helperText}
            isDisabled={isDisabled}
            label={label}
            onChange={onChangeDateTime}
            slug={slug}
            validationHandler={handlers.validateDateTime}
            value={value}
          />
        </Grid>
      )
    case InputType.DocumentCreatedTimestamp:
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <ReadOnlyTextField
            path={path}
            classes={classes}
            label={label}
            slug={slug}
            value={createdTimestamp ? formatTimestamp(createdTimestamp) : ""}
          />
        </Grid>
      )
    case InputType.DocumentUpdatedTimestamp:
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <ReadOnlyTextField
            path={path}
            classes={classes}
            label={label}
            slug={slug}
            value={updatedTimestamp ? formatTimestamp(updatedTimestamp) : ""}
          />
        </Grid>
      )
    case InputType.ContactReference:
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <ContactReferenceDropdown
            isDisabled={isDisabled}
            label={label}
            onChange={onChange}
            selectedContactId={value}
            slug={slug}
          />
        </Grid>
      )
    case InputType.Attachments:
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <FeatureErrorBoundary
            feature="attachments"
            message={ERROR_ATTACHMENTS_UNAVAILABLE}
          >
            {documentId ? (
              <Attachments collectionId={collectionId} requestId={documentId} />
            ) : (
              "Attachments will be available after saving this request."
            )}
          </FeatureErrorBoundary>
        </Grid>
      )
    case InputType.Notes:
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <FeatureErrorBoundary
            feature="comments"
            message={ERROR_COMMENTS_UNAVAILABLE}
          >
            {documentId ? (
              <CommentsBuiltin
                parentCollectionId={collectionId}
                documentId={documentId}
              />
            ) : (
              "Notes will be available after saving this request."
            )}
          </FeatureErrorBoundary>
        </Grid>
      )
    case InputType.AttorneyInformation:
      return (
        <Grid item key={slug + "grid"} xs={12}>
          <FeatureErrorBoundary
            feature="attorneyinformation"
            message={ERROR_ATTORNEY_INFO_UNAVAILABLE}
          >
            <AttorneyInformationBuiltin
              attorneyId={
                (getValueForSlug!(SLUG_ATTORNEY_ID) as string) || null
              }
              isDisabled={isDisabled}
              onChange={onChange}
            />
          </FeatureErrorBoundary>
        </Grid>
      )
    case InputType.NestedCollection:
      return (
        <NestedCollection
          allCardsExpanded={allCardsExpanded}
          collectionSlug={slug}
          removeHooks={handlers.removeHooks!}
          replaceHooks={handlers.replaceHooks!}
        />
      )
    default:
      throw Error(`Unsupported input type: ${type}`)
  }
}
export default InputElementFactory
