import { createStyles } from "@material-ui/core/styles"
import { UI_COLORS } from "styles/theme"

export const filterChipWrapperStyles = () =>
  createStyles({
    root: {
      display: "flex",
      listStyle: "none",
      alignItems: "center",
      marginLeft: "12px",
    },
    filterButtonGroup: {
      "marginRight": "8px",
      "display": "inline-flex",
      "& :not(:last-child)": {
        "&, button": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
      "& :not(:first-child)": {
        "&, button": {
          borderLeft: "1px solid transparent",
          marginLeft: "-1px",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      },
    },
    filterButton: {
      border: "1px solid #E4E5E7",
      borderRadius: "4px",
      width: "40px",
      height: "40px",
      padding: "0px",
      margin: 0,
    },
    filterChipWrapper: {
      display: "flex",
      flexWrap: "nowrap",
      flexDirection: "row-reverse",
    },
    clearSimpleFilters: {
      padding: "2px",
      width: "14px",
      height: "14px",
    },
    clearSimpleFiltersIcon: {
      "background": UI_COLORS.black_400,
      "borderRadius": "50px",
      "color": UI_COLORS.black_50,
      "padding": "2px",
      "width": "14px",
      "height": "14px",
      "margin": "0px 2px 2px",

      "&:hover": {
        background: UI_COLORS.black_600,
        color: UI_COLORS.black_50,
      },
    },
    clearSimpleFiltersWrapper: {
      marginRight: "8px",
    },
  })
