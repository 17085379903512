import { makeStyles } from "@material-ui/core/styles"
import FormatLineSpacingRounded from "@material-ui/icons/FormatLineSpacingRounded"
import React, { FC, useEffect } from "react"
import TextField from "@material-ui/core/TextField"

import { requestFieldStyles } from "styles/theme"
import { useState } from "react"

const FORM_VARIANT = "filled"
const useStyles = makeStyles((theme) => requestFieldStyles(theme))

export interface Props {
  isDisabled: boolean
  label: string
  onChange: (slug: string, value: string) => void
  onBlur?: (value: string) => void
  path: string
  slug: string
  value: string
  error?: boolean
  helperText?: string
}

const TextInput: FC<Props> = ({
  isDisabled,
  label,
  onChange,
  onBlur,
  slug,
  path,
  value,
  error,
  helperText,
}) => {
  const handleFocus = () => {
    setExpanded(true)
    setAnimating(true)
    //setting a time out here so that the text area does not animate when a user is typing in it.
    setTimeout(function () {
      setAnimating(false)
    }, 1000)
  }

  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const [animating, setAnimating] = useState(false)
  const [inputWidth, setInputWidth] = useState(0)
  const [hiddenWidth, setHiddenWidth] = useState(0)
  const heightOverride = !expanded ? classes.heightOverride : undefined
  const expandAnimation = animating ? classes.expandAnimation : undefined
  const [isMultiLine, setIsMultiLine] = useState(false)
  const inputRef = (node: any) => {
    if (node === null) {
      return
    }
    const newWidth = node.getBoundingClientRect().width
    if (!inputWidth) {
      setInputWidth(newWidth)
      return
    }
    if (inputWidth !== newWidth) {
      setInputWidth(newWidth)
    }
  }
  const hiddenRef = (node: HTMLSpanElement | null) => {
    if (node === null) {
      return
    }
    const newWidth = node.getBoundingClientRect().width
    if (!hiddenWidth) {
      setHiddenWidth(newWidth)
      return
    }
    if (hiddenWidth !== newWidth) {
      setHiddenWidth(newWidth)
    }
  }

  const containsNewLine = value.includes("\n")

  useEffect(() => {
    setIsMultiLine(hiddenWidth > inputWidth || containsNewLine)
  }, [hiddenWidth, inputWidth, containsNewLine])

  return (
    <>
      <span ref={hiddenRef} className={classes.hiddenText}>
        {value}
      </span>
      <div className={classes.expandContainer}>
        <TextField
          ref={inputRef}
          className={`${classes.formControlFull} ${expandAnimation} ${heightOverride}`}
          disabled={isDisabled}
          id={path + slug}
          InputLabelProps={{
            classes: {
              root: classes.label,
              shrink: classes.shrink,
            },
          }}
          InputProps={{
            classes: {
              input: classes.input,
              underline: classes.underline,
              focused: classes.focused,
              disabled: classes.disabled,
              multiline: classes.multiline,
            },
          }}
          key={slug}
          label={label}
          multiline
          onBlur={(event) => {
            setExpanded(false)
            onBlur && onBlur(event.target.value)
          }}
          onChange={(event) => onChange(slug, event.target.value)}
          onFocus={() => handleFocus()}
          value={value}
          variant={FORM_VARIANT}
          error={error}
          helperText={helperText}
        />
        {isMultiLine && (
          <div className={classes.showAll}>
            <FormatLineSpacingRounded fontSize={"small"} />
          </div>
        )}
      </div>
    </>
  )
}
export default TextInput
