import { Breadcrumb } from "providers/Navigator"

export const GLOBAL_BANNER = {
  copy:
    "The Bail Fund App will be down for maintenance. The app will not be accessible at this time.",
  isActivated: false,
}

export function getCrumb(
  breadcrumbs: Breadcrumb[],
  idxFromEnd: number,
  selector = (x: any) => x
) {
  const crumb = breadcrumbs.slice(idxFromEnd)[0]
  if (crumb) {
    return selector(crumb)
  }
}
