import { ERROR_SIGNING_TOS } from "lib/errorMessages"
import { ErrorGeneric } from "components/common/errors/ErrorGeneric"
import { Initializing } from "components/common/Initializing/Initializing"
import { useCurrentOrganization } from "providers/CurrentOrganizationProvider"
import { useSignDocsMutation } from "generated/api"
import PrivacyPolicyAgreement from "components/core/RequirePrivacyPolicyAgreement/PrivacyPolicyAgreement"
import React, { FC, useEffect, useReducer } from "react"

type State =
  | "accepted" // Organization shows that TOS has been accepted.
  | "error" // something went wrong when sending the API call.
  | "needs-acceptance" // customer must click button to proceed.
  | "waiting" // we're waiting for Organization to reflect signing.

type Action =
  | "api-call-failed" // Urql reported a problem with the API call.
  | "clicked" // customer has clicked button.
  | "confirmed" // Organization signing status has changed from false to true.

const reducer = (state: State, action: Action): State => {
  switch (action) {
    case "clicked":
      return "waiting"
    case "confirmed":
      return "accepted"
    case "api-call-failed":
      return "error"
  }
}

/**
 * RequirePrivacyPolicyAgreement ensures that the user has accepted the
 * terms of use before any children components are rendered.
 */
export const RequirePrivacyPolicyAgreement: FC = ({ children }) => {
  const { organization } = useCurrentOrganization()
  const [{ error }, sendSignLegalDocs] = useSignDocsMutation()
  const [state, send] = useReducer(
    reducer,
    organization.legalDocsSigned ? "accepted" : "needs-acceptance"
  )

  useEffect(() => {
    if (state === "waiting" && organization.legalDocsSigned) {
      send("confirmed")
    }
  }, [organization.legalDocsSigned, state])

  useEffect(() => {
    if (error) {
      send("api-call-failed")
    }
  }, [error])

  const handleClick = () => {
    send("clicked")
    sendSignLegalDocs({}, { additionalTypenames: ["User", "Organization"] })
  }

  switch (state) {
    case "accepted":
      return <>{children}</>
    case "error":
      return <ErrorGeneric message={ERROR_SIGNING_TOS} />
    case "needs-acceptance":
      return <PrivacyPolicyAgreement signLegalDocs={handleClick} />
    case "waiting":
      return <Initializing />
  }
}
