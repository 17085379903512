import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles"
import { TableCell as CustomTableCell } from "../TableCell/TableCell"
import { Query, Row, UiFieldsTable } from "components/common/TabularView/types"
import { tabularViewStyles } from "styles/theme"
import range from "lodash/range"
import React, { useEffect, useState } from "react"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

/**
 * The number of "loading" rows and columns to display when in a loading state.
 *
 * TODO: we would capture the last known good # of rows and columns so that
 * the blurs would appear in-place without visual jumps. However, the current
 * CSS implementation causes the blur table to be placed higher on the page
 * than the data table, so that would need to be fixed at the same time.
 */
const LOADING_ROWS_COLS = { rows: 10, cols: 10 }
interface LoadingRowsCols {
  rows: number
  cols: number
}

const useStyles = makeStyles(tabularViewStyles)

interface CustomRowProps {
  order: string[]
  row: Row
  uiFieldsTable: UiFieldsTable
}

const CustomRow = ({ uiFieldsTable, order, row }: CustomRowProps) => {
  const classes = useStyles()

  return (
    <>
      {order.map((slug, i) => {
        const field = uiFieldsTable.get(slug)

        return (
          <TableCell key={i} className={classes.tableCell}>
            <CustomTableCell field={field} row={row} />
          </TableCell>
        )
      })}
    </>
  )
}

const LoadingTable = ({ rows, cols }: LoadingRowsCols) => {
  const classes = useStyles()
  return (
    <>
      {range(rows + 1).map((
        rowIdx //added +1 to rows so that the Table Header is visible on rows that do not have filter results
      ) => (
        <TableRow
          key={rowIdx}
          hover={false}
          className={classes.tableRowLoading}
        >
          {range(cols).map((colIdx) => (
            <TableCell key={colIdx} className={classes.tableCell}>
              <div className={classes.loading} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#f0f3ff",
      },
    },
  })
)(TableRow)

interface TableRowsProps {
  handleRowClick: (click: React.MouseEvent<HTMLElement>, row: Row) => void
  query: Query
  rows: Row[] | null // The rows to display, or null to display blurred rows as a loading indicator.
  uiFieldsTable: UiFieldsTable
}

const TableRows = ({
  handleRowClick,
  query: { order },
  rows,
  uiFieldsTable,
}: TableRowsProps) => {
  const [loadingRowsCols, setLoadingRowsCols] = useState<LoadingRowsCols>(
    LOADING_ROWS_COLS
  )
  const classes = useStyles()
  useEffect(() => {
    if (rows != null) {
      setLoadingRowsCols({ rows: rows.length, cols: order.length })
    }
  }, [rows, order])
  if (rows === null) {
    return (
      <LoadingTable rows={loadingRowsCols.rows} cols={loadingRowsCols.cols} />
    )
  }
  return (
    <>
      {rows.map((row, rowIndex) => (
        <StyledTableRow
          key={rowIndex}
          hover={true}
          className={classes.tableRow}
          onClick={(click) => handleRowClick(click, row)}
        >
          <CustomRow order={order} row={row} uiFieldsTable={uiFieldsTable} />
        </StyledTableRow>
      ))}
    </>
  )
}

export default TableRows
