import axios, { AxiosInstance, AxiosRequestConfig } from "axios"

import { API_BASE_URL } from "lib/settings"
import {
  HTTP_RESPONSE_VERSION_HEADER,
  useServerVersion,
} from "providers/ServerVersionProvider"
import { useAuth0 } from "@auth0/auth0-react"
import { useCurrentOrganization } from "providers/CurrentOrganizationProvider"
import React, { createContext, FC, useContext, useMemo } from "react"

const AxiosClientContext = createContext<AxiosInstance | null>(null)

export const useAxiosClient = () => {
  const context = useContext(AxiosClientContext)
  if (context === null) {
    throw Error("useAxiosClient must be wrapped in an AxiosClientProvider")
  }
  return context
}

const createApiClient = (
  asyncTokenFunction: () => Promise<string>,
  setObservedVersion: (version: string) => void,
  bailFundSlug: string | number
) => {
  const axiosConfig: AxiosRequestConfig = {
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
    validateStatus: (status: number) => status === 200,
  }
  const instance = axios.create(axiosConfig)
  instance.interceptors.request.use(async (options) => {
    const authToken = await asyncTokenFunction()
    options.headers["Authorization"] = `Bearer ${authToken}`
    options.headers["x-bfa-org"] = bailFundSlug
    return options
  })

  instance.interceptors.response.use(
    (response) => {
      const serverVersion = response.headers[HTTP_RESPONSE_VERSION_HEADER]
      if (serverVersion) {
        setObservedVersion(serverVersion)
      }
      return response
    },
    (error) => {
      console.log("axios error: ", error)
      // Treat Django Rest Framework errors in a unique way.
      if (error.response.data && error.response.data.error) {
        return Promise.reject(error.response.data.error)
      }
      return Promise.reject(error)
    }
  )

  return instance
}

export const AxiosClientProvider: FC = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()
  const {
    organization: { bailFundSlug },
  } = useCurrentOrganization()
  const { setObservedVersion } = useServerVersion()

  const client = useMemo(
    () =>
      createApiClient(getAccessTokenSilently, setObservedVersion, bailFundSlug),
    [getAccessTokenSilently, setObservedVersion, bailFundSlug]
  )

  return (
    <AxiosClientContext.Provider value={client}>
      {children}
    </AxiosClientContext.Provider>
  )
}
