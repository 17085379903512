import { Add } from "@material-ui/icons"
import { ReactComponent as AddFilterGroupSvg } from "./AddFilterGroup.svg"
import { advancedFiltersStyles } from "./styles"
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  SvgIcon,
} from "@material-ui/core"
import React, { FC } from "react"

const useStyles = makeStyles(advancedFiltersStyles)

interface Props {
  level: number
  disabled: boolean
  onAddFilterClick: () => void
  onAddFilterGroupClick: () => void
}

const AddFiltersButtonGroup: FC<Props> = ({
  level,
  disabled,
  onAddFilterClick,
  onAddFilterGroupClick,
}) => {
  const classes = useStyles({ level })

  const showIconButtons = level !== 0
  const hideAddFilterGroupButton = level >= 2

  const addFilterButton = (
    <Button
      aria-label={`add_filter_level_${level}`}
      classes={{
        root: classes.addFilterButton,
      }}
      disabled={disabled}
      disableElevation
      onClick={onAddFilterClick}
      startIcon={<Add />}
      variant="outlined"
    >
      Add Filter
    </Button>
  )

  const addFilterIconButton = (
    <IconButton
      aria-label={`add_filter_level_${level}`}
      className={classes.addFilterIconButton}
      disabled={disabled}
      onClick={onAddFilterClick}
      size="small"
    >
      <Add fontSize="small" />
    </IconButton>
  )

  const addFilterGroupButton = (
    <Button
      aria-label={`add_filter_group_level_${level}`}
      classes={{
        root: classes.addFilterButton,
      }}
      disabled={disabled}
      disableElevation
      onClick={onAddFilterGroupClick}
      startIcon={<SvgIcon component={AddFilterGroupSvg} />}
      variant="outlined"
    >
      Add Filter Group
    </Button>
  )

  const addFilterGroupIconButton = (
    <IconButton
      aria-label={`add_filter_group_level_${level}`}
      className={classes.addFilterIconButton}
      disabled={disabled}
      onClick={onAddFilterGroupClick}
      size="small"
    >
      <SvgIcon component={AddFilterGroupSvg} fontSize="small" />
    </IconButton>
  )

  return (
    <Grid alignItems="flex-start" container justify="flex-end" wrap="nowrap">
      {showIconButtons ? (
        <>
          {!hideAddFilterGroupButton && addFilterGroupIconButton}
          {addFilterIconButton}
        </>
      ) : (
        <>
          {!hideAddFilterGroupButton && addFilterGroupButton}
          {addFilterButton}
        </>
      )}
    </Grid>
  )
}

export default AddFiltersButtonGroup
