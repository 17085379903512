import {
  Collection,
  DocumentCreateMutation,
  DocumentCreateMutationVariables,
  DocumentGetDocument,
  DocumentOperationInput,
  DocumentUpdateMutation,
  DocumentUpdateMutationVariables,
  useDocumentCreateMutation,
  useDocumentUpdateMutation,
} from "generated/api"
import { Collections } from "lib/Collections"
import { ErrorFormMissing } from "components/common/errors/ErrorFormMissing"
import {
  FieldValuesMap,
  Handlers as FormHandlers,
  HookMap,
  OnChangeDateTimeType,
  OnChangeDateType,
  OnChangeMultiselectType,
  OnChangeType,
  RemoveHooksType,
  ReplaceHooksType,
  ValidateDateTimeType,
  ValidateDateType,
  ValidateIntegerType,
  ValidationErrorsMap,
  VisibleFieldValuesMap,
} from "lib/forms/types"
import { FieldValueType, objectToFields, toUseful } from "lib/apihelpers"
import {
  fillPdf,
  shouldShowGeneratePdfButton,
} from "components/core/DetailPage/pdf"
import { GeneratedFormState } from "lib/forms/formLayoutToJsxConverter"
import {
  makeDateTimeValidator,
  makeDateValidator,
  makeIntegerValidator,
  toggleMultiselectValue,
} from "components/core/FlexForm/helpers"
import { MessageType, useSnackbar } from "providers/Snackbar"
import { OperationResult, useClient } from "urql"
import { useAxiosClient } from "providers/AxiosClientProvider"
import { useCollectionOrNull } from "providers/CurrentOrganizationProvider"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import { useDocumentDetails } from "providers/DocumentDetailsProvider"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useNavigator } from "providers/Navigator"
import _ from "lodash"
import Detail from "components/core/DetailPage/Detail"
import moment from "moment"
import React, { useCallback, useEffect, useMemo, useState } from "react"

const SLUG_FOR_DETAILS_FORM = "details"
const SLUG_CONTACTS_COLLECTION = Collections.CONTACTS

const createTitleFromDocument = (
  document: FieldValuesMap,
  titleField: string
) => {
  const titleValue = document[titleField]
  if (typeof titleValue === "undefined" || titleValue === null) {
    return ""
  }
  return String(titleValue)
}

const useQueryParams = () => {
  return new URLSearchParams(window.location.search)
}

const getSummaryTitleField = (
  summaryTitleField: string,
  collection: Collection
) => {
  if (summaryTitleField) {
    return summaryTitleField
  } else if (collection.slug === SLUG_CONTACTS_COLLECTION) {
    return "name"
  }
  return "client_name"
}

const DetailPage = () => {
  const history = useHistory()
  const location = useLocation<{ newRequestData: { [key: string]: string } }>()
  const { documentId } = useParams() as any
  const closeOnSave = useQueryParams().get("closeOnSave") === "true"
  const [registeredHandlers, setRegisteredHandlers] = useState<HookMap>({})
  const { getUrlForDocument } = useCurrentCollection()
  const urql = useClient()
  const axiosClient = useAxiosClient()
  const { id: contactsCollectionId } = useCollectionOrNull(
    Collections.CONTACTS
  ) || { id: null }

  const replaceHooks: ReplaceHooksType = React.useCallback(
    (
      key,
      createHandler,
      updateHandler,
      validationErrorsHandler,
      changesMadeHandler
    ) =>
      setRegisteredHandlers((handlers) => ({
        ...handlers,
        [key]: {
          createHandler,
          updateHandler,
          validationErrorsHandler,
          changesMadeHandler,
        },
      })),
    [setRegisteredHandlers]
  )

  const removeHooks: RemoveHooksType = React.useCallback(
    (key) =>
      setRegisteredHandlers((prevState) => {
        delete prevState[key]
        return prevState
      }),
    [setRegisteredHandlers]
  )

  const isUserActionRequiredBeforeSave = React.useCallback(
    (validationErrors: ValidationErrorsMap) => {
      let validationErrorsHandlerString = ""
      for (const handlers of Object.values(registeredHandlers)) {
        validationErrorsHandlerString = handlers.validationErrorsHandler(
          validationErrorsHandlerString
        )
      }
      if (
        Object.entries(validationErrors).length > 0 ||
        validationErrorsHandlerString !== ""
      ) {
        alert(
          "Can't Save. Please fix validation errors first.\n\n" +
            Object.values(validationErrors)
              .map((v) => `${v.label} - ${v.helperText}`)
              .join("\n") +
            "\n\n" +
            validationErrorsHandlerString
        )
        return true
      }
      return false
    },
    [registeredHandlers]
  )

  const { document: requestDocument } = useDocumentDetails()

  // empty object for new (unsaved) requests, or populated with default values. never null.
  const [persistedDocState, setPersistedDocState] = useState<FieldValuesMap>({})
  // always null until the user edits a field
  const [modifiedDocState, setModifiedDocState] = useState<FieldValuesMap>({})
  const [primaryDocument, setPrimaryDocument] = useState(requestDocument)
  const [disableForm, setDisableForm] = useState(false)
  const [pageTitle, setPageTitle] = useState<string>("")

  const [validationErrors, setValidationErrors] = useState<ValidationErrorsMap>(
    {}
  )
  const [allCardsExpanded, setAllCardsExpanded] = useState(true)

  const { collection } = useCurrentCollection()
  const formDescriptor = collection.forms!.find(
    (form) => form.slug === SLUG_FOR_DETAILS_FORM
  )
  const summaryTitleField = getSummaryTitleField(
    formDescriptor.summary_title_field,
    collection
  )

  const hasPdf = shouldShowGeneratePdfButton(collection, formDescriptor)
  const { openSnackbar, closeSnackbar } = useSnackbar()
  const { setDocumentTitle, setBreadcrumbs } = useNavigator()

  const [, sendDocumentCreate] = useDocumentCreateMutation()
  const [, sendDocumentUpdate] = useDocumentUpdateMutation()

  useEffect(() => {
    // If we're adding a new document, set a breadcrumb so that the
    // button in the upper left turns into a back button instead of a
    // hamburger.
    if (!documentId) {
      setBreadcrumbs([{ name: collection.title }])
      if (location.state && location.state.newRequestData) {
        setModifiedDocState(location.state.newRequestData)
        return
      }
    }

    const state = Object.fromEntries(requestDocument!.fields)

    // persisted persistedDocState is populated only once when the component first
    // renders. It tracks the initial form input, so we don't want to overwrite the
    // user provided values.
    if (_.isEmpty(persistedDocState)) {
      setPersistedDocState(state)
      const title = createTitleFromDocument(state, summaryTitleField)
      setPageTitle(title)
      setDocumentTitle(title)
      setBreadcrumbs([
        {
          name: title,
        },
      ])
    }
  }, [
    collection,
    documentId,
    location,
    persistedDocState,
    requestDocument,
    setBreadcrumbs,
    setDocumentTitle,
    summaryTitleField,
  ])

  const handleChange: OnChangeType = useCallback(
    (slug, value) => {
      if (persistedDocState[slug] === value) {
        setModifiedDocState((prevState) => {
          const { [slug]: unused, ...remaining } = prevState
          return remaining
        })
      } else {
        setModifiedDocState((prevState) => ({
          ...prevState,
          [slug]: value,
        }))
      }
    },
    [persistedDocState, setModifiedDocState]
  )

  const handleChangeDate: OnChangeDateType = useCallback(
    (slug, value) => {
      if (typeof value === "undefined" || value === null) {
        handleChange(slug, null)
        return
      }
      // The input component manages dates in MM/DD/YYYY format, but we want
      // the backend to see YYYY-MM-DD. So, when the date is valid, we convert
      // it to YYYY-MM-DD. This means that the modifiedDocState can have a mix
      // of valid and invalid formats in it.
      const converted = moment(value, "M/D/YYYY", true)
      value = converted.isValid() ? converted.format("YYYY-MM-DD") : value
      handleChange(slug, value)
    },
    [handleChange]
  )

  const handleChangeDateTime: OnChangeDateTimeType = useCallback(
    (slug, value) => {
      if (typeof value === "undefined" || value === null) {
        handleChange(slug, null)
        return
      }
      const converted = moment(value, "MM/DD/YYYY hh:mm a", true)
      value = converted.isValid() ? converted.toISOString() : value
      handleChange(slug, value)
    },
    [handleChange]
  )

  const onSaveBailRequestResponse = useCallback(
    (
      response:
        | OperationResult<
            DocumentCreateMutation,
            DocumentCreateMutationVariables
          >
        | OperationResult<
            DocumentUpdateMutation,
            DocumentUpdateMutationVariables
          >
    ) => {
      if (response.error) {
        openSnackbar(
          `Failed to save changes: ${response.error.message}`,
          MessageType.Error
        )
        return
      }
      const updatedDocument = response.data!.documents!.documents.find(
        (document) => document!.collectionId === collection.id
      )!
      const doc = toUseful(updatedDocument)
      setPrimaryDocument(doc)
      const state = Object.fromEntries(doc.fields)
      setPersistedDocState(state)
      setModifiedDocState({})
      const title = createTitleFromDocument(state, summaryTitleField)
      setDocumentTitle(title)
      setPageTitle(title)
      setBreadcrumbs([
        {
          name: title,
        },
      ])
      const documentType =
        collection.slug === SLUG_CONTACTS_COLLECTION
          ? "contact"
          : "bail request"
      openSnackbar(
        `Successfully saved the ${documentType}${title ? ` for ${title}` : ""}.`
      )
      // If we don't have a documentId, then we just created a new document,
      // so we redirect the user to a URL that contains the new doc ID.
      if (!documentId) {
        history.replace(getUrlForDocument(doc.id))
      }

      if (closeOnSave) {
        window.close()
      }
    },
    [
      collection.slug,
      collection.id,
      summaryTitleField,
      setDocumentTitle,
      setBreadcrumbs,
      openSnackbar,
      documentId,
      closeOnSave,
      history,
      getUrlForDocument,
    ]
  )

  // Saves the document.
  //
  // Saves will not occur if there are any outstanding validation errors. If
  // there are validation errors, this method returns false immediately.
  //
  // If there are no validation errors, the appropriate API call will be sent
  // to the backend. The return value will be the standard Urql API responses,
  // which may indicate success or failure.
  const handleSaveBailRequest = useCallback(async () => {
    if (isUserActionRequiredBeforeSave(validationErrors)) {
      return false
    }
    setDisableForm(true)

    const sendCreate = async () => {
      let variables: DocumentCreateMutationVariables = {
        collectionId: collection.id,
        fields: objectToFields(modifiedDocState),
        children: [],
      }
      for (const handlers of Object.values(registeredHandlers)) {
        variables = handlers.createHandler(variables)
      }
      return sendDocumentCreate(variables)
    }

    const sendUpdate = async () => {
      let operations: DocumentOperationInput[] = [
        {
          updateDocument: {
            id: documentId,
            collectionId: collection.id,
            fields: objectToFields(modifiedDocState),
          },
        },
      ]
      for (const handlers of Object.values(registeredHandlers)) {
        operations = handlers.updateHandler(documentId, operations)
      }
      return sendDocumentUpdate({ operations: operations })
    }

    const response = await (documentId ? sendUpdate() : sendCreate())
    onSaveBailRequestResponse(response)
    setDisableForm(false)
    return response
  }, [
    collection,
    documentId,
    isUserActionRequiredBeforeSave,
    modifiedDocState,
    onSaveBailRequestResponse,
    registeredHandlers,
    sendDocumentCreate,
    sendDocumentUpdate,
    setDisableForm,
    validationErrors,
  ])

  const handleChangeMultiselect: OnChangeMultiselectType = useCallback(
    (slug: string, value) =>
      setModifiedDocState((prevState) => {
        const updated = toggleMultiselectValue(
          (modifiedDocState[slug] ? modifiedDocState : persistedDocState)[slug],
          value
        )
        if (_.isEqual(persistedDocState[slug], updated)) {
          const { [slug]: unused, ...remaining } = prevState || {}
          return remaining
        }
        return {
          ...prevState,
          [slug]: updated,
        }
      }),
    [persistedDocState, modifiedDocState, setModifiedDocState]
  )

  const handleSavePDF = useCallback(async () => {
    if (isUserActionRequiredBeforeSave(validationErrors)) {
      return
    }

    const getStateWithMayBeSave = async () => {
      // avoiding extra call of save to network when the form is not changed.
      if (_.isEmpty(modifiedDocState)) {
        return persistedDocState
      }
      const response = await handleSaveBailRequest()
      if (!response) {
        return
      }
      const updatedDocument = response.data!.documents!.documents.find(
        (document) => document!.collectionId === collection.id
      )!
      const doc = toUseful(updatedDocument)

      const asObject: FieldValuesMap = Object.fromEntries(doc.fields)
      return asObject
    }

    const getContactName = async (state: FieldValuesMap) => {
      const suretyId = state["surety"]
      if (suretyId) {
        const contactLookup = await urql
          .query(DocumentGetDocument, {
            collectionId: contactsCollectionId,
            documentId: suretyId,
          })
          .toPromise()
        if (contactLookup.error) {
          openSnackbar(
            `Failed to fetch contact name: ${contactLookup.error}`,
            MessageType.Error
          )
          return
        }
        if (contactLookup.data === undefined) {
          openSnackbar(`Failed to fetch contact name`, MessageType.Error)
          // handle no result
          return
        }
        const contact = contactLookup.data.documents.documents[0].document
        return toUseful(contact).fields.get("name") as string
      } else {
        openSnackbar(
          `Warning: This bail request does not have a surety assigned.`
        )
        return ""
      }
    }

    const state = await getStateWithMayBeSave()
    if (!state) {
      openSnackbar("Oops! Save failed.", MessageType.Error)
      return
    }
    // We only ever have 1 pdf generated per fund, if we change that this will need to be selected somehow
    const pdfConfig = collection.pdfs![0]!

    // For the BAF we do a special mapping for the surety, which is in a related collection
    const isBAF = pdfConfig.title === "BAF Form"
    const contactName = isBAF ? (await getContactName(state)) || "" : ""

    try {
      const fileName =
        (createTitleFromDocument(state, summaryTitleField)
          .replace(/[^a-z0-9 -]/gi, "")
          .trim() || `${pdfConfig.title}-${state.id}`) + ".pdf"

      // if there is no contact name for BAF, we don't want to replace the warning message
      const displayPendingMessage = contactName || !isBAF
      displayPendingMessage && openSnackbar("Generating PDF...")
      const blob = await fillPdf(
        axiosClient,
        pdfConfig,
        collection.id,
        formDescriptor,
        fileName,
        state,
        contactName
      )
      displayPendingMessage && closeSnackbar()

      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
    } catch (err) {
      // TODO(ch10828)
      openSnackbar(`Failed to save PDF: ${err}`, MessageType.Error)
    }
  }, [
    axiosClient,
    closeSnackbar,
    collection,
    contactsCollectionId,
    formDescriptor,
    handleSaveBailRequest,
    isUserActionRequiredBeforeSave,
    modifiedDocState,
    openSnackbar,
    persistedDocState,
    summaryTitleField,
    urql,
    validationErrors,
  ])

  const integerValidationHandler: ValidateIntegerType = useCallback(
    (label, slug, value) =>
      setValidationErrors(makeIntegerValidator(label, slug, value)),
    [setValidationErrors]
  )

  const dateValidationHandler: ValidateDateType = useCallback(
    (label, slug, value) =>
      setValidationErrors(makeDateValidator(label, slug, value)),
    [setValidationErrors]
  )

  const dateTimeValidationHandler: ValidateDateTimeType = useCallback(
    (label, slug, value) =>
      setValidationErrors(makeDateTimeValidator(label, slug, value)),
    [setValidationErrors]
  )

  const visibleDocState: VisibleFieldValuesMap = useMemo(
    () => ({
      ...persistedDocState,
      ...modifiedDocState,
    }),
    [modifiedDocState, persistedDocState]
  )

  // getValueForSlug allows the child components access to the value of an arbitrary slug.
  // Most components will get their value from their `value` attribute coming down in InputElementFactory,
  // But some complex components (like contacts) may not be affiliated with a slug in the layout
  // And will need to access a value using a well-known slug (e.g. attorney_id).
  const getValueForSlug = useCallback(
    (slug: string): FieldValueType | undefined => visibleDocState[slug],
    [visibleDocState]
  )

  const formState: GeneratedFormState = useMemo(
    () => ({
      collectionId: collection.id,
      createdTimestamp: primaryDocument?.created,
      disableForm,
      documentId,
      documentState: visibleDocState,
      getValueForSlug,
      updatedTimestamp: primaryDocument?.updated,
      validationErrors,
    }),
    [
      collection.id,
      disableForm,
      documentId,
      getValueForSlug,
      primaryDocument,
      validationErrors,
      visibleDocState,
    ]
  )

  const formHandlers: FormHandlers = useMemo(
    () => ({
      onChange: handleChange,
      onChangeDate: handleChangeDate,
      onChangeDateTime: handleChangeDateTime,
      onChangeMultiselect: handleChangeMultiselect,
      onSaveBailRequest: handleSaveBailRequest,
      removeHooks,
      replaceHooks,
      validateDate: dateValidationHandler,
      validateDateTime: dateTimeValidationHandler,
      validateInteger: integerValidationHandler,
    }),
    [
      dateTimeValidationHandler,
      dateValidationHandler,
      handleChangeMultiselect,
      handleSaveBailRequest,
      integerValidationHandler,
      handleChangeDate,
      handleChangeDateTime,
      handleChange,
      removeHooks,
      replaceHooks,
    ]
  )

  // Only enable the save button when there are changes worth saving.
  const disableSave = useMemo(() => {
    const anyFieldChanged = Object.keys(modifiedDocState).length !== 0
    const subcollectionsChanged = Object.values(
      registeredHandlers
    ).some((handlers) => handlers.changesMadeHandler())
    return !(anyFieldChanged || subcollectionsChanged)
  }, [registeredHandlers, modifiedDocState])

  if (!formDescriptor) {
    return <ErrorFormMissing slug={SLUG_FOR_DETAILS_FORM} />
  }

  const handleExpandAllCards = () => {
    setAllCardsExpanded((prev) => !prev)
  }

  return (
    <>
      <Detail
        allCardsExpanded={allCardsExpanded}
        disableSave={disableSave}
        documentId={documentId}
        formDescriptor={formDescriptor}
        formHandlers={formHandlers}
        formPath={["root"]}
        formState={formState}
        onExpandAllCards={handleExpandAllCards}
        onSaveBailRequest={handleSaveBailRequest}
        onSavePDF={hasPdf ? handleSavePDF : null}
        pageTitle={pageTitle}
        saveChangesButtonLabel={closeOnSave ? "Save and Close" : "Save Changes"}
      />
    </>
  )
}

export default DetailPage
