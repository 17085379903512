import {
  Expression,
  Filter,
  UiFieldsTable,
  UiView,
  UiViewTable,
} from "components/common/TabularView/types"
import {
  FieldDefinition,
  FieldType,
  QueryOperator,
  SchemaViewExpressionOutput,
  ViewDefinitionOutput,
  ViewMode,
} from "generated/api"

const convertBackendFieldDefinitionsToMap = (
  fields: FieldDefinition[]
): Map<string, FieldDefinition> =>
  new Map<string, FieldDefinition>(
    fields
      .filter(({ fieldType }) => fieldType !== FieldType.ForeignKey)
      .map((fieldDef) => [fieldDef.slug, fieldDef])
  )

const convertBackendExpressionToUiExpression = (
  expression: SchemaViewExpressionOutput
): Expression => ({
  operator: expression.operator,
  filters: expression.filters
    ? expression.filters.map(
        (filt): Filter => ({
          field: filt.field,
          operation: filt.operation,
          operands: filt.operands,
        })
      )
    : null,
  expressions: expression.expressions
    ? expression.expressions.map(convertBackendExpressionToUiExpression)
    : null,
})

const convertBackendViewToUiView = (
  fieldsBySlug: Map<string, FieldDefinition>,
  view: ViewDefinitionOutput
) => {
  const {
    fields: viewFields,
    order: viewOrder,
    mode: viewMode,
    expression: viewExpression,
  } = view.schema
  // Some incarnations of the UI saved all known slugs in the `order` field.
  // Our newer implementations only allow ordering of visible fields; therefore,
  // we only need to retain the ordering of the fields in `order` that are also
  // in `fields`.
  const visibleFields = new Set(viewFields)
  const visibleOrder = viewOrder.filter((slug) => visibleFields.has(slug))

  // For advanced filters, it would always have some filters/expressions other than order_by, but for simple filters,
  // it could have just one order_by filter, in which case we should consider no filters are applied from UI point of view
  const mode =
    viewMode === ViewMode.Advanced
      ? viewMode
      : viewExpression?.filters?.some(
          (filt) => filt.operation !== QueryOperator.OrderBy
        )
      ? viewMode
      : null

  const uiView: UiView = {
    id: view.id,
    default: view.default,
    name: view.name,
    query: {
      mode,
      expression: viewExpression
        ? convertBackendExpressionToUiExpression(viewExpression)
        : viewExpression,
      fields: viewFields.map((slug) => ({ slug })),
      order: visibleOrder,
    },
  }
  return [uiView.id, uiView]
}

const convertBackendViewsToUiViewTable = (
  views: ViewDefinitionOutput[],
  fieldsBySlug: UiFieldsTable
): UiViewTable => {
  return Object.fromEntries(
    views.map((view) => convertBackendViewToUiView(fieldsBySlug, view))
  )
}

export const convertBackendSchemaToTables = (
  views: ViewDefinitionOutput[],
  fields: FieldDefinition[]
) => {
  // todo: update export to `fieldsTable` & `viewsTable`
  const dataFieldsMap = convertBackendFieldDefinitionsToMap(fields)
  return {
    uiFieldsTable: dataFieldsMap,
    uiViewsTable: convertBackendViewsToUiViewTable(views, dataFieldsMap),
  }
}
