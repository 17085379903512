import { AxiosInstance } from "axios"
import { makeFailure, makeSuccess, reportError } from "lib/result"

// The name of the HTTP request header that specifies the collection ID (or
// slug) that the API should operate on. This should match the value in the
// equivalent attachments.py constant.
const HTTP_X_COLLECTION = "X-collection"

// The name of the HTTP parameter containing the contents of the file.
// This should match the value in the equivalent attachments.py constant.
const ATTACHMENT_POST_PARAM = "fileAttachmentData"

// The name of the HTTP parameter containing the ID of the attachment.
// This should match the value in the equivalent attachments.py constant.
const ATTACHMENT_ID_PARAM = "attachmentId"

// Implements the legacy API methods. axiosClient must be preconfigured to send
// headers appropriate for the current user.
export const AttachmentsApi = (axiosClient: AxiosInstance) => {
  return Object.freeze({
    uploadAttachment: async (
      collectionId: string,
      documentId: string,
      attachment: File
    ) => {
      try {
        const formData = new FormData()
        formData.append(ATTACHMENT_POST_PARAM, attachment)
        const {
          data: { request: result },
        } = await axiosClient.post(`/attachments/${documentId}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            [HTTP_X_COLLECTION]: collectionId,
          },
        })
        return makeSuccess(result)
      } catch (error) {
        reportError(error)
        return makeFailure(error)
      }
    },
    deleteAttachment: async (
      collectionId: string,
      documentId: string,
      attachmentId: number
    ) => {
      try {
        const {
          data: { request: result },
        } = await axiosClient.delete(`/attachments/${documentId}/`, {
          data: {
            [ATTACHMENT_ID_PARAM]: attachmentId,
          },
          headers: {
            [HTTP_X_COLLECTION]: collectionId,
          },
        })
        return makeSuccess(result)
      } catch (error) {
        reportError(error)
        return makeFailure(error)
      }
    },
  })
}
