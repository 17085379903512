import moment from "moment"

const EMDASH = "\u2014"

const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

// Converts 56324 to "$56,342"
export function formatCurrency(value, fractionDigits = null) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fractionDigits || 0,
    maximumFractionDigits: fractionDigits || 0,
  })
  return formatter.format(value)
}

// Converts 0.3 to "30%"
export function formatPercentage(value, sigFig = null) {
  const options = sigFig ? { maximumSignificantDigits: sigFig } : {}
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    ...options,
  })
  return formatter.format(value)
}

// Converts 56324 to "56,324"
export function formatNumber(value, formatSettings) {
  if (isNaN(value)) {
    return EMDASH
  }
  const formatter = new Intl.NumberFormat("en-US", formatSettings || {})
  return formatter.format(value)
}

const MM = 1000000
const K = 1000
export function formatNumberAbbrev(value) {
  if (value / MM > 1) {
    return `${formatNumber(value / MM)}MM`
  }
  if (value / K > 1) {
    return `${formatNumber(value / K)}k`
  }
  return formatNumber(value)
}

export function formatTimestamp(timestamp) {
  const parsed = moment(timestamp)
  return parsed.format("M/D/YYYY h:mmA")
}

// Converts 20180130 to 1/30/2018
export function formatDate(value) {
  return moment(value.toString(), "YYYYMMDD").format("M/D/YYYY")
}

export function format8601Date(
  iso8601DateString,
  withTime = true,
  formatDateString = "MM/DD/YYYY"
) {
  const formatString = withTime ? `${formatDateString} h:mmA` : formatDateString
  return moment(iso8601DateString).format(formatString)
}

export function fromNow(iso8601DateString, noSuffix = false) {
  return moment(iso8601DateString).fromNow(noSuffix)
}

export function getMonthName(monthNumber) {
  return MONTH_NAMES[parseInt(monthNumber, 10) - 1]
}

export const getIsoDateString = ({ month, year, start }) => {
  const startDate = month ? [year, month - 1] : [year]
  let resultDate
  if (start) {
    resultDate = moment(startDate).startOf(month ? "month" : "year")
  } else {
    resultDate = moment(startDate).endOf(month ? "month" : "year")
  }
  return resultDate.utc().toISOString()
}
