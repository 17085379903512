import { createMuiTheme, createStyles, Theme } from "@material-ui/core/styles"
import { ENVIRONMENT } from "lib/settings"
import { formikEditorStyles } from "components/core/CollectionEditor/FormikEditors/styles"

export const BODY_FONT = ['"Roboto"', "sans-serif"].join(",")
const COLOR_BY_ENVIRONMENT: { [key: string]: string } = {
  development: "#F8E71C",
  staging: "#F5A623",
  production: "#FF414B",
}

export const UI_COLORS = {
  white: "#ffffff",
  black: "#000000",
  black_900: "#202123",
  black_700: "#61646B",
  black_600: "#73767D",
  black_500: "#AFB1B6",
  black_400: "#CACBCE",
  black_200: "#E4E5E7",
  black_100: "#F2F2F3",
  black_50: "#FAFAFA",
  blue_900: "#2C65FA", // primary
  blue_50: "#EEF3FF",
  red_900: "#E85B52",
  yellow_900: "#F6CE67",
}

export const getEnvironmentColor = () => COLOR_BY_ENVIRONMENT[ENVIRONMENT]

export const getTheme = (theme: Theme) =>
  createMuiTheme({
    palette: {
      type: "light",
      primary: {
        light: UI_COLORS.black_50,
        main: UI_COLORS.blue_900,
      },
      secondary: {
        light: "#FF7977",
        main: "#FF414B",
      },
    },
    typography: {
      fontFamily: BODY_FONT,
      h1: {
        fontSize: "20px",
        fontWeight: 500,
      },
      h2: {
        fontSize: "60px",
        fontWeight: 300,
      },
      h3: {
        fontWeight: "normal",
        fontSize: "48px",
      },
      h4: {
        fontSize: "34px",
        fontWeight: "normal",
      },
      h5: {
        fontWeight: 500,
        fontSize: "24px",
      },
      h6: {
        fontSize: "20px",
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: "16px",
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: "14px",
        fontWeight: 500,
      },
      body1: {
        fontSize: "16px",
        fontWeight: "normal",
      },
      body2: {
        fontSize: "14px",
        fontWeight: "normal",
      },
      caption: {
        fontSize: "12px",
        fontWeight: "normal",
      },
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    overrides: {
      MuiContainer: {
        root: {
          [theme.breakpoints.down("sm")]: {
            margin: 0,
            padding: 0,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: theme.palette.common.white,
        },
      },
      MuiAppBar: {
        colorPrimary: {
          color: "inherit",
          backgroundColor: UI_COLORS.white,
        },
        root: {
          flexGrow: 1,
          padding: "4px 0",
          boxShadow: "inset 0px -1px 0px rgba(33, 33, 33, 0.06)",
        },
      },
      MuiFilledInput: {
        root: {
          "backgroundColor": "#F6F6F6",
          "&$disabled": {
            backgroundColor: "#F6F6F6",
            color: "rgba(0, 0, 0, 0.38)",
          },
        },
        underline: {
          "&:after": {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            fontWeight: "bolder",
          },
          "&:before": {
            borderBottom: "1px solid #777879",
          },
        },
      },
    },
  })

export const bailFundAppStyles = () =>
  createStyles({
    root: {
      minHeight: "100vh",
    },
  })

export const privacyPolicyAgreementStyles = () =>
  createStyles({
    root: {
      minHeight: "100vh",
    },
    legalContainer: {
      boxShadow: "rgba(0, 0, 0, 0.12) 0px 12px 40px",
      maxWidth: 400,
    },
    legalTitle: {
      fontFamily:
        "ulp-font, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, sans-serif",
      fontSize: 24,
      fontWeight: 400,
      textAlign: "center",
    },
    legalBody: {
      fontFamily:
        "ulp-font, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, sans-serif",
      fontSize: 14,
      textAlign: "center",
    },
    legalLink: {
      color: "#0a84ae",
      textDecoration: "none",
      fontWeight: 600,
      fontSize: 16,
    },
    legalButton: {
      textTransform: "none",
      padding: "10px 0",
      fontSize: 16,
    },
  })

export const environmentChipStyles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: theme.typography.pxToRem(12),
      marginLeft: theme.spacing(2),
      padding: 0,
      backgroundColor: getEnvironmentColor(),
      color: theme.palette.common.black,
    },
  })

export const genericErrorStyles = (theme: Theme) =>
  createStyles({
    wrapper: {
      margin: "0 auto",
      marginTop: "150px",
      width: "1000px",
      textAlign: "center",
    },
    heading: {
      fontWeight: "bold",
      fontSize: "28px",
      marginBottom: "12px",
    },
    links: {
      marginTop: "12px",
    },
    link: {
      fontWeight: theme.typography.fontWeightBold,
      color:
        theme.palette.type === "light" ? theme.palette.primary.main : "inherit",
      cursor: "pointer",
    },
  })

export const tabularViewStyles = (theme: Theme) =>
  createStyles({
    table: {
      marginBottom: "44px",
    },
    tableWrapper: {
      height: "100%",
      marginTop: "0",
      width: "fit-content",
    },
    tableWrapperLoading: {
      overflow: "hidden",
    },
    tableRow: {
      cursor: "pointer",
    },
    tableRowLoading: {
      cursor: "inherit",
    },
    tableHeaderCell: {
      "maxWidth": "320px",
      "padding": "12px 16px 10px 16px",
      "backgroundColor": UI_COLORS.white,
      "left": "auto",
      "& span:first-of-type": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "fit-content",
        maxWidth: "288px",
      },
      "&:last-of-type": {
        width: "100vw",
        maxWidth: "100vw",
      },
    },
    tableHeaderText: {
      "maxWidth": "288px",
      "display": "flex",
      "flexDirection": "row",
      "& svg": {
        "margin-right": "0px",
        "margin-left": "20px",
      },
    },
    tableCell: {
      padding: "12px 16px 11px 16px",
    },
    tableCellText: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "fit-content",
      maxWidth: "288px",
    },
    tablePagination: {
      backgroundColor: UI_COLORS.white,
      bottom: 0,
      left: 0,
      position: "fixed",
      width: "100vw",
    },
    loading: {
      height: 20,
      width: 100,
      background: `linear-gradient(to right, ${
        theme.palette.grey[theme.palette.type === "light" ? 100 : 600]
      }, ${theme.palette.grey[theme.palette.type === "light" ? 300 : 800]}, ${
        theme.palette.grey[theme.palette.type === "light" ? 100 : 600]
      })`,
      backgroundSize: "600% 600%",
      animationName: "table-loading-row",
      animationDuration: "1s",
      animationIterationCount: "infinite",
      animationTimingFunction: "linear",
      animationFillMode: "forwards",
    },
    noRowsGrid: {
      minHeight: theme.spacing(6),
    },
    paper: {
      width: "100%",
    },
  })

export const downloadFormatDialogStyles = () =>
  createStyles({
    radio: {
      "&$checked": {
        color: UI_COLORS.black,
      },
    },
    checked: {},
  })

export const addRequestDialogStyles = () =>
  createStyles({
    addRequestButton: {
      marginBottom: "15px",
      marginRight: "15px",
    },
    dialogContent: {
      height: "215px",
    },
  })

export const requestDetailPageStyles = (theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    cardTitle: {
      margin: theme.spacing(1),
    },
    iconButton: {
      padding: 0,
    },
    listItem: {
      paddingRight: 0,
    },
    loading: {
      height: "80vh",
    },
    notes: {
      width: "100%",
    },
    root: {
      margin: "26px",
      marginTop: theme.spacing(8),
    },
    title: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      margin: theme.spacing(1),
      marginLeft: 0,
    },
    titleRightCol: {
      textAlign: "right",
    },
  })

export const detailPageStyles = (theme: Theme) =>
  createStyles({
    button: {
      marginLeft: "14px",
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    card: {
      width: "100%",
      margin: theme.spacing(1),
    },
    cardContainer: {
      margin: "0 auto",
      maxWidth: "1495px",
      paddingLeft: "24px",
      paddingRight: "24px",
    },
    cardTitle: {
      margin: theme.spacing(1),
    },
    expanded: {}, // leave empty as per docs https://material-ui.com/components/accordion/#customized-accordions
    expansionPanel: {
      "margin": "0 auto",
      "maxWidth": "620px",
      "&$expanded": {
        margin: "0 auto",
        maxWidth: "620px",
      },
    },
    expansionPanelBox: {
      margin: "12px 7px 24px 7px",
      minWidth: "585px",
      padding: "0px 5px",
    },
    expansionPanelDetails: {
      padding: "4px 16px 28px 16px",
    },
    expansionPanelSummary: {
      "fontSize": "16px",
      "fontWeight": 500,
      "height": "56px",
      "paddingLeft": "16px",
      "paddingRight": "18px",
      "&$expanded": {
        minHeight: 0,
        paddingLeft: "16px",
        paddingRight: "18px",
      },
    },
    formControl: {
      flexGrow: 1,
      margin: theme.spacing(1),
      minWidth: 150,
    },
    formControlFull: {
      flexGrow: 1,
      margin: theme.spacing(1),
      width: "100%",
    },
    formJsxOuter: {
      flexGrow: 1,
    },
    horizontalCenter: {
      margin: "0 auto",
    },
    horizontalRule: {
      marginTop: "30px",
      marginBottom: "12px",
      width: "100%",
    },
    horizontalRuleTypography: {
      marginLeft: "8px",
      fontWeight: 600,
    },
    horizontalRuleDivider: {
      marginTop: "5px",
      background: "#757575",
    },
    loading: {
      height: "80vh",
    },
    menu: {
      width: 200,
    },
    nestedFormCardBox: {
      marginBottom: theme.spacing(2),
      marginLeft: 0,
    },
    nestedFormCardDeleteIcon: {
      flexShrink: 0,
    },
    nestedFormCardHeading: {
      "& span": {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    nestedFormCardTitleRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    notes: {
      width: "100%",
    },
    outerContainer: {
      marginRight: "0px !important",
      marginLeft: "0px !important",
      marginTop: theme.spacing(3),
      width: "100%",
    },
    root: {
      margin: "26px",
      marginTop: theme.spacing(8),
    },
    saveBar: {
      backgroundColor: UI_COLORS.black_50,
      borderBottom: "2px solid #EDEDED",
      marginBottom: "90px",
      position: "sticky",
      top: "64px",
      zIndex: 1100,
      width: "100%",
    },
    title: {
      display: "inline",
      fontFamily: "Roboto",
      fontWeight: "bold",
      margin: theme.spacing(1),
      marginLeft: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    titleRightCol: {
      marginRight: theme.spacing(3),
      textAlign: "right",
    },
    deleteDocumentMenuLabel: {
      color: UI_COLORS.red_900,
    },
    iconButton: {
      marginBottom: theme.spacing(2),
      padding: "8px",
    },
    expandButton: {
      "marginBottom": theme.spacing(2),
      "padding": "8px 6px",
      "& svg": {
        fontSize: "24px",
      },
    },
    duplicateChip: {
      display: "inline-flex",
      backgroundColor: "#FA8072",
      color: theme.palette.common.black,
      fontSize: "11px",
      fontWeight: "normal",
      marginLeft: theme.spacing(3),
      marginBottom: "5px",
    },
    duplicateLinks: {
      marginLeft: theme.spacing(3),
    },
    duplicateLink: {
      "display": "inline",
      "textWrap": "nowrap",
      "marginRight": theme.spacing(1),

      "&:hover": {
        textDecoration: "none",
        cursor: "pointer",
      },
      "&:visited": {
        color: "#800080",
      },
    },
  })

export const requestFieldStyles = (theme: Theme) =>
  createStyles({
    checkbox: {
      height: "32px",
      marginLeft: "0px",
    },
    checkboxLabel: {
      fontSize: "14px",
    },
    disabled: {},
    filledInput: {
      "backgroundColor": "#F6F6F6",
      "paddingRight": "7px",
      "&$disabled": {
        backgroundColor: "#F6F6F6",
      },
      "&:hover": {
        backgroundColor: "#EBEBEB",
      },
      "&:focus": {
        backgroundColor: "#F6F6F6",
      },
      "&$focused": {
        backgroundColor: "transparent",
      },
      ":hover, :focus": {
        backgroundColor: "#EBEBEB",
      },
    },
    focused: {},
    formControl: {
      flexGrow: 1,
      margin: theme.spacing(1),
      minWidth: 150,
    },
    formControlFull: {
      border: 0,
      boxSizing: "border-box",
      flexGrow: 1,
      margin: "8px",
      width: "calc(100% - 16px)",
    },
    helperText: {
      backgroundColor: UI_COLORS.white,
      margin: 0,
      paddingLeft: "14px",
      paddingTop: "8px",
      width: "100%",
    },
    hiddenText: {
      padding: "0px 12px",
      marginLeft: "26px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      position: "absolute",
      top: "-1100px",
      left: "-1100px",
      zIndex: -1,
      opacity: 0,
      visibility: "hidden",
    },
    input: {
      "color": UI_COLORS.black,
      "fontSize": "14px",
      "minWidth": "105px",
      "paddingRight": "0px",
      "paddingBottom": "8px",
      "paddingTop": "23px",
      "&$disabled": {
        color: "#535453",
      },
      "&:hover, &:focus": {
        backgroundColor: "#EBEBEB",
      },
    },
    inputAdornment: {
      "backgroundColor": "#F6F6F6",
      "&$focused": {
        backgroundColor: "#F6F6F6",
      },
      "&:hover": {
        background: "#F6F6F6",
      },
      "&:hover, &:focus": {
        backgroundColor: "#F6F6F6",
      },
    },
    label: {
      fontSize: "14px",
      padding: "0px",
      margin: "0px",
      paddingBottom: "5px",
      marginTop: "-3px",
      width: "100%",
    },
    menu: {
      width: 200,
    },
    showAll: {
      "position": "absolute",
      "top": "14px",
      "right": "35px",
      "width": "0px",
      "height": "0px",
      "& svg": {
        fontSize: "16px",
        color: UI_COLORS.black_700,
        pointerEvents: "none",
      },
    },
    expandContainer: {
      position: "relative",
      width: "100%",
    },
    heightOverride: {
      "& textarea": {
        height: "19px !important",
        transition: "height .2s ease-in-out",
        position: "relative",
      },
    },
    expandAnimation: {
      "& textarea": {
        transition: "height .2s ease-in-out",
      },
    },
    multiline: {
      "backgroundColor": "#F6F6F6",
      "padding": "0",
      "margin": "0",
      "transition": "background .200ms cubic-bezier(0.0, 0, 0.2, 1)",
      "&:hover, &$focused": {
        "backgroundColor": "#EBEBEB",
        "& textarea": {
          backgroundColor: "#EBEBEB",
        },
      },
      "& textarea": {
        overflow: "hidden",
        padding: "23px 12px 0px 12px",
        marginBottom: "6px",
      },
      "&$disabled": {
        backgroundColor: "#F6F6F6",
      },
    },
    multiSelect: {
      "backgroundColor": "#F6F6F6",
      "border": "none",
      "color": UI_COLORS.black,
      "float": "left",
      "marginBottom": theme.spacing(1.5),
      "marginRight": theme.spacing(1),
      "&:active": {
        backgroundColor: "#F6F6F6",
      },
      "&$disabled": {
        color: "#535453",
      },
      "&:hover, &:focus": {
        backgroundColor: "#F6F6F6",
      },
      "&:focus": {
        backgroundColor: "#F6F6F6",
      },
    },
    multiSelectOuter: {
      paddingBottom: "0px",
      paddingRight: "8px",
    },
    numberInput: {
      "color": UI_COLORS.black,
      "fontSize": "14px",
      "minWidth": "120px",
      "paddingBottom": "8px",
      "paddingRight": "0px",
      "paddingTop": "23px",
      "&$disabled": {
        color: "#535453",
      },
    },
    readOnly: {
      "background": "rgba(255, 255, 255, 0.85)",
      "border": "none",
      "color": "#535453",
      "&:hover": {
        background: "rgba(255, 255, 255, 0.75)",
      },
    },
    selected: {
      "backgroundColor": theme.palette.primary.main,
      "color": UI_COLORS.white,
      "&:active": {
        backgroundColor: theme.palette.primary.main,
      },
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
      },
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    selectedOption: {
      backgroundColor: "#EBEBEB",
    },
    selectInput: {
      "background": "#F6F6F6",
      "color": UI_COLORS.black,
      "fontSize": "14px",
      "minWidth": "120px",
      "&:hover, &:focus": {
        backgroundColor: "#EBEBEB",
      },
      "paddingTop": "19px",
      "paddingBottom": "9px",
      "marginBottom": "0px",
      "paddingLeft": "9px",
    },
    selectAutoComplete: {
      "& label": {
        color: "#646464",
        fontSize: "15px",
        zIndex: 1,
      },
    },
    autoCompleteInputRoot: {
      "paddingTop": "0px !important",
      "paddingBottom": "0px !important",
      "fontSize": "14px",
      "&:after": {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        fontWeight: "bolder",
      },
      "&:before": {
        borderBottom: "1px solid #777879",
      },
    },
    autoCompleteInput: {
      paddingTop: "23px !important",
      paddingBottom: "8px !important",
    },
    autoCompleteListbox: {
      maxHeight: "176px !important", // limits height of dropdown to 5 items
    },
    autoCompletePopper: {
      zIndex: 1000,
    },
    selectLabel: {
      color: "#646464",
      paddingLeft: "12px",
      top: "-14px",
      paddingTop: "6px",
      paddingBottom: "10px",
      marginBottom: "5px",
      fontSize: "14px",
      zIndex: 1,
    },
    selectOptions: {
      "padding": "4px 16px",
      "margin": "0px",
      "color": UI_COLORS.black,
      "fontSize": "16px",
      "minWidth": "105px",
      "cursor": "pointer",

      "&$disabled": {
        color: "#535453",
      },
      "&:hover, &:focus": {
        backgroundColor: "#EBEBEB",
      },
      "fontFamily": BODY_FONT,
    },
    shrink: {
      top: "0px",
      left: "0px",
      fontSize: "11pt",
    },
    underline: {
      "&:after": {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        fontWeight: "bolder",
      },
      "&:before": {
        borderBottom: "1px solid #777879",
      },
    },
    verticalCenter: {
      position: "relative",
      top: "50%",
      transform: "translateY(-50%)",
    },
    editContactDetails: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(45),
      marginTop: theme.spacing(1),
    },
  })

export const tablePaginationActionsStyles = () =>
  createStyles({
    root: {
      width: "fit-content",
      paddingRight: "14px",
    },
  })

export const nestedCollectionStyles = (theme: Theme) =>
  createStyles({
    addChildButton: {
      marginBottom: theme.spacing(2),
    },
    divider: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
      width: "100%",
    },
  })

export const initializingStyles = (theme: Theme) =>
  createStyles({
    backdrop: {
      backgroundColor: theme.palette.background.default,
    },
  })

export const appMenuStyles = () =>
  createStyles({
    copyright: {
      fontWeight: 700,
    },
  })

const appBarSharedStyles = {
  root: {
    position: "sticky" as "sticky",
  },
  toolbar: {
    minHeight: "56px",
    paddingLeft: "13px",
    paddingRight: "26px",
  },
}

export const appBarBasicStyles = () =>
  createStyles({
    root: {
      ...appBarSharedStyles.root,
    },
    toolbar: {
      ...appBarSharedStyles.toolbar,
    },
  })

export const appBarWorkspaceStyles = (theme: Theme) =>
  createStyles({
    root: {
      ...appBarSharedStyles.root,
    },
    toolbar: {
      ...appBarSharedStyles.toolbar,
    },
    appBarContextMenu: {
      paddingLeft: "7px",
    },

    iconButton: {
      borderRadius: "10%",
      padding: "5px",
      marginRight: theme.spacing(1),
      border: `1px solid ${UI_COLORS.black_200}`,
    },
    updateButton: {
      "background": UI_COLORS.black_100,
      "textTransform": "none",
      "color": UI_COLORS.black_900,
      "marginRight": theme.spacing(1),
      "&$disabled": {
        backgroundColor: UI_COLORS.black_50,
        color: UI_COLORS.black_600,
      },
    },
    saveAsNewButton: {
      "textTransform": "none",
      "&$disabled": {
        backgroundColor: UI_COLORS.black_50,
        color: UI_COLORS.black_600,
      },
    },
    disabled: {},
  })

export const attachmentStyles = () =>
  createStyles({
    iconButton: {
      padding: 0,
    },
    loadingOverlay: {
      height: "93%",
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "10%",
    },
    loadingOpacity: {
      opacity: 0.3,
    },
  })

export const commentStyles = (theme: Theme) =>
  createStyles({
    commentText: {
      marginTop: theme.spacing(1),
    },
    listItem: {
      paddingRight: 0,
    },
    pre: {
      fontFamily: "inherit",
      margin: 0,
      overflowX: "auto",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
  })

export const searchDialogStyles = () =>
  createStyles({
    tab: {
      borderBottom: "1px solid grey",
    },
  })

// todo: this might need to be broken down
export const workspaceDashboardStyles = (theme: Theme) =>
  createStyles({
    workspaceContainer: {
      display: "flex",
    },
    railContainer: {
      boxShadow: "inset -1px 0px 0px rgba(33, 33, 33, 0.06)",
      background: UI_COLORS.white,
    },
    content: {
      height: "calc(100vh - 180px)",
      overflowY: "scroll",
      background: UI_COLORS.white,
      width: "100%",
    },
    contentScrollDisabled: {
      background: UI_COLORS.white,
      width: "100%",
    },
    table: {
      padding: theme.spacing(2),
    },
    slideOutPanel: {
      boxShadow: "inset -1px 0px 0px rgba(33, 33, 33, 0.06)",
      backgroundColor: "white",
    },
  })

export const actionBarStyles = (theme: Theme) =>
  createStyles({
    actionBarContainer: {
      boxShadow: "inset 0px -1px 0px rgba(33, 33, 33, 0.06)",
      alignItems: "center",
      padding: "12px 16px",
      display: "flex",
      background: UI_COLORS.white,
    },
    toggleButton: {
      color: UI_COLORS.black_700,
    },
    toggleButtonActive: {
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: `rgba(44, 101, 250, 0.1) !important`,
    },
  })

export const navigationRailStyles = () =>
  createStyles({
    flexContainer: {
      width: "72px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    iconButton: {
      width: "64px",
      height: "64px",
      borderRadius: "0px",
    },
    activeIconButton: {
      width: "64px",
      height: "64px",
      borderRadius: "0px",
      color: UI_COLORS.blue_900,
    },
    leftBorder: {
      position: "absolute",
      top: "0px",
      height: "48px",
      left: "-4px",
      marginTop: "8px",
      borderRadius: "5px",
      borderLeft: `4px solid ${UI_COLORS.blue_900}`,
    },
  })

export const searchBarStyles = (theme: Theme) =>
  createStyles({
    columnHeader: {
      fontWeight: "normal",
    },
    searchWrapper: {
      height: "64px",
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      boxShadow: "inset 0px -1px 0px rgba(33, 33, 33, 0.06)",
    },
    textFieldSearch: {
      width: "80%",
    },
  })

export const userAdminStyles = (theme: Theme) =>
  createStyles({
    form: {
      margin: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    typography: {
      padding: theme.spacing(2),
    },
    downloadTypography: {
      padding: theme.spacing(2),
    },
    loader: {
      display: "block",
      margin: "0 auto",
    },
    paper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
    tableContainer: { width: "100%" },
  })

export const pschemaEditorStyles = (theme: Theme) =>
  createStyles({
    button: {
      marginBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },
    paper: {
      marginLeft: theme.spacing(4),
      padding: theme.spacing(2),
    },
    textfield: {
      marginTop: theme.spacing(2),
    },
  })

export const fundAdminStyles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(4),
    },
    intro: {
      paddingBottom: "10px",
    },
    paper: {
      padding: theme.spacing(2),
      maxWidth: 400,
    },
    submit: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })

export const settingsEditorStyles = (theme: Theme) =>
  createStyles({
    ...formikEditorStyles,
    button: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
    },
    paper: {
      marginLeft: theme.spacing(4),
      padding: theme.spacing(2),
    },
    textfield: {
      marginTop: theme.spacing(2),
    },
  })
