import { cleanSearchValue } from "../../Workspaces/helpers"
import { makeStyles } from "@material-ui/core/styles"
import { useSchema } from "providers/SchemaProvider/SchemaContext"
import { workspaceManagementStyles } from "./styles"
import ContextMenu from "../ContextMenu/ContextMenu"
import Grid from "@material-ui/core/Grid"
import React, { FC, useState } from "react"
import SearchBar from "../SearchBar/SearchBar"
import sortBy from "lodash/sortBy"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(workspaceManagementStyles)

interface Props {
  onChangeSearchedValue: (searchValue: string) => void
  searchedValue: string
}

const WorkspaceManagement: FC<Props> = ({
  onChangeSearchedValue,
  searchedValue,
}) => {
  const classes = useStyles()
  const { baseView, uiViewsTable, selectViewById } = useSchema()
  const views = Object.values(uiViewsTable)

  const [searchToggledOn, setSearchToggledOn] = useState(false)
  const searchRegex = new RegExp(cleanSearchValue(searchedValue), "i")
  const filteredViews = views.filter((view) => searchRegex.test(view.name))

  return (
    <>
      <div>
        <SearchBar
          label="Workspaces"
          onChangeSearchValue={onChangeSearchedValue}
          onToggle={setSearchToggledOn}
          searchToggledOn={searchToggledOn || searchedValue !== ""}
          searchValue={searchedValue}
        />
        <div className={classes.sidebarContainer} role="listbox">
          {sortBy(filteredViews, "name").map((workspace) => {
            const isSelectedView = workspace.id === baseView.id
            return (
              <Grid
                item
                container
                alignItems="center"
                role="option"
                justify="space-between"
                className={`${classes.workspaceItem} ${
                  isSelectedView && classes.selectedItem
                }`}
                wrap="nowrap"
                key={`workspace-${workspace.id}`}
                onClick={() => selectViewById(`${workspace.id}`)}
              >
                <Typography className={classes.workspaceTitle} variant="body2">
                  {workspace.name}
                </Typography>
                {!workspace.default && <ContextMenu id={workspace.id} />}
              </Grid>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default WorkspaceManagement
