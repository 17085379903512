import { advancedFiltersStyles } from "./styles"
import { areExpressionsEqual } from "components/common/QueryFunctions/helpers"
import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { EmptyFilter } from "components/core/Workspaces/FilterChip/FilterChip"
import { Expression, Filter } from "components/common/TabularView/types"
import { expressionReducer } from "./reducer"
import { useWorkspaces } from "providers/WorkspacesProvider"
import ActionDialog from "components/core/ActionDialog/ActionDialog"
import AddFiltersButtonGroup from "./AddFilterButtonGroup"
import FilterGroup from "./FilterGroup"
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react"

const useStyles = makeStyles(advancedFiltersStyles)

export interface NewFilter {
  filter: EmptyFilter
  groupPosition: number[]
}

interface Props {
  expression?: Expression | null
  closeAdvancedFilters: () => void
}

const AdvancedFilters: FC<Props> = ({ expression, closeAdvancedFilters }) => {
  const classes = useStyles({})
  const { updateAdvancedFilters } = useWorkspaces()
  const [
    { expression: currentExpression, newFilter },
    send,
  ] = useReducer(expressionReducer, { expression })
  const [fieldOfOpenChip, setFieldOfOpenChip] = useState<string | null>(null)
  const [hasExpressionChanged, setHasExpressionChanged] = useState(false)
  const [confirmClearAll, setConfirmClearAll] = useState(false)
  const [confirmCancel, setConfirmCancel] = useState(false)

  useEffect(() => {
    setHasExpressionChanged(!areExpressionsEqual(expression, currentExpression))
  }, [expression, currentExpression])

  const cleanUp = () => send({ type: "CLEAN_UP" })
  const removeNewFilter = () => send({ type: "REMOVE_NEW_FILTER" })

  const addFilter = (groupPosition: number[] = []) => {
    send({ type: "ADD_FILTER", groupPosition })
    setFieldOfOpenChip("")
  }

  const addFilterGroup = (groupPosition: number[] = []) => {
    send({ type: "ADD_FILTER_GROUP", groupPosition })
    setFieldOfOpenChip("")
  }

  const deleteNewFilter = () => {
    removeNewFilter()
    setFieldOfOpenChip(null)
    cleanUp()
  }

  const updateFilter = (
    filter: Filter,
    previousField?: string | null,
    groupPosition: number[] = []
  ) => send({ type: "UPDATE_FILTER", filter, previousField, groupPosition })

  const deleteFilter = (filter: Filter, groupPosition?: number[]) => {
    updateFilter(
      {
        field: filter.field,
        operation: filter.operation,
        operands: null,
      },
      filter.field,
      groupPosition
    )
    cleanUp()
  }

  const toggleOperator = (groupPosition: number[] = []) =>
    send({ type: "TOGGLE_OPERATOR", groupPosition })

  const clearAllFilters = () => send({ type: "CLEAR_ALL_FILTERS" })

  const updateFieldOfOpenChip = (field: string) =>
    setFieldOfOpenChip((prev) => (prev === field ? null : field))

  const onUpdateFieldOfOpenChip = (field: string) => setFieldOfOpenChip(field)

  const getFieldsWithActiveFilters = useCallback(
    (expression?: Expression | null): Set<string> => {
      const fields: Set<string> = new Set()
      expression?.expressions?.forEach((expr) =>
        getFieldsWithActiveFilters(expr).forEach((f) => fields.add(f))
      )
      expression?.filters?.forEach((filt) => fields.add(filt.field))
      return fields
    },
    []
  )
  const fieldsWithActiveFilters = useMemo(
    () => getFieldsWithActiveFilters(currentExpression),
    [currentExpression, getFieldsWithActiveFilters]
  )

  return (
    <>
      <Grid container direction="column">
        <Grid
          alignItems="center"
          className={classes.headerContainer}
          container
          item
          justify="space-between"
        >
          <Typography variant="h6">Advanced Filters</Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              if (newFilter || hasExpressionChanged) {
                setConfirmCancel(true)
              } else {
                closeAdvancedFilters()
              }
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </Grid>
        {currentExpression ? (
          <div className={classes.contentContainer}>
            <FilterGroup
              expression={currentExpression}
              fieldsWithActiveFilters={fieldsWithActiveFilters}
              toggleOperator={toggleOperator}
              // Props for filter chips
              newFilter={newFilter}
              fieldOfOpenChip={fieldOfOpenChip}
              addFilter={addFilter}
              deleteNewFilter={deleteNewFilter}
              updateFieldOfOpenChip={updateFieldOfOpenChip}
              onCompleteEmptyChip={removeNewFilter}
              onUpdateFieldOfOpenChip={onUpdateFieldOfOpenChip}
              updateFilter={updateFilter}
              deleteFilter={deleteFilter}
              // Props for filter groups
              addFilterGroup={addFilterGroup}
            />
          </div>
        ) : (
          <Grid
            className={classes.emptyContentContainer}
            container
            item
            wrap="nowrap"
          >
            <Typography classes={{ root: classes.helperText }} variant="body2">
              Advanced Filters makes it easy to filter your data in a more
              complex way, so that you can display only the records that meet
              specified criteria. Using this space allows you to create filter
              expressions that combine 'AND' and 'OR' filter logic with filter
              groupings, and also nest filter groups within each other, to
              create versatile Workspaces views.
            </Typography>
            <AddFiltersButtonGroup
              level={0}
              disabled={!!newFilter}
              onAddFilterClick={() => addFilter()}
              onAddFilterGroupClick={() => addFilterGroup()}
            />
          </Grid>
        )}
        <Grid
          alignItems="center"
          className={classes.footerContainer}
          container
          item
          justify="flex-end"
        >
          <Button
            aria-label="clear-all"
            classes={{
              root: classes.clearAllButton,
            }}
            disableElevation
            onClick={() => {
              if (currentExpression) {
                setConfirmClearAll(true)
              }
            }}
            variant="text"
          >
            Clear All
          </Button>
          <Button
            aria-label="save"
            classes={{
              root: classes.saveButton,
            }}
            disabled={
              !(currentExpression && hasExpressionChanged && !newFilter)
            }
            disableElevation
            onClick={() => {
              if (currentExpression) {
                updateAdvancedFilters(currentExpression)
                closeAdvancedFilters()
              }
            }}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Grid>
      </Grid>
      {confirmClearAll && (
        <ActionDialog
          confirmButtonLabel="Clear All"
          descriptionMessage={
            <>
              This change cannot be reversed. Are you sure you want to clear all
              filters and filter groups you've added to the advanced filter
              expression?
            </>
          }
          dialogId="clear-all-dialog"
          headerMessage="Clear All Advanced Filters Elements"
          onClose={() => setConfirmClearAll(false)}
          onConfirm={() => {
            clearAllFilters()
            setConfirmClearAll(false)
          }}
          useWarningStyle
        />
      )}
      {confirmCancel && (
        <ActionDialog
          confirmButtonLabel="Close"
          descriptionMessage={
            <>
              Closing the advanced filter area will lose any changes you have
              made to your advanced filter expression but have not saved yet.
              Are you sure you want to close this area?
            </>
          }
          dialogId="cancel-dialog"
          headerMessage="Close Advanced Filter Area"
          onClose={() => setConfirmCancel(false)}
          onConfirm={() => {
            closeAdvancedFilters()
            setConfirmCancel(false)
          }}
          useWarningStyle
        />
      )}
    </>
  )
}

export default AdvancedFilters
