import { FieldDefinition, PschemaSettings } from "generated/api"

export const cleanSearchValue = (value: string): string => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
}

export const getCleanSearchTitle = (
  bailFundFields: Array<FieldDefinition>,
  settings: PschemaSettings
): string => {
  // remove the any special numeric characters from the title
  // We use this since there may be multiple cases ex. "Casenet Number #1" or "[#1]: Case Number"
  const titles = bailFundFields
    .filter((field) => settings.idSearchSlugs.includes(field.slug))
    .map((field) => field.title.replace(/[0-9#:[\]]/g, "").trim())
  const dupeRemovedTitles = Array.from(new Set(titles))
  return dupeRemovedTitles.join(" / ")
}
