import React, { FC } from "react"
import TextField from "@material-ui/core/TextField"

const FORM_VARIANT = "filled"

interface Props {
  slug: string
  label: string
  value: string
  path: string
  classes: Record<
    "formControlFull" | "input" | "readOnly" | "label" | "shrink",
    string
  >
}

const ReadOnlyTextField: FC<Props> = ({
  classes,
  label,
  slug,
  value,
  path,
}) => (
  <TextField
    className={classes.formControlFull}
    disabled
    id={path + slug}
    InputProps={{
      disableUnderline: true,
      readOnly: true,
      classes: { input: `${classes.input} ${classes.readOnly}` },
    }}
    InputLabelProps={{
      classes: {
        root: classes.label,
        shrink: classes.shrink,
      },
    }}
    key={slug}
    label={label}
    value={value}
    variant={FORM_VARIANT}
  />
)

export default ReadOnlyTextField
