import {
  DetailsPageDocumentFieldsFragment,
  Document,
  DocumentFieldInput,
} from "generated/api"

/**
 * ApiValueType is the type for values that are returned by the GQL Document
 * API. Since we express our `fieldsAsDict` field as a GenericScalar, which will
 * map to an any type in the generated TypeScript, we must re-impose the types
 * for our own purposes.
 */
export type FieldValueType = null | string | number | boolean | string[]

/**
 * UsefulDocument makes the backend Document type more fun to use from UI code.
 *
 * TODO: rename
 */
export type UsefulDocument = {
  fields: Map<string, FieldValueType>
  status: "saved" | "new" | "updated"
} & Pick<
  Document,
  "collectionId" | "id" | "created" | "updated" | "attachments"
>

/** Converts a GraphQL Document type to a UsefulDocument. */
export const toUseful = (
  document: Document | DetailsPageDocumentFieldsFragment
): UsefulDocument => {
  const fields = new Map<string, FieldValueType>(
    Object.entries(document.fieldsAsDict)
  )
  return {
    attachments: document.attachments,
    collectionId: document.collectionId,
    created: document.created,
    id: document.id,
    fields: fields,
    status: "saved",
    updated: document.updated,
  }
}

/** Translate "empty" values to null. */
export const maybeToEmpty = (v: any) => {
  if (v === null || v === undefined) {
    return null
  }
  if (typeof v === "string") {
    v = v.trim()
    return v.length === 0 ? null : v
  }
  if (typeof v === "number") {
    return isNaN(v) || v === Infinity ? null : v
  }
  if (typeof v === "boolean") {
    return v
  }
  if (Array.isArray(v)) {
    return v.length === 0 ? null : v
  }
  throw Error(`Unsupported type ${typeof v}: "${v}"`)
}

/**
 * Converts a standard JS Object to an array of DocumentFieldInput types.
 *
 * Values that are "empty" are converted to nulls using maybeToEmpty.
 * */
export const objectToFields = (obj: any): Array<DocumentFieldInput> =>
  Object.entries(obj).map((entry) => ({
    slug: entry[0],
    value: maybeToEmpty(entry[1]),
  }))
