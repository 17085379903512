import { KeyboardDatePicker } from "@material-ui/pickers"
import { makeStyles } from "@material-ui/core/styles"
import { OnChangeDateType, ValidateDateType } from "lib/forms/types"
import React, { FC } from "react"

import { requestFieldStyles } from "styles/theme"

const FORM_VARIANT = "filled"

const useStyles = makeStyles((theme) => requestFieldStyles(theme))

export interface Props {
  error: boolean
  helperText: string
  isDisabled: boolean
  label: string
  onChange: OnChangeDateType
  slug: string
  validationHandler: ValidateDateType
  value?: string | null
}

const DateInput: FC<Props> = ({
  error,
  helperText,
  isDisabled,
  label,
  onChange,
  slug,
  validationHandler,
  value,
}) => {
  const classes = useStyles()

  return (
    <KeyboardDatePicker
      autoOk
      className={classes.formControlFull}
      disabled={isDisabled}
      error={error}
      format="MM/DD/YYYY"
      helperText={helperText}
      InputLabelProps={{
        classes: {
          root: classes.label,
          shrink: classes.shrink,
        },
      }}
      InputProps={{
        classes: {
          disabled: classes.disabled,
          input: classes.input,
          root: classes.filledInput,
          underline: classes.underline,
        },
      }}
      inputVariant={FORM_VARIANT}
      KeyboardButtonProps={{
        "aria-label": `Change ${label}`,
      }}
      label={label}
      margin="normal"
      name={slug}
      onChange={(unused, value) => {
        onChange(slug, value)
        validationHandler(label, slug, value)
      }}
      value={value ? value : null}
    />
  )
}
export default DateInput
