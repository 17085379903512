import { convertBackendFormToUiSchema } from "lib/forms/pschemaFormToUiFormConverter"
import {
  createJSX,
  GeneratedFormState,
} from "lib/forms/formLayoutToJsxConverter"
import { Handlers, NestedFormProperties } from "lib/forms/types"
import { presentation_schema } from "generated/pschema"
import React from "react"

interface Props {
  allCardsExpanded: boolean
  descriptor: presentation_schema.Form
  handlers: Handlers
  nestedProperties: NestedFormProperties | null
  path: Array<string>
  state: GeneratedFormState
}

const FlexForm = ({
  allCardsExpanded,
  descriptor,
  handlers,
  nestedProperties,
  path,
  state,
}: Props) => {
  // The uiSchema changes only when the form layout changes, which is very
  // rare. Constructing the uiSchema requires a lot of work, so we memoize
  // it to avoid doing so on each keypress.
  const uiSchema = React.useMemo(
    () => convertBackendFormToUiSchema(descriptor),
    [descriptor]
  )
  return createJSX(
    state,
    handlers,
    uiSchema.form.layout,
    nestedProperties,
    path,
    allCardsExpanded
  )
}

export default FlexForm
