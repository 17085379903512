import { UiView, UiViewTable } from "components/common/TabularView/types"
import {
  useViewCreateMutation,
  useViewDeleteMutation,
  useViewUpdateMutation,
} from "generated/api"

import { convertUiViewToViewDefinitionInputType } from "lib/tableHelpers"
import { MessageType, useSnackbar } from "providers/Snackbar"
import { OperationResult } from "urql"
import { useCallback } from "react"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import { useCurrentUser } from "providers/CurrentUserProvider"
import { useHistory } from "react-router-dom"

// Urql's cache of ViewDefinitionOutputs will not be automatically
// invalidated on deletes because the API response does not contain
// an object of that type. Therefore we invalidate it explicitly so that
// any other dependent components will get a refreshed version from
// the provider of the list of views.
const INVALIDATE_VIEW_TYPES_ON_DELETION = ["ViewDefinitionOutput"]

export type UpdateViewType = "Rename" | "Update"

const useViewApi = (
  viewsTable: UiViewTable,
  defaultViewId: number,
  setIsHandlingApiCall: (isMutating: boolean) => void
) => {
  const { forceRefresh } = useCurrentUser()
  const { openSnackbar } = useSnackbar()
  const history = useHistory()
  const { collection, getUrlForWorkspace } = useCurrentCollection()
  const [, sendCreateViewMutation] = useViewCreateMutation()
  const [, sendUpdateViewMutation] = useViewUpdateMutation()
  const [, sendDeleteViewMutation] = useViewDeleteMutation()

  const pushViewIdToUrl = useCallback(
    (viewId: string) => {
      history.push(getUrlForWorkspace(viewId))
    },
    [getUrlForWorkspace, history]
  )

  const executeApi = useCallback(
    async <R, V>(
      apiCall: () => Promise<OperationResult<R, V>>,
      messages: { success: string; failure: string },
      onSuccess?: (opResult: OperationResult<R, V>) => void
    ) => {
      setIsHandlingApiCall(true)
      try {
        const res = await apiCall()

        if (res.error) {
          openSnackbar(
            `${messages.failure}: ${res.error.message}`,
            MessageType.Error
          )
        } else {
          forceRefresh()

          openSnackbar(messages.success)

          if (onSuccess) {
            onSuccess(res)
            return res
          }
        }
      } catch (e) {
        console.error(e)
        openSnackbar(messages.failure, MessageType.Error)
      } finally {
        setIsHandlingApiCall(false)
      }
    },
    [forceRefresh, openSnackbar, setIsHandlingApiCall]
  )

  const createViewHandler = useCallback(
    (view: UiView) => {
      const apiCall = () =>
        sendCreateViewMutation({
          collectionId: collection!.id,
          view: convertUiViewToViewDefinitionInputType(view),
        })

      const messages = {
        success: `${view.name} added to Workspaces`,
        failure: `Failed to save view ${view.name}`,
      }

      executeApi(apiCall, messages, ({ data }) => {
        pushViewIdToUrl(data!.createView!.view.id.toString())
      })
    },
    [collection, executeApi, pushViewIdToUrl, sendCreateViewMutation]
  )

  const updateViewHandler = useCallback(
    (view: UiView, updateViewType: UpdateViewType = "Update") => {
      const apiCall = () =>
        sendUpdateViewMutation({
          collectionId: collection!.id,
          viewId: view.id,
          view: convertUiViewToViewDefinitionInputType(view),
        })

      const messages = {
        success: `${view.name} has been updated`,
        failure: `Failed to update view ${view.name}`,
      }
      if (updateViewType === "Rename") {
        messages.success = `Workspace has been renamed to ${view.name}`
      }
      executeApi(apiCall, messages, () => {
        pushViewIdToUrl(view.id.toString())
      })
    },
    [collection, executeApi, pushViewIdToUrl, sendUpdateViewMutation]
  )

  const deleteViewHandler = useCallback(
    (viewId: string) => {
      const currentView = viewsTable[viewId]
      if (currentView.default) {
        openSnackbar("Default views cannot be deleted.", MessageType.Error)
        return
      }

      const apiCall = () =>
        sendDeleteViewMutation(
          {
            collectionId: collection!.id,
            viewId: currentView.id,
          },
          { additionalTypenames: INVALIDATE_VIEW_TYPES_ON_DELETION }
        )

      const messages = {
        success: `${currentView.name} has been deleted.`,
        failure: `Failed to delete view ${currentView.name}`,
      }

      executeApi(apiCall, messages, () => {
        pushViewIdToUrl(defaultViewId.toString())
      })
    },
    [
      collection,
      viewsTable,
      defaultViewId,
      executeApi,
      openSnackbar,
      pushViewIdToUrl,
      sendDeleteViewMutation,
    ]
  )

  return {
    createViewHandler,
    deleteViewHandler,
    pushViewIdToUrl,
    updateViewHandler,
  }
}

export default useViewApi
