import { OnChangeType } from "lib/forms/types"
import _ from "lodash"
import React, { FC, useEffect, useState } from "react"

import { maybeToEmpty } from "lib/apihelpers"
import { Row } from "components/common/TabularView/types"
import {
  UNSET_SELECT_EMDASH_LABEL,
  UNSET_SELECT_EMDASH_VALUE,
} from "lib/forms/constants"
import { useBasicQueryResult } from "providers/BasicQueryProvider"
import AttorneyInformationUi from "components/core/AttorneyInformation/AttorneyInformationUi"

type Props = {
  attorneyId: string | null
  isDisabled: boolean
  onChange: OnChangeType
}

const NO_ATTORNEY_SELECTED_CHOICE: Row = {
  id: 0,
  uuid: UNSET_SELECT_EMDASH_VALUE,
  name: UNSET_SELECT_EMDASH_LABEL,
  email: "",
  primary_phone: "",
  primary_phone_ext: "",
  second_phone: "",
  second_phone_ext: "",
  notes: "",
}

const SLUG_ATTORNEY_ID = "attorney_id"

const AttorneyInformation: FC<Props> = ({
  attorneyId,
  isDisabled,
  onChange,
}) => {
  const { rows } = useBasicQueryResult()

  // sorting first because null will be on the top of the list after ascending sort and then it will be easier to reject the nulls
  const attorneys = [
    NO_ATTORNEY_SELECTED_CHOICE,
    ..._.reject(
      _.sortBy(rows, (row) => row.name),
      (row) => maybeToEmpty(row.name) == null
    ),
  ]

  const [selectedAttorneyInfo, setSelectedAttorneyInfo] = useState<Row>(
    _.find(attorneys, {
      uuid: maybeToEmpty(attorneyId),
    }) || NO_ATTORNEY_SELECTED_CHOICE
  )

  useEffect(() => {
    setSelectedAttorneyInfo(
      _.find(attorneys, {
        uuid: maybeToEmpty(attorneyId),
      }) || NO_ATTORNEY_SELECTED_CHOICE
    )
  }, [attorneyId, attorneys])

  const handleChange = (documentId: string) => {
    onChange(SLUG_ATTORNEY_ID, documentId)
  }

  return (
    <AttorneyInformationUi
      attorneys={attorneys}
      isDisabled={isDisabled}
      onChange={handleChange}
      selectedAttorneyInfo={selectedAttorneyInfo}
    />
  )
}

export default AttorneyInformation
