export const updateArrayOrder = <T>(
  arr: readonly T[],
  source: number,
  destination: number
) => {
  if (source === destination) {
    return [...arr]
  }

  const left = arr.slice(0, source)
  const right = arr.slice(source + 1)
  const reorderedArr = [...left, ...right]

  const incomingField = arr[source]
  reorderedArr.splice(destination, 0, incomingField)

  return reorderedArr
}
