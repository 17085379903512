import { MenuItem } from "@material-ui/core"
import { QueryOperator } from "generated/api"
import React from "react"

export const getOperationDisplayName = (operation: QueryOperator) => {
  switch (operation) {
    case QueryOperator.IsTrue:
      return "Is Yes"
    case QueryOperator.IsFalse:
      return "Is No"
    case QueryOperator.Before:
      return "Is Before"
    case QueryOperator.Between:
      return "Is Between"
    case QueryOperator.After:
      return "Is After"
    case QueryOperator.IsEqualTo:
      return "Is Equal To"
    case QueryOperator.LessThan:
      return "Is Less Than"
    case QueryOperator.GreaterThan:
      return "Is Greater Than"
    case QueryOperator.IsExactly:
      return "Is Exactly"
    case QueryOperator.IsNotExactly:
      return "Is Not Exactly"
    case QueryOperator.Contains:
      return "Contains"
    case QueryOperator.DoesNotContain:
      return "Does Not Contain"
    case QueryOperator.IsEmpty:
      return "Is Empty"
    case QueryOperator.IsNotEmpty:
      return "Is Not Empty"
    case QueryOperator.StartsWith:
      return "Starts With"
    case QueryOperator.EndsWith:
      return "Ends With"
    case QueryOperator.OrderBy:
      throw Error(
        "Bug: OrderBy operations should not be present in selectable filters."
      )
  }
}

export const getOperationOptions = (
  operation: QueryOperator,
  classes: string
) => {
  return (
    <MenuItem className={classes} key={operation} value={String(operation)}>
      {getOperationDisplayName(operation)}
    </MenuItem>
  )
}
