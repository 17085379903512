import { makeStyles } from "@material-ui/core/styles"
import { OnChangeType } from "lib/forms/types"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import React from "react"

import { requestFieldStyles } from "styles/theme"

const useStyles = makeStyles((theme) => requestFieldStyles(theme))

interface Props {
  isDisabled: boolean
  label: string
  onChange: OnChangeType
  slug: string
  value: boolean
}

const BooleanCheckbox = ({
  isDisabled,
  label,
  onChange,
  slug,
  value,
}: Props) => {
  const classes = useStyles()
  const isChecked = value === true

  return (
    <FormControlLabel
      classes={{
        label: classes.checkboxLabel,
        root: classes.checkbox,
      }}
      control={
        <Checkbox
          checked={isChecked}
          color="primary"
          name={slug}
          onChange={(event) => onChange(slug, event.target.checked)}
        />
      }
      disabled={isDisabled}
      label={label}
    />
  )
}
export default BooleanCheckbox
