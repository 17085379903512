import { canAddDateOperand } from "./FilterMenu/helpers"
import { Filter } from "components/common/TabularView/types"
import { filterChipStyles } from "./styles"
import {
  formatDisplayDate,
  getOperationSymbol,
  requiredOperands,
} from "./helpers"
import { makeStyles } from "@material-ui/core/styles"
import { useSchema } from "providers/SchemaProvider/SchemaContext"
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded"
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded"
import Chip from "@material-ui/core/Chip"
import ClearIcon from "@material-ui/icons/Clear"
import FilterMenu from "./FilterMenu/FilterMenu"
import React, { useState } from "react"
import Tooltip from "@material-ui/core/Tooltip"

const useStyles = makeStyles(filterChipStyles)

export interface EmptyFilter {
  field: null
  operation: null
  operands: null
}

interface Props {
  active: boolean
  filter: Filter | EmptyFilter
  fieldsWithActiveFilters: Set<string>
  onClick: () => void
  onDelete: () => void
  onCompleteEmptyChip: () => void
  onUpdateFieldOfOpenChip: (field: string) => void
  updateViewFilters: (data: Filter, previousField?: string) => void
}

const getOverflowOperands = (operands: Array<String>) => {
  if (operands.length > 1) {
    return <span>&nbsp;{`+ ${operands.length - 1}`}</span>
  }
}

const FilterChip = ({
  active,
  filter,
  fieldsWithActiveFilters,
  onClick,
  onDelete,
  onCompleteEmptyChip,
  onUpdateFieldOfOpenChip,
  updateViewFilters,
}: Props) => {
  const classes = useStyles()
  const { uiFieldsTable } = useSchema()
  const fieldDisplayName =
    filter.field === null ? "" : uiFieldsTable.get(filter.field)!.title
  const fieldType =
    filter.field && (uiFieldsTable.get(filter.field)?.fieldType || "")
  const activeClass = active ? classes.activeChip : ""
  const [showTooltip, setShowTooltip] = useState(false)
  const [fieldsMenuOpen, setFieldsMenuOpen] = useState<boolean>(
    filter.field === null
  )

  const fieldRef = (node: any) => {
    if (node === null) {
      return
    }
    if (node.offsetWidth < node.scrollWidth) {
      setShowTooltip(true)
    }
  }

  const operandsRef = (node: any) => {
    if (node === null) {
      return
    }
    if (node.offsetWidth < node.scrollWidth) {
      setShowTooltip(true)
    }
  }

  const enumOperandsRef = (node: any) => {
    if (node === null) {
      return
    }
    if (node.offsetWidth < node.scrollWidth) {
      setShowTooltip(true)
    }
  }
  const getOperationText = () => {
    const operation = filter.operation
    switch (operation) {
      case "is_empty":
        return "Empty"
      case "is_not_empty":
        return "Not empty"
      case "is_true":
        return "Yes"
      case "is_false":
        return "No"
    }
  }

  const visibleOperands = (fieldType: string, filter: Filter) => {
    if (!filter.operands) {
      return
    }

    switch (true) {
      case filter.operands.length === 0: {
        return (
          <span className={classes.singleOperand} ref={operandsRef}>
            {getOperationText()}
          </span>
        )
      }
      case fieldType === "DOLLAR" && filter.operands.length >= 1: {
        return (
          <>
            <span ref={operandsRef}>${filter.operands.join(", $")}</span>
          </>
        )
      }
      case ["ENUM", "LIST"].includes(fieldType): {
        return (
          <>
            {filter.operands.length === 1 && (
              <span ref={enumOperandsRef} className={classes.firstOperand}>
                {filter.operands[0]}
              </span>
            )}

            {filter.operands.length > 1 && (
              <span className={classes.enumList}>
                <span ref={enumOperandsRef} className={classes.firstOperand}>
                  {filter.operands[0]}
                </span>
                <span className={classes.hiddenOperands}>
                  {getOverflowOperands(filter.operands)}
                </span>
              </span>
            )}
          </>
        )
      }
      case !canAddDateOperand(fieldType, filter) &&
        filter.operands.length >= 1: {
        return (
          <>
            <span ref={operandsRef}>{filter.operands.join(", ")}</span>
          </>
        )
      }
      case canAddDateOperand(fieldType, filter) &&
        filter.operands.length === 1: {
        return (
          <>
            <span ref={operandsRef}>
              {formatDisplayDate(filter.operands[0])}
            </span>
          </>
        )
      }
      case canAddDateOperand(fieldType, filter) &&
        filter.operands.length === 2: {
        return (
          <>
            <span ref={operandsRef}>
              {formatDisplayDate(filter.operands[0])}
              {" — "}
              {formatDisplayDate(filter.operands[1])}
            </span>
          </>
        )
      }
    }
  }
  const getChipFieldAndOperation = () => {
    if (filter.field === null) {
      return <>Add New Filter</>
    } else {
      const operationSymbol =
        filter.operands !== null &&
        (!requiredOperands(filter) ||
          (requiredOperands(filter) &&
            filter.operands[0] !== "" &&
            filter.operands.length > 0)) &&
        getOperationSymbol(filter)

      return (
        <>
          <span>
            {fieldDisplayName}
            {operationSymbol}
            &nbsp;
          </span>
        </>
      )
    }
  }

  const fieldText = (
    <span className={classes.fieldTitleLabel} ref={fieldRef}>
      {getChipFieldAndOperation()}
    </span>
  )

  const operandsText = (
    <>
      {filter.field !== null && fieldType && (
        <span className={classes.fieldVisibleOperands}>
          {visibleOperands(fieldType, filter)}
        </span>
      )}
    </>
  )

  const getTooltipText = () => {
    return (
      <>
        {getChipFieldAndOperation()}
        {fieldType === "DOLLAR" &&
          ("$" + filter?.operands?.join(", $") || getOperationText())}

        {fieldType &&
          fieldType !== "DOLLAR" &&
          (filter?.operands?.join(", ") || getOperationText())}
      </>
    )
  }
  const chipText = (
    <>
      {(filter.operands && filter.operands.length > 1) ||
      (filter.field !== null && fieldType && showTooltip) ? (
        <Tooltip placement="top" title={getTooltipText()}>
          <span className={classes.chipTextWrapper}>
            {fieldText}
            {operandsText}
          </span>
        </Tooltip>
      ) : (
        <span className={classes.chipTextWrapper}>
          {fieldText}
          {operandsText}
        </span>
      )}
    </>
  )

  return (
    <div className={classes.filterChipWrapper}>
      <li className={classes.filterChipList}>
        <Chip
          classes={{
            root: `${classes.root} ${activeClass}`,
            icon: classes.icon,
            deleteIcon: classes.deleteIcon,
            label: classes.label,
          }}
          clickable
          label={chipText}
          onClick={() => {
            setFieldsMenuOpen(false)
            onClick()
          }}
          onDelete={onDelete}
          size="small"
          variant="outlined"
          icon={
            active ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />
          }
          deleteIcon={<ClearIcon />}
        />
      </li>
      {active && (
        <FilterMenu
          validOrEmptyFilter={filter}
          openFieldsMenu={fieldsMenuOpen}
          fieldsWithActiveFilters={fieldsWithActiveFilters}
          onCloseFieldsMenu={() => setFieldsMenuOpen(false)}
          onCompleteEmptyChip={onCompleteEmptyChip}
          onUpdateFieldOfOpenChip={onUpdateFieldOfOpenChip}
          updateViewFilters={updateViewFilters}
        />
      )}
    </div>
  )
}

export default FilterChip
