import { detailPageStyles } from "styles/theme"
import { LayoutType, NestedFormProperties } from "lib/forms/types"
import { makeStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Box from "@material-ui/core/Box"
import DeleteIcon from "@material-ui/icons/Delete"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import React, { FC, useEffect } from "react"
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(detailPageStyles)

interface Props {
  allCardsExpanded: boolean
  label?: string
  nestedProperties: NestedFormProperties | null
  slug: string
  type: LayoutType
}

const LayoutElementFactory: FC<Props> = ({
  allCardsExpanded,
  children,
  label,
  nestedProperties,
  slug,
  type,
}) => {
  const classes = useStyles()
  const [isExpanded, setExpanded] = React.useState(allCardsExpanded)
  const labelId = label?.replace(/\s+/g, "-").toLowerCase() || ""

  useEffect(() => {
    setExpanded(allCardsExpanded)
  }, [allCardsExpanded])

  const handleChange = () => {
    setExpanded((prev) => !prev)
  }

  switch (type) {
    case LayoutType.Columns:
      return (
        <Grid container key={slug + "_grid_container"}>
          {children}
        </Grid>
      )
    case LayoutType.Column:
      return (
        <Grid
          className={classes.horizontalCenter}
          item
          key={slug + "_grid_item"}
          xs={true}
        >
          {children}
        </Grid>
      )
    case LayoutType.ExpansionPanel:
      if (nestedProperties) {
        return (
          <Box
            key={slug + "_nested_form_box"}
            className={classes.nestedFormCardBox}
          >
            <div className={classes.nestedFormCardTitleRow}>
              <Typography
                className={classes.nestedFormCardHeading}
                variant="subtitle1"
              >
                {label}{" "}
                {nestedProperties.status && (
                  <span>({nestedProperties.status})</span>
                )}
              </Typography>
              <div className={classes.nestedFormCardDeleteIcon}>
                {nestedProperties.isDeleted && (
                  <Tooltip placement="top" title={"Restore Entry"}>
                    <IconButton
                      aria-label="delete"
                      onClick={nestedProperties.onRestoreDeleteChild}
                    >
                      <RestoreFromTrashIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!nestedProperties.isDeleted && (
                  <Tooltip placement="top" title={"Delete Entry"}>
                    <IconButton
                      aria-label="delete"
                      onClick={nestedProperties.onDeleteChild}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
            <Grid container>{children}</Grid>
          </Box>
        )
      }
      return (
        <Box
          key={slug + "_expansion_panel"}
          margin="12px 7px 24px 7px"
          minWidth="585px"
          padding="0px 5px"
        >
          <Accordion
            classes={{
              expanded: classes.expanded,
              root: classes.expansionPanel,
            }}
            id={labelId}
            expanded={isExpanded}
            onChange={handleChange}
          >
            <AccordionSummary
              classes={{
                root: classes.expansionPanelSummary,
                expanded: classes.expanded,
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <span className={classes.cardTitle}>{label}</span>
            </AccordionSummary>
            <AccordionDetails
              classes={{ root: classes.expansionPanelDetails }}
              data-testid={labelId}
            >
              <Grid container>{children}</Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      )
    case LayoutType.HorizontalRule:
      return (
        <Grid item className={classes.horizontalRule}>
          {label && (
            <Typography
              variant="subtitle2"
              className={classes.horizontalRuleTypography}
            >
              {label}
            </Typography>
          )}
          <Divider className={classes.horizontalRuleDivider} />
        </Grid>
      )
  }
}

export default LayoutElementFactory
