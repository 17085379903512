import * as Sentry from "@sentry/react"
import { createBrowserHistory } from "history"
import { Route } from "react-router-dom"

import {
  ENVIRONMENT,
  SENTRY_DSN,
  SENTRY_ENABLED,
  SENTRY_RELEASE,
} from "lib/settings"

export const SentryRoute = Sentry.withSentryRouting(Route)

const history = createBrowserHistory()

export const initSentry = () => {
  if (SENTRY_ENABLED) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      release: SENTRY_RELEASE,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
        new Sentry.Replay({ maskAllText: true, blockAllMedia: true }),
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      // Filter out health endpoint
      tracesSampler: (samplingContext) => {
        if (samplingContext.transactionContext.name.includes("/health")) {
          return 0
        } else {
          return 0.8
        }
      },
    })
  }
}
