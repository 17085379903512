import { cleanSearchValue } from "../helpers"
import { columnManagementStyles } from "./styles"
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd"
import { makeStyles } from "@material-ui/core/styles"
import { useSchema } from "providers/SchemaProvider/SchemaContext"
import { useSnackbar } from "providers/Snackbar"
import { useWorkspaces } from "providers/WorkspacesProvider"
import DragIcon from "@material-ui/icons/DragHandle"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import React, { useMemo, useState } from "react"
import RootRef from "@material-ui/core/RootRef"
import SearchBar from "../SearchBar/SearchBar"
import sortBy from "lodash/sortBy"
import Switch from "@material-ui/core/Switch"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(columnManagementStyles)

export const getItemStyle = (
  _: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
): React.CSSProperties => ({
  userSelect: "none",
  ...draggableStyle,
})

interface Props {
  onChangeSearchedValue: (searchValue: string) => void
  searchedValue: string
}

const ColumnManagement = ({ onChangeSearchedValue, searchedValue }: Props) => {
  const classes = useStyles()
  const { uiFieldsTable } = useSchema()
  const {
    currentView,
    fieldsWithActiveFilters,
    reorderDataFields,
    toggleDataField,
  } = useWorkspaces()
  const { openSnackbar } = useSnackbar()

  const [searchToggledOn, setSearchToggledOn] = useState(false)
  const searchRegex = new RegExp(cleanSearchValue(searchedValue), "i")

  const selectedFields = currentView.query.fields.filter((field) => {
    return searchRegex.test(uiFieldsTable.get(field.slug)!.title)
  })

  const visibleFields = useMemo(
    () => new Set(currentView.query.fields.map((field) => field.slug)),
    [currentView.query.fields]
  )
  const unSelectedFieldsList = Array.from(uiFieldsTable.values()).filter(
    (field) => !visibleFields.has(field.slug) && searchRegex.test(field.title)
  )
  const unSelectedFields = sortBy(unSelectedFieldsList, "title")

  const handleDragEnd = (result: DropResult) => {
    if (result.destination) {
      reorderDataFields(result.source.index, result.destination.index)
    }
  }

  const handleToggle = (
    fieldSlug: string,
    toggleOn: boolean,
    fieldTitle?: string
  ) => {
    toggleDataField(fieldSlug, toggleOn)

    toggleOn === false
      ? openSnackbar(
          `${uiFieldsTable.get(fieldSlug)!.title} removed from ${
            currentView.name
          }`
        )
      : openSnackbar(`${fieldTitle} added to ${currentView.name}`)
  }

  const displaySelectedFieldToggle = (
    provided: DraggableProvided | null,
    fieldSlug: string
  ) => (
    <Grid item container justify="flex-end" wrap="nowrap" xs={3}>
      <Grid
        alignItems="flex-end"
        className={classes.switchGrid}
        container
        direction="column"
        justify="center"
      >
        <Switch
          classes={{
            track: classes.track,
            colorPrimary: classes.switch,
            disabled: classes.disabled,
            root: classes.switch,
          }}
          checked={true}
          disabled={fieldsWithActiveFilters.has(fieldSlug)}
          color="primary"
          onChange={() => handleToggle(fieldSlug, false)}
          size="small"
          value={fieldSlug}
        />
        <span className={classes.hidden} {...provided?.dragHandleProps} />
      </Grid>
    </Grid>
  )

  return (
    <>
      <SearchBar
        label="Columns"
        onChangeSearchValue={onChangeSearchedValue}
        onToggle={setSearchToggledOn}
        searchToggledOn={searchToggledOn || searchedValue !== ""}
        searchValue={searchedValue}
      />
      <div className={classes.columnsContainer}>
        <Typography variant="caption" className={classes.captionHeader}>
          Visible Columns ({selectedFields.length})
        </Typography>
        {searchedValue === "" ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <RootRef rootRef={provided.innerRef}>
                  <div>
                    {selectedFields.map((field, i) => {
                      return (
                        <Draggable
                          draggableId={field.slug}
                          index={i}
                          key={field.slug}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Grid
                                className={classes.container}
                                container
                                direction="row"
                                justify="space-between"
                                wrap="nowrap"
                              >
                                <Grid
                                  alignItems="center"
                                  container
                                  item
                                  justify="flex-start"
                                  wrap="nowrap"
                                >
                                  <IconButton
                                    size="medium"
                                    className={classes.dragHandle}
                                    {...provided.dragHandleProps}
                                  >
                                    <DragIcon />
                                  </IconButton>
                                  <Typography
                                    className={classes.fieldName}
                                    variant="body2"
                                  >
                                    {uiFieldsTable.get(field.slug)!.title}
                                  </Typography>
                                </Grid>
                                {displaySelectedFieldToggle(
                                  provided,
                                  field.slug
                                )}
                              </Grid>
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                </RootRef>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          selectedFields.map((field) => {
            return (
              <Grid
                className={classes.containerUnselected}
                container
                direction="row"
                justify="space-between"
                wrap="nowrap"
                key={field.slug}
              >
                <Grid alignItems="center" container item justify="flex-start">
                  <Typography variant="body2" className={classes.fieldName}>
                    {uiFieldsTable.get(field.slug)!.title}
                  </Typography>
                </Grid>
                {displaySelectedFieldToggle(null, field.slug)}
              </Grid>
            )
          })
        )}
        <Typography variant="caption" className={classes.captionHeader}>
          Hidden Columns ({unSelectedFields.length})
        </Typography>
        {unSelectedFields.map((field) => {
          return (
            <Grid
              className={classes.containerUnselected}
              container
              direction="row"
              justify="space-between"
              wrap="nowrap"
              key={field.slug}
            >
              <Grid alignItems="center" container item justify="flex-start">
                <Typography variant="body2" className={classes.fieldName}>
                  {field.title}
                </Typography>
              </Grid>
              <Grid item container justify="flex-end" wrap="nowrap" xs={3}>
                <Grid
                  alignItems="flex-end"
                  className={classes.switchGrid}
                  container
                  direction="column"
                  justify="center"
                >
                  <Switch
                    checked={false}
                    color="primary"
                    onChange={() => handleToggle(field.slug, true, field.title)}
                    size="small"
                    value={field}
                    classes={{ switchBase: classes.switchBaseUnselected }}
                  />
                  <span className={classes.hidden} />
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </div>
    </>
  )
}

export default ColumnManagement
