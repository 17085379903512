import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import React, { ChangeEvent } from "react"
import TabPanel from "components/common/TabPanel/TabPanel"
import TextField from "@material-ui/core/TextField"

interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onEnter: () => void
  query: string
  selectedTab: number
  tabIndex: number
  textContent: textContent
}

type textContent = {
  buttonLabel: string
  dialogContent: string
  inputLabel: string
}

const SearchPanel = ({
  onChange,
  onEnter,
  query,
  selectedTab,
  tabIndex,
  textContent,
}: Props) => (
  <TabPanel value={selectedTab} index={tabIndex}>
    <DialogContent>
      <DialogContentText>{textContent.dialogContent}</DialogContentText>
      <TextField
        fullWidth
        autoFocus={true}
        id={`searchPanel-input-${tabIndex}`}
        label={textContent.inputLabel}
        value={query}
        onChange={onChange}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onEnter()
          }
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={onEnter}>
        {textContent.buttonLabel}
      </Button>
    </DialogActions>
  </TabPanel>
)

export default SearchPanel
