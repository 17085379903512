import { bailFundAppStyles } from "styles/theme"
import { BailFundRoutes } from "components/routes/BailFundRoutes"
import { makeStyles } from "@material-ui/core/styles"
import { RequirePrivacyPolicyAgreement } from "components/core/RequirePrivacyPolicyAgreement/RequirePrivacyPolicyAgreement"
import AppMenu from "components/core/App/AppMenu"
import Grid from "@material-ui/core/Grid"
import React from "react"

const useStyles = makeStyles(bailFundAppStyles)

export const BailFundApp = () => (
  <RequirePrivacyPolicyAgreement>
    <BailFundAppUI />
  </RequirePrivacyPolicyAgreement>
)

const BailFundAppUI = function BailFundApp() {
  const classes = useStyles()

  return (
    <Grid className={classes.root} container wrap="nowrap" direction="column">
      <AppMenu />
      <Grid item>
        <BailFundRoutes />
      </Grid>
    </Grid>
  )
}
