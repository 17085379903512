import { currencyInputTheme } from "./CurrencyTheme"
import { FormControl, InputLabel } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { OnChangeType } from "lib/forms/types"
import NumberFormat from "react-number-format"
import React, { FC, useState } from "react"

const FORM_VARIANT = "filled"

const useStyles = makeStyles(() => currencyInputTheme())

interface Props {
  isDisabled: boolean
  label: string
  onChange: OnChangeType
  path: string
  slug: string
  value: number | string
}

const CurrencyInput: FC<Props> = ({
  isDisabled,
  label,
  onChange,
  path,
  slug,
  value,
}) => {
  const [inputFocused, setInputFocused] = useState(true)
  const classes = useStyles()
  const handleOnValueChange = (slug: string, value: number | undefined) => {
    if (value === undefined) {
      onChange(slug, "")
      return
    }
    onChange(slug, value)
  }
  const formControlClass = inputFocused
    ? classes.formControl
    : classes.formControlFocus

  return (
    <>
      <FormControl className={formControlClass} variant={FORM_VARIANT}>
        <InputLabel className={classes.label} shrink htmlFor={path + slug}>
          {label}
        </InputLabel>
        <NumberFormat
          allowLeadingZeros
          onFocus={() => setInputFocused(false)}
          onBlur={() => setInputFocused(true)}
          className={classes.input}
          disabled={isDisabled}
          decimalScale={2}
          fixedDecimalScale={inputFocused}
          value={value}
          thousandSeparator
          onValueChange={(values) => {
            handleOnValueChange(slug, values.floatValue)
          }}
        />
        {<span className={classes.currencyPrefix}>$</span>}
      </FormControl>
    </>
  )
}
export default CurrencyInput
