import {
  Collection,
  Organization,
  OrganizationLite,
  ViewDefinitionOutput,
} from "generated/api"
import { Collections } from "lib/Collections"

/** Computes the URL for a given view by ID. */
export const viewUrlById = (
  organization: Organization,
  collection: Collection,
  viewId: string | number
) => `/o/${organization.organizationId}/c/${collection.id}/w/${viewId}`

/** Computes the URL for a given document by ID. */
export const documentUrlById = (
  organization: Organization,
  collection: Collection,
  documentId: string
) => `/o/${organization.organizationId}/c/${collection.id}/d/${documentId}`

export const organizationUrl = (organization: OrganizationLite) => {
  const organizationId = organization.organizationId
  return `/o/${organizationId}`
}

/**
 * Finds a default landing page for an organization. This is usually a view
 * on bailrequests, but it could be from another collection too.
 */
export const findDefaultPath = (organization: Organization) => {
  const priority = [Collections.BAILREQUESTS, Collections.CONTACTS]
  for (const collectionSlug of priority) {
    const collection = organization.collections.find(
      (coll) => coll.slug === collectionSlug
    )
    if (collection) {
      const view = findDefaultView(collection.views)
      if (view) {
        return viewUrlById(organization, collection, view.id)
      }
    }
  }
  return null
}

/**
 * Finds a reasonable default view in an array of views. We first look for views
 * marked as default, but if that doesn't exist, any view will do!
 */
export const findDefaultView = (views: Array<ViewDefinitionOutput>) => {
  if (!views.length) {
    return null
  }
  return views.find((view) => view.default) || views[0]
}

/**
 * Finds a reasonable default view, or throws an exception if it cannot find one.
 */
export const findDefaultViewRequired = (views: Array<ViewDefinitionOutput>) => {
  const defaultView = findDefaultView(views)
  if (defaultView === null) {
    throw Error("unexpected: no default view available")
  }
  return defaultView
}
