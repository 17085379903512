import { CircularProgress } from "@material-ui/core"
import {
  DELETE_COMMENT_CONFIRMATION,
  getConfirmDeleteCommentMessage,
} from "components/core/CollectionEditor/FormikEditors/DialogMessagesGenerator"
import { MessageType, useSnackbar } from "providers/Snackbar"
import { toUseful, UsefulDocument } from "lib/apihelpers"
import { useAuth0 } from "@auth0/auth0-react"
import {
  useCommentArchiveMutation,
  useCommentCreateMutation,
  useCommentsForBailRequestQuery,
  useCommentUpdateMutation,
} from "generated/api"
import { useCommentsCollection } from "providers/CurrentOrganizationProvider"
import _ from "lodash"
import ActionDialog from "components/core/ActionDialog/ActionDialog"
import CommentsUi from "components/core/Comment/CommentsUi"
import React, { FC, useState } from "react"
interface Props {
  parentCollectionId: string
  documentId: string
}

/**
 * CommentsBuiltin encapsulates API interaction and UI for the self-contained
 * builtin widget on the Details page for Comments.
 */

export const CommentsBuiltin: FC<Props> = ({
  parentCollectionId,
  documentId,
}) => {
  const { user: currentUser } = useAuth0()
  const { openSnackbar } = useSnackbar()
  // TODO: replace with useCurrentCollection.
  const { id: commentsCollectionId } = useCommentsCollection()
  const [, sendCommentCreate] = useCommentCreateMutation()
  const [, sendCommentUpdate] = useCommentUpdateMutation()
  const [, sendCommentArchive] = useCommentArchiveMutation()
  const [documents] = useCommentsForBailRequestQuery({
    variables: {
      bailRequestCollectionId: parentCollectionId,
      commentsCollectionId: commentsCollectionId,
      bailRequestId: documentId,
    },
  })
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [commentToDelete, setCommentToDelete] = useState<UsefulDocument>()
  const handleDeleteComment = (comment: UsefulDocument) => {
    setCommentToDelete(comment)
    setIsDialogOpen(true)
  }

  const createComment = async (comment: string) => {
    if (_.isEmpty(comment)) {
      openSnackbar("Cannot save a blank comment.", MessageType.Error)
      return false
    }
    const response = await sendCommentCreate({
      collectionId: commentsCollectionId,
      bailRequestId: documentId,
      comment: comment,
      // TODO(ch3950): move these to BFA database
      email: currentUser?.email,
      picture: currentUser?.picture,
      name: currentUser?.name,
    })
    if (!response.error) {
      openSnackbar("Successfully added a comment.")
      return true
    }
    openSnackbar(`Failed to add comment: ${response.error}`, MessageType.Error)
    return false
  }
  const updateComment = async (comment: UsefulDocument) => {
    const response = await sendCommentUpdate({
      collectionId: commentsCollectionId,
      commentId: comment.id,
      comment: comment.fields.get("comment"),
    })
    if (!response.error) {
      openSnackbar("Successfully updated comment.")
    } else {
      openSnackbar(
        `Failed to update comment: ${response.error}`,
        MessageType.Error
      )
    }
  }
  const deleteComment = async () => {
    setIsDialogOpen(false)
    const response = await sendCommentArchive({
      collectionId: commentsCollectionId,
      commentId: commentToDelete!.id,
    })
    if (!response.error) {
      openSnackbar("Successfully deleted comment.")
    } else {
      openSnackbar(
        `Failed to delete comment: ${response.error}`,
        MessageType.Error
      )
    }
  }
  if (documents.fetching) {
    return <CircularProgress />
  }
  if (documents.error) {
    // TODO: better error handling!
    console.log(documents.error)
    return <div>Comments functionality is temporarily unavailable.</div>
  }
  const comments = (documents.data?.documents.documents || [])
    .map((edge) => toUseful(edge.document))
    .filter((doc) => doc.collectionId === commentsCollectionId)

  return (
    <>
      <CommentsUi
        comments={comments}
        createComment={createComment}
        currentUser={currentUser}
        deleteComment={handleDeleteComment}
        openSnackbar={openSnackbar}
        updateComment={updateComment}
      />
      {isDialogOpen ? (
        <ActionDialog
          confirmButtonLabel="Confirm"
          useWarningStyle
          descriptionMessage={getConfirmDeleteCommentMessage()}
          dialogId="confirming-delete-dialog"
          headerMessage={DELETE_COMMENT_CONFIRMATION}
          onClose={() => setIsDialogOpen(false)}
          onConfirm={deleteComment}
        />
      ) : null}
    </>
  )
}
