import { deflateRaw, inflateRaw } from "pako"
import { envelope_pb } from "generated/envelope.pb"
import base64 from "@protobufjs/base64"

// Envelope payloads over this size will be compressed. This value was chosen
// arbitrarily.
const COMPRESS_THRESHOLD_BYTES = 120

/**
 * Puts `payload` into a base64-encoded byte array along with a
 * non-cryptographic hash to verify integrity.
 *
 * The structure of the returned string is:
 *
 * ```
 * base64(Envelope(payload))
 * ```
 */
export const sealEnvelope = (payload: Uint8Array): string => {
  const maybeCompress = () => {
    if (payload.length < COMPRESS_THRESHOLD_BYTES) {
      return envelope_pb.Envelope.create({
        checksum: fnv32a(payload),
        compressed: false,
        payload: payload,
      })
    }
    // We use the deflateRaw/inflateRaw variants because they omit irrelevant
    // headers that increase the size of the payload.
    const compressed = deflateRaw(payload)
    return envelope_pb.Envelope.create({
      checksum: fnv32a(compressed),
      compressed: true,
      payload: compressed,
    })
  }

  const envelope = maybeCompress()
  const message = envelope_pb.Envelope.encode(envelope).finish()
  return base64.encode(message, 0, message.length)
}

/**
 * Extracts the payload from a base64-encoded envelope. Returns null if the
 * envelope's checksum does not match the contents.
 */
export const openEnvelope = (envelope: string): Uint8Array | null => {
  const buf = new Uint8Array(base64.length(envelope))
  try {
    base64.decode(envelope, buf, 0)
  } catch (e) {
    return null
  }
  try {
    const contents = envelope_pb.Envelope.decode(buf)
    if (contents.checksum !== fnv32a(contents.payload)) {
      return null
    }
    if (!contents.compressed) {
      return contents.payload
    }
    return inflateRaw(contents.payload)
  } catch (e) {
    return null
  }
}

/**
 * Hashes a Uint8Array using the FNV-32a algorithm.
 *
 * This was chosen for its concise implementation and performance on small
 * strings.
 */
const fnv32a = (arr: Uint8Array) => {
  let hval = 0x811c9dc5

  for (let i = 0, l = arr.length; i < l; i++) {
    hval ^= arr[i]
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24)
  }
  return hval >>> 0
}
