import {
  ERROR_DOCUMENT_NOT_FOUND,
  ERROR_DOCUMENT_NOT_FOUND_SUBMESSAGE,
} from "lib/errorMessages"
import { ErrorGeneric } from "components/common/errors/ErrorGeneric"
import React from "react"

export const ErrorDocumentNotFound = () => (
  <ErrorGeneric
    message={ERROR_DOCUMENT_NOT_FOUND}
    submessage={ERROR_DOCUMENT_NOT_FOUND_SUBMESSAGE}
  />
)
