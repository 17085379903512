import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

/**
 * Expose the URL fragment (Location.hash) as a state variable.
 *
 * Usage is similar to the standard useState() hook, with an additional function
 * returned to allow clearing of the value.
 *
 * Usage:
 * ```
 * const [value, setter, clear] = useHash()
 * ```
 *
 * Caller is responsible for encoding the value.
 */
export const useHash = () => {
  const history = useHistory()
  const [value, setValue] = useState(() => history.location.hash)

  useEffect(() => {
    const unsub = history.listen((loc) => {
      setValue((prev) => {
        if (prev === loc.hash) {
          return prev
        }
        return loc.hash
      })
    })
    return () => unsub()
  }, [history])

  const pushHash = useCallback(
    (newHash: string) => {
      if (newHash !== value) {
        history.push({ hash: newHash })
      }
    },
    [history, value]
  )

  const clearHash = useCallback(() => {
    if (value) {
      history.push({ hash: "" })
    }
  }, [history, value])

  return [value, pushHash, clearHash] as const
}
