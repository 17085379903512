import { createStyles, makeStyles } from "@material-ui/core/styles"

export const formikEditorStyles = {
  header: {
    margin: "0",
    padding: "10px 0",
  },
  headerWrapper: {
    margin: "10px 0 16px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  descriptor: {
    margin: "0 0 16px 0",
  },
  buttonWrapper: {
    "marginTop": "20px",
    "& button:first-child": {
      marginRight: "15px",
    },
  },
  instanceBox: {
    border: "1px solid blue",
  },
  paper: {
    padding: "20px",
    margin: "20px 0",
  },
  chip: {
    marginRight: "6px",
  },
  formCard: {
    padding: "20px",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column" as "column",
  },
}

const FormikEditorsStyles = () => createStyles(formikEditorStyles)

export default makeStyles(FormikEditorsStyles)
