import { detailPageStyles } from "styles/theme"
import {
  Handlers as FormHandlers,
  OnSaveBailRequestType,
} from "lib/forms/types"
import { GeneratedFormState } from "lib/forms/formLayoutToJsxConverter"
import { makeStyles } from "@material-ui/core/styles"
import { Popover } from "@material-ui/core"
import { presentation_schema } from "generated/pschema"
import { useCurrentCollection } from "providers/CurrentCollectionContext"
import Button from "@material-ui/core/Button"
import DuplicateRequests from "./DuplicateRequests"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import MoreHoriz from "@material-ui/icons/MoreHoriz"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import React, { FC, useState } from "react"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import UnfoldLessIcon from "@material-ui/icons/UnfoldLess"
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore"

import DeleteDocumentDialog from "components/core/DetailPage/DeleteDocumentDialog"
import FlexForm from "../FlexForm/FlexForm"

const useStyles = makeStyles((theme) => detailPageStyles(theme))

interface Props {
  allCardsExpanded: boolean
  disableSave: boolean
  documentId: string
  formDescriptor: presentation_schema.Form
  formHandlers: FormHandlers
  formPath: Array<string>
  formState: GeneratedFormState
  onExpandAllCards: () => void
  onSaveBailRequest: OnSaveBailRequestType
  onSavePDF: (() => Promise<void>) | null
  pageTitle: string
  saveChangesButtonLabel: string
}

const Detail: FC<Props> = (props) => {
  const {
    allCardsExpanded,
    disableSave,
    documentId,
    formDescriptor,
    formHandlers,
    formPath,
    formState,
    onExpandAllCards,
    onSaveBailRequest,
    onSavePDF,
    pageTitle,
    saveChangesButtonLabel,
  } = props

  const classes = useStyles()

  const { collection, getUrlForDocument } = useCurrentCollection()
  const shouldShowMoreOptions = documentId && collection.actions?.deleteDocument
  const [requestOptionsEl, setRequestOptionsEl] = useState<HTMLElement | null>(
    null
  )
  const duplicateFieldsChecked = collection.settings?.duplicateFieldsChecked

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const handleRequestOptionsClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => setRequestOptionsEl(e.currentTarget)
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }
  const handleRequestOptionsClose = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    const { action } = e.currentTarget.dataset
    switch (action) {
      case "delete":
        setOpenDeleteDialog(true)
        break
      default:
        break
    }
    setRequestOptionsEl(null)
  }
  return (
    <main>
      <Grid
        className={classes.outerContainer}
        container
        direction="column"
        spacing={2}
      >
        <Grid
          alignItems="center"
          className={classes.saveBar}
          container
          direction="row"
          item
          justify="space-between"
        >
          <Grid item xs>
            <Typography className={classes.title} variant="h5">
              {pageTitle}
            </Typography>
            {duplicateFieldsChecked?.length && (
              <DuplicateRequests
                collectionId={collection.id}
                documentId={documentId}
                urlParser={getUrlForDocument}
                duplicateFieldsChecked={duplicateFieldsChecked}
              />
            )}
          </Grid>
          <Grid className={classes.titleRightCol} item md={4}>
            {shouldShowMoreOptions && (
              <Tooltip title="More actions" placement="top">
                <IconButton
                  aria-label={`request-actions-menu`}
                  className={classes.iconButton}
                  id={`request-actions-menu`}
                  onClick={(e) => handleRequestOptionsClick(e)}
                >
                  <MoreHoriz />
                </IconButton>
              </Tooltip>
            )}
            <Popover
              id={`request-actions-menu-select`}
              anchorEl={requestOptionsEl}
              open={Boolean(requestOptionsEl)}
              onClose={handleRequestOptionsClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                data-action="delete"
                onClick={(e) => handleRequestOptionsClose(e)}
              >
                <Typography className={classes.deleteDocumentMenuLabel}>
                  Delete Request
                </Typography>
              </MenuItem>
            </Popover>
            <Tooltip
              title={
                allCardsExpanded ? "Collapse all cards" : "Expand all cards"
              }
              placement="top"
            >
              <IconButton
                className={classes.expandButton}
                aria-label={
                  allCardsExpanded ? "Collapse all cards" : "Expand all cards"
                }
                onClick={onExpandAllCards}
              >
                {allCardsExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
              </IconButton>
            </Tooltip>
            {onSavePDF && (
              <Tooltip title="Generate PDF" placement="top">
                <IconButton className={classes.iconButton} onClick={onSavePDF}>
                  <PictureAsPdfIcon />
                </IconButton>
              </Tooltip>
            )}
            <Button
              className={classes.button}
              color="primary"
              onClick={onSaveBailRequest}
              variant="contained"
              disabled={disableSave}
            >
              {saveChangesButtonLabel}
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.cardContainer}>
          <div className={classes.formJsxOuter}>
            <Grid container>
              <FlexForm
                state={formState}
                handlers={formHandlers}
                descriptor={formDescriptor}
                nestedProperties={null}
                path={formPath}
                allCardsExpanded={allCardsExpanded}
              />
            </Grid>
          </div>
        </Grid>
      </Grid>
      {shouldShowMoreOptions && documentId && openDeleteDialog && (
        <DeleteDocumentDialog
          documentId={documentId}
          handleCloseDialog={handleCloseDeleteDialog}
        />
      )}
    </main>
  )
}

export default Detail
