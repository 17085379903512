import { Chip, makeStyles } from "@material-ui/core"
import { filterChipStyles } from "./styles"
import ClearIcon from "@material-ui/icons/Clear"
import React, { FC } from "react"

const useStyles = makeStyles(filterChipStyles)

interface Props {
  label: string
  onDelete: () => void
}

const FilterChipLabelOnly: FC<Props> = ({ label, onDelete }) => {
  const classes = useStyles()
  return (
    <Chip
      classes={{
        root: classes.root,
        deleteIcon: classes.deleteIcon,
        label: classes.label,
      }}
      label={label}
      onDelete={onDelete}
      size="small"
      deleteIcon={<ClearIcon />}
    />
  )
}

export default FilterChipLabelOnly
