import React, { FC } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { searchBarStyles } from "styles/theme"

import CloseIcon from "@material-ui/icons/Close"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

interface Props {
  label: string
  onChangeSearchValue: (value: string) => void
  onToggle: (toggleState: boolean) => void
  searchToggledOn: boolean
  searchValue?: string
}

const useStyles = makeStyles(searchBarStyles)

const SearchBar: FC<Props> = ({
  label,
  onChangeSearchValue,
  onToggle,
  searchToggledOn,
  searchValue,
}) => {
  const classes = useStyles()

  if (searchToggledOn) {
    return (
      <Grid
        justify="space-between"
        alignItems="center"
        container
        className={classes.searchWrapper}
      >
        <TextField
          onChange={(event) => {
            onChangeSearchValue(event.target.value)
          }}
          value={searchValue}
          className={classes.textFieldSearch}
        />
        <IconButton
          onClick={() => {
            searchValue === "" ? onToggle(false) : onChangeSearchValue("")
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Grid>
    )
  } else {
    return (
      <Grid
        justify="space-between"
        alignItems="center"
        container
        className={classes.searchWrapper}
      >
        <Typography variant="h6" className={classes.columnHeader}>
          {label}
        </Typography>
        <IconButton onClick={() => onToggle(true)}>
          <SearchIcon />
        </IconButton>
      </Grid>
    )
  }
}

export default SearchBar
