import { createStyles, Theme } from "@material-ui/core/styles"
import { UI_COLORS } from "styles/theme"
import React from "react"

const commonButtonStyles: React.CSSProperties = {
  backgroundColor: UI_COLORS.white,
  borderColor: UI_COLORS.black_200,
  color: UI_COLORS.black_900,
  textTransform: "none",
}

export interface AdvancedFiltersStylesProps {
  lastElementInGroup?: boolean
  level?: number
}

const getAlternatingColor = (
  lightFirst: boolean,
  { level = 0 }: AdvancedFiltersStylesProps
) => {
  if (lightFirst) {
    return level % 2 === 0 ? UI_COLORS.white : UI_COLORS.black_200
  } else {
    return level % 2 === 0 ? UI_COLORS.black_200 : UI_COLORS.white
  }
}

const getFilterGroupSpacing = (theme: Theme) => theme.spacing(1)

export const advancedFiltersStyles = (theme: Theme) =>
  createStyles({
    headerContainer: {
      boxShadow: "inset 0px -1px 0px rgba(33, 33, 33, 0.06)",
      minHeight: "64px",
      padding: "16px",
    },
    footerContainer: {
      backgroundColor: UI_COLORS.white,
      bottom: 0,
      boxShadow: "inset 0px 1px 0px rgba(33, 33, 33, 0.06)",
      minHeight: "64px",
      left: 0,
      padding: "0 16px",
      position: "fixed",
      width: "100vw",
    },
    clearAllButton: {
      marginRight: theme.spacing(2),
      textTransform: "none",
    },
    saveButton: {
      "textTransform": "none",
      "&:disabled": {
        background: UI_COLORS.black_50,
        color: UI_COLORS.black_600,
      },
    },
    helperText: {
      color: UI_COLORS.black_700,
    },
    emptyContentContainer: {
      padding: "16px",
    },
    contentContainer: {
      overflowY: "scroll",
      height: "calc(100vh - 192px)",
    },
    filterGroupContainer: {
      width: "100%",
    },
    filterGroup: {
      "backgroundColor": (props) => getAlternatingColor(true, props),
      "borderRadius": "4px",
      "padding": "16px",
      "& > :not(:first-child)": {
        marginTop: getFilterGroupSpacing(theme),
      },
    },
    addFilterButton: {
      ...commonButtonStyles,
      "&:not(:first-child)": {
        marginLeft: theme.spacing(2),
      },
    },
    addFilterIconButton: {
      ...commonButtonStyles,
      "border": "1px solid",
      "borderRadius": "4px",
      "padding": "7px",
      "&:not(:first-child)": {
        marginLeft: theme.spacing(1),
      },
    },
    operatorButton: {
      ...commonButtonStyles,
      minWidth: "64px",
    },
    lineContainer: {
      display: "flex",
      minWidth: "80px",
    },
    verticalLine: {
      borderRight: (props) => `2px solid ${getAlternatingColor(false, props)}`,
      height: ({ lastElementInGroup = false }: AdvancedFiltersStylesProps) => {
        if (lastElementInGroup) {
          return `calc(50% + ${getFilterGroupSpacing(theme) + 3}px)`
        }
        return `calc(100% + ${getFilterGroupSpacing(theme) + 2}px)`
      },
      position: "relative",
      top: `-${getFilterGroupSpacing(theme) + 1}px`,
      width: "34px",
    },
    horizontalLine: {
      borderColor: (props) => `${getAlternatingColor(false, props)}`,
      borderStyle: "solid",
      borderWidth: "1px 0",
      height: "2px",
      position: "relative",
      top: "50%",
      width: "46px",
    },
  })
