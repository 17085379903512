import { BODY_FONT, UI_COLORS } from "styles/theme"
import { createStyles } from "@material-ui/core/styles"

export const FilterMenuStyles = () =>
  createStyles({
    filterContainer: {
      "width": "228px",
      "padding": "10px",
      "margin": "0px",
      "marginLeft": "4px",
      "display": "flex",
      "flexDirection": "column",
      "alignItems": "stretch",
      "fontFamily": "Roboto",
      "position": "absolute",
      "top": "40px",
      "left": "-4px",
      "background": "white",
      "borderRadius": "4px",
      "zIndex": 3,
      "boxShadow":
        "0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)",
      "& svg": {
        top: "10px",
        right: "11px",
        width: "22px",
        height: "22px",
      },
    },
    selectMenu: {
      width: "228px",
      maxHeight: "60%",
    },
    selectInput: {
      "background": "#F6F6F6",
      "color": UI_COLORS.black_900,
      "fontSize": "14px",
      "&$disabled": {
        color: "#535453",
      },
      "&:hover, &:focus": {
        backgroundColor: "#EBEBEB",
        borderRadius: "4px",
      },
      "marginBottom": "10px",
      "padding": "10px 16px",
      "maxWidth": "100%",
      "borderRadius": "4px",
    },
    selectOptions: {
      "padding": "6px 16px",
      "margin": "0px",
      "color": UI_COLORS.black_900,
      "fontSize": "14px",
      "cursor": "pointer",
      "&:hover, &:focus": {
        backgroundColor: "#EBEBEB",
      },
      "fontFamily": BODY_FONT,
    },
    selectedOption: {
      backgroundColor: "#EBEBEB",
    },
    filledInput: {
      "backgroundColor": "#F6F6F6",
      "borderRadius": "4px",
      "paddingRight": "7px",
      "&$disabled": {
        backgroundColor: "#F6F6F6",
      },
      "&:hover": {
        backgroundColor: "#EBEBEB",
      },
      "&:focus": {
        backgroundColor: "#F6F6F6",
      },
      "&$focused": {
        backgroundColor: "transparent",
      },
      ":hover, :focus": {
        backgroundColor: "#EBEBEB",
      },
    },
    textInput: {
      "backgroundColor": "#F6F6F6",
      "color": UI_COLORS.black,
      "fontSize": "14px",
      "height": "40px",
      "padding": "12px 16px",
      "borderRadius": "4px",
      "marginBottom": "10px",

      "&$disabled": {
        color: "#535453",
      },
      "&:hover, &:focus": {
        backgroundColor: "#EBEBEB",
      },
    },
    numberInput: {
      "backgroundColor": "#F6F6F6",
      "color": UI_COLORS.black_700,
      "fontSize": "14px",
      "height": "40px",
      "padding": "10px 16px",
      "marginBottom": "10px",
      "&$disabled": {
        color: "#535453",
      },
      "&:hover": {
        "backgroundColor": "#EBEBEB !important",
        "& *": {
          backgroundColor: "#EBEBEB !important",
        },
      },
      "&:focus": {
        "backgroundColor": "#F6F6F6",
        "& *": {
          backgroundColor: "#F6F6F6",
        },
      },
      "& input": {
        color: UI_COLORS.black,
        padding: "0px",
        backgroundColor: "#F6F6F6",
      },
      "& div": {
        padding: "0px",
      },
      "borderRadius": "4px",
    },
    helperText: {
      padding: "0 10px",
      margin: "0px",
      color: "#d32f2f",
    },
    multiSelectOptions: {
      paddingLeft: "28px",
      background: "white",
    },
    multiSelectInput: {
      "background": "#F6F6F6",
      "color": UI_COLORS.black_900,
      "fontSize": "14px",
      "borderRadius": "4px",
      "&$disabled": {
        color: "#535453",
      },
      "&:hover, &:focus": {
        backgroundColor: "#EBEBEB",
      },
      "padding": "12px 16px",
      "height": "40px",
      "marginBottom": "10px",
    },
    multiSelect: {
      paddingLeft: "18px",
      width: "228px",
    },
    multiSelectLabel: {
      fontSize: "14px",
    },
    operandContainer: {
      "borderRadius": "4px",
      "height": "40px",
      "cursor": "pointer",
      "padding": "12px 16px",
      "maxWidth": "208px",
      "overflow": "hidden",
      "textOverflow": "ellipsis",
      "whiteSpace": "nowrap",
      "background": "#F6F6F6",
      "position": "relative",
      "&:hover, &:focus": {
        backgroundColor: "#EBEBEB",
      },
      "marginBottom": "10px",

      "& span": {
        color: UI_COLORS.black_500,
        position: "absolute",
        top: "10px",
      },

      "& svg": {
        position: "absolute",
        top: "10px",
        right: "11px",
        width: "22px",
        height: "22px",
        color: UI_COLORS.black_700,
      },
    },
    dateRoot: {
      "padding": "0px",
      "& button": {
        padding: "0px",
      },
      "fontSize": "14px",
    },
    dateEntry: {
      "backgroundColor": "#F6F6F6",
      "&:hover, &:focus": {
        backgroundColor: "#EBEBEB",
      },
      "& button": {
        padding: "0px",
      },
      "width": "100%",
      "padding": "12px 12px 12px 16px",
      "marginBottom": "10px",
      "borderBottom": "none",
      "&:underline": {
        borderBottom: "none",
      },
      "borderRadius": "4px",
      "height": "40px",
    },
    resetFilterButton: {
      "textTransform": "none",
      "color": UI_COLORS.black_900,
      "alignSelf": "flex-end",
      "padding": "10px 16px",
      "height": "40px",
      "&:disabled": {
        background: "white",
        color: UI_COLORS.black_500,
      },
    },
    disabled: {},
    focused: {},

    selectAutoComplete: {
      borderRadius: "4px",
      marginBottom: "10px",
      fontSize: "14px",
      backgroundColor: "#F6F6F6",
    },
    autoCompleteInputRoot: {
      "borderRadius": "4px",
      "backgroundColor": "#F6F6F6",
      "padding": "0px !important",
      "fontSize": "14px",
      "&:after": {
        borderBottom: "0px",
        fontWeight: "bolder",
      },
      "&:before": {
        borderBottom: "0px",
      },
      "&:hover": {
        "backgroundColor": "#EBEBEB",
        "&:before": {
          borderBottom: "0px",
        },
      },
      "&:focus": {
        borderBottom: "0px",
        backgroundColor: "#F6F6F6 !important",
      },
    },
    autoCompleteInput: {
      "padding": "10px 27px 10px 16px !important",
      "borderRadius": "4px",
      "backgroundColor": "#F6F6F6",
      "&:hover": {
        backgroundColor: "#EBEBEB",
      },
      "&:focus": {
        "&:hover": {
          backgroundColor: "#F6F6F6 !important",
        },
      },
    },
    autoCompleteListbox: {
      paddingTop: "0",
      border: "none",
      boxShadow: "none",
    },
    autoCompletePopper: {
      "zIndex": 1000,
      "width": "228px !important",
      "borderRadius": "4px",
      "border": "0px",
      "borderBottom": "none",
      "boxShadow":
        "0px 10px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px 4px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)",
      "& div": {
        margin: 0,
      },
    },
    autoCompletePaper: {
      boxShadow: "none",
    },
    autoCompleteEndAdornment: {
      "right": "12px !important",
      "& button": {
        "&:hover": {
          backgroundColor: "#EBEBEB",
        },
      },
    },
  })
