import { actionBarStyles } from "styles/theme"
import { makeStyles } from "@material-ui/core/styles"
import FilterChipWrapper from "../FilterChipWrapper/FilterChipWrapper"
import React, { FC, MouseEvent } from "react"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import VerticalSplit from "@material-ui/icons/VerticalSplit"
import Visibility from "@material-ui/icons/Visibility"

type PanelMode = "workspaces" | "columns" | "closed"

interface Props {
  onChange: (event: MouseEvent<HTMLElement>, value: PanelMode) => void
  selectedView: string | null
  openAdvancedFilters: () => void
}

const useStyles = makeStyles(actionBarStyles)

const ActionBar: FC<Props> = ({
  onChange,
  selectedView,
  openAdvancedFilters,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.actionBarContainer}>
      <ToggleButtonGroup value={selectedView} exclusive onChange={onChange}>
        <ToggleButton
          classes={{
            root: classes.toggleButton,
            selected: classes.toggleButtonActive,
          }}
          value="workspaces"
          size="small"
          aria-label="workspace-filters"
        >
          <VerticalSplit />
        </ToggleButton>
        <ToggleButton
          classes={{
            root: classes.toggleButton,
            selected: classes.toggleButtonActive,
          }}
          value="columns"
          aria-label="workspace-columns"
          size="small"
        >
          <Visibility />
        </ToggleButton>
      </ToggleButtonGroup>
      <FilterChipWrapper openAdvancedFilters={openAdvancedFilters} />
    </div>
  )
}

export default ActionBar
